import * as React from 'react';
import './index.scss';
import Languages from 'src/platform/services/languages';
import Table, { ITableColumnConfig } from 'src/platform/components/table/table';
import HelperComponent from 'src/platform/classes/helper-component';
import ContextManagementController from 'src/platform/api/context-management/cm';
import { IGetLanguageListResponse } from 'src/platform/api/context-management/cm-interface';
import ContentDetails from './details/content-details';

interface IState {
  list: IGetLanguageListResponse[];
  isFirstInit: boolean;
  loaded: boolean,
  details: boolean,
  activeLanguage: string,
  activeLanguageName: string,
};

const {
  Languages_,
  Segment,
  SEO_code,
} = Languages.Translations;

class ManagementContent extends HelperComponent<{}, IState> {
  
  componentDidMount() {
    this.getList();
  }

  public state: IState = {
    list: [],
    isFirstInit: false,
    loaded: false,
    details: false,
    activeLanguage: '',
    activeLanguageName: '',
  }
  public config: Array<ITableColumnConfig<IGetLanguageListResponse>> = [
    {
      name: `${Segment}`,
      cell: (row: any, index: number) => {
        return (<div className="G-cursor" >
          {row.name}
        </div>);
      },
      style: { marginRight: "2%" },
    },
    {
      name: `${SEO_code}`,
      cell: (row: any, index: number) => {
        return (<div className="G-cursor" >
          {row.uniqueCode}
        </div>);
      },
      style: { marginRight: "2%" },
    },
  ]

  public openModal = (index: number) => {
    this.asyncSetState({ 
      modalIsOpenIndex: index,
     });
  }

  
  private getList = async (pageNum?: { selected: number }) => {

    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const res = await ContextManagementController.getList();
    if (res.success) {
      this.setState({
        loaded: true,
        isFirstInit: this.state.isFirstInit && !res.data
      })
    }
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
    if (res.success) {
      this.asyncSetState({
        list: res.data,
      })
    }
  }

  private toDetails = (row: IGetLanguageListResponse) => {
    this.asyncSetState({ 
      details: true,
      activeLanguage: row.uniqueCode,
      activeLanguageName: row.name,
     });
  }

  private back = () => {
    this.asyncSetState({ 
      details: false,
     });
  }

  public render() { 
    const { list, isFirstInit, loaded, details, activeLanguage, activeLanguageName } = this.state;

    return (
      <section className="P-managment-content"> 
        {!details ? <div className="G-block">
          <div className="P-title">
            <h6>{Languages_}</h6>
            {/* <button className="G-button" onClick={() => this.openModal(1)}>{Create}</button> */}
          </div>
          <Table
            columnConfig={this.config}
            data={list}
            isFirstInit={isFirstInit}
            loaded={loaded}
            onRowClick={(row) => {
              this.toDetails(row)
            }}
          />
        </div> : <ContentDetails activeLanguage={activeLanguage} activeLanguageName={activeLanguageName} back={this.back}/>}
      </section >
    );
  }
}

export default ManagementContent;