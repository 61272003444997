import { ListStatusEnum, MessageSubjectEnum, OrderSellerStatusEnum, SelectTimeRangeEnum, OrderAgentStatusEnum, OrderType, DataShowEnum, SearchUserTypeEnum, ChartsUserTypeEnum } from './../enums/enums';
import { IOptions } from '../components/select/Select';
import { UserTypeEnum, AccountTypeEnum, MessagesTypeEnum, DeviceTypeEnum, OperatorEnum } from 'src/platform/enums/enums';
import Languages from '../services/languages';

const {
  Today,
  Yesterday,
  Last_week,
  Last_month,
  Last_year,
  Custom_range,  
  Active,
  Blocked,
  Deactivated,
  Equal,
  Not_equal,
  Starts_with,
  Ends_with,
  Individuals,
  Corporate,
  All,
  All_sellers,
  All_devices,
  All_users,
  Sellers_,
  Agents_,
  All_messages,
  Important_messages,
  Pending,
  Agent_found,
  Agent_accepted,
  Agent_chosen,
  Guests,
  Accepted,
  Finished,
  Canceled,
  Rejected,
  Application_workflow,
  Suggestion_recommendations,
  Report_an_issue,
  Delivery,
  Self_delivery,
  Deleted,
  NotActive,
  Yearly,
  Monthly,
  INDIVIDUAL_CORPORATE_SELLER,
  CORPORATE_SELLER,
  INDIVIDUAL_SELLER,
} = Languages.Translations;

export const months = [
  'Jan', 'Feb',
  'Mar', 'Apr', 'May',
  'Jun', 'Jul', 'Aug',
  'Sep', 'Oct', 'Nov',
  'Dec',
];

export let sellerTypeOptions: Array<IOptions> = [
  { id: AccountTypeEnum.All, label: `${All_sellers}` },
  { id: AccountTypeEnum.Individual, label: `${Individuals}` },
  { id: AccountTypeEnum.Corporate, label: `${Corporate}` },
];

export let accountTypeOptions: Array<IOptions> = [
  { id: AccountTypeEnum.All, label: `${All}` },
  { id: AccountTypeEnum.Individual, label: `${Individuals}` },
  { id: AccountTypeEnum.Corporate, label: `${Corporate}` },
];

export let accountTypeSingleOptions: Array<IOptions> = [
  { id: AccountTypeEnum.Individual, label: `${Individuals}` },
  { id: AccountTypeEnum.Corporate, label: `${Corporate}` },
  { id: 3, label: `${Agents_}` },
];

export let dataShowEnumOptions: Array<IOptions> = [
  { id: DataShowEnum.Monthly, label: `${Monthly}` },
  { id: DataShowEnum.Yearly, label: `${Yearly}` },
];

export let selectTimeRangeOptions: Array<IOptions> = [
  { id: SelectTimeRangeEnum.Today, label: `${Today}` },
  { id: SelectTimeRangeEnum.Yesterday, label: `${Yesterday}` },
  { id: SelectTimeRangeEnum.Week, label: `${Last_week}` },
  { id: SelectTimeRangeEnum.Month, label: `${Last_month}` },
  { id: SelectTimeRangeEnum.Year, label: `${Last_year}` },
  { id: SelectTimeRangeEnum.CustomRange, label: `${Custom_range}`}, 
  { id: SelectTimeRangeEnum.All, label: `${All}`}
];

export let userTypeOptions: Array<IOptions> = [
  { id: UserTypeEnum.All, label: `${All_users}` },
  { id: UserTypeEnum.Sellers, label: `${Sellers_}` },
  { id: UserTypeEnum.Agents, label: `${Agents_}` },
  { id: UserTypeEnum.Guests, label: `${Guests}` },
];

export let userTypeOptionsPushNotifications: Array<IOptions> = [
  { id: UserTypeEnum.All, label: `${All_users}` },
  { id: UserTypeEnum.Sellers, label: `${Sellers_}` },
  { id: UserTypeEnum.Agents, label: `${Agents_}` },
];

export let userTypeOptionsDashboard: Array<IOptions> = [
  { id: UserTypeEnum.Sellers, label: `${Sellers_}` },
  { id: UserTypeEnum.Agents, label: `${Agents_}` },
];

export let userTypeNotificationsOptions: Array<IOptions> = [
  { id: SearchUserTypeEnum.INDIVIDUAL_CORPORATE_SELLER, label: `INDIVIDUAL_CORPORATE_SELLER` },
  { id: SearchUserTypeEnum.AGENT, label: `${Agents_}` },
  { id: SearchUserTypeEnum.CORPORATE_SELLER, label: `CORPORATE_SELLER` },
  { id: SearchUserTypeEnum.INDIVIDUAL_SELLER, label: `INDIVIDUAL_SELLER` },
  { id: SearchUserTypeEnum.INDIVIDUAL_SELLER_BUYER, label: `INDIVIDUAL_SELLER_BUYER` },
  { id: SearchUserTypeEnum.CORPORATE_SELLER_BUYER, label: `CORPORATE_SELLER_BUYER` },
  { id: SearchUserTypeEnum.ALL, label: `${All}` },
];

export let userTypeDashboardOptions: Array<IOptions> = [
  { id: ChartsUserTypeEnum.CORPORATE_SELLER, label: `${CORPORATE_SELLER}` },
  { id: ChartsUserTypeEnum.INDIVIDUAL_SELLER, label: `${INDIVIDUAL_SELLER}` },
  { id: ChartsUserTypeEnum.AGENT, label: `${Agents_}` },
  { id: ChartsUserTypeEnum.INDIVIDUAL_CORPORATE_SELLER, label: `${INDIVIDUAL_CORPORATE_SELLER}` },
];

export let messagesTypeOptions: Array<IOptions> = [
  { id: MessagesTypeEnum.All, label: `${All_messages}` },
  { id: MessagesTypeEnum.Important, label: `${Important_messages}` },
  // { id: MessagesTypeEnum.Unread, label: "Unread messages" },
];

export let messageSubjectOptions: Array<IOptions> = [
  { id: MessageSubjectEnum.ApplicationWorkflow, label: `${Application_workflow}` },
  { id: MessageSubjectEnum.SuggestionsAndRecommendations, label: `${Suggestion_recommendations}` },
  { id: MessageSubjectEnum.ReportAnIssue, label: `${Report_an_issue}` },
];

export let deviceTypeOptions: Array<IOptions> = [
  { id: DeviceTypeEnum.All, label: `${All_devices}` },
  { id: DeviceTypeEnum.Android, label: "Android" },
  { id: DeviceTypeEnum.iOS, label: "iOS" },
];

export let operatorOptions: IOptions[] = [
  { id: OperatorEnum.Equal, label: `${Equal}` },
  { id: OperatorEnum.NotEqual, label: `${Not_equal}` },
  { id: OperatorEnum.StartsWith, label: `${Starts_with}` },
  { id: OperatorEnum.EndsWith, label: `${Ends_with}` },
];

export let statusOptions = [
  { id: ListStatusEnum.Active, label: `${Active}` },
  { id: ListStatusEnum.Blocked, label: `${Blocked}` },
  { id: ListStatusEnum.Deactivated, label: `${Deactivated}` },
  { id: ListStatusEnum.DELETED, label: `${Deleted}` },
  { id: ListStatusEnum.NotActive, label: `${NotActive}` },
]
export let OrderSellerSearchStatusOptions = [
  { id: OrderSellerStatusEnum.PENDING, label: `${Pending}` },
  { id: OrderSellerStatusEnum.AGENT_FOUND, label: `${Agent_found}` },
  { id: OrderSellerStatusEnum.AGENT_REQUESTED, label: `${Agent_accepted}` },
  { id: OrderSellerStatusEnum.AGENT_CHOSEN, label: `${Agent_chosen}` },
];
export let OrderSellerStatusOptions = [
  { id: OrderSellerStatusEnum.PENDING, label: `${Pending}` },
  { id: OrderSellerStatusEnum.AGENT_FOUND, label: `${Agent_found}` },
  { id: OrderSellerStatusEnum.AGENT_IN_PLACE, label: "Agent In Place" },
  { id: OrderSellerStatusEnum.AGENT_REQUESTED, label: `${Agent_accepted}` },
  { id: OrderSellerStatusEnum.AGENT_CHOSEN, label: `${Agent_chosen}` },
  { id: OrderSellerStatusEnum.FINISHED, label: `${Finished}` },
  { id: OrderSellerStatusEnum.CANCELED, label: `${Canceled}` },
]
export let OrderAgentSearchStatusOptions = [
  { id: OrderAgentStatusEnum.PENDING, label: `${Pending}` },
  { id: OrderAgentStatusEnum.ACCEPTED, label: `${Accepted}` },
]

export let OrderAgentStatusOptions = [
  { id: OrderAgentStatusEnum.PENDING, label: `${Pending}` },
  { id: OrderAgentStatusEnum.ACCEPTED, label: `${Accepted}` },
  { id: OrderAgentStatusEnum.FINISHED, label: `${Finished}` },
  { id: OrderAgentStatusEnum.CANCELED, label: `${Canceled}` },
  { id: OrderAgentStatusEnum.REJECTED, label: `${Rejected}` },
]

export let OrderSellerHistoryStatusOptions = [
  { id: OrderSellerStatusEnum.FINISHED, label: `${Finished}` },
  { id: OrderSellerStatusEnum.CANCELED, label: `${Canceled}` },
]

export let OrderTypeOptions = [
  { id: OrderType.Delivery, label: `${Delivery}` },
  { id: OrderType.SelfDelivery, label: `${Self_delivery}` },
  { id: OrderType.Sellers, label: `${Sellers_}` },
]

export let OrderAgentHistoryStatusOptions = [
  { id: OrderAgentStatusEnum.FINISHED, label: `${Finished}` },
  { id: OrderAgentStatusEnum.CANCELED, label: `${Canceled}` },
  { id: OrderAgentStatusEnum.REJECTED, label: `${Rejected}` },
]