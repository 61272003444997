import React from "react";
import HelperPureComponent from 'src/platform/classes/helper-pure-component';
import "./index.scss";

export interface IOptions {
  id: string | number,
  label: string,
}

interface IProps {
  name?: string,
  placeholder?: string
  options: Array<IOptions>,
  value?: string | number,
  setFieldValue?: Function,
  width?: string,
  height?: string,
  className?: string,
};

interface IState {
  isOpen: boolean;
};

class Select extends HelperPureComponent<IProps, IState> {

  public state: IState = {
    isOpen: false
  }

  close = () => this.asyncSetState({ isOpen: false });
  open = () => this.asyncSetState({ isOpen: true });

  public handleClick = (value: string | number) => () => {
    this.setValue(value);
    this.close();
  };

  public selectedItem = () => {
    const { options, value } = this.props;
    return options.find(option => option.id === value);
  }
  
  public renderLabel = () => {
    return this.selectedItem()?.label || this.props.placeholder;
  };

  public renderOptions = () => {
    const { value } = this.props;

    return this.props.options.map(option => (

      <div
        className={`option ${option.id === value ? "option-active" : ""}`}
        key={option.id}
        onClick={this.handleClick(option.id)}
      >
        {option.label}
      </div>
    ));
  };

  public setValue = (value: string | number) => {
    this.props.setFieldValue(this.props.name, value);
  };

  render() {
    const { isOpen } = this.state;
    const { width, height, className } = this.props;
    return (
      <div className={`custom-select ${isOpen ? "open" : "closed"} ${className ? className : ""}`}>
        <div className="P-field" style={{ width, height }} onClick={isOpen ? this.close : this.open}>
          <div className={`label ${!!this.selectedItem() ? 'P-selected' : ''}`}>{this.renderLabel()}</div>
          <button
            className="G-cursor P-arrow" type="button">
            <i className={`icon-ic_arrowdown ${isOpen ? 'G-arrow-animation' : ''}`} />
          </button>
        </div>
        <div className={`options ${isOpen ? '' : 'hide'}`}>{this.renderOptions()}</div>
        {isOpen ? <div className="overlay" onClick={this.close} /> : null}
      </div>
    );
  }
}

export default Select;