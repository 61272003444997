import * as React from 'react';
import { NavLink, Link } from 'react-router-dom';
import RouteService from '../../services/routes';
import "./index.scss";
import HelperPureComponent from 'src/platform/classes/helper-pure-component';
import logo from "src/assets/images/Logo_tapon.svg";
import { ROUTES } from 'src/platform/constants/routes';
import Helper from 'src/platform/services/helper';

interface IState {
  activeToolbarIndex: number;
  sidebarIsOpen: boolean;
};

interface IProps {
  changeIsOpen: (value: boolean) => void;
};

class Sidebar extends HelperPureComponent<IProps, IState> {

  public state: IState = {
    activeToolbarIndex: null,
    sidebarIsOpen: false,
  }

  private toggleToolbar = (index: number) => {
    const { activeToolbarIndex } = this.state;
    this.openSidebar()
    this.asyncSetState({ activeToolbarIndex: activeToolbarIndex === index ? null : index })
  }

  private openSidebar = () => {
    this.asyncSetState({ sidebarIsOpen: false });
    this.props.changeIsOpen(false);
  }

  private closeSidebar = () => {
    this.asyncSetState({ sidebarIsOpen: true, activeToolbarIndex: null })
    this.props.changeIsOpen(true);
  }

  public render() {
    const { activeToolbarIndex , sidebarIsOpen} = this.state;

    return (
      <aside 
      className={!sidebarIsOpen ? 'P-sidebar G-block' : 'P-sidebar G-block P-sidebar-close'}>
        <div className="P-sidebar-margin"></div>
        <div className={!sidebarIsOpen ? 'P-sidebar-content' : 'P-sidebar-content P-sidebar-close'}>
          <div className="P-sidebar-header">
            {!Helper.role ? 
            // <Link to={ROUTES.dashboad} className={!sidebarIsOpen ? "G-logo" : "G-logo-small"} style={{ background: `url(${logo}) center/cover no-repeat` }} />
            <Link to={ROUTES.dashboad} className={!sidebarIsOpen ? "G-logo" : "G-logo-small"}>
              <img className={!sidebarIsOpen ? "G-logo" : "G-logo-small"} src={logo} alt=""/>
            </Link>
            : <div className={!sidebarIsOpen ? "G-logo" : "G-logo-small"}>
                <img className={!sidebarIsOpen ? "G-logo" : "G-logo-small"} src={logo} alt=""/>
              </div>}
            {!sidebarIsOpen ?
              <div onClick={this.closeSidebar} className="icon-ic_arrowleft G-cursor"></div> :
              <div onClick={this.openSidebar} className="icon-ic_arrowright G-cursor"></div>}
          </div>
          <div className="P-sidebar-navigator">
            {RouteService.sidebar.map((item, index) => (
              <div key={index}>
                {!item.nestedPages
                  ?
                  <NavLink className="P-sidebar-item G-flex" to={item.path} activeClassName="P-sidebar-navigator-active" title={`shift+${index + 1}`}>
                    <div className="G-flex G-align-center">
                      <i className={`${item.icon} P-icon`} />
                      <span className={!sidebarIsOpen ? "" : "G-display-none"}>{item.name}</span>
                    </div>
                  </NavLink>
                  :
                  <React.Fragment>
                    <div onClick={() => this.toggleToolbar(index)} className={`P-sidebar-item `}>
                      <div className="P-routePathBody">
                        <div>
                          <i className={`${item.icon} P-icon`} />
                          <span className={!sidebarIsOpen ? "" : "G-display-none"}>{item.name}</span>
                        </div>
                        {!sidebarIsOpen ? <i className={`icon-ic_arrowdown P-arrow ${activeToolbarIndex === index ? ' G-arrow-animation' : ''}`} /> : ""}
                      </div>
                    </div>
                    <div className={`P-nested-items-block`}
                      style={{ height: `${activeToolbarIndex === index ? item.nestedPages.length * 65 : 0}px` }}>
                      {item.nestedPages.map((item, index) => (
                        <NavLink key={index}
                          className="P-sidebar-item P-sidebar-nested-item"
                          to={item.path}
                          activeClassName="P-sidebar-navigator-active">
                          {item.name}
                        </NavLink>
                      ))}
                    </div>
                  </React.Fragment>
                }
              </div>
            ))}
          </div>
        </div>
      </aside>
    );
  }
}

export default Sidebar;