import * as React from 'react';
import './index.scss';
import Languages from 'src/platform/services/languages';
import HelperComponent from 'src/platform/classes/helper-component';

export interface TaxModalProps {
    closeModal: () => void;
    setTaxNumber: (id: string, tax: string) => void;
    taxNumber: string;
}

export interface TaxModalState {
    tax: string;
}

const {
    Save_changes,
    Company_tax_number,
    Cancel,
} = Languages.Translations


class TaxModal extends HelperComponent<TaxModalProps, TaxModalState> {
public state: TaxModalState = {
    tax: '',
}

componentDidMount() {
    this.setState({ tax: this.props.taxNumber })
}
    
public onInput = async (evt: React.SyntheticEvent<HTMLInputElement>) => {
    await this.asyncSetState({ tax: evt.currentTarget.value })
}

    render() {
        const { tax } = this.state;
        
        return (<div className="P-sign-out-modal P-block-modal">
            <h5 className="G-mb-2 P-title">{Company_tax_number}</h5>
            <input
            type="text"
            value={tax}
            onChange={this.onInput}/>
            <div className="G-flex P-buttons">
                <button className="G-mr-2 P-cancel" onClick={this.props.closeModal}>{Cancel}</button>
                <button className="G-button P-button" onClick={() => {
                    const URL = new URLSearchParams(window.location.search);
                    this.props.setTaxNumber(URL.get('id'), tax);
                }}>
                    {Save_changes}
                </button>
            </div>
        </div>);
    }
}

export default TaxModal;