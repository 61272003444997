import * as React from 'react';
import HelperComponent from 'src/platform/classes/helper-component';
import AgentOrderDetail from './AgentOrderDetail';
import AgentOrderDetailRight from './AgentOrderDetailRightPart';
import { RouteComponentProps } from 'react-router';
import './index.scss';
import Languages from 'src/platform/services/languages';

interface IState {
    activeId: number,
    ordersList: Array<any>,
}

const {
    no_search,
} = Languages.Translations

interface IProps extends RouteComponentProps {
    loaded: boolean,
    ordersList: Array<any>,
    loadMore: (orderNumber: number) => void,
    totalPages: number,
    isFirstInit: boolean,
    orderType: number,
    acceptOrder: (id: string) => void,
    openModal: (index: number, orderId: number) => void,
}


class AgentsOrdersDetails extends HelperComponent<IProps, IState> {

    public state: IState = {
        activeId: null,
        ordersList: [],
    }

    public inputTimer: any;

    public handleScroll = (e: any) => {
        if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight - 50 <= 0) {
            this.props.loadMore(this.props.orderType);
        }
    }

    componentDidMount() {
        const activeId = new URLSearchParams(this.props.location.search).get("activeOrderId")
        this.asyncSetState({ activeId: +activeId || (this.props.ordersList.length && this.props.ordersList[0].orderId), ordersList: this.props.ordersList });
    }

    componentDidUpdate(prevProps: IProps) {
        if (this.props.ordersList !== prevProps.ordersList) {
            this.asyncSetState({ ordersList: this.props.ordersList, });
            const existItem = this.props.ordersList.find(x => x.orderId === this.state.activeId);
            if (existItem) {
                this.asyncSetState({ activeId: existItem.orderId });
            } else {
                this.asyncSetState({ activeId: this.props.ordersList.length && this.props.ordersList[0].orderId });
            }
        }
    }

    public render() {
        
        const { orderType } = this.props;
        const { activeId, ordersList } = this.state;
        
        return (<section className="P-agent-orders-details G-flex">
            <div className="G-flex">
                <div className="P-left" onScroll={this.handleScroll}>
                    {ordersList.map((item, index) => {
                        return <AgentOrderDetail
                            key={index}
                            item={item}
                            makeActive={() => { this.asyncSetState({ activeId: item.orderId }) }}
                            activeId={activeId}
                        />
                    })}
                </div>
            </div>
            <div className="P-right">
            {ordersList.length ?
                <AgentOrderDetailRight
                    ordersList={this.state.ordersList}
                    acceptOrder={this.props.acceptOrder}
                    openModal={this.props.openModal}
                    orderType={orderType}
                    activeId={activeId || (ordersList.length && ordersList[0].orderId)} /> : <div className="G-flex G-justify-center P-empty"><h5>{no_search}</h5></div>}
            </div>
        </section>
        );
    }
}

export default AgentsOrdersDetails;