import * as React from 'react';
import './index.scss';
import HelperComponent from 'src/platform/classes/helper-component';
import Table, { ITableColumnConfig } from 'src/platform/components/table/table';
import { IGetOrganizationsListModelBody, IGetOrganizationsListRequestBody } from 'src/platform/api/donations/donations-interface';
import DonationsController from 'src/platform/api/donations/donations';
import Paging from 'src/platform/components/pagination/pagination';
import Languages from 'src/platform/services/languages';

export interface IProps {
  closeModal: () => void,
  projectList: Array<any>,
}

const {
  Close,
  Participants,
  Winner,
  Add_to_campaign,
  Add,
  Search,
  Name,
} = Languages.Translations;
class AddToCampaign extends HelperComponent<IProps> {

  public state: any = {
    page: 0,
    size: 8,
    totalPages: null,
    text: "",
    projectList: [],
    loaded: false,
    isFirstInit: true,
    languageName: "en",
    projectStateValue: 1,
    checked: null,
    startDate: null,
    amount: null,
  }

  private getProjectList = async (pageNum?: { selected: number }) => {

    const { page, size, languageName, text, projectStateValue } = this.state;

    const body: IGetOrganizationsListRequestBody = {
      page: pageNum ? pageNum.selected : page,
      size,
      languageName,
      text,
      projectStateValue,
    }

    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(async () => {
      window.dispatchEvent(new CustomEvent('loader', { detail: true }));
      const result = await DonationsController.getProjectList({
        page: pageNum ? pageNum.selected : page,
        size,
        languageName,
        body: body
      });
      this.setState({
        loaded: true,
        isFirstInit: this.state.isFirstInit && !result.data.content.length
      })
      window.dispatchEvent(new CustomEvent('loader', { detail: false }));

      if (result.success) {
        this.asyncSetState({
          projectList: result.data.content,
          totalPages: result.data.totalPages,
        });
      } 
    }, 300);

  };

  checkedProject = async (list: any) => {
    const {projectList} = this.props;
    const index = projectList.findIndex(item => item.projectId === list.projectId)
    if (index !== -1){
      projectList.splice(index, 1)
    } else projectList.push(list)
  }

  addCapmaign = async () => {
    this.props.closeModal()
  }

  public config: Array<ITableColumnConfig<IGetOrganizationsListModelBody>> = [
    {
      name: `${Name}`,
      cell: (row: IGetOrganizationsListModelBody) => {
        return (<div className="G-cursor G-flex G-align-center">
          <span className="G-flex G-flex-wrap G-break-word">
            {row.projectName}
          </span>
        </div>);
      },
      style: { marginRight: "2%", minWidth: 280 },
    },
    {
      name: `${Participants}`,
      cell: (row: IGetOrganizationsListModelBody) => {
        return (<div className="G-cursor" >
          {row.participation}
        </div>);
      },
      style: { marginRight: "2%" },
    },
    {
      name: `${Winner}`,
      cell: (row: IGetOrganizationsListModelBody) => {
        return (<div className="G-cursor">
          {row.winner}
        </div>);
      },
      style: { marginRight: "2%" },
    },
    {
      name: "",
      cell: (row: IGetOrganizationsListModelBody) => {
        return (<div>
          <input className="G-cursor P-checkmark" type="checkbox" 
            onClick={() => this.checkedProject(row)} defaultChecked={this.props.projectList.some(item => item.projectId === row.projectId)}/>
        </div>);
      },
      style: { marginLeft: "auto", width: 50 },
    },
  ]

  public inputTimer: any;
  public onSearch = async (evt: React.SyntheticEvent<HTMLInputElement>) => {

    await this.asyncSetState({ text: evt.currentTarget.value })
    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(() => {
      this.getProjectList()
    }, 300);
  }

  componentDidMount() {
    this.getProjectList();
  }

  render() {
    const {text, projectList, loaded, isFirstInit, totalPages} = this.state
    return (
      <section>
        <div className="G-flex G-justify-between">
          <h4>{Add_to_campaign}</h4>
          <input className="G-text-input G-mt-2 G-mb-2"
            placeholder={Search}
            type="text"
            value={text}
            onChange={this.onSearch}
          />
        </div>
        <div className="P-add-list">
          <Table columnConfig={this.config}
            data={projectList}
            isFirstInit={isFirstInit}
            loaded={loaded}
          />
        </div>
        <Paging
          totalPages={totalPages}
          onChange={this.getProjectList}
        />
        <div className="G-flex G-justify-between G-mt-3">
            <button className="G-mr-2 P-cancel" onClick={this.props.closeModal}>{Close}</button>
            <button className="G-button P-button" type="submit" onClick={() => {this.addCapmaign()}} >{Add}</button>
        </div>
      </section>
    )
  }
}

export default AddToCampaign;