 import * as React from 'react';
import { OrderAgentStatusEnum, ListStatusEnum, AccountTypeEnum, SelectTimeRangeEnum, UserTypeEnum, UserTypeSingleEnum, ProjectStatus } from 'src/platform/enums/enums';


enum OrderAgentStatusLangEnum {
  "Սպասման մեջ" = OrderAgentStatusEnum.PENDING,
  "Գործակալը գտնվեց" = OrderAgentStatusEnum.AGENT_FOUND,
  "Գործակալը տեղում է" = OrderAgentStatusEnum.AGENT_IN_PLACE,
  "Գործակալը հայտ է կատարել" = OrderAgentStatusEnum.AGENT_REQUESTED,
  "Գործակալն ընտրված է" = OrderAgentStatusEnum.AGENT_CHOSEN,
  "Ավարտված" = OrderAgentStatusEnum.FINISHED,
  "Չեղարկված" = OrderAgentStatusEnum.CANCELED,
  "Մերժված" = OrderAgentStatusEnum.REJECTED,
  "Գործակալն ընդունեց" = OrderAgentStatusEnum.ACCEPTED,
}

export enum OrderSellerStatusLangEnum {
  "Սպասման մեջ" = OrderAgentStatusEnum.PENDING,
  "Գործակալը գտնվեց" = OrderAgentStatusEnum.AGENT_FOUND,
  "Գործակալը տեղում է" = OrderAgentStatusEnum.AGENT_IN_PLACE,
  "Գործակալը հայտ է կատարել" = OrderAgentStatusEnum.AGENT_REQUESTED,
  "Գործակալն ընտրված է" = OrderAgentStatusEnum.AGENT_CHOSEN,
  "Ավարտված" = OrderAgentStatusEnum.FINISHED,
  "Չեղարկված" = OrderAgentStatusEnum.CANCELED,
  "Մերժված" = OrderAgentStatusEnum.REJECTED,
  "Գործակալն ընդունեց" = OrderAgentStatusEnum.ACCEPTED,
}
export enum ListStatusLangEnum {
  "Ակտիվ" = ListStatusEnum.Active,
  "Արգելափակված" = ListStatusEnum.Blocked,
  "Ապաակտիվացված" = ListStatusEnum.Deactivated,
  "Ջնջված" = ListStatusEnum.DELETED,
  "Ոչ ակտիվ" = ListStatusEnum.NotActive,
}
export enum AccountTypeLangEnum {
  "Անհատ" = AccountTypeEnum.Individual,
  "Կորպորատիվ" = AccountTypeEnum.Corporate,
  "Բոլորը" = AccountTypeEnum.All,
}

export enum SelectTimeRangeLangEnum {
  "Բոլորը" = SelectTimeRangeEnum.All,
  "Այսօր" = SelectTimeRangeEnum.Today,
  "Երեկ" = SelectTimeRangeEnum.Yesterday,
  "Շաբաթ" = SelectTimeRangeEnum.Week,
  "Ամիս" = SelectTimeRangeEnum.Month,
  "Տարի" = SelectTimeRangeEnum.Year,
  "Միջակայքը" = SelectTimeRangeEnum.CustomRange,
}

export enum UserTypeLangEnum {
  "Հանձնող" = UserTypeEnum.Sellers,
  "Գործակալ" = UserTypeEnum.Agents,
  "Հյուր" = UserTypeEnum.Guests,
  "Բոլորը" = UserTypeEnum.All,
}

export enum UserTypeSingleLangEnum {
  "Հանձնող" = UserTypeSingleEnum.Seller,
  "Գործակալ" = UserTypeSingleEnum.Agent,
  "Հյուր" = UserTypeSingleEnum.Guest,
}

export enum ProjectLangStatus {
  "Բաց" = ProjectStatus.Open,
  "Փակված" = ProjectStatus.Closed,
}

export default {
  enums: {
    OrderAgentStatusEnum  : OrderAgentStatusLangEnum,
    ListStatusEnum        : ListStatusLangEnum,
    AccountTypeEnum       : AccountTypeLangEnum,
    SelectTimeRangeEnum   : SelectTimeRangeLangEnum,
    UserTypeEnum          : UserTypeLangEnum,
    UserTypeSingleEnum    : UserTypeSingleLangEnum,
    ProjectStatus         : ProjectLangStatus,
    OrderSellerStatusEnum : OrderSellerStatusLangEnum,
  },
  Dashboard_                : "Կառավարման վահանակ",
  Transactions_             : "Գործարքներ",
  new_sellers               : "նոր հանձնողներ",
  new_user                  : "նոր օգտատերեր",
  new_orders                : "նոր հայտեր",
  orders_to_deliver         : "առաքման հայտեր",
  new_messages              : "նոր հաղորդագրություններ",
  donation_votings          : "նվիրատվության քվեարկություններ",
  Newset_support_message    : "Աջակցման հաղորդագրություն",
  List_is_empty             : "Ցանկը դատարկ է",
  Device_usage              : "Սարքի օգտագործում",
  iOs_devices               : "iOS սարքեր",
  Android_devices           : "Android սարքեր",
  Top_sellers               : "Լավագույն հանձնողներ",
  Individuals               : "Անհատ",
  Corporate                 : "Կորպորատիվ",
  Available_Time            : "Առկա ժամանակ",
  User_                     : "Օգտատեր",
  Question                  : "Հարց",
  Answer                    : "Պատասխան",
  Page_name                 : "PAGE NAME",
  User_name                 : <>Օգտատիրոջ <br/> անուն</>,
  Finished_orders           : <>Ավարտված <br/> հայտեր</>,
  Sold_Cardboard            : <>Հանձնած թղթի <br/> թափոն (կգ)</>,
  Donation_Amounts          : <>Նվիրատվության <br/> գումար</>,
  Top_agents                : "Լավագույն գործակալներ",
  Bought_Cardboard          : <>Գնված թղթի թափոն <br/> (կգ)</>,
  Total_earnings            : <>Ընդհանուր <br/> շահույթ</>,
  Finish_request            : "Վերջացրած հայց",
  Rejection_reason          : "Մերժման պատճառը",
  Сancellation_reason       : "Չեղարկման պատճառը",
  pending_sellers_requests  : "հանձնողների սպասման մեջ գտնվող հայտեր",
  completed_sellers_requests: "հանձնողների ավարտված հայտեր",
  pending_agents_requests   : "գործակալների սպասման մեջ գտնվող հայտեր",
  completed_agents_requests : "գործակալների ավարտված հայտեր",
  User_status_activity      : "User status activity",
  Languages_                : "Լեզուներ",
  Language_                 : "Լեզու",
  Resources_                : "Ռեսուրսներ",
  Today                     : "Այսօր",
  Yesterday                 : "Երեկ",
  Week                      : "Շաբաթ",
  Month                     : "Ամիս",
  Year                      : "Տարի",
  Last_week                 : "Նախորդ շաբաթ",
  Last_month                : "Նախորդ ամիս",
  Last_year                 : "Անցյալ տարի",
  Custom_range              : "Միջակայք",
  Start                     : "Սկիզբ",
  End                       : "Վերջ",
  Projects                  : "Նախագծեր",
  Winner                    : "Հաղթող",
  Winner_                   : "Հաղթող`",
  Customer                  : "Հաճախորդներ",
  Number_registered_customers:"Գրանցված հաճախորդների / գործակալների քանակ(հատ)",
  Sellers_                  : "Հանձնողներ",
  Seller_                   : "Հանձնող",
  Agents_                   : "Գործակալներ",
  Agent_                    : "Գործակալ",
  Search                    : "Որոնում…",
  Balance                   : "Մնացորդ",
  Seller_type               : "Հանձնողի տեսակ",
  Status                    : "Կարգավիճակ",
  Reset                     : "Զրոյացնել",
  Set                       : "Ավելացնել",
  Operator                  : "Օպերատոր",
  Equal                     : "Հավասար",
  Not_equal                 : "Ոչ հավասար",
  Starts_with               : "Սկսած",
  Ends_with                 : "Վերջացրած",
  Value                     : "Արժեք",
  Name                      : "Անուն",
  Gender                    : "Սեռ",
  Male                      : "Արական",
  Female                    : "Իգական",
  Segment                   : 'Սեգմենտ',
  Search_for_specific_user  : 'Որոնել կոնկրետ օգտատիրոջ',
  SEO_code                  : 'SEO կոդ',
  help_top                  : 'Cover picture',
  leaderboard_top           : 'Cover picture',
  projects_top              : 'Cover picture',
  about_app_top             : 'Cover picture',
  home_top                  : 'Cover picture',
  home_video                : 'About file',
  home_footer               : 'Footer file',
  home_page                 : 'Գլխավոր',
  about_app                 : "Հավելվածի մասին",
  projects                  : "Նախագծեր",
  leaderboard               : "Առաջատարների ցանկ",
  help                      : "Օգնություն",
  Customer_age_statistics   : "Հաճախորդի տարիքային վիճակագրություն",
  Content_management        : "Բովանդակության կառավարում",
  Media                     : "Մեդիա",
  Content                   : "Բովանդակություն",
  Faq                       : "Հաճախ տրվող հարցեր",
  Change                    : "Change",
  Phone_number              : "Հեռախոսահամար",
  General                   : "Հիմնական",
  Not_selected              : "Ընտրված չէ",
  Requests_                 : "Հայտեր",
  Requests_count            : "Հայտերի հաշվարկ",
  Waste_weight              : "Բաժանորդներից մթերված թղթի թափոնի քաշ (կգ)",
  Number_of_applications    : "Բաժանորդների կողմից բացված հայտերի քանակ (հատ)",
  Current_balance           : "Ընթացիկ հաշվեկշիռ",
  Donated_amount            : "Նվիրաբերված գումար",
  Donated_point             : "Նվիրաբերված միավորներ ",
  Collected_point           : "Հավաքված միավորներ ",
  Leaderboard_place         : "Դիրք",
  Leaderboard_              : "Առաջատարների ցանկ",
  Account_type              : "Օգտատիրոջ տեսակ",
  Account_settings          : "Օգտատիրոջ կարգավորումներ",
  Audience_count            : "Օգտատիրոջ քանակ",
  Registration_date         : "Գրանցման ամսաթիվը",
  Given_date                : "Տրման ամսաթիվը",
  Sent_date                 : "Ուղարկման ամսաթիվ",
  Creation_date             : <>ՍՏԵՂԾՄԱՆ <br/> ԱՄՍԱԹԻՎ</>,
  Creation_date_            : "Ստեղծման ամսաթիվ",
  Cardboard_balance         : "Թափոնի մնացորդ",
  Agent_cardboard_limits    : "Գործակալի թափոնի սահմանաչափ",
  INDIVIDUAL_SELLER         : "Individual seller",
  INDIVIDUAL_CORPORATE_SELLER: "Individual corporate seller",
  CORPORATE_SELLER          : "Corporate seller",
  Weight_limit              : "Միավորի սահմանաչափ",
  Minimum_limit             : "Նվազագույն շեմ",
  Negative_limit            : "Բացասական շեմ",
  Wallet_balance            : "Դրամապանակի մնացորդ",
  Wallet_                   : "Դրամապանակ",
  Total_Earnings            : "Ընդհանուր շահույթ",
  Earnings                  : "Շահույթ",
  Deals_count               : "Գործարքների հաշվարկ",
  Deals_                    : "Գործարքներ",
  Active                    : "Ակտիվ",
  Blocked                   : "Արգելափակված",
  Deleted                   : "Ջնջված",
  NotActive                 : "Ոչ ակտիվ",
  Yearly                    : "Տարեկան",
  Monthly                   : "Ամսական",
  Deactivated               : "Ապաակտիվացված",
  Block_user                : "Արգելափակել օգտատիրոջը",
  Activate_user             : "Ակտիվացնել օգտատիրոջը",
  Unblock_user              : "Հանել արգելափակումից",
  Block                     : "Արգելափակել",
  Unblock                   : "Հանել արգելափակումից",
  Are_you_sure_unblock      : "Դուք վստա՞հ եք, որ ցանկանում եք հանել արգելափակումից այս օգտագտիրոջը:",
  Are_you_sure_block        : "Դուք վստա՞հ եք, որ ցանկանում եք արգելափակել այս օգտագտիրոջը:",
  All                       : "Բոլորը",
  Order_                    : "Հայտ",
  Orders_                   : "Հայտեր",
  Sellers_orders            : "Հանձնողների հայտեր",
  Agents_orders             : "Գործակալների հայտեր",
  History                   : "Պատմություն",
  Search_name_or            : "Փնտրել անունը կամ #",
  Search_agent              : "Փնտրել գործակալին",
  Address                   : "Հասցե",
  Cardboard_weight          : "Թափոնի քաշ",
  Overloaded_hours          : "Ծանրաբեռնված ժամերը",
  With_weight               : "Թափոնի քաշ",
  Pending                   : "Սպասման մեջ",
  Agent_found               : "Գործակալը գտնվեց",
  Agent_accepted            : "Գործակալն ընդունեց",
  Agent_chosen              : "Գործակալն ընտրված է", 
  Self_delivery             : "Ինքնուրույն առաքում",
  Delivery                  : "Առաքում",
  Finished                  : "Ավարտված",
  Finished_                 : "Ավարտված`",
  Finish                    : "Ավարտել",
  Canceled                  : "Չեղարկված",
  Push_notifications        : "Ծանուցումներ",
  Push_notification_was_sent: "Ծանուցումը հաջողությամբ ուղարկվեց",
  Search_message            : "Որոնում…",
  User_type                 : "Օգտատիրոջ տեսակը",
  Device_type               : "Սարքի տեսակը",
  All_users                 : "Բոլոր օգտատերերը",
  All_devices               : "Բոլոր սարքերը",
  Deal_ID                   : "Գործարքի ID",
  Create                    : "Ստեղծել",
  Views_count               : "Դիտումների քանակ",
  Message_type              : "Հաղորդագրության տեսակ",
  Message                   : "Հաղորդագրություն",
  Notification_title        : "Ծանուցման վերնագիր",
  Notification_message      : "Ծանուցման հաղորդագրություն",
  Description               : "Նկարագրություն",
  Create_push_notifications : "Ստեղծել ծանուցում",
  Auditory                  : "Լսարան",
  Cancel                    : "Չեղարկել",
  All_sellers               : "Բոլոր հանձնողներ",
  Agent_name                : "Գործակալի անուն",
  Points_amount             : "Միավորների քանակ",
  Date_                     : "Ամսաթիվ",
  Settings_                 : "Կարգավորումներ",
  Map_radius                : "Քարտեզի շառավիղը",
  In_place_radius           : "Հաճախորդին հասնելու շառավիղը",
  Buyer_request_kg_limit    : "Գործակալի հայտ (կգ, սահմանաչափ)",
  kg                        : "կգ",
  km                        : "կմ",
  meters                    : "մետր",
  Accept                    : "Ընդունել",
  Accepted                  : "Հաստատված",
  Reject                    : "Մերժել",
  Rejected                  : "Մերժված",
  Location                  : "Գտնվելու վայր",
  Amount                    : "Գումար",
  User_details              : "Օգտատիրոջ տվյալներ",
  Personal_information      : "Անձնական տվյալներ",
  Order_information         : "Հայտի տեղեկատվություն",
  Order_start_date          : "Հայտի ընդունման ամսաթիվ՝",
  Start_date                : "Մեկնարկի ամսաթիվ",
  Agent_attached_date       : "Գործակալի կցման ամսաթիվ՝",
  Order_finish_date         : "Հայտի ավարտման ամսաթիվ՝",
  Order_count               : "Հայտի քանակ",
  Cardboard_apx_amount      : "Թղթի թափոնի մոտավոր քանակ՝",
  Cardboard_apx_weight      : "Թղթի թափոնի մոտավոր քաշ՝",
  Cardboard_actual_amount   : "Թղթի թափոնի փաստացի քանակ՝",
  Cardboard_actual_weight   : "Թղթի թափոնի փաստացի քաշ՝",
  Cardboard_price           : "Թղթի թափոնի արժեք ՝",
  Cardboard_price_          : "Թղթի թափոնի արժեք ՝",
  Details_                  : "Մանրամասներ",
  deals_done                : "ավարտված գործարքներ",
  sold_cardboard            : "հանձնաձ թղթի թափոն",
  total_donated_amount      : "ընդհանուր նվիրաբերված գումար",
  total_withdrawed_amount   : "դուրս բերված ընդհանուր քանակ",
  withdrawals               : "դուրսբերումներ",
  Cardboard_purchases       : "Թափոնի ձեռքբերումներ",
  Cardboard_delivery        : "Թափոնի առաքում",
  Feedbacks                 : "Հետադարձ կապ",
  Ratings                   : "Վարկանիշ",
  new_                      : "նոր",
  donations                 : "Նվիրատվություններ",
  Verified                  : "Հաստատված է",
  Not_verified              : "Հաստատված չէ",
  Add                       : "Ավելացնել",
  Points                    : "միավորներ",
  Point                     : "Միավոր",
  Save_changes              : "Պահպանել փոփոխությունները",
  Company_tax_number        : "Կազմակերպության ՀՎՀՀ",
  Status_period_individuals : "Կարգավիճակի ժամանակահատված անհատ հաճախորդների համար",
  Eco_statuses              : "Էկո կարգավիճակ",
  Eco_master                : "Էկո վարպետ",
  Eco_genius                : "Էկո հանճար",
  Eco_hero                  : "Էկո հերոս",
  Status_period_corporates  : "Կարգավիճակի ժամանակահատված կորպորատիվ հաճախորդների համար",
  Support_                  : "Աջակցություն",
  Support_messages          : "Աջակցության հաղորդագրություններ",
  Archive_                  : "Արխիվ",
  User_name_or_contacts     : "Անուն կամ տվյալներ",
  View_profile              : "Անձնական էջի դիտում",
  view_details              : "տեսնել մանրամասները",
  Send_notification         : "Ուղարկել ծանուցում",
  Mark_as_important         : "Նշել որպես կարևոր",
  Mark_as_unimportant       : "Նշել որպես ոչ կարևոր",
  Move_to_archive           : "Տեղափոխել արխիվ",
  Guests                    : "Հյուրեր",
  All_messages              : "Բոլոր հաղորդագրությունները",
  Important_messages        : "Կարևոր հաղորդագրություններ",
  Donations                 : "Նվիրատվություններ",
  Project                   : "Նախագծեր",
  Charity_campaigns         : "Հանրօգուտ ծրագրեր",
  Participated_in_campaigns : "Մասնակցություն ծրագրերին",
  Winner_of_campaign        : "Հաղթող" ,
  Campaign                  : "Ծրագիր №",
  Campaigns_detail          : "Հանրօգուտ ծրագրի մանրամասներ",
  Current_campaign          : "Ընթացիկ հանրօգուտ ծրագիր",
  Previous_campaigns        : "Ավարտված հանրօգուտ ծրագրեր",
  Date_created              : "Ստեղծման ամսաթիվ",
  Open                      : "Բացել",
  Close                     : "Փակել",
  Edit                      : "Խմբագրել",
  Delete                    : "Ջնջել",
  Delete_project            : "Ջնջել նախագիծը",
  Are_you_sure              : "Համոզվա՞ծ եք, որ ցանկանում եք ջնջել:",
  Are_you_sure_cancel       : "Համոզվա՞ծ եք, որ ցանկանում եք չեղարկել պատվերը:",
  Create_project            : "Ստեղծել նախագիծ",
  days                      : "օրեր",
  Cardboard_price_for1kg    : "Թղթի թափոնը արժեքը (1 կգ-ի դիմաց)",
  Cardboard_price_delivery  : "Թղթի թափոնի արժեքը առաքման համար",
  Cardboard_price_agents    : "Թղթի թափոնի գինը գործակալի համար (1 կգ-ի դիմաց)",
  Buyer_refill_points       : "Գործակալի միավորների վերալիցքավորում",
  Negative_balance          : "Բացասական հաշվեկշիռ",
  Title                     : "Վերնագիր",
  Save                      : "Պահպանել",
  Edit_project              : "Խմբագրել նախագիծը",
  Votes                     : "քվեներ",
  symbol                    : "`",
  vote_date                 : "Քվեարկման օր",
  Donation_date             : "Ծրագրի ավարտման օր",
  Started_date              : "Մեկնարկման ամսաթիվ",
  Finished_Date             : "Ավարտման ամսաթիվ",
  Persentage                : "Տոկոս",
  Started                   : "Մեկնարկեց",
  Participants              : "Մասնակիցներ",
  Add_to_campaign           : "Ավելացնել արշավ",
  Information               : "Տեղեկատվություն",
  New_Campaign              : "Նոր արշավ",
  Edit_Campaign             : "Խմբագրել արշավը",
  Attach_projects           : "Կցել նախագծեր",
  list_is_empty             : "Ցանկը դատարկ է",
  no_search                 : "Ոչինչ չի գտնվել",
  Any_time                  : "Ցանկացած ժամանակ",
  History_orders            : "Հայտերի պատմություն",
  Agent_rate                : "Գործակալի վարկանիշ",
  Seller_rate               : "Հանձնողի վարկանիշ",
  Order_type                : "Հայտի տեսակը ՝ ",
  Change_password           : "Փոխել գաղտնաբառը",
  Change_image              : "Փոխել նկարը",
  Old_password              : "Հին գաղտնաբառ",
  New_password              : "Նոր գաղտնաբառ",
  Confirm_new_password      : "Հաստատեք նոր գաղտնաբառը",
  Show                      : "Ցույց տալ",
  Hide                      : "Թաքցնել",
  Sign_in                   : "Մուտք գործել",
  Sign_out                  : "Դուրս գալ",
  Sign_out_text             : <>Համոզվա՞ծ եք, որ ցանկանում եք <br /> դուրս գալ</>,
  Next                      : "Հաջորդը",
  Enter_P_code              : "Մուտքագրեք կոդը",
  You_have_successfully     : "Դուք բարեհաջող փոխեցիք Ձեր գաղտնաբառը",
  Password_was_changed      : "Գաղտնաբառը հաջողությամբ փոխվեց",
  Please_create_new_password: "Խնդրում ենք ստեղծել նոր գաղտնաբառ",
  Password                  : "Գաղտնաբառ",
  Forgot_password           : "Մոռացե՞լ եք գաղտնաբառը",
  Password_recovery         : "Գաղտնաբառի վերականգնում",
  Please_enter_your_phone   : "Խնդրում ենք մուտագրել Ձեր հեռախոսահամարը",
  Send_code                 : "Ուղարկել ծածկագիր",
  Enter_code                : "Մուտքագրել ծածկագիրը",
  Please_enter_verification : "Մուտքագրեք ձեր հեռախոսահամարին ուղարկված վավերականացման ծածկագիրը",
  rqs                       : "հայտ",
  Set_actual_cardboard      : "Նշել թափոնի փաստացի քաշը",
  Application_workflow      : "Հավելվածի աշխատանքային գործընթաց",
  Suggestion_recommendations: "Առաջարկներ և խորհուրդներ",
  Report_an_issue           : "Հաղորդել խնդրի մասին",
  Add_passport_details      : "Ավելացնել անձնագրային տվյալներ",
  Passport_number           : "Անձնագրի համարը",
  Given_by                  : "Ում կողմից",
  Attach_file_photo         : "Կցեք ֆայլ կամ լուսանկար",
  Selected                  : "Ընտրված",
  option                    : "տարբերակ",
  options                   : "տարբերակ",
  AMD                       : "Միավոր",
  Yes                       : "Այո",
  No                        : "Ոչ",
  Project_names             : "Նախագծի անուն",
  Campaign_information      : "Ծրագրի տվյալներ",
  Campaign_create_text      : "Դուք վստահ եք՞, որ ուզում էք ստեղծել այս ծրագիրը։",
  Use_for_donation          : "Օգտագործել բարեգործության համար",
  Earn_money                : "Վաստակել գումար",
};