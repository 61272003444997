import * as React from 'react';
import './index.scss';
import Languages from 'src/platform/services/languages';
import HelperComponent from 'src/platform/classes/helper-component';
import Modal from 'react-modal';
import CreatePopup from './create/createPopup';
import FaqController from 'src/platform/api/faq/faq';
import ToolBar from 'src/platform/components/toolbar/toolbar';

interface IState {
  list: any[];
  isFirstInit: boolean;
  loaded: boolean,
  modalIsOpenIndex: number,
  activeId: number,
};

const {
  Add,
  Delete,
  Edit,
} = Languages.Translations;

class FAQ extends HelperComponent<{}, IState> {

  componentDidMount() {
    this.getList();
  }
  public state: IState = {
    list: [],
    isFirstInit: false,
    loaded: false,
    modalIsOpenIndex: null,
    activeId: null,
  }

  public deleteFaq = async (id: number) => {
    const res = await FaqController.deleteFaq(id);
    if (res.success) {
      this.closeModal();
      this.getList();
    }
  }

  public openModal = (index: number, activeId?: number) => {
    this.asyncSetState({
      modalIsOpenIndex: index,
      activeId: activeId,
    });
  }

  public closeModal = () => {
    this.asyncSetState({ modalIsOpenIndex: null });
  }

  private getList = async (pageNum?: { selected: number }) => {

    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const res = await FaqController.getList();
    if (res.success) {
      this.setState({
        loaded: true,
        isFirstInit: this.state.isFirstInit && !res.data
      })
    }
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
    if (res.success) {
      this.asyncSetState({
        list: res.data,
      })
    }

  }
  public render() {
    const { list, modalIsOpenIndex, activeId } = this.state;

    return (
      <section className="P-managment-faq">
        <button className="G-button" onClick={() => this.openModal(1)}>{Add}</button>
        {list.map((item, index) => {
          return <div className="G-block G-mb-2">
            <div>
              <div className="P-question">
                {item.question}
              </div>
              <div className="P-answer">
                {item.answer}
              </div>
            </div>
            <ToolBar>
            <li style={{ cursor: "pointer", width: "100%", margin: "0 0 10px 0" }}
              onClick={() => this.openModal(1, item.id)}>
              {Edit}
            </li>
            <li style={{ cursor: "pointer", width: "100%" }}
              onClick={() => this.deleteFaq(item.id)}>
              {Delete}
            </li>
          </ToolBar>
          </div>
        })}

        <Modal
          isOpen={modalIsOpenIndex === 1}
          onRequestClose={this.closeModal}
          style={{
            content: {
              padding: "30px",
              width: "100%",
              maxWidth: "700px",
              display: "flex",
              flexDirection: "column",
            },
          }}>
          <CreatePopup closeModal={this.closeModal} getList={this.getList} id={activeId} />
        </Modal>
      </section >

    );
  }
}

export default FAQ;