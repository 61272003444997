//? Base class to add some helper properties and methods to React.PureComponent

import * as React from 'react';

class HelperPureComponent<Props = {}, State = {}> extends React.PureComponent<Props, State> {

  protected mounted: boolean = true;

  public componentWillUnmount() { this.mounted = false; }

  public async asyncSetState(object: object) {
    return new Promise(res => {
      if (this.mounted) {
        this.setState(object, res)
      } else {
        res();
      }
    })
  }
}

export default HelperPureComponent;