import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ROUTES } from 'src/platform/constants/routes';
import { byPrivateRoute, bySidebar } from 'src/platform/decorators/routes';
import HelperPureComponent from 'src/platform/classes/helper-pure-component';
import './index.scss';
import Languages from 'src/platform/services/languages';
import { ContextPageEnum } from 'src/platform/enums/enums';
import ManagementMedia from './pages/media/ManagementMedia';
import ManagementContent from './pages/content/Content';
import FAQ from './pages/faq/Faq';

interface IState {
  activePage: ContextPageEnum;
};

const {
  Content_management,
  Media,
  Content,
  Faq,
} = Languages.Translations;

@bySidebar(`${Content_management}`, "icon-ic_settings")
@byPrivateRoute(ROUTES.contextManagment)
class ContextManagement extends HelperPureComponent<RouteComponentProps> {
  public state: IState = {
    activePage: ContextPageEnum.Media,
  }

  changePage = (page: ContextPageEnum) => {
    this.setState({ activePage: page });
  }
  public render() { 
    return (
      <section className="G-main-page P-managment-page"> 
       <div className="P-navigation">
          <h4>{Content_management}</h4>
          <p className={`${(this.state.activePage === ContextPageEnum.Media) ? "P-active" : ""}`} onClick={() => this.changePage(ContextPageEnum.Media)}>{Media}</p>
          <p className={`${(this.state.activePage === ContextPageEnum.Content) ? "P-active" : ""}`} onClick={() => this.changePage(ContextPageEnum.Content)}>{Content}</p>
          <p className={`${(this.state.activePage === ContextPageEnum.Faq) ? "P-active" : ""}`} onClick={() => this.changePage(ContextPageEnum.Faq)}>{Faq}</p>
        </div>

        {this.state.activePage === ContextPageEnum.Media ? 
          <ManagementMedia/>
        : this.state.activePage === ContextPageEnum.Content ? 
          <ManagementContent/>
        : this.state.activePage === ContextPageEnum.Faq ? 
          <FAQ/>
        : ''}
      </section >
    );
  }
}

export default ContextManagement;