import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import noPic from 'src/assets/images/no-photo.png';
import { ISellersOrdersListResponseModel } from 'src/platform/api/order/order-interface';
import HelperComponent from 'src/platform/classes/helper-component';
import Paging from 'src/platform/components/pagination/pagination';
import Table, { ITableColumnConfig } from 'src/platform/components/table/table';
import { OrderSellerStatusEnum } from 'src/platform/enums/enums';
import * as moment from 'moment';
import './index.scss';
import Languages from 'src/platform/services/languages';

interface IProps extends RouteComponentProps {
  viewType: number
  loaded: boolean;
  sellersOrdersList: Array<ISellersOrdersListResponseModel>
  getSellersOrdersList: (pageNum?: { selected: number }) => void,
  totalPages: number,
  isFirstInit: boolean,
  pageForPagination?: number,
}

interface IState {
  sellersOrdersList: Array<ISellersOrdersListResponseModel>
}

const {
  Order_,
  Agents_,
  User_,
  Address,
  Cardboard_weight,
  Creation_date,
  Available_Time,
  Status,
  enums,
  Any_time,
} = Languages.Translations

class SellersOrders extends HelperComponent<IProps, IState> {
  state: IState = {
    sellersOrdersList: this.props.sellersOrdersList,
  }
  public inputTimer: any;

  public config: Array<ITableColumnConfig<ISellersOrdersListResponseModel>> = [
    {
      name: `${Order_} №`,
      cell: (row: ISellersOrdersListResponseModel) => {
        return (<div className="G-cursor" >
          {row.orderNumber}
        </div>);
      },
      style: { marginRight: "2%", minWidth: 70, maxWidth: 70 },
    },
    {
      name: `${User_}`,
      cell: (row: ISellersOrdersListResponseModel) => {
        return (<div className="G-cursor G-flex G-align-center" onClick={(evt: React.SyntheticEvent) => {
            evt.stopPropagation();
            this.props.history.push(`/customers/sellers/details?id=${row.sellerPreviewDto.sellerId}`);
        }}>
          <span className="P-image G-mr-4 G-mr-1"
            style={{ background: `url(${row.sellerPreviewDto.sellerImageUrl ? `${row.sellerPreviewDto.sellerImageUrl}/100/100` : noPic}) center/cover no-repeat` }}>
          </span>
          <span className="G-flex G-flex-wrap G-break-word">
            {row.sellerPreviewDto.sellerFullName}
          </span>
        </div>);
      },
      style: { marginRight: "2%", minWidth: 160, maxWidth: 230 },
    },
    {
      name: `${Cardboard_weight}`,
      cell: (row: ISellersOrdersListResponseModel) => {
        return (<div className="G-cursor" >
          {row.weight}
        </div>);
      },
      style: { maxWidth: 110, minWidth: 110, marginRight: "2%" },
    },
    {
      name: `${Address}`,
      cell: (row: ISellersOrdersListResponseModel) => {
        return (<div className="G-cursor" >
          {row.addressNameFull}
        </div>);
      },
      style: { maxWidth: 270, minWidth: 140, marginRight: "2%" },
    },
    {
      name: `${Available_Time}`,
      cell: (row: ISellersOrdersListResponseModel) => {
        return (
          <div className="G-flex G-flex-wrap G-cursor">
            {row.orderTimesDtos.map((item, index) => {
              let from = item.fromTime;
              let to = item.toTime;
              return (<div key={index} className='P-time G-mr-1'>
                {item.fromTime !== 0 ? <>
                {moment(new Date((from * 1000) - 14400000)).format('HH:mm')} - 
                {moment(new Date((to * 1000) - 14400000)).format('HH:mm')}</> : `${Any_time}`}
              </div>)
            })}
          </div>);
      },
      style: { minWidth: 115, maxWidth: 150, marginRight: "2%" },
    },
    {
      name: `${Agents_}`,
      cell: (row: ISellersOrdersListResponseModel) => {
        return (row.agentPreviewDto ? <div className="G-cursor G-flex G-align-center "
          onClick={(evt: React.SyntheticEvent) => {
              evt.stopPropagation();
              this.props.history.push(`/customers/agents/details?id=${row.agentPreviewDto.buyerId}`);
          }}>
          <span className="P-image G-mr-4 G-mr-1"
            style={{ background: `url(${row.agentPreviewDto && row.agentPreviewDto.buyerImageUrl ? `${row.agentPreviewDto.buyerImageUrl}/100/100` : noPic}) center/cover no-repeat` }}>
          </span>
          <span className="G-break-word">
            {row.agentPreviewDto && row.agentPreviewDto.buyerFullName}
          </span>
        </div> : "-");
      },
      style: { marginRight: "2%", minWidth: 125 },
    },
    // {
    //   name: "ACCOUNT TYPE",
    //   cell: (row: ISellersOrdersListResponseModel) => {
    //     return (<div className="G-cursor" >
    //       {AccountTypeEnum[row.accountTypeEnumValue]}
    //     </div>);
    //   },
    //   style: { marginRight: "2%", minWidth: 65, maxWidth: 140 },
    // },
    {
      name: Creation_date,
      cell: (row: ISellersOrdersListResponseModel) => {
        return (<div className="G-cursor" >
          {moment(row.createdDate).format('DD MMM YYYY HH:mm')}
        </div>);
      },
      style: { marginRight: "2%", minWidth: 120, maxWidth: 120 },
    },
    {
      name: `${Status}`,
      cell: (row: ISellersOrdersListResponseModel) => {

        return (<div className="G-cursor"
          style={{
            color: `${(row.orderStatusValue === OrderSellerStatusEnum.AGENT_REQUESTED ||
              row.orderStatusValue === OrderSellerStatusEnum.AGENT_CHOSEN ||
              row.orderStatusValue === OrderSellerStatusEnum.AGENT_FOUND ||
              row.orderStatusValue === OrderSellerStatusEnum.PENDING)
              ? '#7A7A7A'
              : row.orderStatusValue === OrderSellerStatusEnum.CANCELED
                ? '#FF0057' : "#04A556"}`
          }}>
          {enums.OrderSellerStatusEnum[row.orderStatusValue]}
        </div>)
      },
      style: { maxWidth: 90, minWidth: 70 },
    },
  ]

  componentDidUpdate(prevProps: IProps) {

    if (this.props.sellersOrdersList !== prevProps.sellersOrdersList) {
      this.asyncSetState({ sellersOrdersList: this.props.sellersOrdersList });
    }
  }
  public render() {

    const { totalPages, loaded, isFirstInit, getSellersOrdersList, pageForPagination } = this.props;
    const { sellersOrdersList } = this.state;

    return (
      <>
        <Table
          columnConfig={this.config}
          data={sellersOrdersList}
          isFirstInit={isFirstInit}
          loaded={loaded}
          onRowClick={(row) => {
            this.props.history.push(`/orders/sellers/details?activeOrderId=${row.orderId}`);
          }}
        />
        <Paging
          pageForPagination={pageForPagination}
          totalPages={totalPages}
          onChange={getSellersOrdersList}
        />
      </ >
    );
  }
}

export default SellersOrders;