//? Route dynamic configuration tools

import * as React from 'react';

import RouteService from '../services/routes';
import { INestedPages } from '../interfaces';

//? This helps to add new route to application routing system
export function byRoute(route: string | string[]) {
  return <Component extends React.ComponentClass>(component: Component): Component => {
    return RouteService.addRoute<Component>(
      route,
      component,
      false,
    );
  }
};

//? This helps to add new route to application routing system (Private, only for Authorized users)
export function byPrivateRoute(route: string | string[]) {
  return <Component extends React.ComponentClass>(component: Component): Component => {
    return RouteService.addRoute<Component>(
      route,
      component,
      true,
    );
  }
};


//? This helps to add new label in Sidebar
//! Every label in sidebar will have his module
//! To change the position of label in sidebar you will change the module import position on /src/modules/index.ts
export function bySidebar(name: string, icon: string, nestedPages?: INestedPages[],  customPath?: string) {
  return <Component extends React.ComponentClass>(component: Component): Component => {
    if (customPath) {
      RouteService.addSidebarRow(
        name,
        icon,
        nestedPages,
        customPath,
      );
    } else {
      const route = RouteService.routes.find(item => item.component === component);
      route && RouteService.addSidebarRow(
        name,
        icon,
        nestedPages,
        route.path,
      );  
    }

    return component;
  }
}