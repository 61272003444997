import * as React from 'react';
import './index.scss';
import Languages from 'src/platform/services/languages';
import HelperComponent from 'src/platform/classes/helper-component';
import Table, { ITableColumnConfig } from 'src/platform/components/table/table';
import Select from 'src/platform/components/select/Select';
import DatePicker from "react-datepicker";
import { AccountTypeEnum, SelectTimeRangeEnum } from 'src/platform/enums/enums';
import { selectTimeRangeOptions, accountTypeSingleOptions } from 'src/platform/constants/casual';
import DashboardController from 'src/platform/api/dashboard/dashboard';
import moment from 'moment';
import { IGetLeaderboardListModel } from 'src/platform/api/dashboard/dashboard-interface';
import noPic from 'src/assets/images/no-photo.png';
import { RouteComponentProps } from 'react-router-dom';
import Paging from 'src/platform/components/pagination/pagination';
interface IProps extends RouteComponentProps {

}

interface IState {
  sellersList: any;
  isFirstInit: boolean;
  loaded: boolean,
  selectTimeRangeEnum: SelectTimeRangeEnum;
  accountType: AccountTypeEnum,
  isOpen: boolean;
  startDate: Date,
  endDate: Date,
  searchText: string,
  list: IGetLeaderboardListModel[];
  totalPages: number,
  page: number,
  size: number,
};


const {
  enums,
  Status,
  User_,
  Cardboard_weight,
  Donated_point,
  All,
  End,
  Start,
  Search,
} = Languages.Translations

class Leaderboard extends HelperComponent<{}, IState> {

  public state: IState = {
    sellersList: [],
    isFirstInit: false,
    loaded: false,
    selectTimeRangeEnum: SelectTimeRangeEnum.All,
    isOpen: false,
    startDate: null,
    endDate: null,
    searchText: '',
    accountType: AccountTypeEnum.Individual,
    list: [],
    totalPages: 1,
    page: 0,
    size: 10,
  }

  public config: Array<ITableColumnConfig<IGetLeaderboardListModel>> = [
    {
      name: `№`,
      cell: (row: IGetLeaderboardListModel, index: number) => {
        return (<div className="" >
          {index + 1}
        </div>);
      },
      style: { marginRight: "2%" },
    },
    {
      name: `${User_}`,
      cell: (row: IGetLeaderboardListModel) => {
        return (
          <div className="G-cursor G-flex G-align-center">
            <span className="P-image" style={{ background: `url(${row.imageUrl ? `${row.imageUrl}/100/100` : noPic}) center/cover no-repeat` }}></span>
            {row.name}
          </div>
        );
      },
      style: { marginRight: "2%" },
    },
    {
      name: `${Status}`,
      cell: (row: IGetLeaderboardListModel) => {
        return <div className="">{enums.ListStatusEnum[row.status]}</div>;
      },
      style: { marginRight: "2%" },
    },
    {
      name: `${Cardboard_weight}`,
      cell: (row: IGetLeaderboardListModel) => {
        return <div className="G-break-word">
          {row.cardboardWeight}
        </div>;
      },
      style: { marginRight: "2%" },
    },
    {
      name: `${Donated_point}`,
      cell: (row: IGetLeaderboardListModel) => {
        return <div className="G-break-word">
          {row.donatedPoints}
        </div>;
      },
      style: { marginRight: "2%" },
    },
  ]

  componentDidMount() {
    this.getLeaderboard()
  }

  public onSelect = async (field: string, value: number) => {

    await this.asyncSetState({ selectTimeRangeEnum: value });

    if (value === SelectTimeRangeEnum.CustomRange) {
      this.openDateInput();
    } else this.setState({ isOpen: false });
    this.getLeaderboard()
  }

  openDateInput = () => {
    this.setState({ isOpen: true })
  }

  public onSelectType = async (field: string, value: AccountTypeEnum) => {
    this.setState({ accountType: value });
    this.getLeaderboard();
  }

  public getLeaderboard = async (pageNum?: { selected: number }) => {
    let fromDate = this.state.startDate;
    let toDate = this.state.endDate;
    switch (this.state.selectTimeRangeEnum) {
      case SelectTimeRangeEnum.All:
        fromDate = moment().subtract(5, 'years').toDate()
        toDate = null;
        this.state.startDate = null;
        this.state.endDate = null;
        break;
      case SelectTimeRangeEnum.Today:
        fromDate = moment().toDate();
        toDate = null;
        this.state.startDate = null;
        this.state.endDate = null;
        break;
      case SelectTimeRangeEnum.Yesterday:
        fromDate = moment().subtract(1, 'day').toDate();
        toDate = moment().subtract(1, 'day').toDate();
        this.state.startDate = null;
        this.state.endDate = null;
        break;
      case SelectTimeRangeEnum.Week:
        fromDate = moment().subtract(1, 'weeks').toDate();
        toDate = null;
        this.state.startDate = null;
        this.state.endDate = null;
        break;
      case SelectTimeRangeEnum.Month:
        fromDate = moment().subtract(1, 'months').toDate();
        toDate = null;
        this.state.startDate = null;
        this.state.endDate = null;
        break;
      case SelectTimeRangeEnum.Year:
        fromDate = moment().subtract(1, 'years').toDate();
        toDate = null;
        this.state.startDate = null;
        this.state.endDate = null;
        break;
      default:
        break;
    }

    if (fromDate) {
      window.dispatchEvent(new CustomEvent('loader', { detail: true }));
      const result = await DashboardController.getLeaderboard({
        fromDate: moment(fromDate).startOf('day').toDate().getTime(),
        toDate: toDate ? moment(toDate).endOf('day').toDate().getTime() : '',
        page: pageNum ? pageNum.selected : this.state.page,
        size: 10,
        customerType: this.state.accountType,
        searchText: this.state.searchText,
      });
      window.dispatchEvent(new CustomEvent('loader', { detail: false }));

      if (result.success) {
        this.setState({
          list: result.data.content,
          totalPages: result.data.totalPages,
        })
      }
    }
  }
  public inputTimer: any;
  public onInput = async (evt: React.SyntheticEvent<HTMLInputElement>) => {
    await this.asyncSetState({ searchText: evt.currentTarget.value })
    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(async () => {
      this.getLeaderboard();
    }, 300);
  }

  public render() {
    const { selectTimeRangeEnum, startDate, endDate, list, isFirstInit, loaded, searchText, accountType, totalPages } = this.state;

    return (
      <section className="">
        <div className="G-flex-center-between G-mb-2">
          <div className="G-flex">
            <Select
              height="45px"
              width="220px"
              value={accountType}
              options={accountTypeSingleOptions}
              setFieldValue={(field: string, value: string) => this.asyncSetState({ accountType: Number(value) }).then(() => {
                this.getLeaderboard();
              })}
            />
            <div className="G-ml-2">
              <input className="G-text-input"
                placeholder={Search}
                type="text"
                value={searchText}
                onChange={this.onInput}
              />
            </div>
          </div>
          <div className="G-flex">
            <Select
              className="G-ml-5"
              height="45px"
              width="220px"
              placeholder={All}
              value={selectTimeRangeEnum}
              options={selectTimeRangeOptions}
              setFieldValue={this.onSelect}
            />
            {
              this.state.isOpen ?
                <div className="G-flex">
                  <DatePicker
                    className={`G-text-input G-cursor G-ml-3`}
                    selected={startDate}
                    placeholderText={Start}
                    onChange={(startDate: Date) => this.asyncSetState({ startDate }).then(() => {
                      this.getLeaderboard()
                    })}
                  />
                  <DatePicker
                    className={`G-text-input G-cursor G-ml-3`}
                    selected={endDate}
                    minDate={startDate}
                    placeholderText={End}
                    onChange={(endDate: Date) => this.asyncSetState({ endDate }).then(() => {
                      this.getLeaderboard()
                    })}
                  />
                </div>
                : null}
          </div>
        </div>
        <div>
          <Table
            columnConfig={this.config}
            data={list}
            children = {''}
            isFirstInit={isFirstInit}
            loaded={loaded}
            onRowClick={(row) => {
              // window.routerHistory.push(`/customers/sellers/details?id=${row.id}`);
            }}
          />
          <Paging
            totalPages={totalPages}
            onChange={this.getLeaderboard}
          />
        </div>
      </section >
    );
  }
}

export default Leaderboard;