import * as React from 'react';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router-dom';
import { ROUTES } from 'src/platform/constants/routes';
import { byPrivateRoute, bySidebar } from 'src/platform/decorators/routes';
import HelperPureComponent from 'src/platform/classes/helper-pure-component';
import Sellers from './sellers/Sellers';
import Agents from './agents/Agents';
import SellerDetails from './sellers/details/SellerDetails';
import AgentDetails from './agents/details/AgentDetails';
import './index.scss';
import Languages from 'src/platform/services/languages';

const {
  Customer,
  Sellers_,
  Agents_,
} = Languages.Translations;

@bySidebar(`${Customer}`, "icon-ic_user", [{name:`${Sellers_}`, path: "/customers/sellers"},{name:`${Agents_}`, path: "/customers/agents"}])
@byPrivateRoute(ROUTES.customers)
class Customers extends HelperPureComponent<RouteComponentProps> {

  public render() { 
    return (
      <section className="G-main-page P-customers-page"> 
            <Switch>
              <Route path="/customers/sellers" component={Sellers} exact={true}/>
              <Route path="/customers/sellers/details" component={SellerDetails}  exact={true}/>
              <Route path="/customers/agents" component={Agents}  exact={true}/>
              <Route path="/customers/agents/details" component={AgentDetails}  exact={true}/>
              <Redirect to="/customers/sellers"/>
            </Switch> 
      </section >
    );
  }
}

export default Customers;