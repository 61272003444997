import * as React from 'react';
import Paginate from 'react-paginate';
import './index.scss';

interface IProps {
  name?: string;
  totalPages: number;
  onChange(pageNum: {selected: number}): void;
  count?: number;
  pageForPagination?: number;
};

class Paging extends React.Component<IProps, {}> {

  componentDidMount(){
  }

  public render() {
    const {
      totalPages,
      onChange,
      pageForPagination
    } = this.props;
    
    return (
      <div className="B-paging">
        {/* {/* {!!totalPages && <h3>Total {totalPages}</h3>} */}
        {totalPages > 1 &&
          <Paginate
            containerClassName="B-paging-buttons"
            pageCount={totalPages}
            previousLabel={<i className="icon-ic_arrowleft P-icon"></i>}
            nextLabel={<i className="icon-ic_arrowright P-icon"></i>}
            activeClassName="B-active-page"
            onPageChange={onChange}
            disableInitialCallback={true}
            forcePage={pageForPagination}
          />}
      </div>
    );
  }
}
export default Paging;