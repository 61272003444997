import * as React from 'react';
import Table, { ITableColumnConfig } from 'src/platform/components/table/table';
import HelperComponent from 'src/platform/classes/helper-component';
import { RouteComponentProps, withRouter } from 'react-router';
import Paging from 'src/platform/components/pagination/pagination';
import noPic from 'src/assets/images/no-photo.png';
import { OrderSellerStatusEnum } from 'src/platform/enums/enums';
import { ISellersOrdersListResponseModel } from 'src/platform/api/order/order-interface';
import moment from 'moment';
import Helper from 'src/platform/services/helper';
import './index.scss';
import Languages from 'src/platform/services/languages';

const {
  Any_time
} = Languages.Translations
interface IState {
}

const {
  Order_,
  User_,
  Cardboard_weight,
  Available_Time,
  Address,
  Agent_,
  Creation_date,
  Status,
  enums,
  AMD,
} = Languages.Translations

interface IProps extends RouteComponentProps {
  loaded: boolean;
  sellersOrdersHistoryList: Array<ISellersOrdersListResponseModel>
  getSellersHistoryList: (pageNum?: { selected: number }) => void,
  totalPages: number,
  isFirstInit: boolean,
  pageForPagination?: number,
  openModal: (index: number) => void,
}

class SellersOrdersHistory extends HelperComponent<IProps, IState> {
  public state: IState = {

  }

  public config: Array<ITableColumnConfig<ISellersOrdersListResponseModel>> = [
    {
      name: `${Order_} #`,
      cell: (row: ISellersOrdersListResponseModel) => {
        return (<div className="G-cursor" >
          {row.orderNumber}
        </div>);
      },
      style: { marginRight: "1%", minWidth: 70, maxWidth: 100 },
    },
    {
      name: `${User_}`,
      cell: (row: ISellersOrdersListResponseModel) => {
        return (<div className="G-cursor G-flex G-align-center"
          onClick={(evt: React.SyntheticEvent) => {
            if (!Helper.role) {
              evt.stopPropagation();
              this.props.history.push(`/customers/sellers/details?id=${row.sellerPreviewDto.sellerId}`);
            }
          }}
          >
          <span className="P-image G-mr-4 G-mr-1"
            style={{ background: `url(${row.sellerPreviewDto.sellerImageUrl ? `${row.sellerPreviewDto.sellerImageUrl}/100/100` : noPic}) center/cover no-repeat` }}>
          </span>
          <span className="G-break-word">
            {row.sellerPreviewDto.sellerFullName}
          </span>
        </div>);
      },
      style: { minWidth: 185, maxWidth: 200, marginRight: "1%" },
    },
    {
      name: `${Cardboard_weight}`,
      cell: (row: ISellersOrdersListResponseModel) => {
        return (<div className="G-cursor" >
          {row.weight}
        </div>);
      },
      style: { minWidth: 80, maxWidth: 120, marginRight: "1%" },
    },
    {
      name: `${Address}`,
      cell: (row: ISellersOrdersListResponseModel) => {
        return (<div className="G-cursor" >
          {row.addressNameFull && row.addressNameFull}
        </div>);
      },
      style: { maxWidth: 220, minWidth: 170, marginRight: "1%" },
    },
    {
      name: `${Available_Time}`,
      cell: (row: ISellersOrdersListResponseModel) => {
        return (
          <div className="G-flex G-flex-wrap G-cursor">
            {row.orderTimesDtos.map((item, index) => {
              let from = item.fromTime;
              let to = item.toTime;
              return (<div key={index} className='P-time G-mr-1'>
                {item.fromTime !== 0 ? <>
                {moment(new Date((from * 1000) - 14400000)).format('HH:mm')} - 
                {moment(new Date((to * 1000) - 14400000)).format('HH:mm')}</> : `${Any_time}`}
              </div>)
            })}
          </div>);
      },
      style: { marginRight: "1%", minWidth: 105, maxWidth: 200 },
    },
    {
      name: `${Agent_}`,
      cell: (row: ISellersOrdersListResponseModel) => {
        return (row.agentPreviewDto ? <div className="G-cursor G-flex G-align-center"
          onClick={(evt: React.SyntheticEvent) => {
              evt.stopPropagation();
              this.props.history.push(`/customers/agents/details?id=${row.agentPreviewDto.buyerId}`);
          }}
          >
          <span className="P-image G-mr-4 G-mr-1"
            style={{ background: `url(${(row.agentPreviewDto && row.agentPreviewDto.buyerImageUrl) ? `${row.agentPreviewDto.buyerImageUrl}/100/100` : noPic}) center/cover no-repeat` }}>
          </span>
          <span className="G-break-word">
            {row.agentPreviewDto && row.agentPreviewDto.buyerFullName}
          </span>
        </div> : "-");
      },
      style: { marginRight: "1%", minWidth: 100, maxWidth: 200 },
    },
    {
      name: `${AMD}`,
      cell: (row: ISellersOrdersListResponseModel) => {
        return (<div className="G-cursor">
          {row.price.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
        </div>);
      },
      style: { marginRight: "1%", minWidth: 50, maxWidth: 90 },
    },
    {
      name: Creation_date,
      cell: (row: ISellersOrdersListResponseModel) => {
        return (<div className="G-cursor">
          {moment(row.createdDate).format('DD MMM YYYY HH:mm')}
        </div>);
      },
      style: { marginRight: "1%", minWidth: 100, maxWidth: 140 },
    },
    {
      name: `${Status}`,
      cell: (row: ISellersOrdersListResponseModel) => {
        return (<div className="G-cursor"
          style={{
            color: `${
              row.orderStatusValue === OrderSellerStatusEnum.CANCELED
                ? '#FF0057' : "#04A556"}`
          }}>
          {enums.OrderSellerStatusEnum[row.orderStatusValue]}
        </div>)
      },
      style: { maxWidth: 70, minWidth: 70 },
    },
  ]

  public render() {

    const { sellersOrdersHistoryList, isFirstInit, loaded, pageForPagination, totalPages, getSellersHistoryList, openModal } = this.props;

    return (
      <>
        <Table columnConfig={this.config}
          data={sellersOrdersHistoryList}
          isFirstInit={isFirstInit}
          loaded={loaded}
          onRowClick={(row) => {
            openModal(row.orderId);
          }}
        />
        <Paging
          pageForPagination={pageForPagination}
          totalPages={totalPages}
          onChange={getSellersHistoryList}
        />
      </ >
    );
  }
}

export default withRouter(SellersOrdersHistory);