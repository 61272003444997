import * as React from 'react';
import Table, { ITableColumnConfig } from 'src/platform/components/table/table';
import DoubleDropDown from 'src/platform/components/double-drop-down/DoubleDropDown';
import MultiSelect from 'src/platform/components/multi-select/MultiSelect';
import { ListStatusEnum, AccountTypeEnum } from 'src/platform/enums/enums';
import ToolBar from 'src/platform/components/toolbar/toolbar';
import { statusOptions, accountTypeOptions } from 'src/platform/constants/casual';
import { ISellersListResponseModel } from 'src/platform/api/customers/interfaces/sellersModel';
import HelperComponent from 'src/platform/classes/helper-component';
import { RouteComponentProps } from 'react-router';
import UserController from 'src/platform/api/user/user';
import { store } from 'react-notifications-component';
import Select from 'src/platform/components/select/Select';
import Helper from 'src/platform/services/helper';
import Paging from 'src/platform/components/pagination/pagination';
import noPic from 'src/assets/images/no-photo.png';
import { IDoubleDropDownOption } from 'src/platform/interfaces';
import * as moment from 'moment';
import './index.scss';
import Languages from 'src/platform/services/languages';

interface IState {
  statusList: Array<string>,
  searchCriteriaBalance: IDoubleDropDownOption,
  searchCriteriaRequestCount: IDoubleDropDownOption,
  nameOrPhone: string,
  sellersList: Array<ISellersListResponseModel>,
  requestIsOff: boolean,
  page: number,
  size: number,
  totalPages: number,
  totalElements: number,
  accountTypeEnumValue: AccountTypeEnum,
  loaded: boolean;
  isFirstInit: boolean;
}

const {
  enums,
  Sellers_,
  Reset,
  Name,
  Phone_number,
  Requests_count,
  Current_balance,
  Donated_amount,
  Leaderboard_place,
  Account_type,
  Status,
  Block_user,
  Unblock_user,
  Search,
  Seller_type,
  Requests_,
  Balance,
  Registration_date,
} = Languages.Translations;

class Sellers extends HelperComponent<RouteComponentProps, IState> {

  public state: IState = {
    searchCriteriaBalance: {
      operationEnumValue: null,
      value: null,
    },
    searchCriteriaRequestCount: {
      operationEnumValue: null,
      value: null,
    },

    sellersList: [],
    page: 0,
    size: 8,
    requestIsOff: false,
    totalPages: null,
    totalElements: null,

    statusList: [],
    accountTypeEnumValue: null,
    nameOrPhone: "",
    loaded: false,
    isFirstInit: true,
  }

  componentDidMount() {
    this.getSellersList();
  }
  public inputTimer: any;

  private getSellersList = async (pageNum?: { selected: number }) => {

    const { page, size, statusList, accountTypeEnumValue, nameOrPhone, searchCriteriaBalance, searchCriteriaRequestCount } = this.state;

    if (searchCriteriaBalance.value) {
      var body = {
        operationEnumValue: searchCriteriaBalance.operationEnumValue,
        value: searchCriteriaBalance.value,
        balance: "balance",
      }
    } else body = null;

    if (searchCriteriaRequestCount.value) {
      var bodyRequest = {
        operationEnumValue: searchCriteriaRequestCount.operationEnumValue,
        requestCount: "request",
        value: searchCriteriaRequestCount.value,
      }
    } else bodyRequest = null;

    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(async () => {
      window.dispatchEvent(new CustomEvent('loader', { detail: true }));
      const result = await UserController.getSellersList({
        page: pageNum ? pageNum.selected : page,
        size,
        body: {
          statusList,
          accountTypeEnumValue,
          nameOrPhone,
          searchCriteriaBalance: body,
          searchCriteriaRequestCount: bodyRequest
        },
      });
      if (result.success) {
        this.setState({
          loaded: true,
          isFirstInit: this.state.isFirstInit && !result.data.content.length
        })
      }
      window.dispatchEvent(new CustomEvent('loader', { detail: false }));

      if (result.success) {
        this.asyncSetState({
          sellersList: result.data.content,
          totalPages: result.data.totalPages,
          totalElements: result.data.totalElements
        });

      } else {
        store.addNotification(Helper.notificationConfig(result.message, false));
      }

    }, 300);

  };

  public setOperatorValue = (key: string, value: any) => {
    this.state[key].operationEnumValue = value;
    this.asyncSetState({ [key]: this.state[key] }).then(() => {
      this.getSellersList();
    });
  }

  public setDoubleDropdownInputValue = async (key: string, value: any) => {
    this.state[key].value = value;
    this.asyncSetState({ [key]: this.state[key] })
      .then(() => {
        this.getSellersList();
      });
  }

  public config: Array<ITableColumnConfig<ISellersListResponseModel>> = [
    {
      name: `${Name}`,
      cell: (row: ISellersListResponseModel) => {
        return (<div className="G-cursor G-flex G-align-center" onClick={() => {
          this.props.history.push(`/customers/sellers/details?id=${row.id}`);
        }}>
          <span className="P-image G-mr-1"
            style={{ background: `url(${row.imageUrl ? `${row.imageUrl}/100/100` : noPic}) center/cover no-repeat` }}>
          </span>
          <span className="G-flex G-flex-wrap G-break-word">
            {row.fullName ? row.fullName : row.companyName}
          </span>
        </div>);
      },
      style: { marginRight: "1%", minWidth: 130, maxWidth: 180 },
    },
    {
      name: `${Phone_number}`,
      cell: (row: ISellersListResponseModel) => {
        return (<div className="G-cursor" onClick={() => {
          this.props.history.push(`/customers/sellers/details?id=${row.id}`);
        }}>
          {row.phoneNumber}
        </div>);
      },
      style: { marginRight: "3%", minWidth: 100, maxWidth: 140 },
    },
    {
      name: `${Requests_count}`,
      cell: (row: ISellersListResponseModel) => {
        return (<div className="G-cursor" onClick={() => {
          this.props.history.push(`/customers/sellers/details?id=${row.id}`);
        }}>
          {row.requestsCount}
        </div>);
      },
      style: { marginRight: "1%", minWidth: 65, maxWidth: 130 },
    },
    {
      name: `${Current_balance}`,
      cell: (row: ISellersListResponseModel) => {
        return (<div className="G-cursor" onClick={() => {
          this.props.history.push(`/customers/sellers/details?id=${row.id}`);
        }}>
          {row.currentBalance.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
        </div>);
      },
      style: { marginRight: "1%", minWidth: 65, maxWidth: 130 },

    },
    {
      name: `${Donated_amount}`,
      cell: (row: ISellersListResponseModel) => {
        return (<div className="G-cursor" onClick={() => {
          this.props.history.push(`/customers/sellers/details?id=${row.id}`);
        }}>
          <span>{row.donatedAmount ? row.donatedAmount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : "0"}</span>
        </div>);
      },
      style: { marginRight: "3%", minWidth: 65, maxWidth: 150 },
    },
    {
      name: `${Leaderboard_place}`,
      cell: (row: ISellersListResponseModel) => {
        return (<div className="G-cursor" onClick={() => {
          this.props.history.push(`/customers/sellers/details?id=${row.id}`);
        }}>
          {row.leaderBoardPlace}
        </div>);
      },
      style: { marginRight: "1%", minWidth: 100, maxWidth: 120 },
    },
    {
      name: `${Account_type}`,
      cell: (row: ISellersListResponseModel) => {
        return (<div className="G-cursor" onClick={() => {
          this.props.history.push(`/customers/sellers/details?id=${row.id}`);
        }}>
          {enums.AccountTypeEnum[row.accountEnumValue]}
        </div>);
      },
      style: { marginRight: "2%", minWidth: 90, maxWidth: 120 },
    },
    {
      name: `${Registration_date}`,
      cell: (row: ISellersListResponseModel) => {
        return (<div className="G-cursor" onClick={() => {
          this.props.history.push(`/customers/sellers/details?id=${row.id}`);
        }}>
          {moment(row.createdDate).format('DD MMM YYYY HH:mm')}
        </div>);
      },
      style: { marginRight: "1%", minWidth: 100 },
    },
    {
      name: `${Status}`,
      cell: (row: ISellersListResponseModel) => {
        return (<div className="G-cursor" onClick={() => {
          this.props.history.push(`/customers/sellers/details?id=${row.id}`);
        }} style={{
          color: `${row.statusValue === ListStatusEnum.Active ? '#04A556'
            : row.statusValue === ListStatusEnum.Blocked
              ? '#FF0057' : "#7A7A7A"}`
        }}>
          {enums.ListStatusEnum[row.statusValue]}
        </div>)
      },
      style: { maxWidth: 105, marginRight: "1%" },
    },
    {
      cell: (row: ISellersListResponseModel) => {
        return <>
          {!(ListStatusEnum.Deactivated === row.statusValue || ListStatusEnum.DELETED === row.statusValue) ? <ToolBar>
            <li style={{ cursor: "pointer", width: "100%" }}
              onClick={() => this.blockUser(row.id)}>
              {ListStatusEnum.Active === row.statusValue ? `${Block_user}`
                : ListStatusEnum.Blocked === row.statusValue ? `${Unblock_user}` : null
              }
            </li>
          </ToolBar> : null}
        </>
      },
      style: { minWidth: 30, maxWidth: 60, marginLeft: "auto" },
    }
  ]

  private blockUser = async (id: string) => {
    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const res = await UserController.blockUser(id);
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
    if (res.success) {
      this.getSellersList();
    } else {
      store.addNotification(Helper.notificationConfig(res.message, false));
    }
  }

  public reset = () => {
    this.asyncSetState({
      page: 0,
      totalPages: 0,
      statusList: [],
      accountTypeEnumValue: null,
      nameOrPhone: "",
      searchCriteriaBalance: {
        operationEnumValue: "",
        value: "",
      },
      searchCriteriaRequestCount: {
        operationEnumValue: "",
        value: "",
      }
    }).then(() => {
      this.getSellersList();
    });

  }

  public onInput = async (evt: React.SyntheticEvent<HTMLInputElement>) => {
    await this.asyncSetState({ nameOrPhone: evt.currentTarget.value })
    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(async () => {
      this.getSellersList();
    }, 300);
  }

  public onMultiSelect = (arr: Array<string>) => {
    this.asyncSetState({ statusList: arr }).then(() => {
      this.getSellersList();
    });
  }

  public onSelect = (field: string, value: number) => {
    this.asyncSetState({ accountTypeEnumValue: value }).then(() => {
      this.getSellersList();
    });;
  }

  downloadSellers = async () => {
    const { statusList, accountTypeEnumValue, nameOrPhone, searchCriteriaBalance, searchCriteriaRequestCount } = this.state;

    if (searchCriteriaBalance.value) {
      var body = {
        operationEnumValue: searchCriteriaBalance.operationEnumValue,
        value: searchCriteriaBalance.value,
        balance: "balance",
      }
    } else body = null;

    if (searchCriteriaRequestCount.value) {
      var bodyRequest = {
        operationEnumValue: searchCriteriaRequestCount.operationEnumValue,
        requestCount: "request",
        value: searchCriteriaRequestCount.value,
      }
    } else bodyRequest = null;
    const result = await UserController.downloadSellers({
      body: {
        statusList,
        accountTypeEnumValue,
        nameOrPhone,
        searchCriteriaBalance: body,
        searchCriteriaRequestCount: bodyRequest
      }
    });
    window.open(result.data, '_blank');
  }

  public render() {
    const { statusList, totalPages, sellersList, accountTypeEnumValue, nameOrPhone, loaded, isFirstInit, searchCriteriaRequestCount, searchCriteriaBalance, totalElements } = this.state;

    return (
      <section className="P-sellers-page">
        <div className="G-flex G-mb-2 G-justify-between G-align-center">
          <h4>{Sellers_} ({totalElements})</h4>
          <button className="G-gray-btn" onClick={this.downloadSellers}>Export</button>
        </div>

        <div className="G-flex G-flex-wrap P-selects-wrapper">
          <div className="G-mr-1">
            <input className="G-text-input"
              placeholder={Search}
              type="text"
              value={nameOrPhone}
              onChange={this.onInput}
            />
          </div>
          <div className="G-mr-1">
            <DoubleDropDown
              placeholder={Requests_}
              property="searchCriteriaRequestCount"
              operatorValue={searchCriteriaRequestCount.operationEnumValue}
              inputValue={searchCriteriaRequestCount.value}
              setOperatorValue={this.setOperatorValue}
              setDoubleDropdownInputValue={this.setDoubleDropdownInputValue}
            />
          </div>
          <div className="G-mr-1">
            <DoubleDropDown
              placeholder={Balance}
              property="searchCriteriaBalance"
              operatorValue={searchCriteriaBalance.operationEnumValue}
              inputValue={searchCriteriaBalance.value}
              setOperatorValue={this.setOperatorValue}
              setDoubleDropdownInputValue={this.setDoubleDropdownInputValue}
            />
          </div>
          <div className="G-mr-1">
            <Select
              height="45px"
              width="220px"
              placeholder={Seller_type}
              options={accountTypeOptions}
              value={accountTypeEnumValue}
              setFieldValue={this.onSelect}
            />
          </div>
          <div className="G-mr-1">
            <MultiSelect
              name="status"
              height="45px"
              width="220px"
              setFieldValue={this.onMultiSelect}
              value={statusList}
              options={statusOptions} />
          </div>
          <span className=" G-button P-button" onClick={this.reset}>{Reset}</span>

        </div>
        <Table columnConfig={this.config}
          data={sellersList}
          isFirstInit={isFirstInit}
          loaded={loaded}
        />
        <Paging
          totalPages={totalPages}
          onChange={this.getSellersList}
        />
      </section >
    );
  }
}

export default Sellers;