import * as React from 'react';
import HelperComponent from 'src/platform/classes/helper-component';
import "./index.scss";
import { RouteComponentProps, Link } from 'react-router-dom';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import { IVerifyRequestCodeModel } from 'src/platform/api/forgot-password/interfaces/verify-code-req';
import ForgotPassController from "src/platform/api/forgot-password/forgot-pass"
import { store } from 'react-notifications-component';
import DataTransfer from '../services/dataTransfer';
import Helper from 'src/platform/services/helper';
import Loader from 'src/platform/components/loader/loader';
import Languages from 'src/platform/services/languages';

const {
  Next,
  Sign_in,
  Password_recovery,
  Phone_number,
  Please_enter_verification,
  Enter_P_code,
} = Languages.Translations
class VerifyCode extends HelperComponent<RouteComponentProps> {
  

  private verify = async (value: IVerifyRequestCodeModel) => {
    const that = this;

    await DataTransfer.data.confirmationResult
      .confirm(value.code)
      .then(async function (result: any) {
          const res = await ForgotPassController.VerifyCode(result.user.uid);
          if (res.success) {
            DataTransfer.data.code = value.code;
            that.props.history.replace('/login/password-reset');
          } else {
            store.addNotification(Helper.notificationConfig(res.message, false));
          }
            
      }).catch(function (error: any) {
      // User couldn't sign in (bad verification code?)
      // ...
      });
		
	}
  public validationSchema = Yup.object().shape({
    code: Yup.string().min(4).required()
  })

  public render() {

    return (
      <div className="P-sigin-form-block P-sigin-form-block--password-two P-big">
        <Formik
          initialValues={{ phoneNumber: DataTransfer.data.phoneNumber, code: "" }}
          onSubmit={this.verify}
          validationSchema={this.validationSchema}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form className="P-form" noValidate={true}>
              <span className="P-sign-in-title G-mb-2">{Password_recovery}</span>
              <span className="P-info P-info--two">{Please_enter_verification}</span>
              <div className="P-form-input">
                <div className="G-mb-1">
                  <Field
                    type="text"
                    name="phoneNumber"
                    placeholder={Phone_number}
                    disabled={true}
                  />
                </div>
                <div className="G-mb-4 P-code">
                  <Field
                    type="text"
                    name="code"
                    placeholder={Enter_P_code}
                    className={touched.code && errors.code ? "G-error" : ""}
                  />
                </div>
              </div>
              <button
                type="submit"
                className="P-sign-in-btn"
              >
              {isSubmitting ? <Loader/> : `${Next}`}
          </button>
              <Link to={"/login/sign-in"}
                className="G-btn-transparent G-bold"
              >
                {Sign_in}
              </Link>
            </Form>
          )}
        </Formik>
      </div >

    );
  }
}

export default VerifyCode;