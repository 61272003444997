import * as React from 'react';
import Table, { ITableColumnConfig } from 'src/platform/components/table/table';
import DoubleDropDown from 'src/platform/components/double-drop-down/DoubleDropDown';
import MultiSelect from 'src/platform/components/multi-select/MultiSelect';
import { ListStatusEnum } from 'src/platform/enums/enums';
import ToolBar from 'src/platform/components/toolbar/toolbar';
import HelperComponent from 'src/platform/classes/helper-component';
import { statusOptions } from 'src/platform/constants/casual';
import { IAgentsListResponseModel } from 'src/platform/api/customers/interfaces/agentsModel';
import { RouteComponentProps } from 'react-router';
import { store } from 'react-notifications-component';
import UserController from 'src/platform/api/user/user';
import AgentController from 'src/platform/api/agent/agent';
import Helper from 'src/platform/services/helper';
import Paging from 'src/platform/components/pagination/pagination';
import noPic from 'src/assets/images/no-photo.png'
import { IDoubleDropDownOption } from 'src/platform/interfaces';
import moment from 'moment';
import './index.scss';
import Languages from 'src/platform/services/languages';
import Modal from 'react-modal';
import DashboardController from 'src/platform/api/dashboard/dashboard';
import { IGetAgentExportDate } from 'src/platform/api/dashboard/dashboard-interface';

interface IState {
  agentsList: Array<IAgentsListResponseModel>,
  walletBalance: IDoubleDropDownOption,
  cardboardBalance: IDoubleDropDownOption,
  totalWithdraws: IDoubleDropDownOption,
  dealsCount: IDoubleDropDownOption,
  rating: IDoubleDropDownOption,
  requestIsOff: boolean,
  page: number,
  size: number,
  totalPages: number,
  totalElements: number,
  statusList: Array<string>,
  nameOrPhone: string,
  loaded: boolean;
  isFirstInit: boolean;
  deleteModalIsOpen: boolean;
  activeId: string;
}

const {
  enums,
  Agents_,
  Reset,
  Name,
  Phone_number,
  Cardboard_balance,
  Wallet_balance,
  Wallet_,
  Total_Earnings,
  Earnings,
  Deals_count,
  Deals_,
  Status,
  Block_user,
  Activate_user,
  Unblock_user,
  Search,
  Registration_date,
  Agent_rate,
  Delete,
  Are_you_sure,
  Cancel
} = Languages.Translations;


class Agents extends HelperComponent<RouteComponentProps, {}> {

  public state: IState = {
    agentsList: [],
    walletBalance: {
      operationEnumValue: null,
      value: null,
    },
    cardboardBalance: {
      operationEnumValue: null,
      value: null,
    },
    totalWithdraws: {
      operationEnumValue: null,
      value: null,
    },
    dealsCount: {
      operationEnumValue: null,
      value: null,
    },
    rating: {
      operationEnumValue: null,
      value: null,
    },
    page: 0,
    size: 8,
    requestIsOff: false,
    totalPages: null,
    totalElements: null,

    statusList: [],
    nameOrPhone: "",
    loaded: false,
    isFirstInit: true,
    deleteModalIsOpen: false,
    activeId: null,
  }

  componentDidMount() {
    this.getAgentsList();
  }


  private getAgentsList = async (pageNum?: { selected: number }) => {
    const { statusList, nameOrPhone, page, size, walletBalance, dealsCount, rating, totalWithdraws, cardboardBalance } = this.state;
    let body: any = {
      statusList,
      nameOrPhone,
    };
    if (walletBalance.value) {
      body.walletBalance = {
        operationEnumValue: walletBalance.operationEnumValue,
        value: walletBalance.value,
        walletBalance: null,
      }
    }
    if (dealsCount.value) {
      body.dealsCount = {
        operationEnumValue: dealsCount.operationEnumValue,
        value: dealsCount.value,
        dealsCount: null,
      }
    }
    if (rating.value) {
      body.rating = {
        operationEnumValue: rating.operationEnumValue,
        value: rating.value,
        // rating: null,
      }
    }
    if (cardboardBalance.value) {
      body.cardboardBalance = {
        operationEnumValue: cardboardBalance.operationEnumValue,
        value: cardboardBalance.value,
        cardboardBalance: null,
      }
    }
    if (totalWithdraws.value) {
      body.totalWithdraws = {
        operationEnumValue: totalWithdraws.operationEnumValue,
        value: totalWithdraws.value,
        totalWithdraws: null,
      }
    }


    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const res = await UserController.getAgentsList({
      page: pageNum ? pageNum.selected : page,
      size,
      body,
    });
    if (res.success) {
      this.setState({
        loaded: true,
        isFirstInit: this.state.isFirstInit && !res.data.content.length
      })
    }

    window.dispatchEvent(new CustomEvent('loader', { detail: false }));

    if (res.success) {
      this.asyncSetState({
        totalPages: res.data.totalPages,
        totalElements: res.data.totalElements,
        agentsList: res.data.content,
      })
    } else {
      store.addNotification(Helper.notificationConfig(res.message, false));
    }
  }

  public config: Array<ITableColumnConfig<IAgentsListResponseModel>> = [
    {
      name: `${Name}`,
      cell: (row: IAgentsListResponseModel) => {
        return (
          <div className="G-cursor G-flex G-align-center" onClick={() => {
            this.props.history.push(`/customers/agents/details?id=${row.id}`);
          }}>
            <span className="P-image G-mr-1"
              style={{ background: `url(${row.imageUrl ? `${row.imageUrl}/100/100` : noPic}) center/cover no-repeat` }}>
            </span>
            <span className="G-flex G-flex-wrap G-break-word">
              {row.fullName}
            </span>
          </div>
        );
      },
      style: { minWidth: 200, maxWidth: 230 },
    },
    {
      name: `${Phone_number}`,
      cell: (row: IAgentsListResponseModel) => {
        return (
          <div className="G-cursor" onClick={() => {
            this.props.history.push(`/customers/agents/details?id=${row.id}`);
          }}>
            {row.phoneNumber}
          </div>)
      },
      style: { marginRight: "2%", minWidth: 100 },
    },
    {
      name: `${Cardboard_balance}`,
      cell: (row: IAgentsListResponseModel) => {
        return (
          <div className="G-cursor" onClick={() => {
            this.props.history.push(`/customers/agents/details?id=${row.id}`);
          }}>
            {row.cardboardBalance}
          </div>)
      },
      style: { marginRight: "1%" },
    },
    {
      name: `${Wallet_balance}`,
      cell: (row: IAgentsListResponseModel) => {
        return (
          <div className="G-cursor" onClick={() => {
            this.props.history.push(`/customers/agents/details?id=${row.id}`);
          }}>
            {row.walletBalance.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
          </div>)
      },
      style: { marginRight: "1%" },
    },
    {
      name: `${Total_Earnings}`,
      cell: (row: IAgentsListResponseModel) => {
        return (
          <div className="G-cursor" onClick={() => {
            this.props.history.push(`/customers/agents/details?id=${row.id}`);
          }}>
            {row.totalWithdraws.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
          </div>)
      },
      style: { marginRight: "1%", maxWidth: 130 },
    },
    {
      name: `${Deals_count}`,
      cell: (row: IAgentsListResponseModel) => {
        return (
          <div className="G-cursor" onClick={() => {
            this.props.history.push(`/customers/agents/details?id=${row.id}`);
          }}>
            {row.dealsCount}
          </div>)
      },
      style: { marginRight: "1%", maxWidth: 110 },
    },
    {
      name: `${Registration_date}`,
      cell: (row: IAgentsListResponseModel) => {
        return (<div className="G-cursor" onClick={() => {
          this.props.history.push(`/customers/agents/details?id=${row.id}`);
        }}>
          {moment(row.createdDate).format('DD MMM YYYY HH:mm')}
        </div>);
      },
      style: { marginRight: "1%", minWidth: 65, maxWidth: 120 },
    },
    {
      name: `${Status}`,
      cell: (row: IAgentsListResponseModel) => {
        return (<div
          onClick={() => { this.props.history.push(`/customers/agents/details?id=${row.id}`) }}
          style={{ color: `${row.statusValue === ListStatusEnum.Active ? '#04A556' : row.statusValue === ListStatusEnum.Blocked ? '#FF0057' : "#7A7A7A"}` }}>
          {enums.ListStatusEnum[row.statusValue]}
        </div>)
      },
      style: { maxWidth: 90, marginRight: "1%" },
    },
    {
      cell: (row: IAgentsListResponseModel) => {
        return <div className="G-ml-auto">
          <ToolBar>
            {(ListStatusEnum.Active === row.statusValue) ?
              <li style={{ cursor: "pointer", width: "100%" }}
                onClick={() => this.blockUser(row.id)}>
                {ListStatusEnum.Active === row.statusValue ? `${Block_user}`
                  : ListStatusEnum.Blocked === row.statusValue ? `${Unblock_user}` : null
                }
              </li> : ''}
            {(ListStatusEnum.NotActive === row.statusValue) ?
              <li style={{ cursor: "pointer", width: "100%" }}
                onClick={() => this.activateUser(row.id)}>
                {`${Activate_user}`}
              </li> : ''}
            {<li style={{ cursor: "pointer", width: "100%", marginTop: "10px" }}
              onClick={() => this.openDeleteModal(row.id)}
            >{Delete}
            </li>}
          </ToolBar>
        </div>
      },
      style: { minWidth: 50, maxWidth: 50 },
    }
  ]

  public openDeleteModal = (id: string) => {
    this.asyncSetState({ deleteModalIsOpen: true, activeId: id });
    document.body.style.overflow = "hidden";
  }

  public closeModal = () => {
    this.asyncSetState({ deleteModalIsOpen: false });
    document.body.style.overflow = "auto";
  }

  public setOperatorValue = (key: string, value: any) => {
    this.state[key].operationEnumValue = value;
    this.asyncSetState({ [key]: this.state[key] }).then(() => {
      this.getAgentsList();
    });
  }

  public setDoubleDropdownInputValue = async (key: string, value: any) => {
    this.state[key].value = value;
    this.asyncSetState({ [key]: this.state[key] })
      .then(() => {
        clearTimeout(this.inputTimer);
        this.inputTimer = setTimeout(async () => {
          this.getAgentsList();
        }, 300);
      });
  }
  private blockUser = async (id: string) => {
    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const res = await UserController.blockUser(id);
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
    if (res.success) {
      this.getAgentsList();
    } else {
      store.addNotification(Helper.notificationConfig(res.message, false));
    }
  }

  private activateUser = async (id: string) => {
    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const res = await AgentController.activateUser(id);
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
    if (res.success) {
      this.getAgentsList();
    } else {
      store.addNotification(Helper.notificationConfig(res.message, false));
    }
  }

  private deleteBuyer = async (id: string) => {
    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const res = await AgentController.deleteBuyer(id);
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
    if (res.success) {
      this.closeModal();
      this.getAgentsList();
    }
  }

  public reset = () => {

    this.asyncSetState({
      statusList: [],
      nameOrPhone: "",
      page: 0,
      totalPages: 0,
      walletBalance: {
        operationEnumValue: "",
        value: "",
        walletBalance: "",
      },
      cardboardBalance: {
        operationEnumValue: "",
        value: "",
        cardboardBalance: "",
      },
      dealsCount: {
        operationEnumValue: "",
        value: "",
        dealsCount: "",
      },
      rating: {
        operationEnumValue: "",
        value: "",
        rating: "",
      },
      totalWithdraws: {
        operationEnumValue: "",
        value: "",
        totalWithdraws: "",
      },
    }).then(() => {
      this.getAgentsList();
    })
  }

  public inputTimer: any;

  public onInput = async (evt: React.SyntheticEvent<HTMLInputElement>) => {

    await this.asyncSetState({ nameOrPhone: evt.currentTarget.value })
    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(async () => {
      this.getAgentsList();
    }, 300);
  }

  public onSelect = async (arr: Array<number>) => {
    await this.asyncSetState({ statusList: arr });
    this.getAgentsList();
  }

  downloadBuyers = async () => {
    const { statusList, nameOrPhone, walletBalance, dealsCount, rating, totalWithdraws, cardboardBalance } = this.state;
    let body: any = {
      statusList,
      nameOrPhone,
    };
    if (walletBalance.value) {
      body.walletBalance = {
        operationEnumValue: walletBalance.operationEnumValue,
        value: walletBalance.value,
        walletBalance: null,
      }
    }
    if (dealsCount.value) {
      body.dealsCount = {
        operationEnumValue: dealsCount.operationEnumValue,
        value: dealsCount.value,
        dealsCount: null,
      }
    }
    if (rating.value) {
      body.rating = {
        operationEnumValue: rating.operationEnumValue,
        value: rating.value,
        // rating: null,
      }
    }
    if (cardboardBalance.value) {
      body.cardboardBalance = {
        operationEnumValue: cardboardBalance.operationEnumValue,
        value: cardboardBalance.value,
        cardboardBalance: null,
      }
    }
    if (totalWithdraws.value) {
      body.totalWithdraws = {
        operationEnumValue: totalWithdraws.operationEnumValue,
        value: totalWithdraws.value,
        totalWithdraws: null,
      }
    }
    const result = await UserController.downloadBuyers(body);
    window.open(result.data, '_blank');
  }
  
  downloadBuyersDaily = async () => {
    const body: IGetAgentExportDate = {
      fromDate: moment().subtract(45, 'days').toDate().getTime(),
      toDate:  moment(new Date()).endOf('day').toDate().getTime(),
    }
    const result = await DashboardController.downloadBuyersDailyReport(body);
    if (result.data) {
      window.open(result.data, '_blank');
    }
  }

  public render() {

    const { statusList,
      agentsList,
      nameOrPhone,
      totalPages,
      totalElements,
      loaded,
      isFirstInit,
      walletBalance,
      cardboardBalance,
      totalWithdraws,
      dealsCount,
      deleteModalIsOpen,
      rating } = this.state;

    return (
      <section className="P-agents-page" >
        <div className="G-flex G-mb-2 G-justify-between G-align-center">
          <h4 className="">{Agents_} ({totalElements})</h4>
          <div className='G-flex'>
            <button className="G-gray-btn" onClick={this.downloadBuyersDaily}>Export report</button>
            <button className="G-gray-btn G-ml-2" onClick={this.downloadBuyers}>Export</button>
          </div>
        </div>
        <div className="G-flex G-flex-wrap P-selects-wrapper">
          <div className="G-mr-1">
            <input className="G-text-input"
              placeholder={Search}
              type="text"
              value={nameOrPhone}
              onChange={this.onInput}
            />
          </div>
          <div className="G-mr-1">
            <DoubleDropDown placeholder={Cardboard_balance}
              property="cardboardBalance"
              operatorValue={cardboardBalance.operationEnumValue}
              inputValue={cardboardBalance.value}
              setDoubleDropdownInputValue={this.setDoubleDropdownInputValue}
              setOperatorValue={this.setOperatorValue} />
          </div>
          <div className="G-mr-1">
            <DoubleDropDown placeholder={Wallet_}
              property="walletBalance"
              operatorValue={walletBalance.operationEnumValue}
              inputValue={walletBalance.value}
              setDoubleDropdownInputValue={this.setDoubleDropdownInputValue}
              setOperatorValue={this.setOperatorValue} />
          </div>
          <div className="G-mr-1">
            <DoubleDropDown placeholder={Earnings}
              property="totalWithdraws"
              operatorValue={totalWithdraws.operationEnumValue}
              inputValue={totalWithdraws.value}
              setDoubleDropdownInputValue={this.setDoubleDropdownInputValue}
              setOperatorValue={this.setOperatorValue} />
          </div>
          <div className="G-mr-1">
            <DoubleDropDown placeholder={Deals_}
              property="dealsCount"
              operatorValue={dealsCount.operationEnumValue}
              inputValue={dealsCount.value}
              setDoubleDropdownInputValue={this.setDoubleDropdownInputValue}
              setOperatorValue={this.setOperatorValue} />
          </div>
          <div className="G-mr-1">
            <DoubleDropDown placeholder={Agent_rate}
              property="rating"
              operatorValue={rating.operationEnumValue}
              inputValue={rating.value}
              setDoubleDropdownInputValue={this.setDoubleDropdownInputValue}
              setOperatorValue={this.setOperatorValue} />
          </div>
          <div className="G-mr-1">
            <MultiSelect
              name="Status"
              height="45px"
              width="220px"
              setFieldValue={this.onSelect}
              value={statusList}
              options={statusOptions} />
          </div>
          <span className=" G-button P-button" onClick={this.reset}>{Reset}</span>
        </div>
        <Table columnConfig={this.config}
          data={agentsList}
          isFirstInit={isFirstInit}
          loaded={loaded}
        />
        <Paging
          totalPages={totalPages}
          onChange={this.getAgentsList}
        />
        <Modal
          isOpen={deleteModalIsOpen}
          onRequestClose={this.closeModal}
          style={{
            content: {
              padding: "30px",
              minHeight: "180px",
              minWidth: "350px",
              display: "flex",
              flexDirection: "column",
            }
          }}
        >
          <div className="P-delete-modal">
            <h5 className="G-mb-2 P-title">{Delete}</h5>
            <p className="G-mb-3 P-text">{Are_you_sure}</p>
            <div className="G-flex">
              <button className="G-mr-2 P-cancel" onClick={this.closeModal}>{Cancel}</button>
              <button className="G-button P-button" onClick={() => this.deleteBuyer(this.state.activeId)}>{Delete}</button>
            </div>
          </div>
        </Modal>
      </section >
    );
  }
}

export default Agents;