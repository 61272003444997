import * as React from 'react';
import { OrderType } from 'src/platform/enums/enums';
import HelperComponent from 'src/platform/classes/helper-component';
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, } from "react-google-maps";
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
import { RouteComponentProps, withRouter } from 'react-router';
import AgentOrderMapDetail from './AgenOrderMapDetail';
import './index.scss';

interface IProps extends RouteComponentProps {
  deliveryList: Array<any>,
  acceptOrder: (id: string) => void,
  openModal: (index: number, orderId: number) => void,
}

interface IState {
  requestIsOff: boolean,
  page: number,
  size: number,
  totalPages: number,
  loaded: boolean;
  isFirstInit: boolean,
  activeId: number,
}

const MapComponent = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyC8G7V6MGzigq5jmvKhvTpqA-n6T_8th-Q&libraries=places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props: any) =>
  <GoogleMap
    defaultZoom={12}
    center={props.array && { lat: 40.1872023, lng: 44.51520900000003 }}>
    {!!props.array.length && props.array.map((item: any, index: number) => {
      return <InfoBox
        key={index}
        position={new google.maps.LatLng(item.addressForOrderDto && item.addressForOrderDto.latitude, item.addressForOrderDto.longitude)}
        options={{
          closeBoxURL: ``,
          enableEventPropagation: true,
          pixelOffset: new google.maps.Size(-35, -25),
        }}>
        <div
          className="G-cursor"
          style={{
            width: "95px",
            height: "40px",
          }}>
          <div className={`P-marker ${props.activeId === item.orderId ? "P-marker-active" : ""}`}
            onClick={() => {
              props.getActiveId(item.orderId);
            }}
          >
            {item.amount} t
          </div>
        </div>
      </InfoBox>
    })}
  </GoogleMap>
)

class OrdersMap extends HelperComponent<IProps, IState> {

  public state: IState = {
    page: 0,
    size: 8,
    requestIsOff: false,
    totalPages: null,
    loaded: false,
    isFirstInit: true,
    activeId: null,
  }

  private getActiveId = async (orderId: string | number) => {
    this.asyncSetState({ activeId: orderId })
  }

  public render() {

    const { activeId } = this.state;

    return (
      <section className="P-orders-map G-flex">
        {activeId ? <section className="P-agent-order-map-details">
          <AgentOrderMapDetail 
          acceptOrder={this.props.acceptOrder}
          openModal={this.props.openModal}
          orderType={OrderType.Delivery} 
          activeId={activeId} />
        </section >
          : null}
        <div style={{ height: "auto", width: '100%' }}>
          <MapComponent
            array={this.props.deliveryList}
            getActiveId={this.getActiveId}
            activeId={activeId}
          />
        </div>
      </section>
    );
  }
}

export default withRouter(OrdersMap);