import * as React from 'react';
import HelperComponent from 'src/platform/classes/helper-component';
import SellerOrderDetail from './SellerOrderDetail';
import SellerOrderDetailRight from './SellersOrdersDetailRight';
import { ISellersOrdersListResponseModel } from 'src/platform/api/order/order-interface';
import { RouteComponentProps } from 'react-router';
import './index.scss';
import Languages from 'src/platform/services/languages';

interface IState {
    activeId: number,
}

const {
    no_search,
} = Languages.Translations

interface IProps extends RouteComponentProps {
    loaded: boolean;
    sellersOrdersList: Array<ISellersOrdersListResponseModel>
    getSellersOrdersList: () => void,
    loadMore: () => void,
    totalPages: number,
    isFirstInit: boolean,
}

class SellersOrdersDetails extends HelperComponent<IProps, IState> {

    public state: IState = {
        activeId: null,
    }

    public inputTimer: any;

    public handleScroll = (e: any) => {
        if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight - 50 <= 0) {
            this.props.loadMore();
        }
    }

    componentDidMount() {
        const activeId = new URLSearchParams(this.props.location.search).get("activeOrderId")
        this.asyncSetState({ activeId: +activeId || (this.props.sellersOrdersList.length && this.props.sellersOrdersList[0].orderId) });
    }

    componentDidUpdate(prevProps: IProps) {
        if (this.props.sellersOrdersList !== prevProps.sellersOrdersList) {
            const existItem = this.props.sellersOrdersList.find(x => x.orderId === this.state.activeId);
            if (!existItem) {
                this.asyncSetState({ activeId: this.props.sellersOrdersList.length && this.props.sellersOrdersList[0].orderId });
            }
        }
    }

    public render() {
        const { sellersOrdersList } = this.props;
        const { activeId } = this.state;

        return (<section className="P-seller-orders-details G-flex G-mb-1">
            <div className="G-flex">
                <div className="P-left" onScroll={this.handleScroll}>
                    {sellersOrdersList.map((item, index) => {
                        return <SellerOrderDetail
                            key={index}
                            item={item}
                            makeActive={() => { this.asyncSetState({ activeId: item.orderId }) }}
                            activeId={activeId}
                        />
                    })}
                </div>
            </div>
            <div className="P-right">
                {sellersOrdersList.length ?
                    <SellerOrderDetailRight activeId={activeId || (sellersOrdersList.length && sellersOrdersList[0].orderId)} />
                    : <div className="G-flex G-justify-center P-empty"><h5>{no_search}</h5></div>}
            </div>
        </section >
        );
    }
}

export default SellersOrdersDetails;