import * as React from 'react';
import HelperComponent from 'src/platform/classes/helper-component';
import { Formik, Form, Field, FormikValues, FormikErrors } from 'formik';
import * as Yup from 'yup';
import DonationsController from 'src/platform/api/donations/donations';
import Loader from 'src/platform/components/loader/loader';
import "./index.scss";
import { IGreateProject, IGetDetails } from 'src/platform/api/donations/donations-interface';
import Languages from 'src/platform/services/languages';
import Helper from 'src/platform/services/helper';
import { store } from 'react-notifications-component';

export interface IProps {
  closeModal: () => void,
  getList: () => void,
  id: number,
}
export interface IState {
  description: number,
  image: any,
  imageSrc: (ArrayBuffer | string)[],
  images: Blob[],
  detailsData: IGetDetails,
  title_1: string,
  title_2: string,
  title_3: string,
  description_1: string,
  description_2: string,
  description_3: string,
  redBorder: boolean,

}

const {
  Close,
  Description,
  Save,
  Edit_project,
} = Languages.Translations;

class EditPopup extends HelperComponent<IProps, IState> {

  fileElement = React.createRef<HTMLInputElement>();

  state: IState = {
    description: 1,
    image: null,
    imageSrc: [],
    images: [],
    detailsData: null,
    title_1: "",
    title_2: "",
    title_3: "",
    description_1: "",
    description_2: "",
    description_3: "",
    redBorder: false,
  }

  public validationSchema = Yup.object().shape({
    title_1: Yup.string().min(1).required().trim(),
    title_2: Yup.string().min(1).required().trim(),
    title_3: Yup.string().min(1).required().trim(),
    description_1: Yup.string().min(5).required().trim(),
    description_2: Yup.string().min(5).required().trim(),
    description_3: Yup.string().min(5).required().trim(),
  })

  componentDidMount = () => {
    this.getDetails(this.props.id)
  }

  private sendProject = async (values: FormikValues, errors: FormikValues) => {

    const body: IGreateProject = {
      projectTranslateList: [
        {
          languageName: "hy",
          description: values.description_1.trim(),
          title: values.title_1,
        },
        {
          languageName: "ru",
          description: values.description_2.trim(),
          title: values.title_2,
        },
        {
          languageName: "en",
          description: values.description_3.trim(),
          title: values.title_3,
        }
      ],
    }

    const { detailsData } = this.state;

    if (this.state.images.length || detailsData.projectImages.length) {

      const res = await DonationsController.editDetails(this.props.id, body);
      if (res.success) {
        await this.uploadImage();
        this.props.closeModal()
        this.props.getList()
      } else {
          store.addNotification(Helper.notificationConfig(res.message, false));
        }
    } else if (!this.state.images.length && !detailsData.projectImages.length) {
        this.setState({ redBorder: true })
      } else this.setState({ redBorder: false })

    // if (this.state.images.length) {
    //     this.uploadImage();
    // }
  }

  onSubmit = (errors: FormikErrors<FormikValues>) => {
    if (!errors.title_1 && errors.title_2) {
      this.asyncSetState({ description: 2 });
    }
    if (!errors.title_1 && !errors.title_2 && errors.title_3) {
      this.asyncSetState({ description: 3 });
    }
  }

  selectFile = (event: any) => {
    const images = this.state.images;
    const imageSrc = this.state.imageSrc;
    Object.keys(event.target.files).forEach(key => {
      const READER = new FileReader();
      const file = event.target.files[key];
      READER.readAsDataURL(file);
      READER.onload = (READER) => {
        imageSrc.push(READER.target.result);
        images.push(file);
        this.setState({
          imageSrc,
          images,
        });
      };
    });

    this.fileElement.current.value = '';
    this.setState({ redBorder: false })
  }

  uploadImage = async () => {
    const { images } = this.state
    const data = new FormData()
    images.forEach(x => data.append('files', x));
    const res = await DonationsController.uploadImage(data, this.props.id);
    if (res.success) {
        this.props.closeModal()
        this.props.getList()
    }
  }

  deleteImageFromProject = async (url: string) => {
    const urlArr = url.split('/');
    const result = await DonationsController.deleteImageFromProject(this.props.id, urlArr[urlArr.length - 1]);
    if (result.success) {
      const { detailsData } = this.state;
      detailsData.projectImages = detailsData.projectImages.filter(x => x !== url);
      this.setState({ detailsData });
    }
  }

  deleteLocalImage = (index: number) => {
    const { imageSrc, images } = this.state;
    imageSrc.splice(index, 1);
    images.splice(index, 1);
    this.setState({
      imageSrc,
      images,
    });
  }

  private getDetails = async (id: number) => {
    const result = await DonationsController.openDetails(id)
    if (result.success) {
      this.asyncSetState({
        detailsData: result.data,
        title_1: result.data.projectTranslateList.find((item: any) => item.languageName === "hy").title,
        title_2: result.data.projectTranslateList.find((item: any) => item.languageName === "ru").title,
        title_3: result.data.projectTranslateList.find((item: any) => item.languageName === "en").title,
        description_1: result.data.projectTranslateList.find((item: any) => item.languageName === "hy").description,
        description_2: result.data.projectTranslateList.find((item: any) => item.languageName === "ru").description,
        description_3: result.data.projectTranslateList.find((item: any) => item.languageName === "en").description,
      })
    }
  }

  render() {
    const { description, imageSrc, redBorder } = this.state;

    return (<div className="P-create-popup">
      <h5 className="G-mb-3 P-title">{Edit_project}</h5>
      <Formik enableReinitialize
        initialValues={{
          title_1: this.state.title_1,
          title_2: this.state.title_2,
          title_3: this.state.title_3,
          description_1: this.state.description_1,
          description_2: this.state.description_2,
          description_3: this.state.description_3,
        }}

        onSubmit={this.sendProject}
        validationSchema={this.validationSchema}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form noValidate={true}>
            <div className="G-flex G-justify-between">
              <span className="G-mb-2 P-text G-bold">{Description}</span>
              <div>
                <span onClick={() => this.asyncSetState({ description: 1 })}
                  className={`G-mb-2 G-mr-1 P-lang G-cursor ${description === 1 && "P-green"}`}>ARM</span>
                <span onClick={() => this.asyncSetState({ description: 2 })}
                  className={`G-mb-2 G-mr-1 P-lang G-cursor ${description === 2 && "P-green"}`}>RU</span>
                <span onClick={() => this.asyncSetState({ description: 3 })}
                  className={`G-mb-2 G-mr-1 P-lang G-cursor ${description === 3 && "P-green"}`}>ENG</span>
              </div>
            </div>
            <div className="G-flex P-details-images">
              <label className={redBorder ? "P-red-border" : ""}>
                <input
                  name="selectedFile"
                  type="file"
                  ref={this.fileElement}
                  multiple
                  onChange={this.selectFile}
                />
                <i className="icon-ic_plus"></i>
              </label>
              {imageSrc ?
                imageSrc.map((item, index) => {
                  return (
                    <div className="P-details-img" key={index}>
                      <div className="P-project-image"
                        style={{ background: `url('${item}') center/cover no-repeat` }}>
                      <i className="P-delete-icon icon-cancel" onClick={() => this.deleteLocalImage(index)}></i>
                      </div>
                    </div>)
                })
                : ""}
              {this.state.detailsData ?
                this.state.detailsData.projectImages.map((item) => {
                  return (
                    <div className="P-details-img">
                      <div className="P-project-image"
                        style={{ background: `url(${`${item}/300/300`}) center/cover no-repeat` }}>
                      <i className="P-delete-icon icon-cancel" onClick={() => this.deleteImageFromProject(item)}></i>
                      </div>
                      <div className="P-empty-image"></div>
                    </div>
                  )
                })
                : ""}
            </div>
            {this.state.detailsData !== null ?
              this.state.detailsData.projectTranslateList.map((item) => {
                return (

                  <div>
                    {item.languageName === "hy" ? <div>
                      {description === 1 ? <div className="P-am">
                        <Field
                          name="title_1"
                          placeholder="Title"
                          className={`G-text-input G-mb-2 P-input ${touched.title_1 && errors.title_1 ? "G-error" : ""}`}
                          type="text" />
                        <Field
                          component="textarea"
                          name="description_1"
                          placeholder="Description"
                          className={`G-textarea P-text-area G-mb-3 P-input ${touched.description_1 && errors.description_1 ? "G-error" : ""}`}
                          cols={1}
                          rows={1}
                        />
                      </div> : null}
                    </div> : ""}
                    {item.languageName === "ru" ? <div>
                      {description === 2 ?
                        <div className="P-ru">
                          <Field
                            name="title_2"
                            placeholder="Title"
                            className={`G-text-input G-mb-2 P-input ${touched.title_2 && errors.title_2 ? "G-error" : ""}`}
                            type="text" />
                          <Field
                            component="textarea"
                            name="description_2"
                            placeholder="Description"
                            className={`G-textarea P-text-area G-mb-3 P-input ${touched.description_2 && errors.description_2 ? "G-error" : ""}`}
                            cols={1}
                            rows={1}
                          />
                        </div> : null}
                    </div> : ""}
                    {item.languageName === "en" ? <div>
                      {description === 3 ?
                        <div className="P-en">
                          <Field
                            name="title_3"
                            placeholder="Title"
                            className={`G-text-input G-mb-2 P-input ${touched.title_3 && errors.title_3 ? "G-error" : ""}`}
                            type="text" />
                          <Field
                            component="textarea"
                            name="description_3"
                            placeholder="Description"
                            className={`G-textarea P-text-area G-mb-3 P-input ${touched.description_3 && errors.description_3 ? "G-error" : ""}`}
                            cols={1}
                            rows={1}
                          />
                        </div> : null}
                    </div> : ""}
                  </div>
                )
              })
              : ""}
            <div className="G-flex G-justify-between">
              <button className="G-mr-2 P-cancel" onClick={this.props.closeModal}>{Close}</button>
              <button className="G-button P-button" type="submit"
                onClick={() => this.onSubmit(errors)}>
                {isSubmitting ? <Loader /> : `${Save}`}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>);
  }
}

export default EditPopup;