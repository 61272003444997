import * as React from 'react';
import Helper from 'src/platform/services/helper';
import noPic from 'src/assets/images/no-photo.png'
import moment from 'moment';
import { OrderSellerStatusEnum } from 'src/platform/enums/enums';
import { withRouter, RouteComponentProps } from 'react-router';
import Languages from 'src/platform/services/languages';

const {
    Any_time,
    Order_,
    Seller_,
    enums,
} = Languages.Translations
export interface ISellerOrderDetailProps extends RouteComponentProps {
    item: any,
}

const SellerOrderMapDetail = (props: ISellerOrderDetailProps) => {

    return (
        <div className="P-sellerOrderMapDetail">
            <div className="G-flex P-top G-pb-1" >
                <span className="P-order-id">{Order_} #{props.item.orderNumber}</span>
                <span>{moment(props.item.createdDate).format('MMM DD HH:mm')}</span>
                <span className="P-finished" style={{
                    color: `${(props.item.orderStatusEnumValue === OrderSellerStatusEnum.AGENT_REQUESTED ||
                        props.item.orderStatusEnumValue === OrderSellerStatusEnum.AGENT_CHOSEN ||
                        props.item.orderStatusEnumValue === OrderSellerStatusEnum.AGENT_FOUND ||
                        props.item.orderStatusEnumValue === OrderSellerStatusEnum.PENDING)
                        ? '#7A7A7A'
                        : props.item.orderStatusValue === OrderSellerStatusEnum.CANCELED ||
                            props.item.orderStatusEnumValue === OrderSellerStatusEnum.CANCELED
                            ? '#FF0057' : "#04A556"}`
                }}>{enums.OrderSellerStatusEnum[props.item.orderStatusEnumValue]}</span>
            </div>
            <div className="P-bottom G-flex G-justify-between G-pt-1">
                <div className="G-column">
                    <div className="G-mb-1 P-seller">{Seller_}</div>
                    <div className="G-flex G-cursor">
                        <div className="G-flex P-image"
                            style={{ background: `url(${props.item.sellerImageUrl ? `${props.item.sellerImageUrl}/100/100` : noPic}) center/cover no-repeat` }}>
                        </div>
                        <span>{props.item.sellerName}</span>
                    </div>
                </div>
                <div className="P-right-part">
                    <div className="G-mb-1">
                        {props.item.orderTimesDtos.map((item: any, index: number) => {
                            let from = item.fromTime;
                            let to = item.toTime;
                            return (<div key={index} className='P-time G-mr-1'>
                                {item.fromTime !== 0 ? <>
                                {moment(new Date((from * 1000) - 14400000)).format('HH:mm')} - 
                                {moment(new Date((to * 1000) - 14400000)).format('HH:mm')}</> : `${Any_time}`}
                            </div>);
                        })}
                    </div>
                    <div>{Helper.sliceSentence(props.item.addressFullName, 17)}</div>
                </div>
            </div>
        </div>);
}

export default withRouter(SellerOrderMapDetail)