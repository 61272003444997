import { IGetListRequestModel, IGetListRequestModelBody, IGetFeedbackListRequestModel } from "./user-interface";
import { BaseController } from "../../classes/http-service";
import { IResponse } from 'src/platform/interfaces';

class UserController extends BaseController {
  private controller = 'user';

  public getAgentsList = async (form: IGetListRequestModel): Promise<IResponse<any>> => {

    const result = await this.connection.POST<IGetListRequestModelBody>({
      controller: this.controller,
      action: `getAllBuyers/${form.page}/${form.size}`,
      body: form.body || {},
    });

    return result;
  }

  public getSellersList = async (form: IGetListRequestModel): Promise<IResponse<any>> => {
    const result = await this.connection.POST<IGetListRequestModelBody>({
      controller: this.controller,
      action: `getAllSellers/${form.page}/${form.size}`,
      body: form.body || {},
    });
    return result;
  }
  
  public getSellerAccounts = async (userId: number): Promise<IResponse<any>> => {
    const result = await this.connection.GET({
      controller: this.controller,
      action: `getSellerAccounts?UserId=${userId}`,
    });
    return result;
  }

  public blockUser = async (id: string): Promise<IResponse<any>> => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: `blockUser?UserId=${id}`,
      body: null,
    });

    return result;
  };
  
  public userDetails = async (id: string): Promise<IResponse<any>> => {
    const result = await this.connection.GET({
      controller: this.controller,
      action: `getUserById?UserId=${id}`,
    });

    return result;
  };

  public getFeedbackListById = async (form: IGetFeedbackListRequestModel): Promise<IResponse<any>> => {
    const result = await this.connection.GET({
      controller: this.controller,
      action: `getFeedbackListByBuyerId/${form.page}/${form.size}?buyerId=${form.buyerId}`,
    });

    return result;
  };

  public getFeedbackListBySellerId = async (form: IGetFeedbackListRequestModel): Promise<IResponse<any>> => {
    const result = await this.connection.GET({
      controller: this.controller,
      action: `getFeedbackListBySellerId/${form.page}/${form.size}?sellerId=${form.sellerId}`,
    });

    return result;
  };

  public setTaxNumber = async (id: string, taxNumber: string): Promise<IResponse<any>> => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: `setTaxNumber/${id}`,
      body: {taxNumber} || {},
    });
    return result;
  }
  
  public downloadBuyers = async (form: IGetListRequestModel): Promise<IResponse<any>> => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: `downloadBuyers`,
      body: form,
    });
    return result;
  }
  
  public downloadSellers = async (form: IGetListRequestModel): Promise<IResponse<any>> => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: `downloadSellers`,
      body: form.body,
    });
    return result;
  }

  public searchUsers = async (form: IGetListRequestModel, text: string): Promise<IResponse<any>> => {

    const result = await this.connection.GET({
      controller: this.controller,
      action: `searchUsers/${form.page}/${form.size}/?text=${text}`,
    });

    return result;
  }

}
export default new UserController();