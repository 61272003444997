import * as React from 'react';
import { OrderAgentStatusEnum, ListStatusEnum, AccountTypeEnum, SelectTimeRangeEnum, EcoTypeEnum, UserTypeEnum, UserTypeSingleEnum, ProjectStatus, OrderSellerStatusEnum } from 'src/platform/enums/enums';

enum OrderAgentStatusLangEnum {
  "Pending" = OrderAgentStatusEnum.PENDING,
  "Agent found" = OrderAgentStatusEnum.AGENT_FOUND,
  "Agent in place" = OrderAgentStatusEnum.AGENT_IN_PLACE,
  "Agent requested" = OrderAgentStatusEnum.AGENT_REQUESTED,
  "Agent chosen" = OrderAgentStatusEnum.AGENT_CHOSEN,
  "Finished" = OrderAgentStatusEnum.FINISHED,
  "Canceled" = OrderAgentStatusEnum.CANCELED,
  "Rejected" = OrderAgentStatusEnum.REJECTED,
  "Accepted" = OrderAgentStatusEnum.ACCEPTED,
}

export enum OrderSellerStatusLangEnum {
  "Pending" = OrderSellerStatusEnum.PENDING,
  "Agent found" = OrderSellerStatusEnum.AGENT_FOUND,
  "Agent in place" = OrderSellerStatusEnum.AGENT_IN_PLACE,
  "Agent requested" = OrderSellerStatusEnum.AGENT_REQUESTED,
  "Agent chosen" = OrderSellerStatusEnum.AGENT_CHOSEN,
  "Finished" = OrderSellerStatusEnum.FINISHED,
  "Canceled" = OrderSellerStatusEnum.CANCELED,
  "Rejected" = OrderSellerStatusEnum.REJECTED,
  "Accepted" = OrderSellerStatusEnum.ACCEPTED,
}

export enum ListStatusLangEnum {
  "Active" = ListStatusEnum.Active,
  "Blocked" = ListStatusEnum.Blocked,
  "Deactivated" = ListStatusEnum.Deactivated,
  "Deleted" = ListStatusEnum.DELETED,
  "Not active" = ListStatusEnum.NotActive,
}

export enum AccountTypeLangEnum {
  "Individual" = AccountTypeEnum.Individual,
  "Corporate" = AccountTypeEnum.Corporate,
  "All" = AccountTypeEnum.All,
}

export enum SelectTimeRangeLangEnum {
  "Today" = SelectTimeRangeEnum.Today,
  "Yesterday" = SelectTimeRangeEnum.Yesterday,
  "Week" = SelectTimeRangeEnum.Week,
  "Month" = SelectTimeRangeEnum.Month,
  "Year" = SelectTimeRangeEnum.Year,
  "CustomRange" = SelectTimeRangeEnum.CustomRange,
  "All" = SelectTimeRangeEnum.All,
}

export enum EcoTypeLangEnum {
  "CITIZEN" = EcoTypeEnum.CITIZEN,
  "MASTER" = EcoTypeEnum.MASTER,
  "GENIUS" = EcoTypeEnum.GENIUS,
  "HERO" = EcoTypeEnum.HERO,
}

export enum UserTypeLangEnum {
  "Sellers" = UserTypeEnum.Sellers,
  "Agents" = UserTypeEnum.Agents,
  "Guests" = UserTypeEnum.Guests,
  "All" = UserTypeEnum.All,
}

export enum UserTypeSingleLangEnum {
  "Seller" = UserTypeSingleEnum.Seller,
  "Agent" = UserTypeSingleEnum.Agent,
  "Guest" = UserTypeSingleEnum.Guest,
}

export enum ProjectLangStatus {
  "Open" = ProjectStatus.Open,
  "Closed" = ProjectStatus.Closed,
}

export default {
  enums: {
    OrderAgentStatusEnum  : OrderAgentStatusLangEnum,
    ListStatusEnum        : ListStatusLangEnum,
    AccountTypeEnum       : AccountTypeLangEnum,
    SelectTimeRangeEnum   : SelectTimeRangeLangEnum,
    EcoTypeEnum           : EcoTypeLangEnum,
    UserTypeEnum          : UserTypeLangEnum,
    UserTypeSingleEnum    : UserTypeSingleLangEnum,
    ProjectStatus         : ProjectLangStatus,
    OrderSellerStatusEnum : OrderSellerStatusLangEnum,
  },
  Dashboard_                : "Dashboard",
  Transactions_             : "Transactions",
  new_sellers               : "new sellers",
  new_user                  : "new user",
  new_orders                : "new orders",
  orders_to_deliver         : "orders to deliver",
  new_messages              : "new messages",
  donation_votings          : "donation votings",
  Newset_support_message    : "Newset support message",
  List_is_empty             : "List is empty",
  Device_usage              : "Device usage",
  iOs_devices               : "iOS devices ",
  Android_devices           : "Android devices",
  Top_sellers               : "Top sellers",
  Individuals               : "Individuals",
  Corporate                 : "Corporate",
  Available_Time            : "Available Time",
  User_                     : "User",
  Question                  : "Question",
  Answer                    : "Answer",
  Page_name                 : "PAGE NAME",
  User_name                 : <>User <br/> name</>,
  Finished_orders           : <>Finished <br/> orders</>,
  Sold_Cardboard            : <>Sold <br/> Cardboard (KG)</>,
  Donation_Amounts          : <>Donation <br/> Amounts</>,
  Top_agents                : "Top agents",
  Bought_Cardboard          : <>Bought <br/> Cardboard (KG)</>,
  Total_earnings            : <>Total <br/> earnings</>,
  Finish_request            : "Finish request",
  Rejection_reason          : "Rejection reason",
  Сancellation_reason       : "Сancellation reason",
  pending_sellers_requests  : "pending sellers requests",
  completed_sellers_requests: "completed sellers requests",
  pending_agents_requests   : "pending agents requests",
  completed_agents_requests : "completed agents requests",
  User_status_activity      : "User status activity",
  Languages_                : "Languages",
  Language_                 : "Language",
  Resources_                : "Resources",
  Today                     : "Today",
  Yesterday                 : "Yesterday",
  Week                      : "Week",
  Month                     : "Month",
  Year                      : "Year",
  Last_week                 : "Last week",
  Last_month                : "Last month",
  Last_year                 : "Last year",
  Custom_range              : "Custom range",
  Start                     : "Start",
  End                       : "End",
  Projects                  : "Projects",
  Winner                    : "Winner",
  Winner_                   : "Winner",
  Customer                  : "Customers",
  Number_registered_customers:"Number of registered customers / agents (pc)",
  Sellers_                  : "Sellers",
  Seller_                   : "Seller",
  Agents_                   : "Agents",
  Agent_                    : "Agent",
  INDIVIDUAL_SELLER         : "Individual seller",
  INDIVIDUAL_CORPORATE_SELLER: "Individual corporate seller",
  CORPORATE_SELLER          : "Corporate seller",
  Search                    :  "Search…",
  Balance                   : "Balance",
  Seller_type               : "Seller type",
  Status                    : "Status",
  Reset                     : "Reset",
  Set                       : "Set",
  Operator                  : "Operator",
  Equal                     : "Equal",
  Not_equal                 : "Not equal",
  Starts_with               : "Starts with",
  Ends_with                 : "Ends with",
  Value                     : "Value",
  Name                      : "Name",
  Gender                    : "Gender",
  Male                      : "Male",
  Female                    : "Female",
  Segment                   : 'Segment',
  Search_for_specific_user  : 'Search for specific user',
  SEO_code                  : 'SEO code',
  help_top                  : 'Cover picture',
  leaderboard_top           : 'Cover picture',
  projects_top              : 'Cover picture',
  about_app_top             : 'Cover picture',
  home_top                  : 'Cover picture',
  home_video                : 'About file',
  home_footer               : 'Footer file',
  home_page                 : 'Home page',
  about_app                 : "About app",
  projects                  : "Projects",
  leaderboard               : "Leaderboard",
  help                      : "Help",
  Customer_age_statistics   : "Customer age statistics",
  Content_management        : "Content management",
  Media                     : "Media",
  Content                   : "Content",
  Faq                       : "FAQ",
  Change                    : "Change",
  Phone_number              : "Phone number",
  General                   : "General",
  Not_selected              : "Not selected",
  Requests_                 : "Requests",
  Requests_count            : "Requests count",
  Waste_weight              : "Waste weight of paper procured from customers (kg)",
  Number_of_applications    : "Number of orders opened by sellers (unit)",
  Current_balance           : "Current balance",
  Donated_amount            : "Donated amount ",
  Donated_point             : "Donated point ",
  Collected_point           : "Collected point ",
  Leaderboard_place         : "Leaderboard place ",
  Leaderboard_              : "Leaderboard",
  Account_type              : "Account type ",
  Account_settings          : "Account settings",
  Audience_count            : "Audience count",
  Registration_date         : "Registration date",
  Given_date                : "Given date",
  Sent_date                 : "Sent date",
  Creation_date             : "Creation date",
  Creation_date_            : "Creation date",
  Cardboard_balance         : "Cardboard balance ",
  Agent_cardboard_limits    : "Agent cardboard limits",
  Weight_limit              : "Weight limit",
  Minimum_limit             : "Minimum limit",
  Negative_limit            : "Negative limit",
  Wallet_balance            : "Wallet balance ",
  Wallet_                   : "Wallet balance ",
  Total_Earnings            : "Total Earnings ",
  Earnings                  : "Total Earnings ",
  Deals_count               : "Deals count ",
  Deals_                    : "Deals count ",
  Active                    : "Active",
  Blocked                   : "Blocked",
  Deactivated               : "Deactivated",
  Deleted                   : "Deleted",
  NotActive                 : "Not active",
  Yearly                    : "Yearly",
  Monthly                   : "Monthly",
  Block_user                : "Block user",
  Activate_user             : "Activate user",
  Unblock_user              : "Unblock user",
  Unblock                   : "Unblock",
  Are_you_sure_unblock      : "Are you sure you want to unblock this user?",
  Are_you_sure_block        : "Are you sure you want to block this user?",
  Block                     : "Block",
  All                       : "All",
  Orders_                   : "Orders",
  Order_                    : "Order",
  Sellers_orders            : "Sellers orders",
  Agents_orders             : "Agents orders",
  History                   : "History",
  Search_name_or            : "Search name or #",
  Search_agent              : "Search agent",
  Address                   : "Address",
  Cardboard_weight          : "Cardboard weight (KG)",
  Overloaded_hours          : "Overloaded hours",
  With_weight               : "With weight (KG)",
  kg                        : "kg",
  km                        : "km",
  meters                    : "meters",
  Accept                    : "Accept",
  Accepted                  : "Accepted",
  Reject                    : "Reject",
  Rejected                  : "Rejected",
  Pending                   : "Pending",
  Agent_found               : "Agent found",
  Agent_accepted            : "Agent accepted",
  Agent_chosen              : "Agent chosen", 
  Self_delivery             : "Self-delivery",
  Delivery                  : "Delivery",
  Finished                  : "Finished",
  Finished_                 : "Finished",
  Finish                    : "Finish",
  Canceled                  : "Canceled",
  Push_notifications        : "Push notifications",
  Push_notification_was_sent: "Push notification was sent successfully",
  Search_message            : "Search messages",
  User_type                 : "User type",
  Device_type               : "Device type",
  All_users                 : "All users",
  All_devices               : "All devices",
  Deal_ID                   : "Deal ID",
  Create                    : "Create",
  Views_count               : "Views count",
  Message_type              : "Message type",
  Message                   : "Message",
  Notification_title        : "Notification title",
  Notification_message      : "Notification message",
  Description               :  "Description",
  Create_push_notifications : "Create push notifications",
  Auditory                  : "Auditory",
  Cancel                    : "Cancel",
  All_sellers               :  "All sellers",
  Agent_name                :  "Agent name",
  Points_amount             : "Points amount",
  Date_                     : "Date",
  Settings_                 : "Settings",
  Map_radius                : "Map radius",
  In_place_radius           : "In place radius",
  Buyer_request_kg_limit    : "Buyer request kg limit",
  Location                  : "Location",
  Amount                    : "Amount",
  User_details              : "User details",
  Personal_information      : "Personal information",
  Order_information         : "Order information",
  Order_start_date          : "Order start date:",
  Start_date                : "Start date",
  Agent_attached_date       : "Agent attached date:",
  Order_finish_date         : "Order finish date:",
  Order_count               : "Order count",
  Cardboard_apx_amount      : "Cardboard apx amount:",
  Cardboard_apx_weight      : "Cardboard apx weight:",
  Cardboard_actual_amount   : "Cardboard actual amount:",
  Cardboard_actual_weight   : "Cardboard actual weight:",
  Cardboard_price           : "Cardboard price",
  Cardboard_price_          : "Cardboard price:",
  Details_                  : "Details",
  deals_done                : "deals done",
  sold_cardboard            : "sold cardboard",
  total_donated_amount      : "total donated amount",
  total_withdrawed_amount   : "total withdrawed amount",
  withdrawals               : "withdrawals",
  Cardboard_purchases       :  "Cardboard purchases",
  Cardboard_delivery        : "Cardboard delivery",
  Feedbacks                 : "Feedbacks",
  Ratings                   : "Ratings",
  new_                      : "new",
  donations                 : "Donations",
  Verified                  : "Verified",
  Not_verified              : "Not verified",
  Add                       : "Add",
  Points                    : "Points",
  Point                     : "Points",
  Save_changes              : "Save changes",
  Company_tax_number        : "Company tax number",
  Status_period_individuals : "Status period for individuals",
  Eco_statuses              : "Eco statuses",
  Eco_master                : "Eco master",
  Eco_genius                : "Eco genius",
  Eco_hero                  : "Eco hero",
  Status_period_corporates  : "Status period for corporates",
  Support_                  : "Support",
  Support_messages          : "Support messages",
  Archive_                  : "Archive",
  User_name_or_contacts     : "Name or contacts",
  View_profile              : "View profile",
  view_details              : "view details",
  Send_notification         : "Send push notification",
  Mark_as_important         : "Mark as important",
  Mark_as_unimportant       : "Mark as unimportant",
  Move_to_archive           : "Move to archive",
  Guests                    : "Guests",
  All_messages              : "All messages",
  Important_messages        : "Important messages",
  Charity_campaigns         : "Charity campaigns",
  Participated_in_campaigns : "Participated in campaigns",
  Winner_of_campaign        : "Winner of campaign" ,
  Campaign                  : "Campaign №",
  Campaigns_detail          : "Campaigns detail",
  Current_campaign          : "Current campaign",
  Previous_campaigns        : "Previous campaigns",
  Open                      : "Open",
  Close                     : "Close",
  Edit                      : "Edit",
  Delete                    : "Delete",
  Delete_project            : "Delete project",
  Are_you_sure              : "Are you sure you want to delete?",
  Are_you_sure_cancel       : "Are you sure you want to cancel?",
  Create_project            : "Create project",
  days                      : "days",
  Cardboard_price_for1kg    : "Cardboard price (for 1kg)",
  Cardboard_price_delivery  : "Cardboard price for delivery (for 1kg)",
  Cardboard_price_agents    : "Cardboard price for agent (for 1kg)",
  Buyer_refill_points       : "Buyer refill points",
  Negative_balance          : "Negative balance",
  Title                     : "Title",
  Save                      : "Save",
  Edit_project              : "Edit project",
  Votes                     : "Votes",
  symbol                    : ":",
  vote_date                 : "Vote date",
  Donation_date             : "Donation date",
  Started_date              : "Started date",
  Finished_Date             : "Finished Date",
  Persentage                : "Persentage",
  Started                   : "Started",
  Participants              : "Participants",
  Add_to_campaign           : "Add to campaign",
  Information               : "Information",
  New_Campaign              : "New Campaign",
  Edit_Campaign             : "Edit Campaign",
  Attach_projects           : "Attach projects",
  list_is_empty             : "List is empty",
  no_search                 : "No search result",
  Any_time                  : "Any time",
  History_orders            : "History orders",
  Agent_rate                : "Agent rate",
  Seller_rate               : "Seller rate",
  Order_type                : "Order type:",
  Change_password           : "Change password",
  Change_image              : "Change image",
  Old_password              : "Old password",
  New_password              : "New password",
  Confirm_new_password      : "Confirm new password",
  Show                      : "Show",
  Hide                      : "Hide",
  Sign_in                   : "Sign in",
  Sign_out                  : "Sign out",
  Sign_out_text             : <>Are you sure you want <br /> to sign out?</>,
  Next                      : "Next",
  Enter_P_code              : "Enter code",
  You_have_successfully     : "You have successfully changed your password",
  Password_was_changed      : "Password was changed successfully",
  Please_create_new_password: "Please create new password",
  Password                  : "Password",
  Forgot_password           : "Forgot password?",
  Password_recovery         : "Password recovery",
  Please_enter_your_phone   : "Please enter your phone number",
  Send_code                 : "Send code",
  Enter_code                : "Enter code",
  Please_enter_verification : "Please enter verification code sent to your phone number",
  rqs                       : "rqs",
  Set_actual_cardboard      : "Set actual cardboard weight",
  Application_workflow      : "Application workflow",
  Suggestion_recommendations: "Suggestions and recommendations",
  Report_an_issue           : "Report an issue",
  Add_passport_details      : "Add passport details",
  Passport_number           : "Passport number",
  Given_by                  : "Given by",
  Attach_file_photo         : "Attach file or photo",
  Selected                  : "Selected",
  option                    : "option",
  options                   : "options",
  AMD                       : "Unit",
  Yes                       : "Yes",
  No                        : "No",
  Project_names             : "Project names",
  Campaign_information      : "Campaign information",
  Campaign_create_text      : "Are you sure you want to create this campaign?",
  Use_for_donation          : "Use for donation",
  Earn_money                : "Earn money",
};