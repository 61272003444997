import { IResetPassRequestModel } from "./interfaces/reset-pass-req";
import { IResponse } from '../../interfaces';
import { BaseController } from "../../classes/http-service";
import { ISendPhoneNumberRequestModel } from './interfaces/send-phone-number-req';

class ForgotPassController extends BaseController {
  private controller = 'auth';

  public ResetPassword = async (form: IResetPassRequestModel): Promise<IResponse<boolean>> => {
    const result = await this.connection.POST<IResetPassRequestModel>({
      body: form,
      action: 'resetPassword',
      controller: this.controller,
    });
    return result;
  }

  public SendPhoneNumber = async (form: ISendPhoneNumberRequestModel): Promise<IResponse<boolean>> => {
    const result = await this.connection.POST({
      body: form,
      action: 'sendCodeForgot',
      controller: this.controller,
    });
    return result;
  }


  public VerifyCode = async (value: string): Promise<IResponse<boolean>> => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: `verifyFirebaseAuthForForgot?uId=${value}`,
      body: null,
    });
    return result;
  }

};

export default new ForgotPassController();