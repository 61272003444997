
import { BaseController } from "src/platform/classes/http-service";
import { IGetTransactionsListModel } from './transactions-interface';
import { IResponse } from 'src/platform/interfaces';

class TransactionsController extends BaseController {

  private controller = 'transaction';

  public getTransactionsList = async (form: IGetTransactionsListModel): Promise<IResponse<any>> => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: `getTransactions/${form.page}/${form.size}?text=${form.text}`,
      body: form.body,
    });
    return result;
  }

  public downloadTransactions = async (): Promise<IResponse<any>> => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: `downloadTransactions`,
      body: null,
    });
    return result;
  }

}

export default new TransactionsController();