import * as React from 'react';
import './index.scss';
import HelperComponent from 'src/platform/classes/helper-component';
import { RouteComponentProps } from 'react-router-dom';
import DonationsController from 'src/platform/api/donations/donations';
import moment from 'moment';
import { IGetCurrentCampaign, IGetCurrentDetails, IGetPreviusCampaign } from 'src/platform/api/donations/donations-interface';
import Details from '../organizations/details/details';
import Modal from 'react-modal';
import Languages from 'src/platform/services/languages';
import Paging from 'src/platform/components/pagination/pagination';
import CampaignController from 'src/platform/api/campaign/campaign';

interface IState {
  currectCampaignList: IGetCurrentCampaign,
  currectProjectList: boolean,
  currectDetails: IGetCurrentDetails,
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
  previousList: IGetPreviusCampaign;
  detailsModal: boolean;
  projectsId: number,
  previousDetailShow: boolean,
  createButton: boolean,
  deleteModalIsOpen: boolean,
  campaignId: number,
}

const {
  Started,
  Participants,
  symbol,
  Projects,
  Winner,
  Amount,
  Votes,
  Started_date,
  Finished_Date,
  Finished,
  Create,
  Persentage,
  Campaign,
  Charity_campaigns,
  list_is_empty,
  Previous_campaigns,
  Campaigns_detail,
  Current_campaign,
  AMD,
  Delete,
  Are_you_sure,
  Cancel,
} = Languages.Translations;

class Campaigns extends HelperComponent<RouteComponentProps, IState> {
  public state: IState = {
    currectCampaignList: null,
    currectProjectList: false,
    currectDetails: null,
    page: 0,
    size: 8,
    totalElements: null,
    previousList: null,
    detailsModal: false,
    projectsId: null,
    previousDetailShow: false,
    totalPages: null,
    createButton: false,
    deleteModalIsOpen: false,
    campaignId: null,
  }

  openDetails = (id: number) => {
    this.setState({ currectProjectList: true, previousDetailShow: true })
    this.getDetails(id)
  }
  closeDetails = () => {
    this.setState({ currectProjectList: false, previousDetailShow: false })
    document.body.style.overflow = "auto";
  }

  openDetailsModal = (projectsId: number) => {
    this.setState({ detailsModal: true, projectsId: projectsId })
    document.body.style.overflow = "hidden";
  }

  closeDetailsModal = () => {
    this.setState({ detailsModal: false })
    document.body.style.overflow = "auto";
  }
  getCurrectCampaign = async () => {
    const result = await DonationsController.getCurrectCampaign()
    if (result.success) {
      this.setState({ currectCampaignList: result.data })
    }
  }

  getDetails = async (id: number) => {
    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const result = await DonationsController.getDetails(id)
    if (result.success) {
      this.setState({ currectDetails: result.data })
    }
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
  }

  getPreviousList = async (pageNum?: { selected: number }) => {
    const { page, size } = this.state
    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const result = await DonationsController.getPreviousList({page: pageNum ? pageNum.selected : page, size })
    if (result.success) {
      this.setState({ 
        totalPages: result.data.totalPages,
        previousList: result.data, 
        totalElements: result.data.totalElements })
    }
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
  }
  
  public openDeleteModal = (id: number) => {
    this.asyncSetState({ deleteModalIsOpen: true, campaignId: id });
    document.body.style.overflow = "hidden";
  }

  public openModal = () => {
    this.asyncSetState({ modalIsOpenIndex: true });
    document.body.style.overflow = "hidden";
  }

  public openEditModal = (id: number) => {
    this.asyncSetState({ modalEdit: true, projectsId: id });
    document.body.style.overflow = "hidden";
  }

  public closeModal = () => {
    this.asyncSetState({ modalIsOpenIndex: false, infoModal: false, modalEdit: false, deleteModalIsOpen: false });
    document.body.style.overflow = "auto";
  }
  
  private deleteCampaign = async (id: number) => {
    const result = await CampaignController.deleteCampaign(id)
    if (result.success) {
      this.getCurrectCampaign()
      this.getPreviousList()
      this.closeModal();
    }
  }
  
  private downloadCampaign = async () => {
    const result = await DonationsController.downloadCampaign()
    if (result.success) {
      window.open(result.data, '_blank');
    }
  }

  componentDidMount = () => {
    this.getCurrectCampaign()
    this.getPreviousList()
  }

  render() {
    const { currectCampaignList, currectProjectList, currectDetails, previousDetailShow, totalElements, totalPages } = this.state
    return (
      <section>

        <div className="G-flex G-justify-between">
          <div className="G-mb-4 G-flex">
            <h4 className="G-flex G-cursor" onClick={this.closeDetails}>{Charity_campaigns}
              </h4>{currectProjectList ? <h4 className="G-ml-1">/ <span className="P-crumb">{Campaigns_detail}</span></h4> : ""}
          </div>
            {!currectCampaignList && !currectProjectList ?
              <div className="G-flex">
                <button className="G-gray-btn" onClick={this.downloadCampaign}>Export</button>
                <button className="G-button P-create-button G-ml-2"
                  onClick={() => {
                    this.props.history.push(`/donations/Charity/campaigns/create-campaign`);
                  }}
                >{Create}</button>
              </div>
              : ""}
        </div>

            {previousDetailShow && currectDetails ?
              <div className="P-current-box G-mb-4">
                <div className="G-flex G-justify-between G-align-center">
                  <h4>{currectDetails.amount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} {AMD}</h4>
                  <span><b>{currectDetails.percentage}</b>%</span>
                </div>
                <div className="P-line G-mt-2 G-mb-6">
                  <div className="P-green-line" style={{ width: `${currectDetails.percentage}%` }}></div>
                  <div className="P-white-line" style={{ width: `${currectDetails.percentage}%` }}>
                    <p className={currectDetails.percentage > 0 ? '' : 'P-amount-posit'}>
                      <b>{currectDetails.currentAmount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} {AMD}</b>
                    </p>
                  </div>
                </div>

                <div className="G-flex G-align-baseline">
                  <div className="G-flex G-align-center G-mr-6">
                    <div className="G-mr-6 P-campaign-numbers">
                      <div className="G-flex G-align-center G-justify-between">
                        <h6>{Campaign}</h6>
                        <div><p className="P-campaign-number">{currectDetails.campaignNumber}</p></div>
                      </div>
                      <div className="G-flex G-align-center G-justify-between">
                        <h6 className="G-mt-1">{Started}{symbol}</h6>
                        <p className="P-campaign-number G-mt-1">{moment(currectDetails.startedDate).format('DD MMM YYYY')}</p>
                      </div>
                      <div className="G-flex G-align-center G-justify-between">
                        <h6 className="G-mt-1">{Participants}{symbol}</h6>
                        <p className="P-campaign-number G-mt-1">{currectDetails.participants}</p>
                      </div>
                    </div>
                  </div>

                  <div className="G-flex G-align-center">
                    <div className="G-mr-6 P-details-right">
                      <div className="G-flex G-align-center G-justify-between">
                        {currectDetails.finishedDate ? <h6 className="G-mt-1">{Finished}{symbol}</h6> : ""}
                        {currectDetails.finishedDate ? <p className="G-mt-1">{moment(currectDetails.finishedDate).format('DD MMM YYYY')}</p> : ""}
                      </div>
                      <div className="G-flex G-align-baseline G-justify-between">
                        {currectDetails.winnerProjectName ? <h6 className="G-mt-1">{Winner}{symbol}</h6> : ""}
                        {currectDetails.winnerProjectName ? <p className="G-mt-1 G-break-word">{currectDetails.winnerProjectName}</p> : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              : currectCampaignList ?

              <div className="P-current-box G-mb-4 G-cursor" onClick={() => this.openDetails(currectCampaignList.id)}>
                <h6 className="G-mb-2">{Current_campaign}</h6>
                <div className="G-flex G-justify-between G-align-center">
                  <h4>{currectCampaignList.amount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} {AMD}</h4>
                  <span><b>{currectCampaignList.percentage}</b>%</span>
                </div>
                <div className="P-line G-mt-2 G-mb-6">
                  <div className="P-green-line" style={{ width: `${currectCampaignList.percentage}%` }}></div>
                  <div className="P-white-line" style={{ width: `${currectCampaignList.percentage}%` }}>
                    <p className={currectCampaignList.percentage > 0 ? '' : 'P-amount-posit'}>
                      <b>{currectCampaignList.currentAmount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} {AMD}</b>
                    </p>
                  </div>
                </div>

                <div className="G-flex G-align-end">
                  <div className="G-flex G-align-center G-mr-6">
                    <div className="G-mr-6 P-campaign-numbers">
                      <div className="G-flex G-align-center G-justify-between">
                        <h6>{Campaign}</h6>
                        <div><p className="P-campaign-number">{currectCampaignList.campaignNumber}</p></div>
                      </div>
                      <div className="G-flex G-align-center G-justify-between">
                        <h6 className="G-mt-1">{Started}{symbol}</h6>
                        <p className="P-campaign-number G-mt-1">{moment(currectCampaignList.startedDate).format('DD MMM YYYY')}</p>
                      </div>
                      <div className="G-flex G-align-center G-justify-between">
                        <h6 className="G-mt-1">{Participants}{symbol}</h6>
                        <p className="P-campaign-number G-mt-1">{currectCampaignList.participants}</p>
                      </div>
                    </div>
                  </div>

                  <div className="G-flex G-align-center G-mt-auto G-ml-6">
                    <div className="G-mr-6">
                      <div className="G-flex G-align-center G-justify-between">
                        {currectCampaignList.finishedDate ? <h6 className="G-mt-1">{Finished}{symbol}</h6> : ""}
                        {currectCampaignList.finishedDate ? <p className="G-mt-1">{moment(currectCampaignList.finishedDate).format('DD MMM YYYY')}</p> : ""}
                      </div>
                      <div className="G-flex G-align-center G-justify-between">
                        {currectCampaignList.winnerProjectName ? <h6 className="G-mt-1">{Winner}{symbol}</h6> : ""}
                        {currectCampaignList.winnerProjectName ? <p className="G-mt-1 G-break-word">{currectCampaignList.winnerProjectName}</p> : ""}
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              : ""}


        {(this.state.previousList && !currectProjectList && !previousDetailShow) || (this.state.previousList && !currectCampaignList && !previousDetailShow) ? <h6 className="G-mb-2">{Previous_campaigns} ({totalElements})</h6> : ""}
        {(this.state.previousList && !currectProjectList && !previousDetailShow) || (this.state.previousList && !currectCampaignList && !previousDetailShow) ?
          <div>
            <div className="P-top-project G-mb-3">
              {this.state.previousList.content.length ?
              <div className="P-project G-flex">
                <div className="G-flex P-project-title">
                  <span className="G-mr-1">{Campaign}</span>
                </div>
                <div className="G-flex P-project-title">
                  <span className="G-mr-1">{Projects}</span>
                </div>
                <div className="G-flex P-project-title">
                  <span className="G-mr-1">{Winner}</span>
                </div>
                <div className="G-flex P-project-title">
                  <span className="G-mr-1">{Amount}</span>
                </div>
                <div className="G-flex P-project-title">
                  <span className="G-mr-1">{Votes}</span>
                </div>
                <div className="G-flex P-project-title">
                  <span className="G-mr-1">{Started_date}</span>
                </div>
                <div className="G-flex P-project-title">
                  <span className="G-mr-1">{Finished_Date}</span>
                </div>
              </div> : ""}

              <div className="P-previous">
                <div className="P-project-list">
                  {this.state.previousList ? <div>
                    {this.state.previousList ?
                      this.state.previousList.content.map((item, pre) => {
                        return (
                          <div className="G-flex-justify-between G-align-center P-project-item" key={pre}>
                            <p className="G-flex G-align-center G-cursor G-break-word G-mr-1" onClick={() => this.openDetails(item.id)}>{item.campaignNumber}</p>
                            <p className="G-flex G-align-center G-cursor G-break-word G-mr-1" onClick={() => this.openDetails(item.id)}>{item.projectsCount}</p>
                            <p className="G-flex G-align-center G-cursor G-break-word G-mr-1" onClick={() => this.openDetails(item.id)}>{item.winnerProjectName}</p>
                            <p className="G-flex G-align-center G-cursor G-break-word G-mr-1" onClick={() => this.openDetails(item.id)}>{item.amount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                            <p className="G-flex G-align-center G-cursor G-break-word G-mr-1" onClick={() => this.openDetails(item.id)}>{item.votePercent}%</p>
                            <p className="G-flex G-align-center G-cursor G-break-word G-mr-1" onClick={() => this.openDetails(item.id)}>{moment(item.startedDate).format('DD MMM YYYY')}</p>
                            <p className="G-flex G-align-center G-cursor G-break-word G-mr-1" onClick={() => this.openDetails(item.id)}>{moment(item.finishedDate).format('DD MMM YYYY')}</p>
                            {/* <ToolBar>
                              <li style={{ cursor: "pointer", width: "100%", marginBottom: "10px" }}
                                 onClick={() => this.props.history.push(`/donations/Charity/campaigns/edit-campaign/${item.id}`)}>{Edit}
                              </li>
                              <li style={{ cursor: "pointer", width: "100%", marginBottom: "10px" }}
                                onClick={() => this.openDeleteModal(item.id)}>{Delete}
                              </li>
                            </ToolBar> */}
                          </div>
                        )
                      })
                      : ""}
                  </div> : ""}

                </div>
              </div>
            </div>
              <Paging
                totalPages={totalPages}
                onChange={this.getPreviousList}
              />
          </div> : this.state.previousList && !currectProjectList ?
            <div className="G-flex G-justify-center P-previous-empty"><h5>{list_is_empty}</h5></div> : ""
        }

        {currectDetails && currectProjectList ?
          <div>
            <h6 className="G-mb-2">{Projects}</h6>
            <div className="P-top-project">
              <div className="P-project G-flex">
                <div className="G-flex P-project-title">
                  <span className="G-mr-1">{Projects}</span>
                </div>
                <div className="G-flex P-project-title">
                  <span className="G-mr-1">{Votes}</span>
                </div>
                <div className="G-flex P-project-title">
                  <span className="G-mr-1">{Persentage}</span>
                </div>
              </div>

              <div className="P-projects-campaign">
                <div className="P-project-list">
                  {this.state.currectDetails ? <div>
                    {this.state.currectDetails ?
                      this.state.currectDetails.projects.map((item, project) => {
                        return (
                          <div className="G-flex-justify-between G-align-center P-project-item" onClick={() => this.openDetailsModal(item.projectId)} key={project}>
                            <p className="G-flex G-align-center G-cursor G-break-word G-mr-1">
                              <p className={item.probablyWin ? "G-green" : ""}>{item.projectName}</p></p>
                            <p className="G-flex G-align-center G-cursor G-break-word G-mr-1">
                              <p className={item.probablyWin ? "G-green" : ""}>{item.votes}</p></p>
                            <p className="G-flex G-align-center G-cursor G-break-word G-mr-1">
                              <p className={item.probablyWin ? "G-green" : ""}>{item.percentage}%</p></p>
                          </div>
                        )
                      })
                      : ""}
                  </div> : <div className="G-flex G-justify-center P-empty"><h5>{list_is_empty}</h5></div>}

                </div>
              </div>
            </div>
          </div> : ""}
          
        <Modal
          isOpen={this.state.detailsModal}
          onRequestClose={this.closeDetailsModal}
          style={{
            content: {
              padding: "30px",
              minHeight: "500px",
              width: "100%",
              maxWidth: "710px",
              maxHeight: "96vh",
              display: "flex",
              flexDirection: "column",
            },
          }}>
          <Details closeModal={this.closeDetailsModal} getList={() => null} id={this.state.projectsId} />
        </Modal>

        <Modal
            isOpen={this.state.deleteModalIsOpen}
            onRequestClose={this.closeModal}
            style={{
              content: {
                padding: "30px",
                minHeight: "180px",
                minWidth: "350px",
                display: "flex",
                flexDirection: "column",
              }
            }}>
            <div className="P-delete-modal">
              <h5 className="G-mb-2 P-title">{Delete}</h5>
              <p className="G-mb-3 P-text">{Are_you_sure}</p>
              <div className="G-flex">
                <button className="G-mr-2 P-cancel" onClick={this.closeModal}>{Cancel}</button>
                <button className="G-button P-button" onClick={() => this.deleteCampaign(this.state.campaignId)}>{Delete}</button>
              </div>
            </div>
          </Modal>
      </section>
    )
  }
}

export default Campaigns;