import { ISignInRequestModel } from "./interfaces/sign-in-req";
import { BaseController } from "../../classes/http-service";

class AuthController extends BaseController {
  private controller = 'auth';

  public login = async (form: ISignInRequestModel) => {
    
    const result = await this.connection.POST<ISignInRequestModel>({
      body: form,
      action: 'signIn',
      controller: this.controller,
    });

    // if (result.success) {
    //     window.localStorage.setItem('token', result.data.token);
    //   if(result.data.role === "ROLE_CLIENT"){
    //     window.localStorage.setItem('role', "client");
    //   }
    //   window.location.reload();
    // }
    return result;
  }
};

export default new AuthController();