import * as React from 'react';
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, } from "react-google-maps";
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
import './index.scss';

const MapDetails = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyC8G7V6MGzigq5jmvKhvTpqA-n6T_8th-Q&libraries=places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props: any) =>
  <GoogleMap
    defaultZoom={15}
    center={props.data && { lat: props.data.latitude, lng: props.data.longitude }}
  >
    {props.data && <InfoBox
      position={new google.maps.LatLng(props.data.latitude,
        props.data.longitude)}
      options={{
        closeBoxURL: ``,
        enableEventPropagation: true,
        pixelOffset: new google.maps.Size(-35, -25),
      }}>
      <div
        className="G-cursor P-orders-map"
        style={{
          width: "95px",
          height: "40px",
        }}>
        <div className={`P-marker P-marker-active`}>
          {props.details.weightForSale} kg
              </div>
      </div>
    </InfoBox>}
  </GoogleMap>
)


export default MapDetails;
