//? Service for connections with outside resources
//? Service for request
import Config from 'Config';
import { IResponse, IRequest, IRequestNoBody } from '../interfaces';
import { NoneJSONRequestBody } from '../types/request';
import Languages from './languages';
import { languageEnum } from '../enums/enums';

class Connection {

  //? To get query from object
  public static queryFromObject = (obj: any): string => {
    const str: string[] = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p) && (obj[p] !== null && obj[p] !== undefined && obj[p] !== '')) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    }

    return str.join("&");
  }

  //? To set header default configuration
  public static createHeaders = (isUpload: boolean): Headers => {
    const HEADERS = new Headers();
    HEADERS.append("osTypeId", "3");
    HEADERS.append("LanguageName", Languages.ShortCode === languageEnum.hy ? "hy" : "en");

    if(window.localStorage.getItem('token')){
      HEADERS.append('Authorization', `Bearer ${window.localStorage.getItem('token')}`);
    }
    !isUpload && HEADERS.append('Content-Type', 'application/json');
    return HEADERS;
  }

  //? To check the response
  public static responseRestructure = async (response: Response): Promise<any> => {
    if (response.status === 401 || response.status === 403) {
      window.localStorage.removeItem('token');
      window.location.reload();
    }

    return new Promise(resolve => {
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        response.json().then((result: IResponse<any>) => {

          resolve(result);
        });
      } else response.text().then(resolve);
    });
  }


  //? POST request
  public static POST = async <Body extends object>(data: IRequest<Body>): Promise<any> => {
    const { controller, action, body, query, noneJSONBody } = data;
    const onlyQuery: boolean = !action && !!query;
    const HEADERS = Connection.createHeaders(noneJSONBody as boolean);

    if (noneJSONBody) {
      HEADERS.delete('Content-Type')
    }

    const response: Response = await fetch(`${process.env.REACT_APP_BASE_URL || Config.BaseUrl}api/${controller}${!onlyQuery ? '/' : ''}${action}${query ? `?${Connection.queryFromObject(query)}` : ''}`, {
      body: noneJSONBody ? body as NoneJSONRequestBody : JSON.stringify(body),
      method: 'POST',
      headers: HEADERS,
    });

    return Connection.responseRestructure(response);
  }

  //? PUT request
  public static PUT = async <Body extends object>(data: IRequest<Body>): Promise<any> => {
    const { controller, action, body, query, noneJSONBody } = data;
    const onlyQuery: boolean = !action && !!query;
    const HEADERS = Connection.createHeaders(noneJSONBody as boolean);
    const response: Response = await fetch(`${process.env.REACT_APP_BASE_URL || Config.BaseUrl}api/${controller}${!onlyQuery ? '/' : ''}${action}${query ? `?${Connection.queryFromObject(query)}` : ''}`, {
      body: noneJSONBody ? body as NoneJSONRequestBody : JSON.stringify(body),
      method: 'PUT',
      headers: HEADERS,
    });

    return Connection.responseRestructure(response);
  }

  //? DELETE request
  public static DELETE = async <Body extends object>(data: IRequest<Body>): Promise<any> => {
    const { controller, action, body, query, noneJSONBody } = data;
    const onlyQuery: boolean = !action && !!query;
    const HEADERS = Connection.createHeaders(false);
    const response: Response = await fetch(`${process.env.REACT_APP_BASE_URL || Config.BaseUrl}api/${controller}${!onlyQuery ? '/' : ''}${action}${query ? `?${Connection.queryFromObject(query)}` : ''}`, {
      body: noneJSONBody ? body as NoneJSONRequestBody : JSON.stringify(body),
      method: 'DELETE',
      headers: HEADERS,
    });
    return Connection.responseRestructure(response);
  }

  //? GET request
  public static GET = async (data: IRequestNoBody): Promise<any> => {
    const { controller, action, query } = data;
    const onlyQuery = !action && query;
    const HEADERS = Connection.createHeaders(false);
    const response: Response = await fetch(`${process.env.REACT_APP_BASE_URL || Config.BaseUrl}api/${controller}${!onlyQuery ? '/' : ''}${action}${query ? `?${Connection.queryFromObject(query)}` : ''}`, {
      method: 'GET',
      headers: HEADERS,
    });

    return Connection.responseRestructure(response);
  }
}

export default Connection;