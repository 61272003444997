import * as React from 'react';
import { Link } from 'react-router-dom';
import noPic from 'src/assets/images/no-photo.png'
import { UserTypeEnum } from 'src/platform/enums/enums';
import ToolBar from 'src/platform/components/toolbar/toolbar';
import HelperComponent from 'src/platform/classes/helper-component';
import SupportController from 'src/platform/api/support/support';
import Helper from 'src/platform/services/helper';
import { messageSubjectOptions } from 'src/platform/constants/casual';
import * as moment from 'moment';
import { store } from 'react-notifications-component';
import "./index.scss";
import CreatePopup from 'src/pages/support/create/createPopup';
import Modal from 'react-modal';
import Languages from 'src/platform/services/languages';

export interface IMessageProps {
  item: any,
  id?: number,
  getList: () => void,
}

export interface IMessageState {
  important: boolean,
  archive: boolean,
  modalIsOpenIndex: null,
}

const {
  enums,
  Move_to_archive,
  View_profile,
  Mark_as_important,
  Mark_as_unimportant,
  Send_notification,
  new_,
} = Languages.Translations;
class Message extends HelperComponent<IMessageProps, IMessageState> {
  state: IMessageState = {
    important: this.props.item.important,
    archive: null,
    modalIsOpenIndex: null,
  }

  public toggleImportant = async () => {
    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const res = await SupportController.markImportant(this.props.item.id);
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
    if (res.success) {
      this.asyncSetState({ important: !this.state.important });
    } else {
      store.addNotification(Helper.notificationConfig(res.message, false));
    }
  }

  componentDidUpdate(prevProps: IMessageProps) {
    if (prevProps.item.important !== this.props.item.important) {
      this.asyncSetState({ important: this.props.item.important });
    }
  }

  public sendToArchive = async () => {
    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const res = await SupportController.sendToArchive(this.props.item.id);
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
    if (res.success) {
      this.asyncSetState({ archive: true });
      this.props.getList()
    } else {
      store.addNotification(Helper.notificationConfig(res.message, false));
    }
  }

  public openModal = (index: number) => {
    this.asyncSetState({ modalIsOpenIndex: index });
  }

  public closeModal = () => {
    this.asyncSetState({ modalIsOpenIndex: null });
  }

  private getNotifList = async () => {

  }

  render() {
    const { item } = this.props;
    const { important, archive, modalIsOpenIndex } = this.state;

    // let date = new Date(item.createdDate);
    return (
      <>
        {!archive ?
          <div className="P-support-message" >
            <div className="G-flex G-justify-between P-top">
              <div className="P-left G-flex">
                <div className="P-icon P-image G-mr-1"
                  style={{ background: `url(${item && item.imageUrl ? `${item.imageUrl}/100/100` : noPic}) center/cover no-repeat` }} />
                <div className="G-column G-mr-2">
                  {<span className="P-name">
                    {Boolean(item.companyName)
                      ? item.companyName
                      : UserTypeEnum.Guests === item.userTypeEnumValue
                        ? item.name : item.fullName}
                  </span>}
                  <span className="P-userType">{enums.UserTypeSingleEnum[item.userTypeEnumValue]}</span>
                </div>
                {UserTypeEnum.Guests === item.userTypeEnumValue ? null
                  : UserTypeEnum.Agents === item.userTypeEnumValue ?
                    <Link to={item.userId ? `/customers/agents/details?id=${item.userId}` : ""} className="P-view-profile">{View_profile}</Link>
                    : UserTypeEnum.Sellers === item.userTypeEnumValue ?
                      <Link to={item.userId ? `/customers/sellers/details?id=${item.userId}` : ""} className="P-view-profile">{View_profile}</Link> : null}
              </div>
              <div className="P-right G-flex G-align-center">
                <span>
                  {/* {`${formatDate(date.toDateString())} ${moment(date).format('HH:MM')}`} */}
                  {moment(item.createdDate).format('DD MMM YYYY HH:mm')}
                </span>
              </div>
            </div>
            <div className="P-bottom">
              {!item.archive ? <ToolBar width="220px">
                {UserTypeEnum.Guests !== item.userTypeEnumValue ?
                  <li className="P-toolbar-li" onClick={() => this.openModal(1)}>{Send_notification}</li>
                  : null}
                <li className="P-toolbar-li" onClick={this.toggleImportant}>{important ? `${Mark_as_unimportant}` : `${Mark_as_important}`} </li>
                <li className="P-toolbar-li" onClick={this.sendToArchive}>{Move_to_archive}</li>
              </ToolBar> : null}
              <div className={`G-flex G-bold G-mb-1 ${item.archive ? "G-mt-3" : null}`}>
                {!item.seen ? <span className="P-new G-mr-1">{new_}</span> : null}
                {item.subject ? item.subject : messageSubjectOptions[item.messageSubjectEnumValue - 1].label}
                <div className="P-important">
                  {important && <i className="G-ml-1 icon-ic_important P-icon"></i>}
                </div>
              </div>
              <p className="G-mb-3">{item.message}</p>
              <div className="G-flex P-contacts">
                <div className="G-flex P-phone G-mr-3 ">
                  <a className="G-flex" href={`tel:${item.phoneNumber}`}>
                    <i className="icon-ic_phone P-icon"></i>
                    {item.phoneNumber}
                  </a>
                </div>
                <div className="G-flex P-mail">
                  <a className="G-flex G-align-center" href={`mailto:${item.email}`}>
                    <i className="icon-ic_mail P-icon"></i>
                    {item.email}
                  </a>
                </div>
              </div>
            </div>
            <Modal
              isOpen={modalIsOpenIndex === 1}
              onRequestClose={this.closeModal}
              style={{
                content: {
                  padding: "30px",
                  minHeight: "500px",
                  width: "100%",
                  maxWidth: "700px",
                  display: "flex",
                  flexDirection: "column",
                },
              }}>
              <CreatePopup item={this.props.item} id={this.props.item.userId} closeModal={this.closeModal} getSupportMessagesList={this.getNotifList} />
            </Modal>
          </div>
          : null}
      </>
    );
  }
}

export default Message;