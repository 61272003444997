import {
  IGetSellersOrdersListRequestModelBody,
  IGetSellersOrdersListRequestModel,
  IGetSellersOrdersLeftBarListRequestModel,
  IGetSellersOrdersMapListRequestModel,
  IGetSellersOrdersListHistoryRequestModel,
  IGetSellersOrdersListHistoryRequestModelBody,
  IGetSelfDeliveryListRequestModel,
  IGetSelfDeliveryListRequestModelBody,
  IGetAgentsOrdersListRequestModel,
  IGetAgentsOrdersListRequestModelBody,
  ICancelOrderModelBody,
  IGetOrdersBySellersModel,
  IGetOrdersByBuyersModel,
  IGetCardboardOrdersByBuyersModel
} from "./order-interface";
import { BaseController } from "../../classes/http-service";
import { IResponse } from 'src/platform/interfaces';
class OrderController extends BaseController {
  private controller = 'orderAdmin';


  public getSellersOrdersList = async (form: IGetSellersOrdersListRequestModel): Promise<IResponse<any>> => {
    const result = await this.connection.POST<IGetSellersOrdersListRequestModelBody>({
      controller: this.controller,
      action: `getOrderSellerList/${form.page}/${form.size}`,
      body: form.body || {},
    });

    return result;
  }

  public getSellersOrdersHistoryList = async (form: IGetSellersOrdersListHistoryRequestModel): Promise<IResponse<any>> => {
    const result = await this.connection.POST<IGetSellersOrdersListHistoryRequestModelBody>({
      controller: this.controller,
      action: `getHistoryOrderSellerList/${form.page}/${form.size}`,
      body: form.body || {},
    });

    return result;
  }

  public getSelfDeliveryHistoryList = async (form: IGetSelfDeliveryListRequestModel): Promise<IResponse<any>> => {
    const result = await this.connection.POST<IGetSelfDeliveryListRequestModelBody>({
      controller: this.controller,
      action: `getHistoryOrderSelfDeliverBuyerList/${form.page}/${form.size}`,
      body: form.body || {},
    });
    return result;
  }

  public getDeliveryHistoryList = async (form: IGetSelfDeliveryListRequestModel): Promise<IResponse<any>> => {
    const result = await this.connection.POST<IGetSellersOrdersListHistoryRequestModelBody>({
      controller: this.controller,
      action: `getHistoryOrderBuyerList/${form.page}/${form.size}`,
      body: form.body || {},
    });
    return result;
  }


  public getAgentsOrdersDeliveryList = async (form: IGetAgentsOrdersListRequestModel): Promise<IResponse<any>> => {
    const result = await this.connection.POST<IGetAgentsOrdersListRequestModelBody>({
      controller: this.controller,
      action: `getOrderBuyerList/${form.page}/${form.size}`,
      body: form.body || {},
    });

    return result;
  }

  public getAgentsOrdersSelfDeliveryList = async (form: IGetSelfDeliveryListRequestModel): Promise<IResponse<any>> => {
    const result = await this.connection.POST<IGetSelfDeliveryListRequestModelBody>({
      controller: this.controller,
      action: `getOrderSelfDeliverBuyerList/${form.page}/${form.size}`,
      body: form.body || {},
    });

    return result;
  }

  public getSellersOrdersMapList = async (form: IGetSellersOrdersMapListRequestModel): Promise<IResponse<any>> => {
    const result = await this.connection.POST<IGetSellersOrdersMapListRequestModel>({
      controller: this.controller,
      action: `getSellerOngoingListForAdmin`,
      body: form || {},
    });
    return result;
  }

  public getSellersOrdersLeftBarList = async (form: IGetSellersOrdersLeftBarListRequestModel): Promise<IResponse<any>> => {
    const result = await this.connection.GET({
      controller: this.controller,
      action: `getSellerPendingListForAdmin/${form.page}/${form.size}?orderClusterId=${form.orderClusterId}`,
    });

    return result;
  };

  public getSellersOrderDetails = async (id: string | number): Promise<IResponse<any>> => {
    const result = await this.connection.GET({
      controller: "orderSeller",
      action: `${id}`,
    });
    return result;
  };

  public getAllOrdersBySellerId = async (form: IGetOrdersBySellersModel): Promise<IResponse<any>> => {
    const result = await this.connection.GET({
      controller: "orderSeller",
      action: `getAllOrdersBySellerId/${form.page}/${form.size}?sellerId=${form.sellerId}`,
    });
    return result;
  };

  public getAllOrdersByBuyerId = async (form: IGetOrdersByBuyersModel): Promise<IResponse<any>> => {
    const result = await this.connection.GET({
      controller: "orderSeller",
      action: `getAllOrdersByBuyerId/${form.page}/${form.size}?buyerId=${form.buyerId}`,
    });
    return result;
  };

  public getCardboardDeliveryByBuyerId = async (form: IGetCardboardOrdersByBuyersModel): Promise<IResponse<any>> => {
    const result = await this.connection.GET({
      controller: "orderBuyer",
      action: `getAllOrdersByBuyerId/${form.page}/${form.size}?buyerId=${form.buyerId}`,
    });
    return result;
  };

  public getAgentsDeliveryOrderDetails = async (id: string | number): Promise<IResponse<any>> => {
    const result = await this.connection.GET({
      controller: "orderBuyer",
      action: `getOrderBuyerById?orderId=${id}`,
    });
    return result;
  };

  public getAgentsSelfDeliveryOrderDetails = async (id: string | number): Promise<IResponse<any>> => {
    const result = await this.connection.GET({
      controller: "orderBuyer",
      action: `getOrderSelfDeliverBuyerById?orderId=${id}`,
    });
    return result;
  };

  public acceptOrder = async (id: string): Promise<IResponse<any>> => {
    const result = await this.connection.PUT({      
      controller: this.controller,
      action: `clientAcceptOrder?orderId=${id}`,
      body: {},
      noneJSONBody: false
      });
    return result;
  }

  public finishOrder = async (id: string, finalWeight: string): Promise<IResponse<any>> => {
    const result = await this.connection.PUT({      
      controller: 'orderBuyer',
      action: `agentFinishOrder?orderId=${id}&finalWeight=${finalWeight}`,
      body: {},
      noneJSONBody: false
      });
    return result;
  }
  
  public getLimits = async (id: string): Promise<IResponse<any>> => {
    const result = await this.connection.GET({      
      controller: 'orderBuyer',
      action: `limits/${id}`,
      noneJSONBody: false
      });
    return result;
  }
  
  public setLimits = async (id: number, body: any): Promise<IResponse<any>> => {
    const result = await this.connection.POST({      
      controller: 'buyer',
      action: `setLimits/${id}`,
      body: body,
      noneJSONBody: false
      });
    return result;
  }
  
  public attachMinimalPoints = async (id: number, minimalPoints: number): Promise<IResponse<any>> => {
    const result = await this.connection.POST({      
      controller: 'orderBuyer',
      action: `attachMinimalPoints/${id}?minimalPoints=${minimalPoints}`,
      body: {},
      noneJSONBody: false
      });
    return result;
  }
  
  public attachNegativeLimit = async (id: number, negativeLimit: number): Promise<IResponse<any>> => {
    const result = await this.connection.POST({      
      controller: 'orderBuyer',
      action: `attachNegativeLimit/${id}?negativeLimit=${negativeLimit}`,
      body: {},
      noneJSONBody: false
      });
    return result;
  }
  
  public attachPointsLimitForBuyer = async (id: number, pointsLimit: number): Promise<IResponse<any>> => {
    const result = await this.connection.POST({      
      controller: 'orderBuyer',
      action: `attachPointsLimitForBuyer/${id}?pointsLimit=${pointsLimit}`,
      body: {},
      noneJSONBody: false
      });
    return result;
  }
  
  public clientFinishOrder = async (id: string, finalWeight: string): Promise<IResponse<any>> => {
    const result = await this.connection.PUT({      
      controller: 'orderAdmin',
      action: `clientFinishOrder?orderId=${id}&finalWeight=${finalWeight}`,
      body: {},
      noneJSONBody: false
      });
    return result;
  }
  

  public rejectOrder = async (form: ICancelOrderModelBody): Promise<IResponse<any>> => {
    const result = await this.connection.PUT({      
      controller: this.controller,
      action: `clientRejectOrder`,
      body: form,
      noneJSONBody: false
      });
    return result;
  }

  public cancelOrder = async (form: ICancelOrderModelBody): Promise<IResponse<any>> => {
    const result = await this.connection.PUT({      
      controller: 'orderSeller',
      action: `cancelOrder`,
      body: form,
      noneJSONBody: false
      });
    return result;
  }

  
  public downloadAgentOrderDelivery = async (form: IGetAgentsOrdersListRequestModelBody): Promise<IResponse<any>> => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: `downloadAgentOrderDelivery`,
      body: form,
    });
    return result;
  }
  
  public downloadAgentOrderDeliveryHistory = async (form: IGetAgentsOrdersListRequestModelBody): Promise<IResponse<any>> => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: `downloadAgentOrderDeliveryHistory`,
      body: form,
    });
    return result;
  }
  
  public downloadAgentOrderSelfDelivery = async (form: IGetAgentsOrdersListRequestModelBody): Promise<IResponse<any>> => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: `downloadAgentOrderSelfDelivery`,
      body: form,
    });
    return result;
  }
  
  public downloadAgentOrderSelfDeliveryHistory = async (form: IGetAgentsOrdersListRequestModelBody): Promise<IResponse<any>> => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: `downloadAgentOrderSelfDeliveryHistory`,
      body: form,
    });
    return result;
  }
  
  public downloadSellerOrder = async (form: IGetAgentsOrdersListRequestModelBody): Promise<IResponse<any>> => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: `downloadSellerOrder`,
      body: form,
    });
    return result;
  }
  
  public downloadSellerOrderHistory = async (form: IGetAgentsOrdersListRequestModelBody): Promise<IResponse<any>> => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: `downloadSellerOrderHistory`,
      body: form,
    });
    return result;
  }
  
}
export default new OrderController();