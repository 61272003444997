import * as React from 'react';
import Table, { ITableColumnConfig } from 'src/platform/components/table/table';
import { OrderAgentStatusEnum, UserTypeEnum } from 'src/platform/enums/enums';
import HelperComponent from 'src/platform/classes/helper-component';
import { RouteComponentProps } from 'react-router';
import Paging from 'src/platform/components/pagination/pagination';
import noPic from 'src/assets/images/no-photo.png'
import Helper from 'src/platform/services/helper';
import moment from 'moment';
import './index.scss';
import Languages from 'src/platform/services/languages';

interface IProps extends RouteComponentProps {
  deliveryList: Array<any>,
  getDeliveryList: (pageNum?: { selected: number }) => void,
  loaded: boolean;
  totalPages: number,
  isFirstInit: boolean,
  pageForPagination: number,
  acceptOrder: (id: string) => void,
  openModal: (index: number, orderId: number) => void,
}

interface IState {
  deliveryList: Array<any>,
}

const {
  Order_,
  User_,
  Address,
  Cardboard_weight,
  Creation_date,
  Status,
  Available_Time,
  Accept,
  Reject,
  Cancel,
  Finish,
  enums,
  Any_time,
  AMD,
} = Languages.Translations

class AgentsDelivery extends HelperComponent<IProps, IState> {


  public state: IState = {
    deliveryList: this.props.deliveryList,
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.deliveryList !== prevProps.deliveryList) {
      this.asyncSetState({ deliveryList: this.props.deliveryList });
    }
  }


  public config: Array<ITableColumnConfig<any>> = [
    {
      name: `${Order_} #`,
      cell: (row: any) => {
        return (<div className="G-cursor"
        >
          {row.orderNumber}
        </div>);
      },
      style: { marginRight: "1%", maxWidth: 130 },
    },
    {
      name: `${User_}`,
      cell: (row: any) => {
        return (<div className="G-cursor G-flex G-align-center"
        onClick={
          (evt: React.SyntheticEvent) => {
              evt.stopPropagation();
              if (row.userTypeValue === UserTypeEnum.Agents) {
                this.props.history.push(`/customers/agents/details?id=${row.agentPreviewDto.buyerId}`);
              } else this.props.history.push(`/customers/sellers/details?id=${row.agentPreviewDto.buyerId}`);
          }
        }>
          <span className="P-image G-mr-4 G-mr-1"
            
            style={{ background: `url(${(row.agentPreviewDto && row.agentPreviewDto.buyerImageUrl) ? `${row.agentPreviewDto.buyerImageUrl}/100/100` : noPic}) center/cover no-repeat` }}>
          </span>
          <span className="G-flex G-flex-wrap G-break-word">
            {row.agentPreviewDto ? row.agentPreviewDto.buyerFullName : ""}
          </span>
        </div>);
      },
      style: { marginRight: "1%", minWidth: 140, maxWidth: 190 },
    },
    {
      name: `${Address}`,
      cell: (row: any) => {
        return (
          <div className="G-cursor G-flex G-align-center">
            {`${row.addressForOrderDto && row.addressForOrderDto.addressName},
          ${row.addressForOrderDto.apartment ? row.addressForOrderDto.apartment + ' app,' : ''}
          ${row.addressForOrderDto.building ? row.addressForOrderDto.building + ' building,' : ''} 
          ${row.addressForOrderDto.entrance ? row.addressForOrderDto.entrance + ' entrance,' : ''} 
          ${row.addressForOrderDto.floor ? row.addressForOrderDto.floor + ' floor' : ''}
          `}
          </div>
        );
      },
      style: { marginRight: "1%", minWidth: 170, maxWidth: 200 },
    },
    {
      name: `${Cardboard_weight}`,
      cell: (row: any) => {
        return (<div className="G-cursor"
        >
          {row.weight}
        </div>);
      },
      style: { marginRight: "1%", minWidth: 75 },
    },
    {
      name: `${AMD}`,
      cell: (row: any) => {
        return (<div className="G-cursor"
        >
          {row.amount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
        </div>);
      },
      style: { marginRight: "1%", minWidth: 75 },
    },
    {
      name: `${Available_Time}`,
      cell: (row: any) => {
        return (<div className="G-cursor"
        >
          {row.orderTimesDtos ? row.orderTimesDtos.map((item: any, index: any) => {
            let from = item.fromTime;
            let to = item.toTime;
            return (<div key={index} className='P-time G-mr-1'>
              {item.fromTime !== 0 ? <>
                {moment(new Date((from * 1000) - 14400000)).format('HH:mm')} - 
                {moment(new Date((to * 1000) - 14400000)).format('HH:mm')}</> : `${Any_time}`}
            </div>)
          }) : "-"}
        </div>);
      },
      style: { marginRight: "1%", minWidth: 105 },
    },
    {
      name: Creation_date,
      cell: (row: any) => {
        return (<div className="G-cursor">
          {moment(row.createdDate).format('DD MMM YYYY HH:mm')}
        </div>);
      },
      style: { marginRight: "1%", minWidth: 100, maxWidth: 120 },
    },
    {
      name: `${Status}`,
      cell: (row: any) => {
        return (<div className="G-cursor"
          style={{
            color: `${(
              row.orderBuyerStatusEnumValue === OrderAgentStatusEnum.PENDING) ? '#7A7A7A'
              : row.orderBuyerStatusEnumValue === OrderAgentStatusEnum.ACCEPTED ? "#EFC700" : "#04A556"}`
          }}>
          {enums.OrderAgentStatusEnum[row.orderBuyerStatusEnumValue]}
        </div>)
      },
      style: { marginRight: "2%", maxWidth: 70, minWidth: 70 },
    },
    {
      cell: (row: any) => {
        if (Helper.role) {
          return (
            <>
              {row.orderBuyerStatusEnumValue !== OrderAgentStatusEnum.ACCEPTED ?
                <div className="G-flex">
                  <div className="P-accept P-order-btn G-cursor G-mr-2"
                    onClick={
                      (evt: React.SyntheticEvent) => {
                        if (Helper.role) {
                          evt.stopPropagation();
                          this.props.acceptOrder(row.orderId);
                        }
                      }
                    }>{Accept}</div>

                  <div className="P-reject P-order-btn G-cursor"
                    onClick={
                      (evt: React.SyntheticEvent) => {
                        if (Helper.role) {
                          evt.stopPropagation();
                          this.props.openModal(2, row.orderId);;
                        }
                      }
                    }>{Reject}</div>
                </div>
                :

                <div className="G-flex P-finish-container">
                  <div className="P-finish P-order-btn G-cursor G-mr-2"
                    onClick={
                      (evt: React.SyntheticEvent) => {
                        if (Helper.role) {
                          evt.stopPropagation();
                          this.props.openModal(1, row.orderId);
                        }
                      }
                    }>{Finish}</div>

                  <div className="P-cancel P-order-btn G-cursor"
                    onClick={
                      (evt: React.SyntheticEvent) => {
                        if (Helper.role) {
                          evt.stopPropagation();
                          this.props.openModal(3, row.orderId);;
                        }
                      }
                    }>{Cancel}
                  </div>
                </div>}
            </>)
        } else {
          return null;
        }
      },
      style: Helper.role ? { maxWidth: 200, minWidth: 200 } : { width: 0 },
    }
  ]

  public render() {
    const { deliveryList } = this.state;
    const { totalPages, loaded, isFirstInit, pageForPagination } = this.props;

    return (
      <>
        <Table columnConfig={this.config}
          data={deliveryList}
          isFirstInit={isFirstInit}
          loaded={loaded}
          onRowClick={(row) => {
            this.props.history.push(`/orders/agents/delivery-details?activeOrderId=${row.orderId}`);
          }}
        />
        <Paging
          pageForPagination={pageForPagination}
          totalPages={totalPages}
          onChange={this.props.getDeliveryList}
        />
      </ >
    );
  }
}

export default AgentsDelivery