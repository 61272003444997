import { IDataTransfer } from "./interfaces/data-transfer.interface";

class DataTransfer {
  public static data: IDataTransfer = {
      phoneNumber: null,
      code: null,
      confirmationResult: null,
  }
    
    static get getData(){
		  return this.data;
	}
}
 
export default DataTransfer;