import { BaseController } from "../../classes/http-service";
import { IResponse } from 'src/platform/interfaces';
import { IPasspostRequest } from '../customers/interfaces/agentsModel';

class AgentController extends BaseController {
  private controller = 'buyer';

  public addPassportDetails = async (form: IPasspostRequest, formData: FormData): Promise<IResponse<any>> => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: `addPassportDetail?buyerId=${form.buyerId}&givenBy=${form.givenBy}&givenDate=${form.givenDate}&passportNumber=${form.passportNumber}`,
      body: formData,
      noneJSONBody: true,
    });

    return result;
  };

  public getUserPassportDetails = async (id: string): Promise<IResponse<any>> => {
    const result = await this.connection.GET({
      controller: this.controller,
      action: `getPassportDetail?userId=${id}`,
    });

    return result;
  };

  public activateUser = async (id: string): Promise<IResponse<any>> => {
    const result = await this.connection.PUT({
      controller: this.controller,
      action: `activateUser/${id}`,
      body: null,
    });

    return result;
  };

  public deleteBuyer = async (id: string): Promise<IResponse<any>> => {
    const result = await this.connection.DELETE({
      controller: this.controller,
      action: `${id}`,
      body: null,
    });

    return result;
  };

}
export default new AgentController();