import * as React from 'react';
import Helper from 'src/platform/services/helper';
import noPic from 'src/assets/images/no-photo.png'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { OrderSellerStatusEnum, OrderType, OrderAgentStatusEnum } from 'src/platform/enums/enums';
import HelperComponent from 'src/platform/classes/helper-component';
import OrderController from 'src/platform/api/order/order';
import moment from 'moment';
import { store } from 'react-notifications-component';
import './index.scss';
import Languages from 'src/platform/services/languages';

const {
    Any_time
} = Languages.Translations
export interface IProps extends RouteComponentProps {
    activeId: number;
    orderType: number;
    acceptOrder: (id: string) => void,
    openModal: (index: number, orderId: number) => void,
}

export interface IState {
    details: any,
}

class AgentOrderMapDetail extends HelperComponent<IProps, IState> {

    public state: IState = {
        details: {},
    }

    componentDidMount() {
        this.getOrderDetails(this.props.activeId);

    }

    componentDidUpdate(prevProps: IProps) {
        if (this.props.activeId !== prevProps.activeId) {
            this.getOrderDetails(this.props.activeId);
        }
    }

    private getOrderDetails = async (orderId: string | number) => {
        const result = await OrderController.getAgentsDeliveryOrderDetails(orderId);
        if (result.success) {
            this.asyncSetState({
                details: result.data,
                totalPages: result.data.totalPages,
            });
        } else {
            store.addNotification(Helper.notificationConfig(result.message, false));
        }
    };

    render() {
        const { details } = this.state;
        const { orderType } = this.props;

        return (
            <>
                <div className="P-top G-flex G-align-center G-pl-2 P-arrow">
                    <div className="G-flex G-justify-between P-title">
                        <span>Order information</span>
                        <span className="P-order-num">Order #{details.orderNumber}</span>
                        {details.orderSellerStatusEnumValue
                            ? <span className="P-status"
                                style={{
                                    color: `${
                                        details.orderSellerStatusEnumValue === OrderSellerStatusEnum.CANCELED
                                            ? '#FF0057' : "#04A556"}`
                                }}>
                                {OrderSellerStatusEnum[details.orderSellerStatusEnumValue]}
                            </span> : null}
                        {details.statusEnumValue ? <span className="P-status" style={{
                            color: `${
                                details.statusEnumValue === OrderAgentStatusEnum.CANCELED ||
                                    details.statusEnumValue === OrderAgentStatusEnum.REJECTED
                                    ? '#FF0057' : "#04A556"}`
                        }}>
                            {OrderAgentStatusEnum[details.statusEnumValue]}
                        </span> : null}
                        {details.orderBuyerStatusEnumValue ?
                            <span className="P-status"
                                style={{
                                    color: `${(
                                        details.orderBuyerStatusEnumValue === OrderAgentStatusEnum.PENDING) ? '#7A7A7A'
                                        : details.orderBuyerStatusEnumValue === OrderAgentStatusEnum.ACCEPTED ? "#EFC700" : "#04A556"}`
                                }}>

                                {OrderAgentStatusEnum[details.orderBuyerStatusEnumValue]}
                            </span> : null}
                    </div>
                </div>
                <div className="G-flex P-sub-right">
                    <div className="P-information">
                        <div className="P-row P-row-one G-flex">
                            <div>
                                <div className="G-mb-1 P-seller">{orderType === OrderType.Sellers ? "Seller" : "Agent"}</div>
                                <div className="G-flex G-align-center">
                                    <div className="G-flex P-image"
                                        style={{ background: `url(${details.agentPreviewDto && details.agentPreviewDto.buyerImageUrl ? `${details.agentPreviewDto.buyerImageUrl}/100/100` : noPic}) center/cover no-repeat` }}>
                                    </div>
                                    <div>
                                        <div>{details.agentPreviewDto ? details.agentPreviewDto.buyerFullName : ""}</div>
                                        <div>{details.sellerPreviewDto ? details.sellerPreviewDto.sellerFullName : ""}</div>
                                        {details.agentPreviewDto && !Helper.role  ?
                                            <Link to={`/customers/agents/details?id=${details.agentPreviewDto.buyerId}`}
                                                className="P-green G-cursor">view details
                                            </Link>
                                            : null}
                                        {details.sellerPreviewDto && !Helper.role ?
                                            <Link to={`/customers/sellers/details?id=${details.sellerPreviewDto.sellerId}`}
                                                className="P-green G-cursor">view details
                                            </Link>
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="P-row P-row-two">
                            {orderType !== OrderType.Sellers && <div className="G-flex G-align-center">
                                <p>Order type: </p>
                                <p>
                                    {OrderType[orderType]}
                                </p>
                            </div>}
                            <div className="G-flex G-align-center">
                                <p>Order start date: </p>
                                <p>
                                    {moment(details.createdDate).format('DD MMM YYYY HH:mm')}
                                </p>
                            </div>
                            <div className="G-flex">
                                {OrderType.Sellers === orderType
                                    ? <>
                                        <p> Agent attached date: </p>
                                        {details.buyerAttachDate ? <p>
                                            {moment(details.buyerAttachDate).format('DD MMM YYYY HH:mm')}
                                        </p> : <p>-</p>}
                                    </> : ""}
                            </div>
                            <div className="G-flex">
                                <p>Order finish date: </p>
                                <p>
                                    {moment(details.lastModifiedDate ? details.lastModifiedDate : details.orderFinishDate).format('DD MMM YYYY HH:mm')}
                                </p>
                            </div>
                        </div>

                        <div className="P-row P-row-three">
                            {orderType === OrderType.Sellers
                                && <div className="G-mb-1">
                                    <i className="icon-ic_map G-mr-2" />
                                    {details && details.addressForOrderDto &&
                                        `${details.addressForOrderDto.addressName},
                                         ${details.addressForOrderDto.apartment ? details.addressForOrderDto.apartment + ' app,' : ''}
                                         ${details.addressForOrderDto.building ? details.addressForOrderDto.building + ' building,' : ''} 
                                         ${details.addressForOrderDto.entrance ? details.addressForOrderDto.entrance + ' entrance,' : ''} 
                                         ${details.addressForOrderDto.floor ? details.addressForOrderDto.floor + ' floor' : ''}`
                                    }
                                </div>}

                            {orderType === OrderType.SelfDelivery
                                && <div className="G-mb-1">
                                    <i className="icon-ic_map G-mr-2" />
                                    {details && details.deliverAddressPreviewDto && details.deliverAddressPreviewDto.addressName}
                                </div>}

                            {orderType === OrderType.Delivery
                                && <div className="G-mb-1">
                                    <i className="icon-ic_map G-mr-2" />
                                    {details && details.addressForOrderDto && details.addressForOrderDto.addressName}
                                </div>}

                            {orderType !== OrderType.SelfDelivery && !!details.orderTimes && !!details.orderTimes.length ?
                                <div className="G-flex G-align-center G-mb-1">
                                    <i className="icon-clock-5 G-mr-2" />
                                    {details && details.orderTimes && details.orderTimes.map((item: any, index: any) => {
                                        let from = item.fromTime;
                                        let to = item.toTime;
                                        return (<div key={index} className="G-mr-1">
                                            {item.fromTime !== 0 ? <>
                                            {moment(new Date((from * 1000) - 14400000)).format('HH:mm')} - 
                                            {moment(new Date((to * 1000) - 14400000)).format('HH:mm')}</> : `${Any_time}`}
                                        </div>)
                                    })}
                                </div>
                                : ""}
                            {!!details.comment && <div className="G-flex G-align-center">
                                <i className="icon-ic_comment G-mr-2" />
                                {details.comment}
                            </div>}
                        </div>
                        <div className="P-row P-row-four">
                            <div>
                                <span>Cardboard apx amount:</span>
                                <span className="G-bold P-forSale">{details.weightForSale ? `${details.weightForSale} kg` : "-"}</span>
                            </div>
                            <div>
                                <span>Cardboard actual amount:</span>
                                {details.finalWeight ? <span className="G-bold P-weight-final">{details.finalWeight} kg</span> : "-"}
                            </div>
                        </div>
                        {Helper.role ? <>
                            {details.orderBuyerStatusEnumValue !== OrderAgentStatusEnum.ACCEPTED ?
                                <div className="G-flex G-mt-4">
                                    <div className="P-accept P-order-btn G-cursor G-mr-2"
                                        onClick={
                                            (evt: React.SyntheticEvent) => {
                                                if (Helper.role) {
                                                    evt.stopPropagation();
                                                    this.props.acceptOrder(details.id);
                                                }
                                            }
                                        }>Accept</div>

                                    <div className="P-reject P-order-btn G-cursor"
                                        onClick={
                                            (evt: React.SyntheticEvent) => {
                                                if (Helper.role) {
                                                    evt.stopPropagation();
                                                    this.props.openModal(2, details.id);
                                                }
                                            }
                                        }>Reject</div>
                                </div>
                                :
                                <div className="G-flex P-finish-container G-mt-4">
                                    <div className="P-finish P-order-btn G-cursor G-mr-2"
                                        onClick={
                                            (evt: React.SyntheticEvent) => {
                                                if (Helper.role) {
                                                    evt.stopPropagation();
                                                    this.props.openModal(1, details.id);
                                                }
                                            }
                                        }>Finish</div>

                                    <div className="P-cancel P-order-btn G-cursor"
                                        onClick={
                                            (evt: React.SyntheticEvent) => {
                                                if (Helper.role) {
                                                    evt.stopPropagation();
                                                    this.props.openModal(3, details.id);
                                                }
                                            }
                                        }>Cancel
                                </div>
                                </div>}
                        </> : null}
                    </div>
                </div>
            </>);
    }

}

export default withRouter(AgentOrderMapDetail);