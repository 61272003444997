import { IResponse } from '../../interfaces';
import { BaseController } from "../../classes/http-service";
import { ISendNotif, IGetListRequestBody, IGetListRequest, ISendNotifCustom } from './notification-interface';


class NotificationController extends BaseController {
  private controller = 'notification';

  public sendNotification = async (form: ISendNotif): Promise<IResponse<any>> => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: `sendNotification`,
      body: form,
    });

    return result;
  };

  public sendNotificationToCustomUser = async (form: ISendNotifCustom): Promise<IResponse<any>> => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: `sendNotificationToCustomUser`,
      body: form,
    });

    return result;
  };

  public getList = async (body: IGetListRequest): Promise<IResponse<any>> => {
    const result = await this.connection.POST<IGetListRequestBody>({
      controller: this.controller,
      action: `getListForAdmin?page=${body.page}&size=${body.size}`,
      body: body.form,
    });

    return result;
  };
  
  public downloadNotifications = async (body: IGetListRequestBody): Promise<IResponse<any>> => {
    const result = await this.connection.POST<IGetListRequestBody>({
      controller: this.controller,
      action: `downloadNotifications`,
      body: body,
    });

    return result;
  };

}
export default new NotificationController();
