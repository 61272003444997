import * as React from 'react';
import { RouteComponentProps, Switch, Route } from 'react-router-dom';
import { ROUTES } from 'src/platform/constants/routes';
import { byPrivateRoute, bySidebar } from 'src/platform/decorators/routes';
import HelperPureComponent from 'src/platform/classes/helper-pure-component';
import Helper from 'src/platform/services/helper';
import AgentsContainer from './agents/AgentsContainer';
import HistoryContainer from './history/HistoryContainer';
import SellersContainer from './sellers/SellersContainer';
import './index.scss';
import Languages from 'src/platform/services/languages';

interface IState {
};


const {
  Orders_,
  Sellers_orders,
  Agents_orders,
  History,
} = Languages.Translations

let pages;
if (Helper.role) {
  pages = [
    { name: `${Sellers_orders}`, path: "/orders/sellers" },
    { name: `${Agents_orders}`, path: "/orders/agents" },
    { name: `${History}`, path: "/orders/history" },
  ]
} else {
  pages = [
    { name: `${Sellers_orders}`, path: "/orders/sellers" },
    { name: `${Agents_orders}`, path: "/orders/agents" },
    { name: `${History}`, path: "/orders/history" },
  ]
}


@bySidebar(`${Orders_}`, "icon-ic_loyality-", pages)
@byPrivateRoute(ROUTES.orders)

class Orders extends HelperPureComponent<RouteComponentProps, IState> {

  public state: IState = {
  }

  public render() {
    return (
      <section className="G-main-page P-orders-page">
        <Switch>
          <Route path="/orders/agents" component={AgentsContainer} />
          <Route path="/orders/sellers" component={SellersContainer} />
          <Route path="/orders/history" component={HistoryContainer} />
        </Switch>
      </section >
    );
  }
}

export default Orders;