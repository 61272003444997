import * as React from 'react';
import './index.scss';
import Languages from 'src/platform/services/languages';
import HelperComponent from 'src/platform/classes/helper-component';
import ReactMinimalPieChart from 'react-minimal-pie-chart';
import Select from 'src/platform/components/select/Select';
import { SelectTimeRangeEnum } from 'src/platform/enums/enums';
import { selectTimeRangeOptions } from 'src/platform/constants/casual';
import DatePicker from "react-datepicker";
import DashboardController from 'src/platform/api/dashboard/dashboard';
import { IGetDashboardUserDetailsModel } from 'src/platform/api/dashboard/dashboard-interface';
import moment from 'moment';

interface IState {
  selectTimeRangeEnum: SelectTimeRangeEnum;
  isOpen: boolean;
  startDate: Date,
  endDate: Date,
  userDetails: IGetDashboardUserDetailsModel;
};


const {
  Today,
  End,
  Start,
  Gender,
  Male,
  Female,
  Not_selected,
  Customer_age_statistics,
} = Languages.Translations

class Users extends HelperComponent<{}, IState> {

  public state: IState = {
    selectTimeRangeEnum: SelectTimeRangeEnum.Today,
    isOpen: false,
    startDate: null,
    endDate: null,
    userDetails: {
      femaleUserPercent: null,
      maleUserPercent: null,
      notSelectedGenderUserPercent: null,
      userPercentInAgeRange16to25: null,
      userPercentInAgeRange26to45: null,
      userPercentInAgeRange46to65: null,
      userPercentInAgeRange66plus: null,
      userPercentWithNotSelectedBirth: null,
    },
  }

  componentDidMount() {
    this.getCustomers()
  }

  public onSelect = async (field: string, value: number) => {

    await this.asyncSetState({ selectTimeRangeEnum: value });

    if (value === SelectTimeRangeEnum.CustomRange) {
      this.openDateInput();
    } else this.setState({ isOpen: false });
    this.getCustomers()
  }

  getCustomers = async () => {
    let fromDate = this.state.startDate;
    let toDate = this.state.endDate;

    switch (this.state.selectTimeRangeEnum) {
      case SelectTimeRangeEnum.Today:
        fromDate = moment().toDate();
        toDate = null;
        this.state.startDate = null;
        this.state.endDate = null;
        break;
      case SelectTimeRangeEnum.Yesterday:
        fromDate = moment().subtract(1, 'day').toDate();
        toDate = moment().subtract(1, 'day').toDate();
        this.state.startDate = null;
        this.state.endDate = null;
        break;
      case SelectTimeRangeEnum.Week:
        fromDate = moment().subtract(1, 'weeks').toDate();
        toDate = null;
        this.state.startDate = null;
        this.state.endDate = null;
        break;
      case SelectTimeRangeEnum.Month:
        fromDate = moment().subtract(1, 'months').toDate();
        toDate = null;
        this.state.startDate = null;
        this.state.endDate = null;
        break;
      case SelectTimeRangeEnum.Year:
        fromDate = moment().subtract(1, 'years').toDate();
        toDate = null;
        this.state.startDate = null;
        this.state.endDate = null;
        break;
      default:
        break;
    }

    if (fromDate) {
      window.dispatchEvent(new CustomEvent('loader', { detail: true }));
      const result = await DashboardController.getCustomers({
        fromDate: moment(fromDate).startOf('day').toDate().getTime(),
        toDate: toDate ? moment(toDate).endOf('day').toDate().getTime() : ''
      });
      window.dispatchEvent(new CustomEvent('loader', { detail: false }));

      if (result.success) {
        this.setState({
          userDetails: result.data,
        })
      }
    }
  }

  openDateInput = () => {
    this.setState({ isOpen: true })
  }

  public render() {
    const { selectTimeRangeEnum, startDate, endDate, userDetails } = this.state;

    return (
      <section className="P-dashboard-users">
        <div>
          <div className="G-flex-center-end G-mb-2">

            <Select
              className="G-ml-5"
              height="45px"
              width="220px"
              placeholder={Today}
              value={selectTimeRangeEnum}
              options={selectTimeRangeOptions}
              setFieldValue={this.onSelect}
            />
            {
              this.state.isOpen ?
                <div className="G-flex">
                  <DatePicker
                    className={`G-text-input G-cursor G-ml-3`}
                    selected={startDate}
                    placeholderText={Start}
                    onChange={(startDate: Date) => this.asyncSetState({ startDate }).then(() => {
                      this.getCustomers()
                    })}
                  />
                  <DatePicker
                    className={`G-text-input G-cursor G-ml-3`}
                    selected={endDate}
                    minDate={startDate}
                    placeholderText={End}
                    onChange={(endDate: Date) => this.asyncSetState({ endDate }).then(() => {
                      this.getCustomers()
                    })}
                  />
                </div>
                : null}
          </div>
          <div className="G-flex">
            <div className="P-device">
              <h5>{Customer_age_statistics}</h5>
              <div className="G-flex-align-center">
                <div className="P-device-usage">
                  <ReactMinimalPieChart
                    animate={true}
                    animationDuration={500}
                    animationEasing="ease-out"
                    cx={50}
                    cy={50}
                    data={[
                      {
                        color: '#04A556',
                        title: `16 - 25 ${userDetails.userPercentInAgeRange16to25}%`,
                        value: userDetails.userPercentInAgeRange16to25
                      },
                      {
                        color: '#E30558',
                        title: `26 - 45 ${userDetails.userPercentInAgeRange26to45}%`,
                        value: userDetails.userPercentInAgeRange26to45
                      },
                      {
                        color: '#FFD25A',
                        title: `46 - 65 ${userDetails.userPercentInAgeRange46to65}%`,
                        value: userDetails.userPercentInAgeRange46to65
                      },
                      {
                        color: '#C2C2C2',
                        title: `over 65 ${userDetails.userPercentInAgeRange66plus}%`,
                        value: userDetails.userPercentInAgeRange66plus
                      },
                      {
                        color: '#000000',
                        title: `${Not_selected} ${userDetails.userPercentWithNotSelectedBirth}%`,
                        value: userDetails.userPercentWithNotSelectedBirth
                      },
                    ]}
                    label={false}
                    labelPosition={50}
                    lengthAngle={360}
                    lineWidth={50}
                    onClick={undefined}
                    onMouseOut={undefined}
                    onMouseOver={undefined}
                    paddingAngle={2}
                    radius={50}
                    rounded={false}
                    startAngle={270}
                    viewBoxSize={[100, 100]}
                  />
                </div>

                <div className="P-device-right">
                  <div className="G-mb-2 G-flex">
                    <div className="P-age16 P-cub G-mr-1"></div>
                    <p>16 - 25 <span>{(userDetails.userPercentInAgeRange16to25 && userDetails.userPercentInAgeRange16to25.toString().length > 4 ? userDetails.userPercentInAgeRange16to25.toString().slice(0, 4) : userDetails.userPercentInAgeRange16to25) + '%'}</span></p>
                  </div>
                  <div className="G-mb-2 G-flex">
                    <div className="P-age26 P-cub G-mr-1"></div>
                    <p>26 - 45 <span>{(userDetails.userPercentInAgeRange26to45 && userDetails.userPercentInAgeRange26to45.toString().length > 4 ? userDetails.userPercentInAgeRange26to45.toString().slice(0, 4) : userDetails.userPercentInAgeRange26to45) + '%'}</span></p>
                  </div>
                  <div className="G-mb-2 G-flex">
                    <div className="P-age46 P-cub G-mr-1"></div>
                    <p>46 - 65 <span>{(userDetails.userPercentInAgeRange46to65 && userDetails.userPercentInAgeRange46to65.toString().length > 4 ? userDetails.userPercentInAgeRange46to65.toString().slice(0, 4) : userDetails.userPercentInAgeRange46to65) + '%'}</span></p>
                  </div>
                  <div className="G-mb-2 G-flex">
                    <div className="P-age66 P-cub G-mr-1"></div>
                    <p>over 65 <span>{(userDetails.userPercentInAgeRange66plus && userDetails.userPercentInAgeRange66plus.toString().length > 4 ? userDetails.userPercentInAgeRange66plus.toString().slice(0, 4) : userDetails.userPercentInAgeRange66plus) + '%'}</span></p>
                  </div>
                  <div className="G-mb-2 G-flex">
                    <div className="P-no-selected P-cub G-mr-1"></div>
                    <p>{Not_selected} <span>{(userDetails.userPercentWithNotSelectedBirth && userDetails.userPercentWithNotSelectedBirth.toString().length > 4 ? userDetails.userPercentWithNotSelectedBirth.toString().slice(0, 4) : userDetails.userPercentWithNotSelectedBirth) + '%'}</span></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="P-device G-ml-4">
              <h5>{Gender}</h5>
              <div className="G-flex-align-center">
                <div className="P-device-usage">
                  <ReactMinimalPieChart
                    animate={true}
                    animationDuration={500}
                    animationEasing="ease-out"
                    cx={50}
                    cy={50}
                    data={[
                      {
                        color: '#04A556',
                        title: `${Male} ${userDetails.maleUserPercent}%`,
                        value: userDetails.maleUserPercent
                      },
                      {
                        color: '#000000',
                        title: `${Female} ${userDetails.femaleUserPercent}%`,
                        value: userDetails.femaleUserPercent
                      },
                      {
                        color: '#C2C2C2',
                        title: `${Not_selected} ${userDetails.notSelectedGenderUserPercent}%`,
                        value: userDetails.notSelectedGenderUserPercent
                      },
                    ]}
                    label={false}
                    labelPosition={50}
                    lengthAngle={360}
                    lineWidth={50}
                    onClick={undefined}
                    onMouseOut={undefined}
                    onMouseOver={undefined}
                    paddingAngle={2}
                    radius={50}
                    rounded={false}
                    startAngle={270}
                    viewBoxSize={[100, 100]}
                  />
                </div>

                <div className="P-device-right">
                  <div className="G-mb-2 G-flex">
                    <div className="P-male P-cub G-mr-1"></div>
                    <p>{Male} <span>{(userDetails.maleUserPercent && userDetails.maleUserPercent.toString().length > 4 ? userDetails.maleUserPercent.toString().slice(0, 4) : userDetails.maleUserPercent) + '%'}</span></p>
                  </div>
                  <div className="G-mb-2 G-flex">
                    <div className="P-female P-cub G-mr-1"></div>
                    <p>{Female} <span>{(userDetails.femaleUserPercent && userDetails.femaleUserPercent.toString().length > 4 ? userDetails.femaleUserPercent.toString().slice(0, 4) : userDetails.femaleUserPercent) + '%'}</span></p>
                  </div>
                  <div className="G-flex">
                    <div className="P-not-selected P-cub G-mr-1"></div>
                    <p>{Not_selected} <span>{(userDetails.notSelectedGenderUserPercent && userDetails.notSelectedGenderUserPercent.toString().length > 4 ? userDetails.notSelectedGenderUserPercent.toString().slice(0, 4) : userDetails.notSelectedGenderUserPercent) + '%'}</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
    );
  }
}

export default Users;