import * as React from 'react';
import ClickOutside from '../click-outside';
import Modal from 'react-modal';
import AdminController from 'src/platform/api/admin/admin';
import { store } from 'react-notifications-component';
import { IGetUserDetailsResponseModel } from 'src/platform/api/admin/interfaces/admin-interface';
import HelperPureComponent from 'src/platform/classes/helper-pure-component';
import { ROUTES } from 'src/platform/constants/routes';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Helper from 'src/platform/services/helper';
import LineLoader from '../lineLoader';
import "./index.scss";
import { languageEnum } from 'src/platform/enums/enums';
import Languages from 'src/platform/services/languages';

interface IState {
  toolbar: boolean;
  modalIsOpen: boolean;
  userDetailsData: IGetUserDetailsResponseModel;
  loader: boolean,
  selected: any
}

const {
  Account_settings,
  Cancel,
  Sign_out,
  Sign_out_text,
} = Languages.Translations

class Header extends HelperPureComponent<RouteComponentProps, IState> {

  public state: IState = {
    toolbar: false,
    modalIsOpen: false,
    userDetailsData: null,
    loader: false,
    selected: +window.localStorage.getItem('language'),
  }
  componentDidMount() {
    this.getUserDetails();
    window.addEventListener('loader', (e: CustomEvent)=>{
      this.asyncSetState({loader: e.detail})
    })
  }

  private getUserDetails = async () => {
    const res = await AdminController.getUserDetails();
    if (res.success) {
      this.asyncSetState({ userDetailsData: res.data })
    } else {
      store.addNotification(Helper.notificationConfig(res.message, false));
    }
  }
  public splitNumber = (num: string) => {
    return `${num.slice(0, 4)}-${num.slice(4, 6)}-${num.slice(6, 8)}-${num.slice(8, 10)}-${num.slice(10, 12)}`
  }

  public openModal = () => {
    this.asyncSetState({ modalIsOpen: true });
  }

  public closeModal = () => {
    this.asyncSetState({ modalIsOpen: false });
  }

  private toggleToolbar = () => {
    const { toolbar } = this.state;
    this.asyncSetState({ toolbar: !toolbar })
  }

  public changeLanguage = (shortCode: any) => {
    window.localStorage.setItem('language', shortCode);
    window.location.reload();
  }

  public render() {
    const { toolbar, userDetailsData, loader } = this.state;

    return (
      <>
        {loader ? <LineLoader/>: null}
        <header className="P-header G-block">
          <div className="P-header-wrapper">

          <div className="P-lang-open">
            <div className={`G-bold G-cursor G-mr-2 ${Languages.ShortCode === languageEnum.hy ? 'G-green' : ''}`}
              onClick={() => this.changeLanguage(languageEnum.hy)}>Հայ</div>
            <div className={`G-bold G-cursor ${Languages.ShortCode === languageEnum.en ? 'G-green' : ''}`}
              onClick={() => this.changeLanguage(languageEnum.en)}>Eng</div>
          </div>

            <ClickOutside onClickOutside={() => this.asyncSetState({ toolbar: false })}>
              <div className="P-user">
                <button
                  className="G-cursor P-number"
                  onClick={this.toggleToolbar}>
                  {!!userDetailsData && this.splitNumber(userDetailsData.phoneNumber)}
                  <i className={`icon-ic_arrowdown ${toolbar ? 'G-arrow-animation' : ''}`} />
                </button>
                <ul className={`P-toolbar G-block ${toolbar ? "" : "P-off "}`}>
                  <li onClick={() => {
                    this.asyncSetState({ toolbar: false })
                    this.props.history.push(ROUTES.accountSettings)
                  }}>
                    {Account_settings}
                  </li>
                  <li onClick={this.openModal}>{Sign_out}</li>
                </ul>
              </div>
            </ClickOutside>
          </div>
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal}
            style={{
              content: {
                padding: "30px",
                minHeight: "180px",
                minWidth: "350px",
                display: "flex",
                flexDirection: "column",
              }
            }}
          >
            <div className="P-sign-out-modal">
              <h5 className="G-mb-2 P-title">{Sign_out}</h5>
              <p className="G-mb-3 P-text">{Sign_out_text}</p>
              <div className="G-flex">
                <button className="G-mr-2 P-cancel" onClick={this.closeModal}>{Cancel}</button>
                <button className="G-button P-button" onClick={() => Helper.logout()}>{Sign_out}</button>
              </div>
            </div>
          </Modal>
        </header>
      </>
    );
  }
}

export default withRouter(Header);

