import * as React from 'react';
import HelperComponent from 'src/platform/classes/helper-component';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Formik, Form, Field, FormikValues } from 'formik';
import * as Yup from 'yup';
import DataTransfer from '../services/dataTransfer';
import Loader from 'src/platform/components/loader/loader';
import { phoneRegExp } from 'src/platform/constants/regExp';
import { ISendPhoneNumberRequestModel } from 'src/platform/api/forgot-password/interfaces/send-phone-number-req'
import * as firebase from 'firebase';
import Languages from 'src/platform/services/languages';

const {
  Sign_in,
	Password_recovery,
	Please_enter_your_phone,
	Send_code,
	Phone_number,
} = Languages.Translations

class SendPhoneNumber extends HelperComponent<RouteComponentProps> {

	private sendNumber = async (value: ISendPhoneNumberRequestModel, actions: FormikValues) => {

		let num: string;

		if (!value.phoneNumber.toString().startsWith("374")) {
			num = `+374${value.phoneNumber}`;
		} else {
			num = `+${value.phoneNumber}`;
		}

		window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('submit-button', {
			'size': 'invisible',
			'callback': function(response : any) {
			}
		});
		let phoneNumber = num;
		let appVerifier = window.recaptchaVerifier;
		await firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
			.then((confirmationResult: any) => {
				DataTransfer.data.phoneNumber = phoneNumber;
				DataTransfer.data.confirmationResult = confirmationResult;
				this.props.history.push('/login/verify-code');
			})

	}

	public validationSchema = Yup.object().shape({
		phoneNumber: Yup.string().matches(phoneRegExp).required()
	});

	public render() {
		
		return (
			<div className="P-sigin-form-block P-small">
				<Formik
					initialValues={{ phoneNumber: DataTransfer.data.phoneNumber || "" }}
					onSubmit={this.sendNumber}
					validationSchema={this.validationSchema}>
					{({ errors, touched, isSubmitting }) => (
						<Form className="P-form"
							noValidate={true}
						>
							<span className="P-sign-in-title G-mb-2">{Password_recovery}</span>
							<span className="P-info P-info--one">{Please_enter_your_phone}</span>
							<div className="P-form-input">
								<div className="G-mb-4">
									<Field type="number"
										name="phoneNumber"
										placeholder={Phone_number}
										className={touched.phoneNumber && errors.phoneNumber ? "G-error" : ""} />
								</div>
							</div>
							<button id="submit-button" type="submit" className="P-sign-in-btn">
								{isSubmitting ? <Loader /> : `${Send_code}`}
							</button>
							<Link to={'/login/sign-in'} className="G-btn-transparent G-bold">
								{Sign_in}
							</Link>
						</Form>
					)}
				</Formik>
			</div>
		);
	}
}

export default SendPhoneNumber;