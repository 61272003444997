import * as React from 'react';
import './index.scss';
import Languages from 'src/platform/services/languages';
import HelperComponent from 'src/platform/classes/helper-component';
import Select from 'src/platform/components/select/Select';
import DatePicker from "react-datepicker";
import { DataShowEnum, ChartsUserTypeEnum, SelectTimeRangeEnum, StatisticShowDateEnum, UserTypeEnum } from 'src/platform/enums/enums';
import { userTypeDashboardOptions, dataShowEnumOptions, selectTimeRangeOptions, userTypeOptionsDashboard } from 'src/platform/constants/casual';
import moment from 'moment';
import DashboardController from 'src/platform/api/dashboard/dashboard';
import Chart, { ChartIProps } from './chart';
import { IGetDashboardCustomerWrap, IGetDashboardRequests, IGetDashboardRequestsWrap, IGetRequestsCount } from 'src/platform/api/dashboard/dashboard-interface';
import ReactMinimalPieChart from 'react-minimal-pie-chart';


interface IState {
  startDate: Date,
  endDate: Date,
  startDateMontly: Date,
  endDateMontly: Date,
  startDateTapPoint: Date,
  selectTimeRangeEnum: SelectTimeRangeEnum;
  dataShowEnum: DataShowEnum;
  statisticShowDateEnum: StatisticShowDateEnum;
  isOpen: boolean;
  customerData: IGetRequestsCount[];
  cardBoardWeightData: [];
  cardBoardWeightComparisonData: {
    cardboardWeight1: [],
    cardboardWeight2: [],
  };
  requestsComparisonData: {
    requestsCount1: [],
    requestsCount2: [],
  };
  tapPointData: [];

  customerComparisonData: IGetDashboardCustomerWrap;
  allData: IGetDashboardRequests;
  requestsData: IGetRequestsCount[];
  requestsWrapData: IGetDashboardRequestsWrap;
  chartData: {},
  accountType1: UserTypeEnum,
  accountType2: ChartsUserTypeEnum,
  accountType3: ChartsUserTypeEnum,
  accountType4: UserTypeEnum,
  filterByMonth: boolean,
  searchText: string,
};

const {
  Today,
  Start,
  End,
  pending_sellers_requests,
  completed_sellers_requests,
  pending_agents_requests,
  completed_agents_requests,
  Number_registered_customers,
  Number_of_applications,
  Waste_weight,
  Overloaded_hours,
  Any_time,
  Search_for_specific_user,
} = Languages.Translations

class Requests extends HelperComponent<ChartIProps, IState> {
  public state: IState = {
    startDate: null,
    endDate: null,
    startDateMontly: null,
    startDateTapPoint: null,
    endDateMontly: null,
    selectTimeRangeEnum: SelectTimeRangeEnum.Today,
    statisticShowDateEnum: StatisticShowDateEnum.Daily,
    dataShowEnum: DataShowEnum.Monthly,
    accountType1: UserTypeEnum.Sellers,
    accountType2: ChartsUserTypeEnum.INDIVIDUAL_CORPORATE_SELLER,
    accountType3: ChartsUserTypeEnum.INDIVIDUAL_CORPORATE_SELLER,
    accountType4: UserTypeEnum.Sellers,
    isOpen: false,
    filterByMonth: false,
    tapPointData: [],
    chartData: {},
    customerComparisonData: {
      customerStatisticPreviewListDtoList1: [],
      customerStatisticPreviewListDtoList2: [],
    },
    customerData: [],
    cardBoardWeightData: [],
    cardBoardWeightComparisonData: {
      cardboardWeight1: [],
      cardboardWeight2: [],
    },
    requestsComparisonData: {
      requestsCount1: [],
      requestsCount2: [],
    },
    requestsData: [],
    allData: {
      cardboardWeight: [],
      completedAgentsRequests: 0,
      completedSellersRequests: null,
      pendingAgentsRequests: 0,
      pendingSellersRequests: 0,
      requestsCount: null,
      statisticShowDate: 0,
      overloadedHours: {
        from9to12: 0,
        from12to15: 0,
        from15to18: 0,
        from18to21: 0,
        theRestTime: 0,
      }
    },
    requestsWrapData: {
      requestsPreviewDto1: {
        cardboardWeight: [],
        completedAgentsRequests: 0,
        completedSellersRequests: null,
        pendingAgentsRequests: 0,
        pendingSellersRequests: 0,
        requestsCount: null,
        statisticShowDate: 0,
        overloadedHours: {
          from9to12: 0,
          from12to15: 0,
          from15to18: 0,
          from18to21: 0,
          theRestTime: 0,
        }
      },
      requestsPreviewDto2: {
        cardboardWeight: [],
        completedAgentsRequests: 0,
        completedSellersRequests: null,
        pendingAgentsRequests: 0,
        pendingSellersRequests: 0,
        requestsCount: null,
        statisticShowDate: 0,
        overloadedHours: {
          from9to12: 0,
          from12to15: 0,
          from15to18: 0,
          from18to21: 0,
          theRestTime: 0,
        }
      },
    },
    searchText: '',
  }

  componentDidMount() {
    this.getCustomersStatistic()
  }

  public onSelect = async (field: string, value: number) => {

    await this.asyncSetState({ selectTimeRangeEnum: value });

    if (value === SelectTimeRangeEnum.CustomRange) {
      this.openDateInput();
    } else this.setState({ isOpen: false });
    this.getCustomersStatistic()
  }

  public onSelectMontly = async (field: string, value: number) => {
    await this.asyncSetState({ dataShowEnum: value });
  }

  openDateInput = () => {
    this.setState({ isOpen: true })
  }

  public getTapPoints = async () => {
    if (!this.state.startDateTapPoint) {
      this.state.startDateTapPoint = new Date();
    }
    const resultTapPoints = await DashboardController.tapPoints({
      text: this.state.searchText,
      customerType: this.state.accountType4 + 2,
      fromDate: moment(this.state.startDateTapPoint).startOf('month').toDate().getTime(),
      toDate: moment(this.state.startDateTapPoint).endOf('month').toDate().getTime(),
    });
    if (resultTapPoints.success) {
      this.setState({
        tapPointData: resultTapPoints.data
      })
    }
  }

  public getCustomersStatistic = async () => {
    let fromDate = this.state.startDate;
    let toDate = this.state.endDate;
    console.log(this.state.selectTimeRangeEnum);
    switch (this.state.selectTimeRangeEnum) {
      case SelectTimeRangeEnum.Today:
        fromDate = moment().toDate();
        toDate = null;
        this.state.startDate = null;
        this.state.endDate = null;
        break;
      case SelectTimeRangeEnum.Yesterday:
        fromDate = moment().subtract(1, 'day').toDate();
        toDate = moment().subtract(1, 'day').toDate();
        this.state.startDate = null;
        this.state.endDate = null;
        break;
      case SelectTimeRangeEnum.Week:
        fromDate = moment().subtract(1, 'weeks').toDate();
        toDate = null;
        this.state.startDate = null;
        this.state.endDate = null;
        break;
      case SelectTimeRangeEnum.Month:
        fromDate = moment().subtract(1, 'months').toDate();
        toDate = null;
        this.state.startDate = null;
        this.state.endDate = null;
        break;
      case SelectTimeRangeEnum.Year:
        fromDate = moment().subtract(1, 'years').toDate();
        toDate = null;
        this.state.startDate = null;
        this.state.endDate = null;
        break;
      default:
        break;
    }

    if (fromDate) {
      window.dispatchEvent(new CustomEvent('loader', { detail: true }));
      
      const resultCustomersStatistic = await DashboardController.getCustomersStatistic({
        customerType: this.state.accountType3,
        fromDate: moment(fromDate).startOf('day').toDate().getTime(),
        toDate: toDate ? moment(toDate).endOf('day').toDate().getTime() : new Date().getTime()
      });
      if (resultCustomersStatistic.success) {
        this.setState({
          customerData: resultCustomersStatistic.data.customerStatisticPreviewList
        })

      }
            
      const resultCustomersComparisonStatistic = await DashboardController.getCustomersComparisonStatistic({
        customerType: this.state.accountType3,
        dataShowBy: this.state.dataShowEnum,
        date1: moment(this.state.startDateMontly).startOf('month').toDate().getTime(),
        date2: moment(this.state.endDateMontly).startOf('month').toDate().getTime(),
      });
      if (resultCustomersComparisonStatistic.success) {
        this.setState({
          customerComparisonData: resultCustomersComparisonStatistic.data
        })
      }

      const resultRequestV2 = await DashboardController.getRequestsV2({
        customerType: this.state.accountType2,
        fromDate: moment(fromDate).startOf('day').toDate().getTime(),
        toDate: toDate ? moment(toDate).endOf('day').toDate().getTime() : new Date().getTime()
      });
      if (resultRequestV2.success) {
        this.setState({
          requestsData: resultRequestV2.data
        })
      }
            
      const resultRequestComparisonV2 = await DashboardController.getRequestsComparisonV2({
        customerType: this.state.accountType2,
        dataShowBy: this.state.dataShowEnum,
        date1: moment(this.state.startDateMontly).startOf('month').toDate().getTime(),
        date2: moment(this.state.endDateMontly).startOf('month').toDate().getTime(),
      });
      if (resultRequestComparisonV2.success) {
        this.setState({
          requestsComparisonData: resultRequestComparisonV2.data
        })
      }
      
      const resultCardBoardWeightData = await DashboardController.getCardBoardWeightStatistic({
        customerType: this.state.accountType1 + 2,
        fromDate: moment(fromDate).startOf('day').toDate().getTime(),
        toDate: toDate ? moment(toDate).endOf('day').toDate().getTime() : new Date().getTime()
      });
      if (resultCardBoardWeightData.success) {
        this.setState({
          cardBoardWeightData: resultCardBoardWeightData.data
        })
      }

      const resultCardBoardWeightComparisonData = await DashboardController.getCardBoardWeightComparisonStatistic({
        dataShowBy: this.state.dataShowEnum,
        customerType: this.state.accountType1,
        date1: moment(this.state.startDateMontly).startOf('month').toDate().getTime(),
        date2: moment(this.state.endDateMontly).startOf('month').toDate().getTime(),
      });
      if (resultCardBoardWeightComparisonData.success) {
        this.setState({
          cardBoardWeightComparisonData: resultCardBoardWeightComparisonData.data
        })
      }

      const resultRequests = await DashboardController.getRequests({
        fromDate: moment(fromDate).startOf('day').toDate().getTime(),
        toDate: toDate ? moment(toDate).endOf('day').toDate().getTime() : new Date().getTime()
      });
      if (resultRequests.success) {
        this.setState({
          allData: resultRequests.data
        })
      }

      const resultRequestsComparison = await DashboardController.getRequestsComparison({
        dataShowBy: DataShowEnum.Monthly,
        customerType: this.state.accountType1,
        date1: moment(this.state.startDateMontly).startOf('month').toDate().getTime(),
        date2: moment(this.state.endDateMontly).startOf('month').toDate().getTime(),
      });
      if (resultRequestsComparison.success) {
        this.setState({
          requestsWrapData: resultRequestsComparison.data
        })
      }
      this.getTapPoints();
      window.dispatchEvent(new CustomEvent('loader', { detail: false }));
    }
  }

  public changeFilter = (type: boolean) => {
    this.setState({ filterByMonth: type })
  }
  
  public inputTimer: any;
  public onInput = async (evt: React.SyntheticEvent<HTMLInputElement>) => {
    await this.asyncSetState({ searchText: evt.currentTarget.value })
    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(async () => {
      this.getTapPoints();
    }, 300);
  }

  public render() {
    const { searchText, startDate, endDate, startDateMontly, endDateMontly, startDateTapPoint, selectTimeRangeEnum, allData, requestsWrapData, accountType1, accountType2, accountType3, accountType4, filterByMonth, dataShowEnum } = this.state;
    return (
      <section className="P-requests">
        <div>
          <div className="G-flex-center-end G-mr-1">

            {!filterByMonth ?
              <div className="G-flex-center-end">
                <Select
                  className="G-ml-5"
                  height="45px"
                  width="220px"
                  placeholder={Today}
                  value={selectTimeRangeEnum}
                  options={selectTimeRangeOptions}
                  setFieldValue={this.onSelect}
                />
                {
                  this.state.isOpen ?
                    <div className="G-flex">
                      <DatePicker
                        className={`G-text-input G-cursor G-ml-3`}
                        selected={startDate}
                        placeholderText={Start}
                        onChange={(startDate: Date) => this.asyncSetState({ startDate }).then(() => {
                          this.getCustomersStatistic()
                        })}
                      />
                      <DatePicker
                        className={`G-text-input G-cursor G-ml-3`}
                        selected={endDate}
                        minDate={startDate}
                        placeholderText={End}
                        onChange={(endDate: Date) => this.asyncSetState({ endDate }).then(() => {
                          this.getCustomersStatistic()
                        })}
                      />
                    </div>
                    : null}
              </div>
              :
              <div className="G-flex-center-end">
                <Select
                  className="G-ml-5"
                  height="45px"
                  width="220px"
                  placeholder={Today}
                  value={dataShowEnum}
                  options={dataShowEnumOptions}
                  setFieldValue={this.onSelectMontly}
                />
                <div className="G-flex">
                  <DatePicker
                    className={`G-text-input G-cursor G-ml-3`}
                    selected={startDateMontly}
                    dateFormat="MMMM yyyy"
                    showMonthYearPicker
                    showFullMonthYearPicker
                    showTwoColumnMonthYearPicker
                    onChange={(startDateMontly: Date) => this.asyncSetState({ startDateMontly }).then(() => {
                      this.getCustomersStatistic()
                    })}
                  />
                  <DatePicker
                    className={`G-text-input G-cursor G-ml-3`}
                    selected={endDateMontly}
                    minDate={startDate}
                    dateFormat="MMMM yyyy"
                    showMonthYearPicker
                    showFullMonthYearPicker
                    showTwoColumnMonthYearPicker
                    onChange={(endDateMontly: Date) => this.asyncSetState({ endDateMontly }).then(() => {
                      this.getCustomersStatistic()
                    })}
                  />
                </div>
              </div>}

            <div className="P-plus-icon">
              {!filterByMonth ?
                <i className="icon-ic_plus" onClick={() => this.asyncSetState({ filterByMonth: true })}></i>

                : <i className="icon-ic_close" onClick={() => this.asyncSetState({ filterByMonth: false })}></i>}
            </div>
          </div>


          <div className="G-flex G-mt-2 G-mb-2 G-justify-around">
            <div className="P-box">
              <h1>{filterByMonth ? '-' : allData.pendingSellersRequests}</h1>
              <p>{pending_sellers_requests}</p>
            </div>
            <div className="P-box">
              <h1>{filterByMonth ? requestsWrapData.requestsPreviewDto1.completedSellersRequests + ' - ' + requestsWrapData.requestsPreviewDto2.completedSellersRequests : allData.completedSellersRequests}</h1>
              <p>{completed_sellers_requests}</p>
            </div>
            <div className="P-box">
              <h1>{filterByMonth ? '-' : allData.pendingAgentsRequests}</h1>
              <p>{pending_agents_requests}</p>
            </div>
            <div className="P-box">
              <h1>{filterByMonth ? requestsWrapData.requestsPreviewDto1.completedAgentsRequests + ' - ' + requestsWrapData.requestsPreviewDto2.completedAgentsRequests : allData.completedAgentsRequests}</h1>
              <p>{completed_agents_requests}</p>
            </div>
          </div>
        </div>

        <div className="P-chart-wrap">
          <div className="P-chart-header">
            <h5>{Waste_weight}</h5>
            <Select
              className="G-mr-3"
              height="45px"
              width="220px"
              value={accountType1}
              options={userTypeOptionsDashboard}
              setFieldValue={(field: string, value: string) => this.asyncSetState({ accountType1: Number(value) }).then(() => {
                this.getCustomersStatistic();
              })}
            />
          </div>
          <div className="P-chart">
            <Chart filterByMonth={filterByMonth} type={1} 
            data1={filterByMonth ? this.state.cardBoardWeightComparisonData.cardboardWeight1 : this.state.cardBoardWeightData} 
            data2={filterByMonth ? this.state.cardBoardWeightComparisonData.cardboardWeight2 : []}
            showType={this.state.allData.statisticShowDate}/>
          </div>
        </div>

        <div className="P-chart-wrap">
          <div className="P-chart-header">
            <h5>{Number_of_applications}</h5>
            <Select
              className="G-mr-3"
              height="45px"
              width="220px"
              value={accountType2}
              options={userTypeDashboardOptions}
              setFieldValue={(field: string, value: string) => this.asyncSetState({ accountType2: Number(value) }).then(() => {
                this.getCustomersStatistic();
              })}
            />
          </div>
          <div className="P-chart">
            <Chart filterByMonth={filterByMonth} type={2} 
            data1={filterByMonth ? (this.state.requestsComparisonData ? this.state.requestsComparisonData.requestsCount1 : []) : this.state.requestsData || []} 
            data2={filterByMonth ? (this.state.requestsComparisonData ? this.state.requestsComparisonData.requestsCount2 : []) : []} 
            showType={this.state.allData.statisticShowDate}/>
          </div>
        </div>

        <div className="P-chart-wrap">
          <div className="P-chart-header">
            <h5>{Number_registered_customers}</h5>
            <Select
              className="G-mr-3"
              height="45px"
              width="220px"
              value={accountType3}
              options={userTypeDashboardOptions}
              setFieldValue={(field: string, value: string) => this.asyncSetState({ accountType3: Number(value) }).then(() => {
                this.getCustomersStatistic();
              })}
            />
          </div>
          <div className="P-chart">
            <Chart filterByMonth={filterByMonth} type={3}
            data1={filterByMonth ? this.state.customerComparisonData.customerStatisticPreviewListDtoList1 : this.state.customerData || []} 
            data2={filterByMonth ? this.state.customerComparisonData.customerStatisticPreviewListDtoList2 : []}
            showType={this.state.allData.statisticShowDate}/>
          </div>
        </div>
        
        <div className="P-chart-wrap">
          <div className="P-chart-header">
            <h5>Tap points</h5>
            <div className="G-flex">
            <div className="G-ml-2">
              <input className="G-text-input"
                placeholder={Search_for_specific_user}
                type="text"
                value={searchText}
                onChange={this.onInput}
              />
            </div>
            <DatePicker
                    className={`G-text-input G-cursor G-ml-3`}
                    selected={startDateTapPoint}
                    placeholderText='Select month'
                    dateFormat="MMMM yyyy"
                    showMonthYearPicker
                    showFullMonthYearPicker
                    showTwoColumnMonthYearPicker
                    onChange={(startDateTapPoint: Date) => this.asyncSetState({ startDateTapPoint }).then(() => {
                      this.getCustomersStatistic()
                    })}
                  />
              <Select
                className="G-mr-3 G-ml-3"
                height="45px"
                width="220px"
                value={accountType4}
                options={userTypeOptionsDashboard}
                setFieldValue={(field: string, value: string) => this.asyncSetState({ accountType4: Number(value) }).then(() => {
                  this.getTapPoints();
                })}
              />
            </div>
          </div>
          <div className="P-chart">
            <Chart filterByMonth={filterByMonth} type={4}
            data1={this.state.tapPointData || []} 
            data2={[]}
            showType={2}/>
          </div>
        </div>
        <div>
        </div>
        <div className="P-device">
          <h5>{Overloaded_hours}</h5>
          <div className="G-flex-align-center">
            <div className="P-device-usage">
              <ReactMinimalPieChart
                animate={true}
                animationDuration={500}
                animationEasing="ease-out"
                cx={50}
                cy={50}
                data={[
                  {
                    color: '#04A556',
                    title: `09 ։ 00 - 12 ։ 00 ${allData.overloadedHours.from9to12}%`,
                    value: allData.overloadedHours.from9to12
                  },
                  {
                    color: '#E30558',
                    title: `12 ։ 00 - 15 ։ 00 ${allData.overloadedHours.from12to15}%`,
                    value: allData.overloadedHours.from12to15
                  },
                  {
                    color: '#FFD25A',
                    title: `15 ։ 00 - 18 ։ 00 ${allData.overloadedHours.from15to18}%`,
                    value: allData.overloadedHours.from15to18
                  },
                  {
                    color: '#C2C2C2',
                    title: `18 ։ 00 - 21 ։ 00 ${allData.overloadedHours.from18to21}%`,
                    value: allData.overloadedHours.from18to21
                  },
                  {
                    color: '#000000',
                    title: `${Any_time} ${allData.overloadedHours.theRestTime}%`,
                    value: allData.overloadedHours.theRestTime
                  },
                ]}
                label={false}
                labelPosition={50}
                lengthAngle={360}
                lineWidth={50}
                onClick={undefined}
                onMouseOut={undefined}
                onMouseOver={undefined}
                paddingAngle={2}
                radius={50}
                rounded={false}
                startAngle={270}
                viewBoxSize={[100, 100]}
              />
            </div>

            <div className="P-device-right">
              <div className="G-mb-2 G-flex">
                <div className="P-time1 P-cub G-mr-1"></div>
                <p>09 ։ 00 - 12 ։ 00 <span>{(allData.overloadedHours.from9to12 && allData.overloadedHours.from9to12.toString().length > 4 ? allData.overloadedHours.from9to12.toString().slice(0, 4) : allData.overloadedHours.from9to12) + '%'}</span></p>
              </div>
              <div className="G-mb-2 G-flex">
                <div className="P-time2 P-cub G-mr-1"></div>
                <p>12 ։ 00 - 15 ։ 00 <span>{(allData.overloadedHours.from12to15 && allData.overloadedHours.from12to15.toString().length > 4 ? allData.overloadedHours.from12to15.toString().slice(0, 4) : allData.overloadedHours.from12to15) + '%'}</span></p>
              </div>
              <div className="G-mb-2 G-flex">
                <div className="P-time3 P-cub G-mr-1"></div>
                <p>15 ։ 00 - 18 ։ 00 <span>{(allData.overloadedHours.from15to18 && allData.overloadedHours.from15to18.toString().length > 4 ? allData.overloadedHours.from15to18.toString().slice(0, 4) : allData.overloadedHours.from15to18) + '%'}</span></p>
              </div>
              <div className="G-mb-2 G-flex">
                <div className="P-time4 P-cub G-mr-1"></div>
                <p>18 ։ 00 - 21 ։ 00 <span>{(allData.overloadedHours.from18to21 && allData.overloadedHours.from18to21.toString().length > 4 ? allData.overloadedHours.from18to21.toString().slice(0, 4) : allData.overloadedHours.from18to21) + '%'}</span></p>
              </div>
              <div className="G-mb-2 G-flex">
                <div className="P-time5 P-cub G-mr-1"></div>
                <p>{Any_time} <span>{(allData.overloadedHours.theRestTime && allData.overloadedHours.theRestTime.toString().length > 4 ? allData.overloadedHours.theRestTime.toString().slice(0, 4) : allData.overloadedHours.theRestTime) + '%'}</span></p>
              </div>
            </div>
          </div>
        </div>
      </section >
    );
  }
}

export default Requests;