import { IGetUserDetailsResponseModel, IChangePassword } from './interfaces/admin-interface';
import { IResponse } from '../../interfaces';
import { BaseController } from "../../classes/http-service";
class AdminController extends BaseController {
  private controller = 'admin';

  public getUserDetails = async (): Promise<IResponse<IGetUserDetailsResponseModel>> => {
    const result = await this.connection.GET({
      query: {},
      action: 'getUserDetails',
      controller: this.controller,
      noneJSONBody: false,
    });

    return result;
  }

  public changePassword = async (body: IChangePassword): Promise<IResponse<any>> => {
    const result = await this.connection.PUT({      
      action: 'changePassword',
      controller: this.controller,
      body: body,
      noneJSONBody: false
      });
    return result;
  }

};

export default new AdminController();

