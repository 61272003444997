import * as React from 'react';
import HelperComponent from 'src/platform/classes/helper-component';
import { Formik, Form, Field, FormikValues, FormikErrors } from 'formik';
import * as Yup from 'yup';
import NotificationController from 'src/platform/api/notification/notification';
import { store } from 'react-notifications-component';
import Loader from 'src/platform/components/loader/loader';
import Helper from 'src/platform/services/helper';
import {  ISendNotifCustom } from 'src/platform/api/notification/notification-interface';
import "./index.scss";
import noPic from 'src/assets/images/no-photo.png'
import Languages from 'src/platform/services/languages';


interface IProps {
    closeModal: () => void,
    getSupportMessagesList: () => void,
    item: any,
    id: number
}
interface IState {
    description: number,
}
export interface IMessageProps {
    item: any,
}


const {
Notification_title,
Notification_message,
Description,
Send_notification,
Cancel ,
enums,
Create_push_notifications,
Push_notification_was_sent,
} = Languages.Translations;

class CreatePopup extends HelperComponent<IProps, IState> {

    state: IState = {
        description: 1,
    }

    public validationSchema = Yup.object().shape({
        notifTitle_1: Yup.string().min(1).required(),
        notifTitle_2: Yup.string().min(1).required(),
        notifTitle_3: Yup.string().min(1).required(),
        notifMessage_1: Yup.string().min(5).required(),
        notifMessage_2: Yup.string().min(5).required(),
        notifMessage_3: Yup.string().min(5).required(),
    })

    private sendNotificationCustom = async (values: FormikValues, errors: FormikValues) => {
        
        const body: ISendNotifCustom = {
                userId: this.props.id,
                notificationTranslates: [
                  {
                    languageName: "hy",
                    message: values.notifMessage_1.trim(),
                    title: values.notifTitle_1,
                  },
                  {
                    languageName: "ru",
                    message: values.notifMessage_2.trim(),
                    title: values.notifTitle_2,
                  },
                  {
                    languageName: "en",
                    message: values.notifMessage_3.trim(),
                    title: values.notifTitle_3,
                  }
                ],
        }
        const res = await NotificationController.sendNotificationToCustomUser(body);

        if (res.success) {            
            this.props.closeModal();
            this.props.getSupportMessagesList();
          store.addNotification(Helper.notificationConfig(`${Push_notification_was_sent}`, true));
        } else {
          store.addNotification(Helper.notificationConfig(res.message, false));
        }
    }

    onSubmit = (errors: FormikErrors<FormikValues>) => {
        if (!errors.notifTitle_1 && errors.notifTitle_2) {
            this.asyncSetState({ description: 2 });
        }
        if (!errors.notifTitle_1 && !errors.notifTitle_2 && errors.notifTitle_3) {
            this.asyncSetState({description: 3});
        }
    }

    render() {
        const { description } = this.state;
        const { item } = this.props;

        return (<div className="P-create-popup">
            <h5 className="G-mb-3 P-title">{Create_push_notifications}</h5>
            <Formik
                initialValues={{
                    notifTitle_1: "",
                    notifTitle_2: "",
                    notifTitle_3: "",
                    notifMessage_1: "",
                    notifMessage_2: "",
                    notifMessage_3: "",
                }}
                onSubmit={this.sendNotificationCustom}
                validationSchema={this.validationSchema}
            >
                {({ errors, touched, isSubmitting }) => (
                    <Form noValidate={true}>
                        <div className="P-left G-flex G-mb-3">
                            <div className="P-icon P-image G-mr-1"
                                style={{ background: `url(${item && item.imageUrl ? `${item.imageUrl}/100/100` : noPic}) center/cover no-repeat` }}></div>
                            <div className="G-column G-mr-2">
                                <span className="P-name">{item.fullName ? item.fullName : item.companyName}</span>
                                <span className="P-userType">{enums.UserTypeSingleEnum[item.userTypeEnumValue]}</span>
                            </div>
                        </div>
                            <div className="G-flex G-justify-between">
                            <span className="G-mb-2 P-text G-bold">{Description}</span>
                            <div>
                                <span onClick={() => this.asyncSetState({ description: 1 })}
                                    className={`G-mb-2 G-mr-1 P-lang G-cursor ${description === 1 && "P-green"}`}>ARM</span>
                                <span onClick={() => this.asyncSetState({ description: 2 })}
                                    className={`G-mb-2 G-mr-1 P-lang G-cursor ${description === 2 && "P-green"}`}>RU</span>
                                <span onClick={() => this.asyncSetState({ description: 3 })}
                                    className={`G-mb-2 G-mr-1 P-lang G-cursor ${description === 3 && "P-green"}`}>ENG</span>
                            </div>
                        </div>
                        {description === 1 ? <div className="P-am">
                            <Field
                                name="notifTitle_1"
                                placeholder={Notification_title}
                                className={`G-mb-2 P-input ${touched.notifTitle_1 && errors.notifTitle_1 ? "G-error" : ""}`}
                                type="text"
                                id={this.props.id} />
                            <Field
                                component="textarea"
                                name="notifMessage_1"
                                placeholder={Notification_message}
                                className={`G-textarea G-mb-3 P-input ${touched.notifMessage_1 && errors.notifMessage_1 ? "G-error" : ""}`}
                                cols={1}
                                rows={1}
                                id={this.props.id} 
                            />
                        </div> : null}
                        {description === 2 ?
                            <div className="P-ru">
                                <Field
                                    name="notifTitle_2"
                                    placeholder={Notification_title}
                                    className={`G-mb-2 P-input ${touched.notifTitle_2 && errors.notifTitle_2 ? "G-error" : ""}`}
                                    type="text"
                                    id={this.props.id}  />
                                <Field
                                    component="textarea"
                                    name="notifMessage_2"
                                    placeholder={Notification_message}
                                    className={`G-textarea G-mb-3 P-input ${touched.notifMessage_2 && errors.notifMessage_2 ? "G-error" : ""}`}
                                    cols={1}
                                    rows={1}
                                    id={this.props.id} 
                                />
                            </div> : null}
                        {description === 3 ?
                            <div className="P-en">
                                <Field
                                    name="notifTitle_3"
                                    placeholder={Notification_title}
                                    className={`G-mb-2 P-input ${touched.notifTitle_3 && errors.notifTitle_3 ? "G-error" : ""}`}
                                    type="text" 
                                    id={this.props.id} />
                                <Field
                                    component="textarea"
                                    name="notifMessage_3"
                                    placeholder={Notification_message}
                                    className={`G-textarea G-mb-3 P-input ${touched.notifMessage_3 && errors.notifMessage_3 ? "G-error" : ""}`}
                                    cols={1}
                                    rows={1}
                                    id={this.props.id} 
                                />
                            </div> : null}
                        
                        <div className="G-flex G-justify-between">
                            <button className="G-mr-2 P-cancel" onClick={this.props.closeModal}>{Cancel}</button>
                            <button className="G-button P-button" type="submit"
                            onClick={() => this.onSubmit(errors)}>
                                {isSubmitting ? <Loader /> : `${Send_notification}`}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>);
    }
}

export default CreatePopup;