import * as React from 'react';
import HelperComponent from 'src/platform/classes/helper-component';
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router';
import SellersOrdersHistory from './SellersOrdersHistory';
import DoubleDropDown from 'src/platform/components/double-drop-down/DoubleDropDown';
import MultiSelect from 'src/platform/components/multi-select/MultiSelect';
import { AccountTypeEnum, OrderType, } from 'src/platform/enums/enums';
import { OrderSellerHistoryStatusOptions } from 'src/platform/constants/casual';
import { OrderAgentHistoryStatusOptions } from 'src/platform/constants/casual';
import OrderController from 'src/platform/api/order/order';
import { ISellersOrdersListResponseModel } from 'src/platform/api/order/order-interface';
import { IDoubleDropDownOption } from 'src/platform/interfaces';
import { NavLink } from 'react-router-dom';
import SelfDeliveryHistory from './SelfDeliveryHistory';
import DatePicker from "react-datepicker";
import DeliveryHistory from './DeliveryHistory';
import Modal from 'react-modal';
import HistoryOrderDetailRightPart from './details/HistoryOrderDetailRightPart';
import Helper from 'src/platform/services/helper';
import { store } from 'react-notifications-component';
import './index.scss';
import Languages from 'src/platform/services/languages';

interface IState {
  statusList: Array<number>,
  sellersOrdersHistoryList: Array<ISellersOrdersListResponseModel>,
  deliveryHistoryList: Array<any>,
  selfDeliveryHistoryList: Array<any>,
  requestIsOff: boolean,
  page: number,
  size: number,
  totalPages: number,
  totalElements: number,
  accountTypeEnumValue: AccountTypeEnum,
  loaded: boolean;
  isFirstInit: boolean,
  activeId: number;
  addressName: string,
  nameBuyer: string,
  nameSellerOrOrderNumber: string,
  searchCriteriaWeight: IDoubleDropDownOption,
  searchCriteriaPrice: IDoubleDropDownOption,
  pageForPagination?: number,
  createdDate: Date,
  modal: boolean,
  orderType: number,
}

const {
  Sellers_,
  Reset,
  Amount,
  Search_name_or,
  Search_agent,
  Address,
  With_weight,
  Creation_date_,
  Self_delivery,
  Delivery,
  Status,
  History_orders,
} = Languages.Translations

class HistoryContainer extends HelperComponent<RouteComponentProps, IState> {

  public state: IState = {

    sellersOrdersHistoryList: [],
    deliveryHistoryList: [],
    selfDeliveryHistoryList: [],
    page: 0,
    size: 8,
    requestIsOff: false,
    totalPages: null,
    totalElements: null,

    statusList: [],
    accountTypeEnumValue: null,
    loaded: false,
    activeId: null,
    isFirstInit: true,
    addressName: "",
    nameBuyer: "",
    createdDate: null,
    nameSellerOrOrderNumber: null,
    searchCriteriaWeight: {
      operationEnumValue: null,
      value: null,
    },
    searchCriteriaPrice: {
      operationEnumValue: null,
      value: null,
    },
    modal: false,
    orderType: null,
  }

   async componentDidMount() {
    await this.asyncSetState({
      orderType: window.location.pathname.split('/').pop() === "self-delivery"
      ? OrderType.SelfDelivery : window.location.pathname.split('/').pop() === "sellers"
        ? OrderType.Sellers : OrderType.Delivery
    });
    this.getHistoryList();
  }
  public inputTimer: any;

  private getHistoryList = async (pageNum?: { selected: number }) => {
    this.setState({ loaded: false });
    const { page,
      size,
      addressName,
      nameSellerOrOrderNumber,
      accountTypeEnumValue,
      nameBuyer,
      searchCriteriaWeight,
      searchCriteriaPrice,
      statusList,
      createdDate,
    } = this.state;

    var body: {
      operationEnumValue: number,
      value: number,
    };

    var bodyPrice: {
      operationEnumValue: number,
      value: number,
    };

    if (searchCriteriaWeight.value) {
      body = {
        operationEnumValue: Number(searchCriteriaWeight.operationEnumValue),
        value: Number(searchCriteriaWeight.value),
      }
    } else body = null;

    if (searchCriteriaPrice.value) {
      bodyPrice = {
        operationEnumValue: Number(searchCriteriaPrice.operationEnumValue),
        value: Number(searchCriteriaPrice.value),
      }
    } else bodyPrice = null;


    if (window.location.pathname.split('/').pop() === "sellers") {
      this.setState({ loaded: false });

      clearTimeout(this.inputTimer);
      this.inputTimer = setTimeout(async () => {
        window.dispatchEvent(new CustomEvent('loader', { detail: true }));

        const result = await OrderController.getSellersOrdersHistoryList({
          page: pageNum ? pageNum.selected : page,
          size,
          body: {
            addressName,
            nameBuyer,
            nameSellerOrOrderNumber,
            accountTypeEnumValue,
            searchCriteriaWeight: body,
            searchCriteriaPrice: bodyPrice,
            statusList,
            createdDate,
          }
        });

        window.dispatchEvent(new CustomEvent('loader', { detail: false }));
        if (result.success) {
          this.asyncSetState({
            sellersOrdersHistoryList: result.data.content,
            totalPages: result.data.totalPages,
            totalElements: result.data.totalElements,
            loaded: true,
            isFirstInit: this.state.isFirstInit && !result.data.content.length
          });
        } else {
          store.addNotification(Helper.notificationConfig(result.message, false));
        }
      }, 300); 

    } else if (window.location.pathname.split('/').pop() === "self-delivery") {
    this.setState({ loaded: false });

      clearTimeout(this.inputTimer);
      this.inputTimer = setTimeout(async () => {
        window.dispatchEvent(new CustomEvent('loader', { detail: true }));

        const result = await OrderController.getSelfDeliveryHistoryList({

          page: pageNum ? pageNum.selected : page,
          size,
          body: {
            addressName,
            nameSellerOrOrderNumber,
            searchCriteriaPrice: bodyPrice,
            searchCriteriaWeight: body,
            statusList,
            createdDate,
          }
        });

        window.dispatchEvent(new CustomEvent('loader', { detail: false }));
        if (result.success) {
          this.asyncSetState({
            selfDeliveryHistoryList: result.data.content,
            totalPages: result.data.totalPages,
            totalElements: result.data.totalElements,
            loaded: true,
            isFirstInit: this.state.isFirstInit && !result.data.content.length
          });
        } else {
          store.addNotification(Helper.notificationConfig(result.message, false));
        }

      }, 300);

    } else if (window.location.pathname.split('/').pop() === "delivery") {
      this.setState({ loaded: false });

      clearTimeout(this.inputTimer);
      this.inputTimer = setTimeout(async () => {
        window.dispatchEvent(new CustomEvent('loader', { detail: true }));
        const result = await OrderController.getDeliveryHistoryList({
          page: pageNum ? pageNum.selected : page,
          size,
          body: {
            addressName,
            nameSellerOrOrderNumber,
            searchCriteriaWeight: body,
            searchCriteriaPrice: bodyPrice,
            statusList,
            createdDate,
          }
        });

        window.dispatchEvent(new CustomEvent('loader', { detail: false }));
        if (result.success) {
          this.asyncSetState({
            deliveryHistoryList: result.data.content,
            totalPages: result.data.totalPages,
            totalElements: result.data.totalElements,
            loaded: true,
            isFirstInit: this.state.isFirstInit && !result.data.content.length
          });
        } else {
          store.addNotification(Helper.notificationConfig(result.message, false));
        }

      }, 300);
    }
  };

  public setOperatorValue = (key: string, value: any) => {
    this.state[key].operationEnumValue = value;
    this.asyncSetState({ [key]: this.state[key] }).then(() => {
      this.getHistoryList();
    });
  }

  public setDoubleDropdownInputValue = async (key: string, value: any) => {
    this.state[key].value = value;

    this.asyncSetState({ key: this.state[key] })
      .then(() => {
        this.getHistoryList();
      });
  }

  public reset = () => {
    this.asyncSetState({
      accountTypeEnumValue: null,
      nameSellerOrOrderNumber: "",
      statusList: [],
      addressName: "",
      nameBuyer: "",
      createdDate: null,
      totalPages: 0,
      pageForPagination: 0,
      pageNum: 0,
      searchCriteriaWeight: {
        operationEnumValue: null,
        value: "",
      },
      searchCriteriaPrice: {
        operationEnumValue: "",
        value: "",
      },
    }).then(() => {
      this.getHistoryList();
    });
  }

  public onInput = async (name: string, evt?: React.SyntheticEvent<HTMLInputElement>) => {

    if (name === "addressName") {
      await this.asyncSetState({ addressName: evt.currentTarget.value })
    } else if (name === "nameSellerOrOrderNumber") {
      await this.asyncSetState({ nameSellerOrOrderNumber: evt.currentTarget.value })
    } else if (name === "nameBuyer") {
      await this.asyncSetState({ nameBuyer: evt.currentTarget.value })
    }

    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(async () => {
      this.getHistoryList();
    }, 300);
  }

  public onMultiSelect = (arr: Array<string>) => {
    this.asyncSetState({ statusList: arr }).then(() => {
      this.getHistoryList();
    });;
  }

  public onSelect = (field: string, value: number) => {
    this.asyncSetState({ accountTypeEnumValue: value }).then(() => {
      this.getHistoryList();
    });;
  }

  public openModal = (index: number) => {
    this.asyncSetState({ modal: true, activeId: index });
  }

  public closeModal = () => {
    this.asyncSetState({ modal: false });
  }

  download = async () => {
    const { addressName,
      nameSellerOrOrderNumber,
      searchCriteriaWeight,
      searchCriteriaPrice,
      statusList,
      createdDate,
    } = this.state;
    
    var bodyWeight: {
      operationEnumValue: number,
      value: number,
    };

    var bodyPrice: {
      operationEnumValue: number,
      value: number,
    };

    if (searchCriteriaWeight.value) {
      bodyWeight = {
        operationEnumValue: Number(searchCriteriaWeight.operationEnumValue),
        value: Number(searchCriteriaWeight.value),
      }
    } else bodyWeight = null;

    if (searchCriteriaPrice.value) {
      bodyPrice = {
        operationEnumValue: Number(searchCriteriaPrice.operationEnumValue),
        value: Number(searchCriteriaPrice.value),
      }
    } else bodyPrice = null;
    const body = {
      addressName,
      nameSellerOrOrderNumber,
      statusList,
      createdDate,
      searchCriteriaWeight: bodyWeight,
      searchCriteriaPrice: bodyPrice,
    }
    if (this.state.orderType === OrderType.Sellers) {
      const result = await OrderController.downloadSellerOrderHistory(body);
      if (result.data) {
        window.open(result.data, '_blank');
      }
    } else if (this.state.orderType === OrderType.SelfDelivery) {
      const result = await OrderController.downloadAgentOrderSelfDeliveryHistory(body);
      if (result.data) {
        window.open(result.data, '_blank');
      }
    } else {
      const result = await OrderController.downloadAgentOrderDeliveryHistory(body);
      if (result.data) {
        window.open(result.data, '_blank');
      }
    }
  }
  public render() {
    const {
      statusList,
      totalPages,
      totalElements,
      sellersOrdersHistoryList,
      nameSellerOrOrderNumber,
      addressName,
      nameBuyer,
      loaded,
      isFirstInit,
      searchCriteriaWeight,
      searchCriteriaPrice,
      selfDeliveryHistoryList,
      deliveryHistoryList,
      createdDate,
      modal,
      orderType,
      activeId,
    } = this.state;

    return (
      <section className="P-agents-orders-page">
        <div className="G-flex G-mb-2 G-align-center">
          <h4 className="G-mr-4">{History_orders} ({totalElements})</h4>
          <NavLink
            to={"/orders/history/sellers"}
            className="G-mr-2 P-sub-title G-cursor"
            exact={true} activeClassName="P-sub-title-active"
            onClick={() => {
              this.asyncSetState({ orderType: OrderType.Sellers, isFirstInit: true });
              setTimeout(() => {
                this.reset();
              }, 0);
            }}
          >
            {Sellers_}
          </NavLink>
          <NavLink
            to={"/orders/history/self-delivery"}
            className="G-mr-2 P-sub-title G-cursor"
            exact={true} activeClassName="P-sub-title-active"
            onClick={() => {
              this.asyncSetState({  orderType: OrderType.SelfDelivery, isFirstInit: true });
              setTimeout(() => {
                this.reset();
              }, 0);
            }}
          >
            {Self_delivery}
          </NavLink>
          <NavLink
            to={"/orders/history/delivery"}
            className={`G-mr-2 P-sub-title G-cursor`}
            exact={true}
            onClick={() => {
              this.asyncSetState({  orderType: OrderType.Delivery, isFirstInit: true });
              setTimeout(() => {
                this.reset();
              }, 0);
            }}
            activeClassName="P-sub-title-active"
          > {Delivery}
          </NavLink>
          <button className="G-gray-btn G-ml-auto" onClick={this.download}>Export</button>
        </div>
        <div className="G-flex G-flex-wrap P-selects-wrapper">
          <input className="G-text-input G-mr-1"
            placeholder={Search_name_or}
            type="text"
            value={nameSellerOrOrderNumber}
            onChange={(event) => this.onInput("nameSellerOrOrderNumber", event)}
          />
          <div className="G-mr-1">
            <DoubleDropDown placeholder={With_weight}
              property="searchCriteriaWeight"
              operatorValue={searchCriteriaWeight.operationEnumValue}
              inputValue={searchCriteriaWeight.value}
              setOperatorValue={this.setOperatorValue}
              setDoubleDropdownInputValue={this.setDoubleDropdownInputValue}
            />
          </div>
          <div className="G-mr-1">
            <DoubleDropDown placeholder={Amount}
              property="searchCriteriaPrice"
              operatorValue={searchCriteriaPrice.operationEnumValue}
              inputValue={searchCriteriaPrice.value}
              setOperatorValue={this.setOperatorValue}
              setDoubleDropdownInputValue={this.setDoubleDropdownInputValue}
            />
          </div>
          {window.location.pathname.split('/').pop() !== "self-delivery" &&
            <input className="G-text-input G-mr-1"
              placeholder={Address}
              type="text"
              value={addressName}
              onChange={(event) => this.onInput("addressName", event)}
            />
          }
          {window.location.pathname.split('/').pop() === "sellers" &&
            <input className="G-text-input G-mr-1"
              placeholder={Search_agent}
              type="text"
              value={nameBuyer}
              onChange={(event) => this.onInput("nameBuyer", event)}
            />}
          <div className="P-date-picker">
            <label>
              <DatePicker
                className="G-text-input G-mr-1 G-cursor"
                selected={createdDate}
                placeholderText={Creation_date_}
                onChange={(date: Date) => {
                  if (date) {
                    this.asyncSetState({ createdDate: date.getTime() }).then(() => {
                      this.getHistoryList();
                    })
                  }
                }}
              />
              <i className="icon-ic_arrowdown P-icon-ic_arrowdown"></i>
            </label>
          </div>
          {window.location.pathname.split('/').pop() === "sellers" ?
            <div className="G-mr-1">
              <MultiSelect
                name={Status}
                height="45px"
                width="220px"
                setFieldValue={this.onMultiSelect}
                value={statusList}
                options={OrderSellerHistoryStatusOptions} />
            </div> : <div className="G-mr-1">
              <MultiSelect
                name={Status}
                height="45px"
                width="220px"
                setFieldValue={this.onMultiSelect}
                value={statusList}
                options={OrderAgentHistoryStatusOptions} />
            </div>}

          <span className=" G-button P-button" onClick={this.reset}>{Reset}</span>
        </div>

        <Switch>
          <Route path="/orders/history/sellers"
            render={(props) => <SellersOrdersHistory
              {...props}
              sellersOrdersHistoryList={sellersOrdersHistoryList}
              getSellersHistoryList={this.getHistoryList}
              loaded={loaded}
              pageForPagination={this.state.pageForPagination}
              openModal={this.openModal}
              isFirstInit={isFirstInit}
              totalPages={totalPages} />}
            exact={true}
          />
          <Route path="/orders/history/self-delivery"
            render={(props) => <SelfDeliveryHistory
              {...props}
              selfDeliveryHistoryList={selfDeliveryHistoryList}
              getSelfDeliveryHistoryList={this.getHistoryList}
              loaded={loaded}
              pageForPagination={this.state.pageForPagination}
              openModal={this.openModal}
              isFirstInit={isFirstInit}
              totalPages={totalPages} />}
            exact={true}
          />
          <Route path="/orders/history/delivery"
            render={(props) => <DeliveryHistory
              {...props}
              deliveryHistoryList={deliveryHistoryList}
              getDeliveryHistoryList={this.getHistoryList}
              loaded={loaded}
              pageForPagination={this.state.pageForPagination}
              openModal={this.openModal}
              isFirstInit={isFirstInit}
              totalPages={totalPages} />}
            exact={true}
          />
          <Redirect to="/orders/history/sellers" />
        </Switch>
        <Modal
          isOpen={modal}
          onRequestClose={this.closeModal}
          style={{
            content: {
              padding: "0px",
              height: "90vh",
              width: "100%",
              maxWidth: "700px",
              display: "flex",
              flexDirection: "column",
            },
          }}>
          <HistoryOrderDetailRightPart activeId={activeId} orderType={orderType} closeModal={this.closeModal} />
        </Modal>
      </section >
    );
  }
}

export default HistoryContainer;