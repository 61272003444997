import React from 'react';
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom';
import RouteService from './platform/services/routes';
import Helper from './platform/services/helper';
import Sidebar from './platform/components/sidebar/sidebar';
import Header from './platform/components/header/header';
import Modal from 'react-modal';
import ScrollIntoView from './platform/hocs/ScrollIntoView';
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/scss/_containers.scss'
import 'react-notifications-component/dist/scss/_types.scss'
import 'react-notifications-component/dist/scss/_variables.scss'
import 'react-notifications-component/dist/scss/notification.scss'
import "animate.css"
import "react-datepicker/dist/react-datepicker.css";
import './pages';
import './app.scss';
import * as firebase from 'firebase';
Modal.setAppElement(document.getElementById('root'));

Modal.defaultStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,.5)",
    zIndex: 3,
  },
  content: {
    position: "absolute",
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: "1px solid #ccc",
    background: "#fff",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    borderRadius: "4px",
    outline: "none",
    padding: "20px"
  }
};

class App extends React.Component {

  private firebaseConfig = {
		apiKey: "AIzaSyDooE7yVvhRJTN_IVe99ENrNgIan3Vty1Y",
		authDomain: "tapon-231c6.firebaseapp.com",
		databaseURL: "https://tapon-231c6.firebaseio.com",
		projectId: "tapon-231c6",
		storageBucket: "tapon-231c6.appspot.com",
		messagingSenderId: "125936930613",
		appId: "1:125936930613:web:d5514acdccc235f01b867c",
		measurementId: "G-NRBT2KDJ94",
  };

  state = {
    sidebarIsOpen: false
  }
  
	componentDidMount() {
		firebase.initializeApp(this.firebaseConfig);
  }
  
  changeSidebar = (value: boolean) => {
    this.setState({ sidebarIsOpen: value });
  }

  public render() {
    return (
      <Router>
        <div className={`P-content ${this.state.sidebarIsOpen ? 'P-small' : ''} ${window.innerWidth < 1300 ? 'P-sidebar-mobile' : ''}`}>
          {!!Helper.token && <Header />}
          {!!Helper.token && <Sidebar changeIsOpen={this.changeSidebar} />}
          <ScrollIntoView>
            <Switch>
              {!!Helper.token ? RouteService.subscribeAuthorized(routes => routes.map(item => <Route
                key={item.path}
                path={item.path}
                component={item.component}
              />))  
              : 
             RouteService.subscribeUnauthorized(routes => 
                routes.map(item => <Route
                  key={item.path}
                  path={item.path}
                  component={item.component}
              />)
              )}
              <Redirect to={RouteService.defaultRoute} />
            </Switch>
          </ScrollIntoView>
        </div>
        <ReactNotification />
      </Router>
    );
  }
}

export default App;