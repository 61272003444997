import * as React from 'react';
import './index.scss';
import Languages from 'src/platform/services/languages';
import Table, { ITableColumnConfig } from 'src/platform/components/table/table';
import HelperComponent from 'src/platform/classes/helper-component';
import KeyController from 'src/platform/api/key/key';
import { ICreateKey, IGetKeyListRequest, IGetKeyListResponse } from 'src/platform/api/key/key-interface';
import CreateKeyPopup from '../create-key/create-key';
import Modal from 'react-modal';
import Paging from 'src/platform/components/pagination/pagination';

export interface IProps {
  activeLanguage: string;
  activeLanguageName: string;
  back: () => void,
}
interface IState {
  list: IGetKeyListResponse[];
  isFirstInit: boolean;
  loaded: boolean,
  modalIsOpenIndex: number,
  page: number,
  size: number,
  selected: number,
  newValue: string,
  totalPages: number,
  activePage: number,
};

const {
  Language_,
  Resources_,
  Create,
  Edit,
  Segment,
  SEO_code,
} = Languages.Translations;

class ContentDetails extends HelperComponent<IProps, IState> {

  componentDidMount() {
    this.getList();
  }

  public state: IState = {
    list: [],
    modalIsOpenIndex: null,
    isFirstInit: false,
    loaded: false,
    page: 0,
    size: 10,
    selected: null,
    newValue: '',
    totalPages: 1,
    activePage: null,
  }
  public config: Array<ITableColumnConfig<IGetKeyListResponse>> = [
    {
      name: `Key`,
      cell: (row: IGetKeyListResponse, index: number) => {
        return (<div className="">
          {row.keyName}
        </div>);
      },
      style: { marginRight: "2%" },
    },
    {
      name: `Value`,
      cell: (row: IGetKeyListResponse, index: number) => {
        return (<div className="G-cursor">
          {this.state.selected !== index && row.value ? <p onClick={() => this.openInput(row, index)}>{row.value}</p> : !row.value && this.state.selected !== index ? 
          <p className="G-green" onClick={() => this.openInput(row, index)}>{Edit}</p> :
          <div className="G-flex">
            <input className="G-text-input G-mr-1"
            placeholder={row.value}
            type="text"
            value={this.state.newValue}
            onChange={this.onInput}
          />
            <button className="G-button G-prime-btn" onClick={() => this.edit(row)}>{Edit}</button>
          </div>
          }
        </div>);
      },
      style: { marginRight: "2%" },
    },
  ]

  public onInput = async (evt: React.SyntheticEvent<HTMLInputElement>) => {
    await this.asyncSetState({ newValue: evt.currentTarget.value })
  }
  
  public openInput = (row: IGetKeyListResponse, index: number) => {
    this.asyncSetState({ 
      selected: index,
      newValue: row.value,
     });
    row.isOpen = !row.isOpen;
  }

  public openModal = (index: number) => {
    this.asyncSetState({ modalIsOpenIndex: index });
  }

  public closeModal = () => {
    this.asyncSetState({ modalIsOpenIndex: null });
  }
  
  public edit = async (row: IGetKeyListResponse) => {
    const body: ICreateKey = {
      languageName: this.props.activeLanguage,
      keyName: row.keyName,
      value: this.state.newValue || row.value,
    }
    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const res = await KeyController.updateKey(body, row.keyName);
    if (res.success) {
      this.setState({
        selected: null
      })
      const selected: number = this.state.activePage;
      this.getList({selected})
    }
    // this.asyncSetState({ selected: null });
  }


  private getList = async (pageNum?: { selected: number }) => {
    this.setState({ activePage: pageNum ? pageNum.selected : null})
    const body: IGetKeyListRequest = {
      page: pageNum ? pageNum.selected : this.state.page,
      size: this.state.size,
      form: {
        text: '',
      }
    }

    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const res = await KeyController.getList(body, this.props.activeLanguage);
    if (res.success) {
      this.setState({
        loaded: true,
        isFirstInit: this.state.isFirstInit && !res.data
      })
    }
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
    if (res.success) {
      this.asyncSetState({
        selected: null,
        list: res.data.content,
        totalPages: res.data.totalPages,
      })
    }

  }
  public render() {
    const { list, isFirstInit, loaded, modalIsOpenIndex, totalPages } = this.state;

    return (
      <section className="P-managment-details">
        <div className="P-top">
          <div>
            <h6 className="G-cursor" onClick={() => this.props.back()}>{Language_}</h6>
          </div>
          <div>
            <div className="P-top-titles">
              <span>{Segment}</span>
              <span>{SEO_code}</span>
            </div>
            <div className="P-top-table">
              <p>{this.props.activeLanguageName}</p>
              <p>{this.props.activeLanguage}</p>
            </div>
          </div>
        </div>
        <div className="G-block">
          <div className="P-title">
            <h6>{Resources_}</h6>
            <button className="G-button" onClick={() => this.openModal(1)}>{Create}</button>
          </div>
          <Table
            columnConfig={this.config}
            data={list}
            isFirstInit={isFirstInit}
            loaded={loaded}
            onRowClick={(row) => {
              // window.routerHistory.push(`/customers/sellers/details?id=${row.id}`);
            }}
          />
          <Paging
            totalPages={totalPages}
            onChange={this.getList}
          />
        </div>
        <Modal
          isOpen={modalIsOpenIndex === 1}
          onRequestClose={this.closeModal}
          style={{
            content: {
              padding: "30px",
              width: "100%",
              maxWidth: "700px",
              display: "flex",
              flexDirection: "column",
            },
          }}>
          <CreateKeyPopup closeModal={this.closeModal} getList={this.getList} activeLanguage={this.props.activeLanguage}/>
        </Modal>
      </section >
    );
  }
}

export default ContentDetails;