import * as React from 'react';
import HelperComponent from 'src/platform/classes/helper-component';
import { RouteComponentProps, Switch, Route, Redirect, withRouter } from 'react-router';
import AgentsDelivery from './AgentsDelivery';
import { AccountTypeEnum, OrderType } from 'src/platform/enums/enums';
import OrderController from 'src/platform/api/order/order';
import { Form, Formik, Field, FormikValues } from 'formik';
import AgentsSelfDelivery from './AgentsSelfDelivery';
import DoubleDropDown from 'src/platform/components/double-drop-down/DoubleDropDown';
import MultiSelect from 'src/platform/components/multi-select/MultiSelect';
import { NavLink } from 'react-router-dom';
import { OrderAgentSearchStatusOptions } from 'src/platform/constants/casual';
import DatePicker from "react-datepicker";
import { IDoubleDropDownOption } from 'src/platform/interfaces';
import OrdersMap from './orders-map/OrdersMap';
import AgentOrdersDetails from './details/AgentsOrdersDetails';
import Helper from 'src/platform/services/helper';
import { store } from 'react-notifications-component';
import * as Yup from 'yup';
import Modal from 'react-modal';
import Loader from 'src/platform/components/loader/loader';
import './index.scss';
import { ICancelOrderModelBody } from 'src/platform/api/order/order-interface';
import Languages from 'src/platform/services/languages';

interface IState {
  selfDeliveryList: Array<any>,
  deliveryList: Array<any>,
  agentsOrdersMapList: Array<any>,
  requestIsOff: boolean,
  page: number,
  size: number,
  pageForPagination: number,
  totalPages: number,
  totalElements: number,
  loaded: boolean;
  isFirstInit: boolean,
  modalIsOpenIndex: number,
  activeOrder: number,

  viewType: number,
  orderType: number,
  addressName: string,
  nameBuyer: string,
  nameSellerOrOrderNumber: string,
  statusList: Array<number>,
  accountTypeEnumValue: AccountTypeEnum,
  searchCriteriaWeight: IDoubleDropDownOption,
  searchCriteriaPrice: IDoubleDropDownOption,
  createdDate: Date,
  orderId: number,
  reason: string
}

const {
  Agents_orders,
  Reset,
  Search_name_or,
  With_weight,
  Details_,
  Amount,
  Сancellation_reason,
  Creation_date_,
  Finish_request,
  Close,
  Status,
  Rejection_reason,
  Delivery,
  Self_delivery,
  Address,
  Cancel,
  Reject,
  Finish,
  kg,
  Set_actual_cardboard,
} = Languages.Translations

class AgentsContainer extends HelperComponent<RouteComponentProps, IState> {


  public state: IState = {
    selfDeliveryList: [],
    modalIsOpenIndex: null,
    deliveryList: [],
    agentsOrdersMapList: [],
    page: 0,
    size: 8,
    requestIsOff: false,
    totalPages: null,
    totalElements: null,
    loaded: false,
    viewType: 1,
    orderType: (window.location.pathname.split('/').pop() === "delivery-details" ||
      window.location.pathname.split('/').pop() === "delivery") ?
      OrderType.Delivery : OrderType.SelfDelivery,
    isFirstInit: true,
    pageForPagination: 0,
    activeOrder: null,

    accountTypeEnumValue: null,
    nameSellerOrOrderNumber: "",
    statusList: [],
    addressName: "",
    nameBuyer: "",
    searchCriteriaWeight: {
      operationEnumValue: "",
      value: "",
    },
    searchCriteriaPrice: {
      operationEnumValue: "",
      value: "",
    },
    createdDate: null,
    orderId: null,
    reason: "",
  }

  componentDidMount() {
    const last = window.location.pathname.split('/').pop();
    if (last === "map") {
      this.asyncSetState({ viewType: 2, orderType: OrderType.Delivery }).then(() => {
        this.getList();
      })
    } else {
      this.getList();
    }
  }

  componentDidUpdate() {
    const orderType = (window.location.pathname.split('/').pop() === "delivery-details" ||
    window.location.pathname.split('/').pop() === "delivery") ?
    OrderType.Delivery : OrderType.SelfDelivery;

    if (orderType !== this.state.orderType) {
      this.setState({ orderType })
    }
  }

  private acceptOrder = async (id: string) => {

    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const result = await OrderController.acceptOrder(id);
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
    if (result.success) {
      store.addNotification(Helper.notificationConfig(result.message, true));
      this.asyncSetState({ page: 0 }).then(() => {
        this.getList()
      });
    } else {
      store.addNotification(Helper.notificationConfig(result.message, false));
    }
  }

  private finishOrder = async (id: string, finalWeight: string) => {

    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const result = await OrderController.clientFinishOrder(id, finalWeight);
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
    if (result.success) {
      store.addNotification(Helper.notificationConfig(result.message, true));
      this.asyncSetState({ page: 0 }).then(() => {
        this.getList()
      });
      this.closeModal()
      if (window.location.pathname.split('/').pop() === "self-delivery" || window.location.pathname.split('/').pop() === "self-delivery-details") {
        this.props.history.push(`/orders/agents/self-delivery`)
      } else this.props.history.push(`/orders/agents/delivery`)
    } else {
      store.addNotification(Helper.notificationConfig(result.message, false));
      this.closeModal()
    }
  }

  private rejectOrder = async (id: number, rejectReason: string) => {

    const body: ICancelOrderModelBody = {
      orderId: id,
      reason: rejectReason
    }

    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const result = await OrderController.rejectOrder(body);
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
    if (result.success) {
      store.addNotification(Helper.notificationConfig(result.message, true));
      this.asyncSetState({ page: 0 }).then(() => {
        this.getList()
      });
      this.closeModal();
    } else {
      store.addNotification(Helper.notificationConfig(result.message, false));
      this.closeModal();
    }
  }


  private cancelOrder = async (id: number, cancelReason: string) => {

    const body: ICancelOrderModelBody = {
      orderId: id,
      reason: cancelReason
    }

    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const result = await OrderController.cancelOrder(body);
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
    if (result.success) {
      store.addNotification(Helper.notificationConfig(result.message, true));
      this.asyncSetState({ page: 0 }).then(() => {
        this.getList()
      });
      this.closeModal();
    } else {
      store.addNotification(Helper.notificationConfig(result.message, false));
      this.closeModal();
    }
  }

  public inputTimer: any;
  private getList = async (pageNum?: { selected: number }) => {

    const { page,
      size,
      statusList,
      nameSellerOrOrderNumber,
      viewType,
      searchCriteriaWeight,
      searchCriteriaPrice,
      addressName,
      orderType,
      createdDate } = this.state;

    let criteriaBody: {
      operationEnumValue: number,
      value: number,
    };

    let criteriaPriceBody: {
      operationEnumValue: number,
      value: number,
    };

    if (searchCriteriaWeight.value) {
      criteriaBody = {
        operationEnumValue: Number(searchCriteriaWeight.operationEnumValue),
        value: Number(searchCriteriaWeight.value),
      }
    } else criteriaBody = null;

    if (searchCriteriaPrice.value) {
      criteriaPriceBody = {
        operationEnumValue: Number(searchCriteriaPrice.operationEnumValue),
        value: Number(searchCriteriaPrice.value),
      }
    } else criteriaPriceBody = null;

    if (orderType === OrderType.SelfDelivery) {

      clearTimeout(this.inputTimer);
      this.inputTimer = setTimeout(async () => {
        window.dispatchEvent(new CustomEvent('loader', { detail: true }));
        const result = await OrderController.getAgentsOrdersSelfDeliveryList({
          page: pageNum ?
            pageNum.selected : page,
          size,
          body: {
            addressName,
            nameSellerOrOrderNumber,
            searchCriteriaWeight: criteriaBody,
            searchCriteriaPrice: criteriaPriceBody,
            statusList,
            createdDate
          }
        });
        window.dispatchEvent(new CustomEvent('loader', { detail: false }));
        if (result.success) {
          this.asyncSetState({
            selfDeliveryList: result.data.content,
            totalPages: result.data.totalPages,
            totalElements: result.data.totalElements,
            pageForPagination: pageNum ? pageNum.selected : page,
            loaded: true,
            isFirstInit: this.state.isFirstInit && !result.data.content.length
          });
        } else {
          store.addNotification(Helper.notificationConfig(result.message, false));
        }
      }, 300);
    }
    else {
      
      clearTimeout(this.inputTimer);
      this.inputTimer = setTimeout(async () => {
        window.dispatchEvent(new CustomEvent('loader', { detail: true }));
        let size: number = 8;
        if (viewType === 2) {
          size = 1000;
        }
        const result = await OrderController.getAgentsOrdersDeliveryList({
          page: pageNum ?
            pageNum.selected : page,
          size,
          body: {
            addressName,
            nameSellerOrOrderNumber,
            statusList,
            createdDate,
            searchCriteriaWeight: criteriaBody,
            searchCriteriaPrice: criteriaPriceBody,
          },
        });

        window.dispatchEvent(new CustomEvent('loader', { detail: false }));
        if (result.success) {
          this.asyncSetState({
            deliveryList: result.data.content,
            totalPages: result.data.totalPages,
            totalElements: result.data.totalElements,
            pageForPagination: pageNum ? pageNum.selected : page,
            loaded: true,
            isFirstInit: this.state.isFirstInit && !result.data.content.length
          })
        } else {
          store.addNotification(Helper.notificationConfig(result.message, false));
        }
      }, 300);
    }
  };

  loadMore = async (orderType: number) => {
    if (orderType === OrderType.SelfDelivery) {
      const { requestIsOff, selfDeliveryList, page, size, totalPages } = this.state;

      if (!requestIsOff && page < totalPages) {
        this.asyncSetState({ requestIsOff: true })
        const res = await OrderController.getAgentsOrdersSelfDeliveryList({ page: this.state.page + 1, size });
        if (res && res.success) {
          this.asyncSetState({
            selfDeliveryList: [...selfDeliveryList, ...res.data.content],
            page: this.state.page + 1,
            totalPages: res.data.totalPages,
          });
          this.asyncSetState({ requestIsOff: false });
        } else {
          store.addNotification(Helper.notificationConfig(res.message, false));
        }
      }

    } else {
      const { requestIsOff, deliveryList, page, totalPages, viewType } = this.state;

      if (!requestIsOff && page < totalPages) {
        this.asyncSetState({ requestIsOff: true });

        let size: number = 8;
        if (viewType === 2) {
          size = 50;
        }
        const res = await OrderController.getAgentsOrdersDeliveryList({ page: this.state.page + 1, size });
        if (res && res.success) {
          this.asyncSetState({
            deliveryList: [...deliveryList, ...res.data.content],
            page: this.state.page + 1,
            totalPages: res.data.totalPages,
          });
          this.asyncSetState({ requestIsOff: false });
        } else {
          store.addNotification(Helper.notificationConfig(res.message, false));
        }
      }
    }
  }


  public reset = () => {
    this.asyncSetState({
      page: 0,
      accountTypeEnumValue: null,
      nameSellerOrOrderNumber: "",
      statusList: [],
      addressName: "",
      nameBuyer: "",
      totalPages: 0,
      pageForPagination: 0,
      pageNum: 0,
      searchCriteriaWeight: {
        operationEnumValue: "",
        value: "",
      },
      searchCriteriaPrice: {
        operationEnumValue: "",
        value: "",
      },
      createdDate: null,
    }).then(() => {
      this.getList();
    });
  }



  public onInput = async (name: string, evt?: React.SyntheticEvent<HTMLInputElement>) => {

    if (name === "addressName") {
      await this.asyncSetState({ addressName: evt.currentTarget.value })
    } else if (name === "nameSellerOrOrderNumber") {
      await this.asyncSetState({ nameSellerOrOrderNumber: evt.currentTarget.value })
    }
    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(async () => {
      this.getList();
    }, 300);
  }

  public onMultiSelect = (arr: Array<string>) => {
    this.asyncSetState({ statusList: arr }).then(() => {
      this.getList();
    });;
  }

  public onSelect = (field: string, value: number) => {
    this.asyncSetState({ accountTypeEnumValue: value }).then(() => {
      this.getList();
    });;
  }

  public setOperatorValue = (key: string, value: any) => {
    this.state[key].operationEnumValue = value;
    this.asyncSetState({ [key]: this.state[key] }).then(() => {
      this.getList();
    });
  }

  
  public setOperatorPriceValue = (key: string, value: any) => {
    this.state[key].operationEnumValue = value;
    this.asyncSetState({ [key]: this.state[key] }).then(() => {
      this.getList();
    });
  }


  public setDoubleDropdownInputValue = async (key: string, value: any) => {
    this.state[key].value = value;

    this.asyncSetState({ key: this.state[key] })
      .then(() => {
        this.getList();
      });
  }

  public setDoubleDropdownInputPriceValue = async (key: string, value: any) => {
    this.state[key].value = value;

    this.asyncSetState({ key: this.state[key] })
      .then(() => {
        this.getList();
      });
  }

  public openModal = (index: number, orderId: number) => {
    this.asyncSetState({ modalIsOpenIndex: index, activeOrder: orderId });
  }

  public closeModal = () => {
    this.asyncSetState({ modalIsOpenIndex: null });
  }

  download = async () => {
    const { addressName,
      nameSellerOrOrderNumber,
      searchCriteriaWeight,
      searchCriteriaPrice,
      statusList,
      createdDate,
    } = this.state;
    
    let criteriaBody: {
      operationEnumValue: number,
      value: number,
    };

    let criteriaPriceBody: {
      operationEnumValue: number,
      value: number,
    };

    if (searchCriteriaWeight.value) {
      criteriaBody = {
        operationEnumValue: Number(searchCriteriaWeight.operationEnumValue),
        value: Number(searchCriteriaWeight.value),
      }
    } else criteriaBody = null;

    if (searchCriteriaPrice.value) {
      criteriaPriceBody = {
        operationEnumValue: Number(searchCriteriaPrice.operationEnumValue),
        value: Number(searchCriteriaPrice.value),
      }
    } else criteriaPriceBody = null;
    const body = {
      addressName,
      nameSellerOrOrderNumber,
      statusList,
      createdDate,
      searchCriteriaWeight: criteriaBody,
      searchCriteriaPrice: criteriaPriceBody,
    }
    if (this.state.orderType === OrderType.SelfDelivery) {
      const result = await OrderController.downloadAgentOrderSelfDelivery(body);
      if (result.data) {
        window.open(result.data, '_blank');
      }
    } else if (this.state.orderType === OrderType.Delivery) {
      const result = await OrderController.downloadAgentOrderDelivery(body);
      if (result.data) {
        window.open(result.data, '_blank');
      }
    }
  }

  public render() {
    const {
      viewType,
      selfDeliveryList,
      statusList,
      nameSellerOrOrderNumber,
      loaded,
      isFirstInit,
      totalPages,
      addressName,
      searchCriteriaWeight,
      searchCriteriaPrice,
      createdDate,
      deliveryList,
      orderType,
      modalIsOpenIndex,
      activeOrder,
      totalElements,
    } = this.state;

    return (
      <section className="P-agents-orders-page">
        <div className="G-flex G-mb-2 G-align-center">
          <div className={"G-cursor"}
            onClick={() => {
              if (window.location.pathname.split('/').pop() !== "delivery" && window.location.pathname.split('/').pop() !== "self-delivery") {
                this.props.history.goBack();
              }
            }}
          >
            <h4 className="G-mr-1">{Agents_orders} ({totalElements})</h4>
          </div>
          <div className="G-flex G-justify-between G-align-center">
            {window.location.pathname.split('/').pop() === "details" ? <>
              <h4>/</h4>
              <h4 className="G-ml-1 P-crumb"> {Details_}</h4>
            </> : ""}
          </div>
          {window.location.pathname.split('/').pop() !== "details" &&
            <>
              <NavLink
                to={"/orders/agents/self-delivery"}
                className={`G-mr-2 G-ml-3 P-sub-title G-cursor ${orderType === OrderType.SelfDelivery ? "P-sub-title-active" : ""}`}
                exact={true} activeClassName="P-sub-title-active"
                onClick={() => {
                  this.asyncSetState({ isFirstInit: true, loaded: false, viewType: 1, orderType: OrderType.SelfDelivery, page: 0 }).then(() => {
                    this.reset();
                  });
                }}
              > {Self_delivery}
            </NavLink>
              <NavLink
                to={"/orders/agents/delivery"}
                className={`G-mr-2 P-sub-title G-cursor ${(viewType === 2 || orderType === OrderType.Delivery) ? "P-sub-title-active" : ""}`}
                exact={true}
                onClick={() => {
                  this.asyncSetState({ isFirstInit: true, loaded: false, orderType: OrderType.Delivery, page: 0 }).then(() => {
                    this.reset();
                  });
                }}
                activeClassName="P-sub-title-active"
              > {Delivery}
            </NavLink>
            </>
          }
          {(window.location.pathname.split('/').pop() === "delivery" ||
            window.location.pathname.split('/').pop() === "map") &&
            <div className='G-ml-auto'>
              <NavLink to={"/orders/agents/delivery"} exact={true} className="P-icon G-mr-2" activeClassName="P-icon-active">
                <i className={`icon-ic_list`}
                  onClick={() => this.asyncSetState({ viewType: 1, isFirstInit: true }).then(() => {
                    this.getList();
                  })} />
              </NavLink>
              <NavLink to={"/orders/agents/delivery/map"} exact={true} className="P-icon G-mr-2" activeClassName="P-icon-active">
                <i className={`icon-ic_map`}
                  onClick={() => this.asyncSetState({ viewType: 2, isFirstInit: true }).then(() => {
                    this.getList();
                  })} />
              </NavLink>
            </div>}
          <button className={orderType === 1 ? "G-gray-btn" : "G-gray-btn G-ml-auto"} onClick={this.download}>Export</button>
        </div>

        <div className="G-flex G-flex-wrap P-selects-wrapper">
          <input className="G-text-input G-mr-1"
            placeholder={Search_name_or}
            type="text"
            value={nameSellerOrOrderNumber}
            onChange={(event) => this.onInput("nameSellerOrOrderNumber", event)}
          />
          <div className="G-mr-1">
            <DoubleDropDown placeholder={With_weight}
              property="searchCriteriaWeight"
              operatorValue={searchCriteriaWeight.operationEnumValue}
              inputValue={searchCriteriaWeight.value}
              setOperatorValue={this.setOperatorValue}
              setDoubleDropdownInputValue={this.setDoubleDropdownInputValue}
            />
          </div>
          <div className="G-mr-1">
            <DoubleDropDown placeholder={Amount}
              property="searchCriteriaPrice"
              operatorValue={searchCriteriaPrice.operationEnumValue}
              inputValue={searchCriteriaPrice.value}
              setOperatorValue={this.setOperatorPriceValue}
              setDoubleDropdownInputValue={this.setDoubleDropdownInputPriceValue}
            />
          </div>
          {window.location.pathname.split('/').pop() === "delivery" ? <input className="G-text-input G-mr-1"
            placeholder={Address}
            type="text"
            value={addressName}
            onChange={(event) => this.onInput("addressName", event)}
          /> : null}
          <div className="P-date-picker">
            <label>
              <DatePicker
                className="G-text-input G-mr-1 G-cursor"
                selected={createdDate}
                placeholderText={Creation_date_}
                onChange={(date: Date) => {
                  if (date) {
                    this.asyncSetState({ createdDate: date.getTime() }).then(() => {
                      this.getList();
                    })
                  }
                }}
              />
              <i className="icon-ic_arrowdown P-icon-ic_arrowdown"></i>
            </label>
          </div>
          <div className="G-mr-1">
            <MultiSelect
              name={Status}
              height="45px"
              width="220px"
              setFieldValue={this.onMultiSelect}
              value={statusList}
              options={OrderAgentSearchStatusOptions} />
          </div>
          <span className=" G-button P-button" onClick={this.reset}>{Reset}</span>
        </div>
        <Switch>
          <Route path="/orders/agents/self-delivery"
            render={(props) =>
              <AgentsSelfDelivery
                {...props}
                getSelfDeliveryList={this.getList}
                loaded={loaded}
                pageForPagination={this.state.pageForPagination}
                isFirstInit={isFirstInit}
                totalPages={totalPages}
                selfDeliveryList={selfDeliveryList}
                acceptOrder={this.acceptOrder}
                openModal={this.openModal}
              />}
            exact={true}
          />
          <Route path="/orders/agents/delivery"
            render={(props) =>
              <AgentsDelivery
                {...props}
                getDeliveryList={this.getList}
                deliveryList={deliveryList}
                loaded={loaded}
                pageForPagination={this.state.pageForPagination}
                isFirstInit={isFirstInit}
                totalPages={totalPages}
                acceptOrder={this.acceptOrder}
                openModal={this.openModal}
              />}
            exact={true} />
          <Route path="/orders/agents/delivery-details"
            render={
              (props) => {
                return <AgentOrdersDetails
                  ordersList={deliveryList}
                  orderType={OrderType.Delivery}
                  loaded={loaded}
                  isFirstInit={isFirstInit}
                  totalPages={totalPages}
                  loadMore={this.loadMore}
                  acceptOrder={this.acceptOrder}
                  openModal={this.openModal}
                  {...props} />
              }
            }
            exact={true} />
          <Route path="/orders/agents/self-delivery-details"
            render={
              (props) => {
                return <AgentOrdersDetails
                  ordersList={selfDeliveryList}
                  orderType={OrderType.SelfDelivery}
                  loaded={loaded}
                  isFirstInit={isFirstInit}
                  totalPages={totalPages}
                  loadMore={this.loadMore}
                  acceptOrder={this.acceptOrder}
                  openModal={this.openModal}
                  {...props} />
              }
            }
            exact={true} />
          <Route path="/orders/agents/delivery/map"
            render={(props) =>
              <OrdersMap
                deliveryList={deliveryList}
                acceptOrder={this.acceptOrder}
                openModal={this.openModal}
              />}
          />
          <Redirect to="/orders/agents/self-delivery" />
        </Switch>


        {/* // Finish btn modal */}

        <Modal
          isOpen={modalIsOpenIndex === 1}
          onRequestClose={this.closeModal}
          style={{
            content: {
              padding: "25px",
              height: "270px",
              minWidth: "550px",
              display: "flex",
              flexDirection: "column",
            }
          }}>
          <div className="P-order-modal">
            <h5 className="P-title">{Finish_request}</h5>
            <p className="P-text G-mb-2">{Set_actual_cardboard}</p>
            <Formik
              initialValues={{ finalWeight: "" }}
              onSubmit={(values: FormikValues) => this.finishOrder(String(activeOrder), String(values.finalWeight))}
              validationSchema={ Yup.object().shape({
                finalWeight: Yup.string().matches(/^\+?[0-9]*$/).min(1).max(5).required(),
              })}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form className="P-form" noValidate={true}>
                  <Field type="number" name="finalWeight" placeholder={kg}
                    className={`P-weight-input ${touched.finalWeight && errors.finalWeight ? "G-error" : ""}`} />
                  <div className="G-flex G-justify-between G-mt-auto">
                    <button className="G-mr-2 P-cancel" onClick={this.closeModal}>{Close}</button>
                    <button className="G-button P-order-button">
                      {isSubmitting ? <Loader /> : `${Finish}`}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>


        {/* // Reject btn modal */}

        <Modal
          isOpen={modalIsOpenIndex === 2}
          onRequestClose={this.closeModal}
          style={{
            content: {
              padding: "25px",
              height: "270px",
              minWidth: "550px",
              display: "flex",
              flexDirection: "column",
            }
          }}>
          <div className="P-order-modal">
            <h5 className="P-title G-mb-2">{Rejection_reason}</h5>
            <Formik
              initialValues={{ reason: "" }}
              onSubmit={(values: FormikValues) => this.rejectOrder(Number(activeOrder), String(values.reason))}
              validationSchema={Yup.object().shape({
                reason: Yup.string().min(1).max(400).required().trim(),
              })}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form className="P-form" noValidate={true}>
                  <Field type="text" as="textarea" name="reason"
                    className={`P-weight-input ${touched.reason && errors.reason ? "G-error" : ""}`} />
                  <div className="G-flex G-justify-between G-mt-auto">
                    <button className="G-mr-2 P-cancel" onClick={this.closeModal}>{Close}</button>
                    <button className="G-button P-reject-button P-order-button">
                      {isSubmitting ? <Loader /> : `${Reject}`}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>


        {/* // Cancel btn modal */}

        <Modal
          isOpen={modalIsOpenIndex === 3}
          onRequestClose={this.closeModal}
          style={{
            content: {
              padding: "25px",
              height: "270px",
              minWidth: "550px",
              display: "flex",
              flexDirection: "column",
            }
          }}>
          <div className="P-order-modal">
            <h5 className="P-title G-mb-2">{Сancellation_reason}</h5>
            <Formik
              initialValues={{ cancelReason: "" }}
              onSubmit={(values: FormikValues) => this.cancelOrder(Number(activeOrder), String(values.cancelReason))}
              validationSchema={Yup.object().shape({
                cancelReason: Yup.string().min(1).max(400).required().trim(),
              })}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form className="P-form" noValidate={true}>
                  <Field type="text" as="textarea" name="cancelReason"
                    className={`P-weight-input ${touched.cancelReason && errors.cancelReason ? "G-error" : ""}`} />
                  <div className="G-flex G-justify-between G-mt-auto">
                    <button className="G-mr-2 P-cancel" onClick={this.closeModal}>{Close}</button>
                    <button className="G-button P-cancel-button P-order-button">
                      {isSubmitting ? <Loader /> : `${Cancel}`}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      </section >
    );
  }
}

export default withRouter(AgentsContainer);