import * as React from 'react';
import HelperComponent from 'src/platform/classes/helper-component';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Form, Formik, Field, FormikValues } from 'formik';
import * as Yup from 'yup';
import { IResetPassRequestModel } from 'src/platform/api/forgot-password/interfaces/reset-pass-req';
import ForgotPassController from "src/platform/api/forgot-password/forgot-pass";
import { store } from 'react-notifications-component';
import DataTransfer from '../services/dataTransfer';
import Helper from 'src/platform/services/helper';
import Loader from 'src/platform/components/loader/loader';
import Languages from 'src/platform/services/languages';

interface IState {
  // Boolean
  passwordIsShown1: boolean
  passwordIsShown2: boolean
};

const {
  Sign_in,
  Password_recovery,
  New_password,
  Confirm_new_password,
  Save,
  Hide,
  Show,
  Please_create_new_password,
  You_have_successfully,
} = Languages.Translations

class PasswordReset extends HelperComponent<RouteComponentProps, IState> {

  public state: IState = {
    passwordIsShown1: false,
    passwordIsShown2: false
  }
  private reset = async (value: FormikValues, actions: FormikValues) => {
    const body: IResetPassRequestModel = {
      code: DataTransfer.data.code,
      phoneNumber: DataTransfer.data.phoneNumber,
      password: value.newPassword,
    }

    const res = await ForgotPassController.ResetPassword(body);
    if (res.success) {
      this.props.history.replace('/login/sign-in');
      actions.resetForm();
      store.addNotification(Helper.notificationConfig(`${You_have_successfully}`, true));
    } else {
      store.addNotification(Helper.notificationConfig(res.message, false));
    }
  }
  public validationSchema = Yup.object().shape({
    newPassword: Yup.string().min(6).required(),
    newPasswordConfirm: Yup.string().min(6).oneOf([Yup.ref('newPassword'), null]).required(),
  })

  public render() {

    const { passwordIsShown1, passwordIsShown2 } = this.state

    return (
      <div className="P-sigin-form-block P-big">
        <Formik
          initialValues={{ newPassword: "", newPasswordConfirm: "" }}
          onSubmit={this.reset}
          validationSchema={this.validationSchema}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form className="P-form" noValidate={true}>
              <span className="P-sign-in-title G-mb-2">{Password_recovery}</span>
              <span className="P-info">{Please_create_new_password}</span>
              <div className="P-form-input">
                <div className="G-mb-1">
                  <Field
                    type={passwordIsShown1 ? "text" : "password"}
                    name="newPassword"
                    placeholder={New_password}
                    className={touched.newPassword && errors.newPassword ? "G-error" : ""}
                  />
                  <span onClick={() => this.asyncSetState({ passwordIsShown1: !passwordIsShown1 })}
                    className={`P-show-btn ${passwordIsShown1 ? "P-hide" : "P-show"}`}
                  > {passwordIsShown1 ? `${Hide}` : `${Show}`}</span>
                </div>
                <div className="G-mb-4">
                  <Field
                    type={passwordIsShown2 ? "text" : "password"}
                    name="newPasswordConfirm"
                    placeholder={Confirm_new_password}
                    className={touched.newPasswordConfirm && errors.newPasswordConfirm ? "G-error" : ""}
                  />
                  <span onClick={() => this.asyncSetState({ passwordIsShown2: !passwordIsShown2 })}
                    className={`P-show-btn ${passwordIsShown2 ? "P-hide" : "P-show"}`}
                  > {passwordIsShown2 ? `${Hide}` : `${Show}`}</span>
                </div>
              </div>
              <button
                type="submit"
                className="P-sign-in-btn"
              >
                {isSubmitting ? <Loader /> : `${Save}`}
              </button>
              <Link to={"/login/sign-in"}
                className="G-btn-transparent G-bold"
              >
                {Sign_in}
          </Link>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default PasswordReset;