import * as React from 'react';
import HelperComponent from 'src/platform/classes/helper-component';
import { Link } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { ISignInRequestModel } from 'src/platform/api/auth/interfaces/sign-in-req';
import AuthController from 'src/platform/api/auth/auth';
import { store } from 'react-notifications-component';
import Helper from 'src/platform/services/helper';
import Loader from 'src/platform/components/loader/loader';
import { phoneRegExp } from 'src/platform/constants/regExp';
import logo from "src/assets/images/Logo_tapon.svg";
import Languages from 'src/platform/services/languages';

interface IState {
	// Boolean
	passwordIsShown: boolean;
};

const {
  Sign_in,
	Phone_number,
	Forgot_password,
	Password,
	Hide,
	Show,
} = Languages.Translations

class SignIn extends HelperComponent<{}, IState> {
	public state: IState = {
		passwordIsShown: false,
	}

	private signIn = async (value: ISignInRequestModel) => {

		let num: string;

		if (!value.phoneNumber.toString().startsWith("374")) {
			num = `+374${value.phoneNumber}`;
		} else {
			num = `+${value.phoneNumber}`;
		}

		const res = await AuthController.login({ password: value.password, phoneNumber: num });
		if (res.success) {
			window.localStorage.setItem('token', res.data.token);
			if(res.data.role === "ROLE_CLIENT"){
			  window.localStorage.setItem('role', "client");
			}
			window.location.reload();
		} else store.addNotification(Helper.notificationConfig(res.message, false));
		
	}

	public validationSchema = Yup.object().shape({
		phoneNumber: Yup.string().matches(phoneRegExp).required(),
		password: Yup.string().min(1).max(25).required(),
	})

	public render() {

		const { passwordIsShown } = this.state

		return (
			<>
				<div className="P-sigin-form-block P-small">
				<div className="G-logo P-signin-logo">
					<img className="G-logo" src={logo} alt=""/>
				</div>
					<div className="P-form">
						<span className="P-sign-in-title G-mb-3">{Sign_in}</span>
						<Formik
							initialValues={{
								phoneNumber: "",
								password: "",
							}}
							onSubmit={this.signIn}
							validationSchema={this.validationSchema}
						>
							{({ errors, touched, isSubmitting }) => (
								<Form noValidate={true}>
									<div className="P-form-input">
										<div>
											<Field
												type="number"
												name="phoneNumber"
												placeholder={Phone_number}
												className={touched.phoneNumber && errors.phoneNumber ? "G-error" : ""}
											/>
										</div>
										<div>
											<Field
												type={passwordIsShown ? 'text' : 'password'}
												name="password"
												placeholder={Password}
												className={touched.password && errors.password ? "G-error" : ""}
											/>
											<span
												onClick={() => this.asyncSetState({ passwordIsShown: !passwordIsShown })}
												className={`P-show-btn ${passwordIsShown ? 'P-hide' : 'P-show'} `}
											>
												{passwordIsShown ? `${Hide}` : `${Show}`}
											</span>
										</div>
										<Link className="P-password" to="/login/send-number">
											{Forgot_password}
										</Link>
										<button type="submit" className="P-sign-in-btn">
											{isSubmitting ? <Loader /> : `${Sign_in}`}
										</button>
									</div>
								</Form>
							)}
						</Formik>
					</div>
				</div>
			</>
		);
	}
}

export default SignIn;
