import * as React from 'react';
import Helper from 'src/platform/services/helper';
import moment from 'moment';
import { OrderSellerStatusEnum } from 'src/platform/enums/enums';
import { withRouter, RouteComponentProps } from 'react-router';
import Languages from 'src/platform/services/languages';

const {
    Order_,
    Seller_,
    enums,
    Any_time,
  } = Languages.Translations
  
export interface ISellerOrderDetailProps extends RouteComponentProps {
    item: any,
    makeActive: () => void,
    activeId: number,
}

const SellerOrderDetail = (props: ISellerOrderDetailProps) => {
    return (
        <div className="P-sellerOrderDetail"
            onClick={() => props.makeActive()}
            style={props.activeId === props.item.orderId ? { backgroundColor: "#F1F3F2" } : null}
        >
            <div className="G-flex P-top G-pb-1">
                <span className="P-order-id G-mr-2">{Order_} #{props.item.orderNumber}</span>
                <span className="G-mr-2">{moment(props.item.createdDate).format('DD MMM HH:mm')}</span>
                <span className="P-finished" style={{
                    color: `${(
                        props.item.orderStatusValue === OrderSellerStatusEnum.AGENT_REQUESTED ||
                        props.item.orderStatusValue === OrderSellerStatusEnum.AGENT_CHOSEN ||
                        props.item.orderStatusValue === OrderSellerStatusEnum.AGENT_FOUND ||
                        props.item.orderStatusValue === OrderSellerStatusEnum.PENDING)
                        ? '#7A7A7A'
                        : props.item.orderStatusValue === OrderSellerStatusEnum.CANCELED
                            ? '#FF0057' : "#04A556"}`
                }}>{enums.OrderSellerStatusEnum[props.item.orderStatusValue]}</span>
            </div>
            <div className="P-bottom G-flex G-justify-between G-pt-1">
                <div className="G-column">
                    <div className="G-mb-1 P-seller">{Seller_}</div>
                    <div className="G-flex" onClick={(evt: React.SyntheticEvent) => {
                            evt.stopPropagation();
                            props.history.push(`/customers/sellers/details?id=${props.item.sellerPreviewDto && props.item.sellerPreviewDto.sellerId}`)
                    }
                    }>
                        {/* <div className="G-flex P-image"
                            style={{ background: `url(${props.item.sellerPreviewDto.sellerImageUrl ? `${props.item.sellerPreviewDto.sellerImageUrl}/100/100` : noPic}) center/cover no-repeat` }}>
                        </div> */}
                        <span className="G-break-word">{props.item.sellerPreviewDto && props.item.sellerPreviewDto.sellerFullName}</span>
                    </div>
                </div>
                <div className="P-right-part">
                    <div className="G-mb-1">
                        {props.item.orderTimesDtos.map((item: any, index: number) => {
                            let from = item.fromTime;
                            let to = item.toTime;
                            return (<div key={index} className='P-time G-mr-1'>
                               {item.fromTime !== 0 ? <>
                                {moment(new Date((from * 1000) - 14400000)).format('HH:mm')} - 
                                {moment(new Date((to * 1000) - 14400000)).format('HH:mm')}</> : `${Any_time}`}
                            </div>);
                        })}
                    </div>
                    <div>{Helper.sliceSentence(props.item.addressNameFull || props.item.addressFullName, 17)}</div>
                </div>
            </div>
        </div>);
}

export default withRouter(SellerOrderDetail);