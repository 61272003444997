import { BaseController } from "src/platform/classes/http-service";
import { IResponse } from "src/platform/interfaces";
import { IGetAgentExportDate, IGetCustomerComparisonDetailsDate, IGetLeaderboardListRequestModel, IGetTapPoints, IGetUserComparisonDetailsDate, IGetUserDetailsDate } from './dashboard-interface';

class DashboardController extends BaseController {

  private controller = 'dashboard';

  public getDashboardDetail = async (form: IGetUserDetailsDate) => {
    const result = await this.connection.GET({
      controller: this.controller,
      action: `getDashboardDetail?fromDate=${form.fromDate}&toDate=${form.toDate}`,
    });
    return result;
  }

  public getNewestSupportMessage = async () => {
    const result = await this.connection.GET({
      controller: this.controller,
      action: `newestSupportMessage`,
    });
    return result;
  }

  public deviceUsage = async () => {
    const result = await this.connection.GET({
      controller: this.controller,
      action: `deviceUsage`,
    });
    return result;
  }

  public getTopFiveUsers = async () => {
    const result = await this.connection.GET({
      controller: this.controller,
      action: `getTopFiveUsers`,
    });
    return result;
  }

  public getCustomers = async (data: IGetUserDetailsDate) => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: `customers`,
      body: data,
    });
    return result;
  }

  public getLeaderboard = async (data: IGetLeaderboardListRequestModel) => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: `leaderboard/${data.page}/${data.size}`,
      body: data,
    });
    return result;
  }
  
  public getCustomersStatistic = async (data: IGetUserDetailsDate) => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: `requests/customers`,
      body: data,
    });
    return result;
  }
  
  public getCustomersComparisonStatistic = async (data: IGetCustomerComparisonDetailsDate) => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: `requests/customers/comparison`,
      body: data,
    });
    return result;
  }
  
  public getRequests = async (data: IGetUserDetailsDate) => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: `requests`,
      body: data,
    });
    return result;
  }
  
  public getRequestsComparison = async (data: IGetUserComparisonDetailsDate) => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: `requests/comparison`,
      body: data,
    });
    return result;
  }
  
  public downloadBuyersDailyReport = async (form: IGetAgentExportDate): Promise<IResponse<any>> => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: `downloadBuyersDailyReport`,
      body: form,
    });
    return result;
  }
    
  public getCardBoardWeightStatistic = async (data: IGetUserDetailsDate) => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: `v2/cardBoardWeight/`,
      body: data,
    });
    return result;
  }
  
  public getCardBoardWeightComparisonStatistic = async (data: IGetCustomerComparisonDetailsDate) => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: `v2/cardBoardWeight/comparison`,
      body: data,
    });
    return result;
  }
  
  public getRequestsV2 = async (data: IGetUserDetailsDate) => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: `v2/requests/`,
      body: data,
    });
    return result;
  }
  
  public getRequestsComparisonV2 = async (data: IGetCustomerComparisonDetailsDate) => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: `v2/requests/comparison`,
      body: data,
    });
    return result;
  }

  
  public tapPoints = async (data: IGetTapPoints) => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: `tapPoints`,
      body: data,
    });
    return result;
  }

}

export default new DashboardController();
