import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Form, Formik, Field, FormikValues } from 'formik';
import * as Yup from 'yup';
import { store } from 'react-notifications-component';
import { ROUTES } from 'src/platform/constants/routes';
import { byPrivateRoute } from 'src/platform/decorators/routes';
import HelperPureComponent from 'src/platform/classes/helper-pure-component';
import Helper from 'src/platform/services/helper';
import Loader from 'src/platform/components/loader/loader';
import AdminController from "src/platform/api/admin/admin";
import './index.scss';
import Languages from 'src/platform/services/languages';

interface IState {
  // Boolean
  passwordIsShown1: boolean,
  passwordIsShown2: boolean,
  passwordIsShown3: boolean,
};

const {
  Account_settings,
  Change_password,
  Old_password,
  New_password,
  Confirm_new_password,
  Save_changes,
  Show,
  Hide,
  Password_was_changed,
} = Languages.Translations

@byPrivateRoute(ROUTES.accountSettings)
class AccountSettings extends HelperPureComponent<RouteComponentProps, IState> {

  public state: IState = {
    passwordIsShown1: false,
    passwordIsShown2: false,
    passwordIsShown3: false
  }

  private reset = async (value: FormikValues, actions: FormikValues) => {

    const body = {
      oldPassword: value.oldPassword,
      password: value.newPassword,
    }
    const res = await AdminController.changePassword(body)
    
    if (res.success) {
      actions.resetForm();
      store.addNotification(Helper.notificationConfig(`${Password_was_changed}`, true));
    } else {
      store.addNotification(Helper.notificationConfig(res.message, false));
    }
  }

  public validationSchema = Yup.object().shape({
    oldPassword: Yup.string().min(6).max(30).required(),
    newPassword: Yup.string().min(6).max(30).required(),
    confirmNewPassword: Yup.string().min(6).max(30).oneOf([Yup.ref('newPassword'), null]).required(),
  });

  public render() {

    const { passwordIsShown1, passwordIsShown2, passwordIsShown3 } = this.state

    return (
      <section className="G-main-page P-account-settings-page">
        <h4 className="G-mb-2">{Account_settings}</h4>
        <div className="P-account-settings-wrapper">
          <h6 className="G-mb-3">{Change_password}</h6>
          <Formik
            initialValues={{ oldPassword: "", newPassword: "", confirmNewPassword: "" }}
            onSubmit={this.reset}
            validationSchema={this.validationSchema}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form className="P-form" noValidate={true}>
                <div className="G-flex G-flex-wrap">
                  <div className="G-mb-3 P-input-container">
                    <Field
                      type={passwordIsShown1 ? "text" : "password"}
                      name="oldPassword"
                      placeholder={Old_password}
                      className={`G-text-input P-input ${touched.oldPassword && errors.oldPassword ? "G-error" : ""}`}
                    />
                    <span onClick={() => this.asyncSetState({ passwordIsShown1: !passwordIsShown1 })}
                      className={`P-show-btn ${passwordIsShown1 ? "P-hide" : "P-show"}`}
                    > {passwordIsShown1 ? `${Hide}` : `${Show}`}
                    </span>
                  </div>
                  <div className="G-mb-3 P-input P-input-container">
                    <Field
                      type={passwordIsShown2 ? "text" : "password"}
                      name="newPassword"
                      placeholder={New_password}
                      className={`G-text-input P-input ${touched.newPassword && errors.newPassword ? "G-error" : ""}`}
                    />
                    <span onClick={() => this.asyncSetState({ passwordIsShown2: !passwordIsShown2 })}
                      className={`P-show-btn ${passwordIsShown2 ? "P-hide" : "P-show"}`}
                    > {passwordIsShown2 ? `${Hide}` : `${Show}`}
                    </span>
                  </div>
                  <div className="G-mb-5 P-input-container">
                    <Field
                      type={passwordIsShown3 ? "text" : "password"}
                      name="confirmNewPassword"
                      placeholder={Confirm_new_password}
                      className={`G-text-input P-input ${touched.confirmNewPassword && errors.confirmNewPassword ? "G-error" :  ""}`}
                    />
                    <span onClick={() => this.asyncSetState({ passwordIsShown3: !passwordIsShown3 })}
                      className={`P-show-btn ${passwordIsShown3 ? "P-hide" : "P-show"}`}
                    > {passwordIsShown3 ? `${Hide}` : `${Show}`}
                    </span>
                  </div>
                </div>
                <button
                  type="submit"
                  className="G-button P-button"
                > {isSubmitting ? <Loader /> : `${Save_changes}`}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </section >
    );
  }
}

export default AccountSettings;