//? Route Helper Service
//? The routing system has one more layer, please first read comments on /src/platform/decorators/routes.ts


import { INestedPages } from './../interfaces/index';
import Helper from './helper';
import { IRoute, ISidebar } from '../interfaces';
import { ROUTES } from '../constants/routes';
import Languages from './languages';

const {
  Orders_,
  Customer,
} = Languages.Translations
type RouteCallback = (routes: IRoute[]) => void;

class RouteService {

  public static routes: IRoute[] = [];
  public static sidebar: ISidebar[] = [];
  public static subscribeUnauthorizedCallbacks: RouteCallback[] = [];
  public static subscribeAuthorizedCallbacks: RouteCallback[] = [];

  //? Get application default route
  public static get defaultRoute(): string {
    return !!Helper.token && !!Helper.role ? (ROUTES.orders + "/sellers")
           : !!Helper.token && !Helper.role ? ROUTES.dashboad : ROUTES.login;
  }

  // //? Check route
  // public static isRoute = (route: string): boolean => {
  //   return !!matchPath(window.location.pathname, { path: route, exact: true });
  // }
  
  //? Add route to routing system
  //! Will be called only by using the @byRouter or @byPrivateRouter decorators
  public static addRoute<Component extends React.ComponentClass>(
    path: string | string[],
    component: Component,
    isPrivate: boolean,
  ): Component {
    Array.isArray(path) ? path.map((item: string) => RouteService.routes.push({
      path: item,
      component,
      isPrivate,
    })) : RouteService.routes.push({
      path,
      component,
      isPrivate,
    });

    RouteService
      .subscribeUnauthorizedCallbacks
        .map((item) => item(
          RouteService.routes.filter((sub: IRoute) => !sub.isPrivate)
        ));

    RouteService
      .subscribeAuthorizedCallbacks
        .map(item => item(RouteService.routes.filter(sub => sub.isPrivate)));

    return component;
  }

  //? Add label to sidebar
  //! Will be called only by using the @bySidebar decorator
  public static addSidebarRow(
    name: string,
    icon: string,
    nestedPages: Array<INestedPages>,
    path: string,
  ): string {
    if(Helper.role && (name === Orders_ || name === Customer)){
      
      RouteService.sidebar.push({
        name,
        icon,
        nestedPages,
        path,
      });
    }
    if(!Helper.role) {
      RouteService.sidebar.push({
        name,
        icon,
        nestedPages,
        path,
      });
    }

    return path;
  }

  //? To subscribe to authorized routes changes
  public static subscribeAuthorized(callback: RouteCallback) {
    const routes: IRoute[] = RouteService.routes.filter((item: IRoute) => item.isPrivate);
    RouteService
      .subscribeAuthorizedCallbacks
        .push(callback);
    return callback(routes);
  }

  //? To subscribe to unauthorized routes changes
  public static subscribeUnauthorized(callback: RouteCallback) {
    RouteService
      .subscribeUnauthorizedCallbacks
        .push(callback);

    return callback(RouteService.routes.filter(item => !item.isPrivate));
  }

}

export default RouteService;