import { IResponse } from '../../interfaces';
import { BaseController } from "../../classes/http-service";
import { ICreateKey, IGetKeyListRequest } from './key-interface';


class KeyController extends BaseController {
  private controller = 'key';

  public createKey = async (form: ICreateKey): Promise<IResponse<any>> => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: ``,
      body: form,
    });

    return result;
  };
  
  public updateKey = async (form: ICreateKey, keyName: string): Promise<IResponse<any>> => {
    const result = await this.connection.PUT({
      controller: this.controller,
      action: `?keyName=${keyName}`,
      body: form,
    });

    return result;
  };

  public deleteFaq = async (id: number): Promise<IResponse<any>> => {
    const result = await this.connection.DELETE({
      controller: this.controller,
      action: `${id}`,
      body: null
    });

    return result;
  };
  
  public getById = async (id: number): Promise<IResponse<any>> => {
    const result = await this.connection.GET({
      controller: this.controller,
      action: `${id}`,
    });

    return result;
  };

  public getList = async (data: IGetKeyListRequest, LanguageName: string): Promise<IResponse<any>> => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: `getAllKeys/${data.page}/${data.size}?LanguageName=${LanguageName}`,
      body: data.form
    });

    return result;
  };

}
export default new KeyController();
