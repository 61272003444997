import { months } from './../constants/casual';

//? Service to save some settings

class Helper {

  public static token: string = window.localStorage.getItem('token');
  public static role: string = window.localStorage.getItem('role');

  //? To logout
  public static logout = () => {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('role');
    window.location.reload();
  }

  public static notificationConfig(message: string, isSuccess: boolean) {
    return {
      title: message ? message : 'Something went wrong',
      message: "    ",
      type: isSuccess ? "success" : "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "bounceIn"],
      animationOut: ["animated", "bounceOut"],
      dismiss: {
        duration: 4000,
        onScreen: false
      },
    }
  }

  public static sliceSentence = (value: string, limit: number) => {

    if (value) {
      return value.length > limit ? value.substring(0, limit) + '...' : value;
    }

    return '';
  }

}
//? Format date to show in view
export const formatDate = (date: string | number, withHours?: boolean): string => {
  if (!date) return 'N/A';
  
  // const localDate = moment.utc(date).local().toDate();
  const localDate = new Date(date);
  const onlyHours = localDate.getHours() < 10 ? `0${localDate.getHours()}` : localDate.getHours();
  const onlyMinutes = localDate.getMinutes() < 10 ? `0${localDate.getMinutes()}` : localDate.getMinutes();
  const hours = `${onlyHours}:${onlyMinutes}`;

  return `${localDate.getDate() < 10 ? `0${localDate.getDate()}` : localDate.getDate()} ${months[localDate.getMonth()]} ${localDate.getFullYear()}${withHours ? ` | ${hours}` : ''}`;
};

export default Helper;

