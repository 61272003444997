import EN from '../../assets/translations/en';
import AM from '../../assets/translations/am';

const languageEnum = {
  hy : 1,
  en : 2
};

const getTranslations = (shortCode: any) => {
  switch (shortCode) {
    case languageEnum.en: return EN;
    default  : return AM;
  } 
};

// if (!window.localStorage.getItem('language')) {
//   window.localStorage.setItem('language', languageEnum.hy);
// }

class Languages {
  static get ShortCode() { return +window.localStorage.getItem('language') || languageEnum.hy; }
  static get Translations() { return getTranslations(Languages.ShortCode); }
}

export default Languages;