import * as React from 'react';
import HelperComponent from 'src/platform/classes/helper-component';
import { Formik, Form, Field, FormikValues, FormikErrors } from 'formik';
import Loader from 'src/platform/components/loader/loader';
import "./index.scss";
import Languages from 'src/platform/services/languages';
import KeyController from 'src/platform/api/key/key';
import Helper from 'src/platform/services/helper';
import { store } from 'react-notifications-component';
import { ICreateKey } from 'src/platform/api/key/key-interface';

export interface IProps {
  closeModal: () => void,
  getList: () => void,
  activeLanguage: string;
}
export interface IState {
  description: number,
  keyName: string,
  value: string,
}

const {
  Create,
  Cancel,
  Value,
} = Languages.Translations;

class CreateKeyPopup extends HelperComponent<IProps, IState> {

  componentDidMount() {

  }

  state: IState = {
    description: 1,
    keyName: '',
    value: '',
  }

  private createKey = async (values: FormikValues, errors: FormikValues) => {

    const body: ICreateKey = {
      languageName: this.props.activeLanguage,
      keyName: values.keyName,
      value: values.value,
    }
    
    const res = await KeyController.createKey(body);
    if (res.success) {
      this.props.closeModal();
      this.props.getList();
    } else {
      store.addNotification(Helper.notificationConfig(res.message, false));
    }
  }

  onSubmit = (errors: FormikErrors<FormikValues>) => {
    if (!errors.question_1 && errors.question_2) {
      this.asyncSetState({ description: 2 });
    }
    if (!errors.question_1 && !errors.question_2 && errors.question_3) {
      this.asyncSetState({ description: 3 });
    }
  }

  render() {
    return (<div className="P-create-popup">
      <h5 className="G-mb-3 P-title">{Create}</h5>
      <Formik enableReinitialize
        initialValues={{
          key: this.state.keyName,
          value: this.state.value,
        }}

        onSubmit={this.createKey}
      >
        {({ errors, touched, isSubmitting, values, setFieldValue }) => (
          <Form noValidate={true}>
            <div className="P-am">
              <Field
                name="keyName"
                placeholder="Key"
                className={`G-mb-2 P-input ${touched.keyName && errors.question_1 ? "G-error" : ""}`}
                type="text" />
              <Field
                component="textarea"
                name="value"
                placeholder={Value}
                className={`G-textarea G-mb-3 P-input ${touched.value && errors.answer_1 ? "G-error" : ""}`}
                cols={1}
                rows={1}
              />
            </div>
            <div className="G-flex G-justify-between">
              <button className="G-mr-2 P-cancel" onClick={this.props.closeModal}>{Cancel}</button>
              <button className="G-button P-button" type="submit"
                onClick={() => this.onSubmit(errors)}>
                {isSubmitting ? <Loader /> : `${ Create}`}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>);
  }
}

export default CreateKeyPopup;