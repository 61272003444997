import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ROUTES } from 'src/platform/constants/routes';
import { byPrivateRoute, bySidebar } from 'src/platform/decorators/routes';
import HelperPureComponent from 'src/platform/classes/helper-pure-component';
import Helper from 'src/platform/services/helper';
import { Form, Formik, Field, FormikValues } from 'formik';
import { store } from 'react-notifications-component';
import Loader from 'src/platform/components/loader/loader';
import SettingsController from 'src/platform/api/settings/settings';
import { EcoTypeEnum } from 'src/platform/enums/enums';
import genius from 'src/assets/images/ic_master.png'
import master from 'src/assets/images/ic_genius.png'
import hero from 'src/assets/images/ic_hero.png'
import * as Yup from 'yup';
import './index.scss';
import Languages from 'src/platform/services/languages';


interface IState {
  settingsData: {
    mapRadius: number,
    inPlaceRadius: number,
    buyerRequestLimit: number,
    priceForKg: number,
    buyerRefillPoints: number,
    cardboardPriceForDeliver: number,
    priceForKgForAgent: number,
    agentAvailableBalance: number
  },
  ecoStatusIndividualList: Array<IEcoStatusIndividual>
  ecoStatusCorporateList: Array<IEcoStatusCorporate>
};

const {
  Points,
  Save_changes,
  Status_period_individuals,
  Eco_statuses,
  Eco_master,
  Eco_genius,
  Eco_hero,
  Status_period_corporates,
  days,
  kg,
  km,
  meters,
  Settings_,
  Map_radius,
  In_place_radius,
  Buyer_request_kg_limit,
  Cardboard_price_for1kg,
  Cardboard_price_delivery,
  Cardboard_price_agents,
  Buyer_refill_points,
  Negative_balance,
  rqs,
} = Languages.Translations;

@bySidebar(`${Settings_}`, "icon-ic_settings")
@byPrivateRoute(ROUTES.settings)
class Settings extends HelperPureComponent<RouteComponentProps, IState> {


  public state: IState = {
    settingsData: null,
    ecoStatusIndividualList: [],
    ecoStatusCorporateList: []
  }

  componentDidMount() {
    this.getSettingsData();
    this.getEcoStatusIndividual();
    this.getEcoStatusCorporate();
  }

  private getSettingsData = async () => {

    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const result = await SettingsController.getSettings();
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));

    if (result.success) {

      this.asyncSetState({
        settingsData: result.data,
      });
    }
    else {
      store.addNotification(Helper.notificationConfig(result.message, false));
    }
  };

  private getEcoStatusIndividual = async () => {

    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const result = await SettingsController.getEcoStatusIndividual();
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
    if (result.success) {
      this.asyncSetState({
        ecoStatusIndividualList: result.data
      });
    } else {
      store.addNotification(Helper.notificationConfig(result.message, false));
    }
  };

  private getEcoStatusCorporate = async () => {

    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const result = await SettingsController.getEcoStatusCorporate();
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
    if (result.success) {
      this.asyncSetState({
        ecoStatusCorporateList: result.data
      });
    } else {
      store.addNotification(Helper.notificationConfig(result.message, false));
    }
  };

  private editSettings = async (values: FormikValues, actions: FormikValues) => {

    const body = {
      buyerRefillPoints: values.refill,
      buyerRequestLimit: values.kgLimit,
      mapRadius: values.mapRadius,
      inPlaceRadius: values.inPlaceRadius,
      priceForKg: values.cardboardPrice,
      cardboardPriceForDeliver: values.cardboardPriceForDeliver,
      priceForKgForAgent: values.cardboardPriceForAgent,
      agentAvailableBalance: values.agentAvailableBalance
    }

    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const result = await SettingsController.editSettings(body);
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
    if (result.success) {
      store.addNotification(Helper.notificationConfig(result.message, true));
    } else {
      store.addNotification(Helper.notificationConfig(result.message, false));
    }
  }

  private editEcoStatusIndividual = async (values: FormikValues, actions: FormikValues) => {


    const from: { statusDtos: Array<IEditEcoStatusIndividual> } = {
      statusDtos: [{
        statusPeriod: values.statusPeriod,
        ecoStatusValue: EcoTypeEnum.MASTER,
        maxKg: values.ecoMasterKg,
        requestsCount: values.ecoMasterRqs,
      }, {
        statusPeriod: values.statusPeriod,
        ecoStatusValue: EcoTypeEnum.GENIUS,
        maxKg: values.ecoGeniusKg,
        requestsCount: values.ecoGeniusRqs,
      }, {
        statusPeriod: values.statusPeriod,
        ecoStatusValue: EcoTypeEnum.HERO,
        maxKg: values.ecoHeroKg,
        requestsCount: values.ecoHeroRqs,
      }
      ]
    }

    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const result = await SettingsController.editEcoStatusIndividual(from);
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
    if (result.success) {
      store.addNotification(Helper.notificationConfig(result.message, true));
    } else {
      store.addNotification(Helper.notificationConfig(result.message, false));
    }
  }

  private editEcoStatusCorporate = async (values: FormikValues, actions: FormikValues) => {


    const from: { statusDtos: Array<IEditEcoStatusCorporate> } = {
      statusDtos: [{
        statusPeriod: values.statusPeriod,
        ecoStatusValue: EcoTypeEnum.MASTER,
        maxKg: values.ecoMasterKg,
        requestsCount: values.ecoMasterRqs,
      }, {
        statusPeriod: values.statusPeriod,
        ecoStatusValue: EcoTypeEnum.GENIUS,
        maxKg: values.ecoGeniusKg,
        requestsCount: values.ecoGeniusRqs,
      }, {
        statusPeriod: values.statusPeriod,
        ecoStatusValue: EcoTypeEnum.HERO,
        maxKg: values.ecoHeroKg,
        requestsCount: values.ecoHeroRqs,
      }
      ]
    }

    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const result = await SettingsController.editEcoStatusCorporate(from);
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
    if (result.success) {
      store.addNotification(Helper.notificationConfig(result.message, true));
    } else {
      store.addNotification(Helper.notificationConfig(result.message, false));
    }
  }
  public validationSchemaSettingsData = Yup.object().shape({
    mapRadius: Yup.number().min(0).required(),
    inPlaceRadius: Yup.number().min(0).required(),
    kgLimit: Yup.number().min(0).required(),
    cardboardPrice: Yup.number().min(0).required(),
    cardboardPriceForDeliver: Yup.number().min(0).required(),
    cardboardPriceForAgent: Yup.number().min(0).required(),
    refill: Yup.number().min(0).required(),
    agentAvailableBalance: Yup.number().min(0).required(),
  });

  public validationSchemaEco = Yup.object().shape({
    statusPeriod: Yup.number().min(0).required(),
    ecoMasterKg: Yup.number().min(0).required(),
    ecoMasterRqs: Yup.number().min(0).required(),
    ecoGeniusKg: Yup.number().min(0).required(),
    ecoGeniusRqs: Yup.number().min(0).required(),
    ecoHeroKg: Yup.number().min(0).required(),
    ecoHeroRqs: Yup.number().min(0).required(),
  });


  public render() {
    const { settingsData, ecoStatusIndividualList, ecoStatusCorporateList } = this.state;
      
    return (

      <section className="G-main-page P-settings-page">
        <h4 className="G-mb-2">{Settings_}</h4>
        <div className="P-container G-mb-6">
          <Formik
            enableReinitialize={true}
            initialValues={{
              mapRadius: settingsData && settingsData.mapRadius,
              inPlaceRadius: settingsData && settingsData.inPlaceRadius,
              kgLimit: settingsData && settingsData.buyerRequestLimit,
              cardboardPrice: settingsData && settingsData.priceForKg,
              cardboardPriceForAgent: settingsData && settingsData.priceForKgForAgent,
              cardboardPriceForDeliver: settingsData && settingsData.cardboardPriceForDeliver,
              refill: settingsData && settingsData.buyerRefillPoints,
              agentAvailableBalance: settingsData && settingsData.agentAvailableBalance,
            }}
            onSubmit={this.editSettings}
            validationSchema={this.validationSchemaSettingsData}
          >
            {({ errors, touched, isSubmitting, values }) => (
              <Form className="P-form" noValidate={true}>
                <div className="G-flex">
                  <div className="G-mr-3">
                    <div className="P-inp P-inp-1">
                      <p className="P-title">{Map_radius}</p>
                      <Field
                        type="number"
                        name="mapRadius"
                        className={`P-field ${touched.mapRadius && errors.mapRadius ? "G-error" : ""}`}
                      />
                      <span>{km}</span>
                    </div>
                    <div className="P-inp P-inp-1">
                      <p className="P-title">{In_place_radius}</p>
                      <Field
                        type="number"
                        name="inPlaceRadius"
                        className={`P-field ${touched.inPlaceRadius && errors.inPlaceRadius ? "G-error" : ""}`}
                      />
                      <span>{meters}</span>
                    </div>
                    <div className="P-inp P-inp-2">
                      <p className="P-title">{Buyer_request_kg_limit}</p>
                      <Field
                        type="number"
                        name="kgLimit"
                        className={`P-field ${touched.kgLimit && errors.kgLimit ? "G-error" : ""}`}
                      />
                      <span>{kg}</span>
                    </div>
                    <div className="P-inp P-inp-3">
                      <p className="P-title">{Cardboard_price_for1kg}</p>
                      <Field
                        type="number"
                        name="cardboardPrice"
                        className={`P-field ${touched.cardboardPrice && errors.cardboardPrice ? "G-error" : ""}`}
                      />
                      <span>{Points}</span>
                    </div>
                  </div>
                  <div>
                    <div className="P-inp P-inp-3">
                      <p className="P-title">{Cardboard_price_delivery}</p>
                      <Field
                        type="number"
                        name="cardboardPriceForDeliver"
                        className={`P-field ${touched.cardboardPriceForDeliver && errors.cardboardPriceForDeliver ? "G-error" : ""}`}
                      />
                      <span>{Points}</span>
                    </div>
                    <div className="P-inp P-inp-3">
                      <p className="P-title">{Cardboard_price_agents}</p>
                      <Field
                        type="number"
                        name="cardboardPriceForAgent"
                        className={`P-field ${touched.cardboardPriceForAgent && errors.cardboardPriceForAgent ? "G-error" : ""}`}
                      />
                      <span>{Points}</span>
                    </div>
                    <div className="P-inp P-inp-4">
                      <p className="P-title">{Buyer_refill_points}</p>
                      <Field
                        type="number"
                        name="refill"
                        className={`P-field ${touched.refill && errors.refill ? "G-error" : ""}`}
                      />
                      <span>{Points}</span>
                    </div>
                    <div className="P-inp P-inp-4">
                      <p className="P-title">{Negative_balance}</p>
                      <Field
                        type="number"
                        name="agentAvailableBalance"
                        className={`P-field ${touched.agentAvailableBalance && errors.agentAvailableBalance ? "G-error" : ""}`}
                      />
                      <span>{Points}</span>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="G-button P-button"
                > {isSubmitting ? <Loader /> : `${Save_changes}`}
                </button>
              </Form>
            )}
          </Formik>
        </div>

        <div className="P-container G-mb-6">
          <Formik
            enableReinitialize={true}
            initialValues={{
              statusPeriod: ecoStatusIndividualList.length && ecoStatusIndividualList[0].statusPeriod,
              ecoMasterKg: ecoStatusIndividualList.length && ecoStatusIndividualList[0].maxKg,
              ecoMasterRqs: ecoStatusIndividualList.length && ecoStatusIndividualList[0].requestsCount,
              ecoGeniusKg: ecoStatusIndividualList.length && ecoStatusIndividualList[1].maxKg,
              ecoGeniusRqs: ecoStatusIndividualList.length && ecoStatusIndividualList[1].requestsCount,
              ecoHeroKg: ecoStatusIndividualList.length && ecoStatusIndividualList[2].maxKg,
              ecoHeroRqs: ecoStatusIndividualList.length && ecoStatusIndividualList[2].requestsCount,
            }}
            onSubmit={this.editEcoStatusIndividual}
            validationSchema={this.validationSchemaEco}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form className="P-form" noValidate={true}>
                <div className="P-inp P-inp-1">
                  <p className="P-title">{Status_period_individuals}</p>
                  <Field
                    type="number"
                    name="statusPeriod"
                    className={`P-field ${touched.statusPeriod && errors.statusPeriod ? "G-error" : ""}`} />
                  <span>{days}</span>
                </div>
                <div className="P-bottom G-mb-3">
                  <p className="P-title P-title-eco">{Eco_statuses}</p>
                  <div className="G-flex G-justify-between  G-mb-1">
                    <div className="G-flex G-align-center">
                      <div className="P-eco-img G-mr-1" style={{ background: `url(${master}) center/cover no-repeat` }} />
                      <span className="P-eco">{Eco_master}</span>
                    </div>
                    <div className="G-flex G-flex-wrap">
                      <div className="P-inp">
                        <Field
                          type="number"
                          name="ecoMasterKg"
                          className={`P-field G-mr-2 ${touched.ecoMasterKg && errors.ecoMasterKg ? "G-error" : ""}`} />
                        <span className="kg">{kg}</span>
                      </div>
                      <div className="P-inp">
                        <Field
                          type="number"
                          name="ecoMasterRqs"
                          className={`P-field ${touched.ecoMasterRqs && errors.ecoMasterRqs ? "G-error" : ""}`} />
                        <span className="rqs">{rqs}</span>
                      </div>
                    </div>
                  </div>
                  <div className="G-flex G-justify-between G-mb-1">
                    <div className="G-flex G-align-center">
                      <div className="P-eco-img G-mr-1" style={{ background: `url(${genius}) center/cover no-repeat` }} />
                      <span className="P-eco">{Eco_genius}</span>
                    </div>
                    <div className="G-flex G-flex-wrap">
                      <div className="P-inp">
                        <Field
                          type="number"
                          name="ecoGeniusKg"
                          className={`P-field G-mr-2 ${touched.ecoGeniusKg && errors.ecoGeniusKg ? "G-error" : ""}`} />
                        <span className="kg">{kg}</span>
                      </div>
                      <div className="P-inp">
                        <Field
                          type="number"
                          name="ecoGeniusRqs"
                          className={`P-field ${touched.ecoGeniusRqs && errors.ecoGeniusRqs ? "G-error" : ""}`} />
                        <span className="rqs">{rqs}</span>
                      </div>
                    </div>
                  </div>
                  <div className="G-flex G-justify-between G-mb-5">
                    <div className="G-flex G-align-center">
                      <div className="P-eco-img G-mr-1" style={{ background: `url(${hero}) center/cover no-repeat` }} />
                      <span className="P-eco">{Eco_hero}</span>
                    </div>
                    <div className="G-flex G-flex-wrap">
                      <div className="P-inp">
                        <Field
                          type="number"
                          name="ecoHeroKg"
                          className={`P-field G-mr-2 ${touched.ecoHeroKg && errors.ecoHeroKg ? "G-error" : ""}`} />
                        <span className="kg">{kg}</span>
                      </div>
                      <div className="P-inp">
                        <Field
                          type="number"
                          name="ecoHeroRqs"
                          className={`P-field ${touched.ecoHeroRqs && errors.ecoHeroRqs ? "G-error" : ""}`} />
                        <span className="rqs">{rqs}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="G-button P-button"
                > {isSubmitting ? <Loader /> : `${Save_changes}`}
                </button>
              </Form>
            )}
          </Formik>
        </div>

        <div className="P-container">
          <Formik
            enableReinitialize={true}
            initialValues={{
              statusPeriod: ecoStatusCorporateList.length && ecoStatusCorporateList[0].statusPeriod,
              ecoMasterKg: ecoStatusCorporateList.length && ecoStatusCorporateList[0].maxKg,
              ecoMasterRqs: ecoStatusCorporateList.length && ecoStatusCorporateList[0].requestsCount,
              ecoGeniusKg: ecoStatusCorporateList.length && ecoStatusCorporateList[1].maxKg,
              ecoGeniusRqs: ecoStatusCorporateList.length && ecoStatusCorporateList[1].requestsCount,
              ecoHeroKg: ecoStatusCorporateList.length && ecoStatusCorporateList[2].maxKg,
              ecoHeroRqs: ecoStatusCorporateList.length && ecoStatusCorporateList[2].requestsCount,
            }}
            onSubmit={this.editEcoStatusCorporate}
            validationSchema={this.validationSchemaEco}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form className="P-form" noValidate={true}>
                <div className="P-inp P-inp-1">
                  <p className="P-title">{Status_period_corporates}</p>
                  <Field
                    type="number"
                    name="statusPeriod"
                    className={`P-field ${touched.statusPeriod && errors.statusPeriod ? "G-error" : ""}`} />
                  <span>{days}</span>
                </div>
                <div className="P-bottom G-mb-3">
                  <p className="P-title P-title-eco">{Eco_statuses}</p>
                  <div className="G-flex G-justify-between  G-mb-1">
                    <div className="G-flex G-align-center">
                      <div className="P-eco-img G-mr-1" style={{ background: `url(${master}) center/cover no-repeat` }} />
                      <span className="P-eco">{Eco_master}</span>
                    </div>
                    <div className="G-flex G-flex-wrap">
                      <div className="P-inp">
                        <Field
                          type="number"
                          name="ecoMasterKg"
                          className={`P-field G-mr-2 ${touched.ecoMasterKg && errors.ecoMasterKg ? "G-error" : ""}`} />
                        <span className="kg">{kg}</span>
                      </div>
                      <div className="P-inp">
                        <Field
                          type="number"
                          name="ecoMasterRqs"
                          className={`P-field ${touched.ecoMasterRqs && errors.ecoMasterRqs ? "G-error" : ""}`} />
                        <span className="rqs">{rqs}</span>
                      </div>
                    </div>
                  </div>
                  <div className="G-flex G-justify-between G-mb-1">
                    <div className="G-flex G-align-center">
                      <div className="P-eco-img G-mr-1" style={{ background: `url(${genius}) center/cover no-repeat` }} />
                      <span className="P-eco">{Eco_genius}</span>
                    </div>
                    <div className="G-flex G-flex-wrap">
                      <div className="P-inp">
                        <Field
                          type="number"
                          name="ecoGeniusKg"
                          className={`P-field G-mr-2 ${touched.ecoGeniusKg && errors.ecoGeniusKg ? "G-error" : ""}`} />
                        <span className="kg">{kg}</span>
                      </div>
                      <div className="P-inp">
                        <Field
                          type="number"
                          name="ecoGeniusRqs"
                          className={`P-field ${touched.ecoGeniusRqs && errors.ecoGeniusRqs ? "G-error" : ""}`} />
                        <span className="rqs">{rqs}</span>
                      </div>
                    </div>
                  </div>
                  <div className="G-flex G-justify-between G-mb-5">
                    <div className="G-flex G-align-center">
                      <div className="P-eco-img G-mr-1" style={{ background: `url(${hero}) center/cover no-repeat` }} />
                      <span className="P-eco">{Eco_hero}</span>
                    </div>
                    <div className="G-flex G-flex-wrap">
                      <div className="P-inp">
                        <Field
                          type="number"
                          name="ecoHeroKg"
                          className={`P-field G-mr-2 ${touched.ecoHeroKg && errors.ecoHeroKg ? "G-error" : ""}`} />
                        <span className="kg">{kg}</span>
                      </div>
                      <div className="P-inp">
                        <Field
                          type="number"
                          name="ecoHeroRqs"
                          className={`P-field ${touched.ecoHeroRqs && errors.ecoHeroRqs ? "G-error" : ""}`} />
                        <span className="rqs">{rqs}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="G-button P-button"
                > {isSubmitting ? <Loader /> : `${Save_changes}`}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </section>);
  }
}

export default Settings;