
export const ROUTES = {
  accountSettings: '/account-settings',
  login: '/login',
  dashboad: '/dashboad',
  customers: '/customers',
  orders: '/orders',
  pushNotifications: '/push-notifications',
  transactions: '/transactions',
  donations: '/donations',
  loyality: '/loyality',
  support: '/support',
  settings: '/settings',
  contextManagment: '/content-managment',
}


