import * as React from 'react';
import './index.scss';
import Languages from 'src/platform/services/languages';
import HelperComponent from 'src/platform/classes/helper-component';
import ContextManagementController from 'src/platform/api/context-management/cm';
import { IGetMediaListResponse } from 'src/platform/api/context-management/cm-interface';
import { MediaTypeEnum } from 'src/platform/enums/enums';

export interface IProps {
  id: number;
}

interface IState {
  mediaList: IGetMediaListResponse[];
  isFirstInit: boolean;
  loaded: boolean,
  img: string;
  mediaType: MediaTypeEnum;
};

const {
  Change_image
} = Languages.Translations;

class ManagementMediaDetails extends HelperComponent<IProps, IState> {

  componentDidMount() {
    this.getMediaById()
  }

  public state: IState = {
    mediaList: [],
    isFirstInit: false,
    loaded: false,
    img: '',
    mediaType: MediaTypeEnum.Image,
  }

  private getMediaById = async (pageNum?: { selected: number }) => {
    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const res = await ContextManagementController.getMediaById(this.props.id);
    if (res.success) {
      this.setState({
        loaded: true,
        isFirstInit: this.state.isFirstInit && !res.data
      })
    }
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
    if (res.success) {
      this.asyncSetState({
        mediaList: res.data,
      })
    }
  }
  
  private uploadasd = async (file: Blob) => {
    if (file) {
      const data = new FormData();
      data.append(`mediasList[0].key`, 'help_top');
      data.append(`mediasList[0].file`, file);
      this.asyncSetState({
        img: URL.createObjectURL(file),
      })
      const res = await ContextManagementController.uploadPageMedias(this.props.id, data);
      if (res.success) {
        this.getMediaById();
      }
      // UserService.uploadImage(data).then((res) => {
      //   userState.setUser({ ...userState.user, imageUrl: res.data });
      // });
    }
  }


  private upload = async (file: Blob, key: string, index: number) => {
    if (file) {
      const data = new FormData();
      data.append(`mediasList[${index}].key`, key);
      data.append(`mediasList[${index}].file`, file);
      this.asyncSetState({
        img: URL.createObjectURL(file),
      })
      const res = await ContextManagementController.uploadPageMedias(this.props.id, data);
      if (res.success) {
        this.getMediaById();
      }
      // UserService.uploadImage(data).then((res) => {
      //   userState.setUser({ ...userState.user, imageUrl: res.data });
      // });
    }
  }
  public render() {
    const { mediaList, img } = this.state;

    return (
      <section className="P-media-details">
        <div className="G-block">
        {/* <label className='P-image-wrap G-position-relative'
                style={{ backgroundImage: `url(${''})` }}>
         
                  <div className="P-upload-icon-wrap">
                  <i className="icon-ic_plus P-upload-icon" />
                  <h6>Change image</h6>
                </div>
                <input ref={img} type="file" onChange={(e) => { this.uploadasd(e.target.files[0]); }} />
              </label> */}

          {mediaList.map((item, index) => {
            return <div>
              <h6 className="G-mb-2">{Languages.Translations[item.name]}</h6>
              <label className='P-image-wrap G-position-relative'
                style={{ backgroundImage: `url(${item.mediaUrl})` }}>
                {item.mediaType === MediaTypeEnum.Video ?
                  <video className="P-video" controls>
                    <source src={item.mediaUrl} />
                  </video> : ''}
                {/* <img className="P-avatar" src={img} alt="" /> */}
         
                {item.mediaType !== MediaTypeEnum.Video ?
                  <div className="P-upload-icon-wrap">
                  <i className="icon-ic_plus P-upload-icon" />
                  <h6>{Change_image}</h6>
                </div> : ''}
                <input ref={img} type="file" onChange={(e) => { this.upload(e.target.files[0], item.key, index); }} />
              </label>
            </div>
          })}

        </div>
      </section >
    );
  }
}

export default ManagementMediaDetails;