import * as React from 'react';
import './index.scss';
import Languages from 'src/platform/services/languages';
import Table, { ITableColumnConfig } from 'src/platform/components/table/table';
import HelperComponent from 'src/platform/classes/helper-component';
import ContextManagementController from 'src/platform/api/context-management/cm';
import { IGetPagesListResponse } from 'src/platform/api/context-management/cm-interface';
import ManagementMediaDetails from './details/media-details';

interface IState {
  list: any[];
  isFirstInit: boolean;
  loaded: boolean,
  details: boolean,
  activeId: number,
};

const {
  Page_name,
} = Languages.Translations;

class ManagementMedia extends HelperComponent<{}, IState> {
  
  componentDidMount() {
    this.getList();
  }

  public state: IState = {
    list: [],
    isFirstInit: false,
    loaded: false,
    details: false,
    activeId: null,
  }
  public config: Array<ITableColumnConfig<IGetPagesListResponse>> = [
    {
      name: `${Page_name}`,
      cell: (row: IGetPagesListResponse, index: number) => {
        return (<div className="G-cursor"  onClick={() => this.toDetails(row.id)}>
            {Languages.Translations[row.slug]}
        </div>);
      },
      style: { marginRight: "2%" },
    },
  ]
  
  private getList = async (pageNum?: { selected: number }) => {
    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const res = await ContextManagementController.getPages();
    if (res.success) {
      this.setState({
        loaded: true,
        isFirstInit: this.state.isFirstInit && !res.data
      })
    }
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
    if (res.success) {
      this.asyncSetState({
        list: res.data,
      })
    }
  }

  private toDetails = (id: number) => {
    this.asyncSetState({ 
      details: true,
      activeId: id,
     });
  }
  public render() { 
    const { list, isFirstInit, loaded, details, activeId } = this.state;

    return (
      <section className="P-managment-media"> 
        {!details ? <div>
          <Table
            columnConfig={this.config}
            data={list}
            isFirstInit={isFirstInit}
            loaded={loaded}
            onRowClick={(row) => {
              // window.routerHistory.push(`/customers/sellers/details?id=${row.id}`);
            }}
          />
        </div> : <ManagementMediaDetails id={activeId}/>}
      </section >
    );
  }
}

export default ManagementMedia;