import * as React from 'react';
import HelperComponent from 'src/platform/classes/helper-component';
import { Formik, Form, Field, FormikValues, FormikErrors } from 'formik';
import Loader from 'src/platform/components/loader/loader';
import "./index.scss";
import Languages from 'src/platform/services/languages';
import { ICreateFaq } from 'src/platform/api/faq/faq-interface';
import FaqController from 'src/platform/api/faq/faq';
import Helper from 'src/platform/services/helper';
import { store } from 'react-notifications-component';

export interface IProps {
    closeModal: () => void,
    getList: () => void,
    id: number;
}
export interface IState {
    description: number,
    question_1: string,
    question_2: string,
    question_3: string,
    answer_1: string,
    answer_2: string,
    answer_3: string,
    position: number,
}

const {
    Question,
    Answer,
    Create,
    Edit,
    Cancel,
} = Languages.Translations;

class CreatePopup extends HelperComponent<IProps, IState> {

    componentDidMount() {
        if (this.props.id) {
            this.getById();
        }
    }

    state: IState = {
        description: 1,
        question_1: '',
        question_2: '',
        question_3: '',
        answer_1: '',
        answer_2: '',
        answer_3: '',
        position: null,
    }

    private createFaq = async (values: FormikValues, errors: FormikValues) => {

        const body: ICreateFaq = {
            position: values.position,
            translates: [
                {
                    languageName: "hy",
                    question: values.question_1.trim(),
                    answer: values.answer_1,
                },
                {
                    languageName: "ru",
                    question: values.question_2.trim(),
                    answer: values.answer_2,
                },
                {
                    languageName: "en",
                    question: values.question_3.trim(),
                    answer: values.answer_3,
                }
            ],
        }
        if (this.props.id) {
            const res = await FaqController.updateFaq(body, this.props.id);
            if (res.success) {
                this.props.closeModal();
                this.props.getList();
            } else {
                store.addNotification(Helper.notificationConfig(res.message, false));
            }
        } else {
            const res = await FaqController.createFaq(body);
            if (res.success) {
                this.props.closeModal();
                this.props.getList();
            } else {
                store.addNotification(Helper.notificationConfig(res.message, false));
            }
        }
    }

    private getById = async () => {

        window.dispatchEvent(new CustomEvent('loader', { detail: true }));
        const res = await FaqController.getById(this.props.id);
        window.dispatchEvent(new CustomEvent('loader', { detail: false }));
        if (res.success) {
            this.asyncSetState({
                question_1: res.data.translates.find((item: any) => item.languageName === "hy").question,
                question_2: res.data.translates.find((item: any) => item.languageName === "ru").question,
                question_3: res.data.translates.find((item: any) => item.languageName === "en").question,
                answer_1: res.data.translates.find((item: any) => item.languageName === "hy").answer,
                answer_2: res.data.translates.find((item: any) => item.languageName === "ru").answer,
                answer_3: res.data.translates.find((item: any) => item.languageName === "en").answer,
                position: res.data.position,
            })
        }
    }

    onSubmit = (errors: FormikErrors<FormikValues>) => {
        if (!errors.question_1 && errors.question_2) {
            this.asyncSetState({ description: 2 });
        }
        if (!errors.question_1 && !errors.question_2 && errors.question_3) {
            this.asyncSetState({ description: 3 });
        }
    }

    render() {
        const { description } = this.state;
        const { id } = this.props;

        return (<div className="P-create-popup">
            <h5 className="G-mb-3 P-title">{id ? Edit : Create}</h5>
            <Formik enableReinitialize
                initialValues={{
                    question_1: this.state.question_1,
                    question_2: this.state.question_2,
                    question_3: this.state.question_3,
                    answer_1: this.state.answer_1,
                    answer_2: this.state.answer_2,
                    answer_3: this.state.answer_3,
                    position: this.state.position,
                }}

                onSubmit={this.createFaq}
            >
                {({ errors, touched, isSubmitting, values, setFieldValue }) => (
                    <Form noValidate={true}>
                        <div className="G-mb-2 G-flex G-justify-between">
                            <span className="P-text G-bold"></span>
                            <div>
                                <span onClick={() => this.asyncSetState({ description: 1 })}
                                    className={`G-mb-2 G-mr-1 P-lang G-cursor ${description === 1 && "P-green"}`}>ARM</span>
                                <span onClick={() => this.asyncSetState({ description: 2 })}
                                    className={`G-mb-2 G-mr-1 P-lang G-cursor ${description === 2 && "P-green"}`}>RU</span>
                                <span onClick={() => this.asyncSetState({ description: 3 })}
                                    className={`G-mb-2 G-mr-1 P-lang G-cursor ${description === 3 && "P-green"}`}>ENG</span>
                            </div>
                        </div>
                        {description === 1 ? <div className="P-am">
                            <Field
                                name="question_1"
                                placeholder={Question}
                                className={`G-mb-2 P-input ${touched.question_1 && errors.question_1 ? "G-error" : ""}`}
                                type="text" />
                            <Field
                                component="textarea"
                                name="answer_1"
                                placeholder={Answer}
                                className={`G-textarea G-mb-3 P-input ${touched.answer_1 && errors.answer_1 ? "G-error" : ""}`}
                                cols={1}
                                rows={1}
                            />
                        </div> : null}
                        {description === 2 ?
                            <div className="P-ru">
                                <Field
                                    name="question_2"
                                    placeholder={Question}
                                    className={`G-mb-2 P-input ${touched.question_2 && errors.question_2 ? "G-error" : ""}`}
                                    type="text" />
                                <Field
                                    component="textarea"
                                    name="answer_2"
                                    placeholder={Answer}
                                    className={`G-textarea G-mb-3 P-input ${touched.answer_2 && errors.answer_2 ? "G-error" : ""}`}
                                    cols={1}
                                    rows={1}
                                />
                            </div> : null}
                        {description === 3 ?
                            <div className="P-en">
                                <Field
                                    name="question_3"
                                    placeholder={Question}
                                    className={`G-mb-2 P-input ${touched.question_3 && errors.question_3 ? "G-error" : ""}`}
                                    type="text" />
                                <Field
                                    component="textarea"
                                    name="answer_3"
                                    placeholder={Answer}
                                    className={`G-textarea G-mb-3 P-input ${touched.answer_3 && errors.answer_3 ? "G-error" : ""}`}
                                    cols={1}
                                    rows={1}
                                />
                            </div> : null}
                        <Field
                            name="position"
                            placeholder={'position'}
                            className={`G-mb-2 P-input ${touched.question_1 && errors.question_1 ? "G-error" : ""}`}
                            type="number" />
                        <div className="G-flex G-justify-between">
                            <button className="G-mr-2 P-cancel" onClick={this.props.closeModal}>{Cancel}</button>
                            <button className="G-button P-button" type="submit"
                                onClick={() => this.onSubmit(errors)}>
                                {isSubmitting ? <Loader /> : `${id ? Edit : Create}`}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>);
    }
}

export default CreatePopup;