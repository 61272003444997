import * as React from 'react';
import HelperComponent from 'src/platform/classes/helper-component';
import { statusOptions, userTypeNotificationsOptions } from 'src/platform/constants/casual';
import { Formik, Form, Field, FormikValues, FormikErrors } from 'formik';
import * as Yup from 'yup';
import NotificationController from 'src/platform/api/notification/notification';
import UserController from 'src/platform/api/user/user';
import { store } from 'react-notifications-component';
import Loader from 'src/platform/components/loader/loader';
import Helper from 'src/platform/services/helper';
import { ISendNotif } from 'src/platform/api/notification/notification-interface';
import { AuditoryTypeEnum, CustomerTypeEnum, PushTypeEnum, SearchUserTypeEnum } from 'src/platform/enums/enums';
import "./index.scss";
import Languages from 'src/platform/services/languages';
import Radio from 'src/platform/components/radio';
import DoubleDropDown from 'src/platform/components/double-drop-down/DoubleDropDown';
import { IDoubleDropDownOption } from 'src/platform/interfaces';
import { ISearchUserListResponseModel } from 'src/platform/api/user/user-interface';
import Table, { ITableColumnConfig } from 'src/platform/components/table/table';
import Paging from 'src/platform/components/pagination/pagination';
// import CheckBox from 'rc-checkbox';
import MultiSelect from 'src/platform/components/multi-select/MultiSelect';

export interface IProps {
    closeModal: () => void,
    getList: () => void,
}
export interface IState {
    description: number,
    customerTypeEnum: CustomerTypeEnum,
    selectedType: number;
    selectedUserId: number;
    searchMessage: string,
    orderCount: IDoubleDropDownOption,
    collectedPoint: IDoubleDropDownOption,
    page: number,
    size: number,
    userList: Array<ISearchUserListResponseModel>,
    loaded: boolean;
    isFirstInit: boolean;
    totalPages: number;
    selectedUser: number;
    statusList: Array<number>,
    userTypeList: Array<number>,
    auditoryType: AuditoryTypeEnum,
}

const {
    Phone_number,
    Name,
    // Device_type,
    // Seller_type,
    // User_,
    Notification_title,
    Notification_message,
    Description,
    Send_notification,
    Search,
    Cancel,
    Collected_point,
    Order_count,
    Create_push_notifications,
    Push_notification_was_sent,
    Segment,
    User_type,
    All_users,
    Search_for_specific_user,
} = Languages.Translations;

class CreatePopup extends HelperComponent<IProps, IState> {

    state: IState = {
        userList: [],
        loaded: false,
        isFirstInit: false,
        totalPages: null,
        selectedUser: null,
        selectedUserId: null,
        description: 1,
        customerTypeEnum: CustomerTypeEnum.Individual,
        selectedType: 1,
        page: 0,
        size: 10,
        searchMessage: '',
        statusList: [],
        userTypeList: [],
        auditoryType: null,
        orderCount: {
            operationEnumValue: null,
            value: null,
        },
        collectedPoint: {
            operationEnumValue: null,
            value: null,
        },
    }

    public config: Array<ITableColumnConfig<ISearchUserListResponseModel>> = [
        {
            name: `${Name}`,
            cell: (row: ISearchUserListResponseModel) => {
                return (
                    <div className="G-cursor G-flex G-align-center">
                        <span className="G-flex G-flex-wrap G-break-word">
                            {row.fullName}
                        </span>
                    </div>
                );
            },
            style: { minWidth: 200, maxWidth: 230 },
        },
        {
            name: `${Phone_number}`,
            cell: (row: ISearchUserListResponseModel) => {
                return (
                    <div className="G-cursor">
                        {row.phoneNumber}
                    </div>)
            },
            style: { marginRight: "2%", minWidth: 100 },
        },
        {
            name: `customerType`,
            cell: (row: ISearchUserListResponseModel) => {
                return (
                    <div className="G-cursor">
                        {SearchUserTypeEnum[row.customerType]}
                    </div>)
            },
            style: { marginRight: "1%" },
        },
        {
            name: "",
            cell: (row: ISearchUserListResponseModel) => {
                return (<div>
                <Radio callback={(value: number) => { this.setState({selectedUserId: value}) }} value={row.id} isChecked={this.state.selectedUserId === row.id}>
                    <span></span></Radio>
                </div>);
            },
            style: { marginLeft: "auto", width: 50 },
        },
    ]

    // checked(value: number) {
    //     this.setState((prevState) => {
    //         return { selectedUserId: value }
    //     });
    //     console.log(value, 'value');
    //     console.log(this.state.selectedUserId, 'selectedUserId');
    // }

    public validationSchema = Yup.object().shape({
        // userType: Yup.string().required(),
        // sellerType: Yup.string().when("userType", {
        //     is: (val: string) => {
        //         return val === "1"
        //     },
        //     then: Yup.string().required(),
        // }),
        // deviceType: Yup.number().min(1).required(),
        notifTitle_1: Yup.string().min(1).required().trim(),
        notifTitle_2: Yup.string().min(1).required().trim(),
        notifTitle_3: Yup.string().min(1).required().trim(),
        notifMessage_1: Yup.string().min(5).required().trim(),
        notifMessage_2: Yup.string().min(5).required().trim(),
        notifMessage_3: Yup.string().min(5).required().trim(),
    })

    private sendNotif = async (values: FormikValues, errors: FormikValues) => {
        const body: ISendNotif = {
            notificationTranslates: [
                {
                    languageName: "hy",
                    message: values.notifMessage_1.trim(),
                    title: values.notifTitle_1,
                },
                {
                    languageName: "ru",
                    message: values.notifMessage_2.trim(),
                    title: values.notifTitle_2,
                },
                {
                    languageName: "en",
                    message: values.notifMessage_3.trim(),
                    title: values.notifTitle_3,
                }
            ],
            segment: {
                searchCriteriaOrderCount: this.state.orderCount,
                searchCriteriaBalance: this.state.collectedPoint,
                customerTypes: this.state.userTypeList,
                orderStatuses: this.state.statusList,
            },
            auditoryType: this.state.selectedType,
            userId: this.state.selectedUserId,
        }
        

        const res = await NotificationController.sendNotification(body);
        if (res.success) {
            this.props.closeModal();
            this.props.getList();
            store.addNotification(Helper.notificationConfig(`${Push_notification_was_sent}`, true));
        } else {
            store.addNotification(Helper.notificationConfig(res.message, false));
        }
    }

    onSubmit = (errors: FormikErrors<FormikValues>) => {
        if (!errors.notifTitle_1 && errors.notifTitle_2) {
            this.asyncSetState({ description: 2 });
        }
        if (!errors.notifTitle_1 && !errors.notifTitle_2 && errors.notifTitle_3) {
            this.asyncSetState({ description: 3 });
        }
    }

    chooseType = (item: CustomerTypeEnum) => {
        this.setState({ selectedUserId: null })
        this.setState({ selectedType: item })
    }

    public inputTimer: any;
    public onInput = async (evt: React.SyntheticEvent<HTMLInputElement>) => {
        await this.asyncSetState({ searchMessage: evt.currentTarget.value })
        clearTimeout(this.inputTimer);
        this.inputTimer = setTimeout(async () => {
            this.searchUser();
        }, 300);
    }

    public setOperatorValue = (key: string, value: any) => {
        this.state[key].operationEnumValue = value;
        this.asyncSetState({ [key]: this.state[key] }).then(() => {
        });
    }

    public setDoubleDropdownInputValue = async (key: string, value: any) => {
        this.state[key].value = value;
        this.asyncSetState({ [key]: this.state[key] })
            .then(() => {
                clearTimeout(this.inputTimer);
                this.inputTimer = setTimeout(async () => {
                }, 300);
            });
    }

    public searchUser = async (pageNum?: { selected: number }) => {
        const { searchMessage, page, size, } = this.state;
        const body = {
            page: pageNum ? pageNum.selected : page,
            size,
        }
        const res = await UserController.searchUsers(body, searchMessage);
        if (res.success) {
            this.asyncSetState({
                totalPages: res.data.totalPages,
                userList: res.data.content,
            })
        }
    }

    public onSelect = async (arr: Array<number>) => {
        await this.asyncSetState({ statusList: arr });
    }

    public onSelectType = async (arr: Array<number>) => {
        await this.asyncSetState({ userTypeList: arr });
    }

    render() {
        const { description, selectedType, searchMessage, orderCount, collectedPoint, userList, isFirstInit, loaded, totalPages, statusList, userTypeList } = this.state;

        return (<div className="P-create-popup P-notification-modal">
            <h5 className="G-mb-3 P-title">{Create_push_notifications}</h5>
            <Formik
                initialValues={{
                    notifTitle_1: "",
                    notifTitle_2: "",
                    notifTitle_3: "",
                    notifMessage_1: "",
                    notifMessage_2: "",
                    notifMessage_3: "",
                    userType: "",
                    customerTypes: "",
                    orderStatuses: "",
                    orderCount: "",
                    collectedPoint: "",
                }}

                onSubmit={this.sendNotif}
                validationSchema={this.validationSchema}
            >
                {({ errors, touched, isSubmitting, values, setFieldValue }) => (
                    <Form noValidate={true}>
                        <div className="G-flex G-justify-between">
                            <span className="G-mb-2 P-text G-bold">{Description}</span>
                            <div>
                                <span onClick={() => this.asyncSetState({ description: 1 })}
                                    className={`G-mb-2 G-mr-1 P-lang G-cursor ${description === 1 && "P-green"}`}>ARM</span>
                                <span onClick={() => this.asyncSetState({ description: 2 })}
                                    className={`G-mb-2 G-mr-1 P-lang G-cursor ${description === 2 && "P-green"}`}>RU</span>
                                <span onClick={() => this.asyncSetState({ description: 3 })}
                                    className={`G-mb-2 G-mr-1 P-lang G-cursor ${description === 3 && "P-green"}`}>ENG</span>
                            </div>
                        </div>
                        {description === 1 ? <div className="P-am">
                            <Field
                                name="notifTitle_1"
                                placeholder={Notification_title}
                                className={`G-mb-2 P-input ${touched.notifTitle_1 && errors.notifTitle_1 ? "G-error" : ""}`}
                                type="text" />
                            <Field
                                component="textarea"
                                name="notifMessage_1"
                                placeholder={Notification_message}
                                className={`G-textarea G-mb-3 P-input ${touched.notifMessage_1 && errors.notifMessage_1 ? "G-error" : ""}`}
                                cols={1}
                                rows={1}
                            />
                        </div> : null}
                        {description === 2 ?
                            <div className="P-ru">
                                <Field
                                    name="notifTitle_2"
                                    placeholder={Notification_title}
                                    className={`G-mb-2 P-input ${touched.notifTitle_2 && errors.notifTitle_2 ? "G-error" : ""}`}
                                    type="text" />
                                <Field
                                    component="textarea"
                                    name="notifMessage_2"
                                    placeholder={Notification_message}
                                    className={`G-textarea G-mb-3 P-input ${touched.notifMessage_2 && errors.notifMessage_2 ? "G-error" : ""}`}
                                    cols={1}
                                    rows={1}
                                />
                            </div> : null}
                        {description === 3 ?
                            <div className="P-en">
                                <Field
                                    name="notifTitle_3"
                                    placeholder={Notification_title}
                                    className={`G-mb-2 P-input ${touched.notifTitle_3 && errors.notifTitle_3 ? "G-error" : ""}`}
                                    type="text" />
                                <Field
                                    component="textarea"
                                    name="notifMessage_3"
                                    placeholder={Notification_message}
                                    className={`G-textarea G-mb-3 P-input ${touched.notifMessage_3 && errors.notifMessage_3 ? "G-error" : ""}`}
                                    cols={1}
                                    rows={1}
                                />
                            </div> : null}
                        <div className="G-mb-6">

                            <div className="G-flex G-mb-2">
                                <Radio callback={(value: number) => { this.chooseType(value) }} value={PushTypeEnum.Segment} isChecked={selectedType === PushTypeEnum.Segment}>
                                    <span>{Segment}</span>
                                </Radio>
                                <Radio callback={(value: number) => { this.chooseType(value) }} value={PushTypeEnum.SearchUser} isChecked={selectedType === PushTypeEnum.SearchUser}>
                                    <span>{Search_for_specific_user}</span>
                                </Radio>
                                <Radio callback={(value: number) => { this.chooseType(value) }} value={PushTypeEnum.All} isChecked={selectedType === PushTypeEnum.All}>
                                    <span>{All_users}</span>
                                </Radio>
                            </div>
                            {selectedType === PushTypeEnum.Segment ? <>
                                <div className="G-flex">
                                    <div className="G-mr-2">
                                        <DoubleDropDown placeholder={Order_count}
                                            property="orderCount"
                                            operatorValue={orderCount.operationEnumValue}
                                            inputValue={orderCount.value}
                                            setDoubleDropdownInputValue={this.setDoubleDropdownInputValue}
                                            setOperatorValue={this.setOperatorValue} />
                                    </div>
                                    <div className="G-mr-2">
                                        <DoubleDropDown placeholder={Collected_point}
                                            property="collectedPoint"
                                            operatorValue={collectedPoint.operationEnumValue}
                                            inputValue={collectedPoint.value}
                                            setDoubleDropdownInputValue={this.setDoubleDropdownInputValue}
                                            setOperatorValue={this.setOperatorValue} />
                                    </div>
                                </div>
                                <div className="G-flex">
                                    <div className="G-mr-2">
                                        <MultiSelect
                                            placeholder={User_type}
                                            name="userType"
                                            height="45px"
                                            width="300px"
                                            setFieldValue={this.onSelect}
                                            value={statusList}
                                            options={userTypeNotificationsOptions} />
                                        {/* <Select
                                            width="300px"
                                            name="userType"
                                            placeholder={User_type}
                                            options={userTypeNotificationsOptions}
                                            value={values.userType}
                                            setFieldValue={(field: string, value: string) => {
                                                setFieldValue("userType", value);
                                                if (Number(value) !== UserTypeEnum.Sellers) {
                                                    setFieldValue("sellerType", "");
                                                }
                                            }}
                                            className={` ${touched.userType && errors.userType ? "G-error" : ""}`}
                                        /> */}
                                    </div>
                                    {/* <div className={values.userType ===  1 ? "P-code" : "P-type"}>
                                        <Select
                                            width="100%"
                                            name="sellerType"
                                            placeholder={Seller_type}
                                            options={sellerTypeOptions}
                                            value={values.sellerType}
                                            setFieldValue={(field: string, value: string) => setFieldValue( "sellerType", value )}
                                            className={`G-mb-2 ${touched.sellerType && errors.sellerType ? "G-error" : ""}`}
                                        />
                                    </div> */}
                                    <MultiSelect
                                        name="userType"
                                        height="45px"
                                        width="300px"
                                        setFieldValue={this.onSelectType}
                                        value={userTypeList}
                                        options={statusOptions} />
                                    {/* <Select
                                        width="300px"
                                        name="orderStatuses"
                                        placeholder={Status}
                                        options={statusOptions}
                                        value={values.orderStatuses}
                                        setFieldValue={(field: string, value: string) => setFieldValue("orderStatuses", value)}
                                        className={`${touched.deviceType && errors.deviceType ? "G-error" : ""}`}
                                    /> */}
                                </div>
                            </> : selectedType === PushTypeEnum.SearchUser ?
                                <>
                                    <input className="G-text-input  G-mr-1 G-mb-2"
                                        placeholder={Search}
                                        value={searchMessage}
                                        onChange={this.onInput}
                                        type="text" />

                                    <Table columnConfig={this.config}
                                        data={userList}
                                        isFirstInit={isFirstInit}
                                        loaded={loaded}
                                    />
                                    <Paging
                                        totalPages={totalPages}
                                        onChange={this.searchUser}
                                    />

                                    {/* <div className="P-wrap-list">
                                        <div className="P-list-header">
                                            <p>123</p>
                                            <p>123</p>
                                            <p>123</p>
                                            <p>123</p>
                                        </div>
                                        <div className="P-list">
                                            <div className="P-item">
                                                <p>123</p>
                                                <p>123</p>
                                                <p>123</p>
                                                <p>123</p>
                                                <input className="G-cursor P-checkmark" type="checkbox"
                                                    onClick={() => this.checked(1)} defaultChecked={true} />
                                            </div>
                                        </div>
                                    </div> */}
                                </>
                                : ''}
                        </div>
                        <div className="G-flex G-justify-between">
                            <button className="G-mr-2 P-cancel" onClick={this.props.closeModal}>{Cancel}</button>
                            <button className="G-button P-button" type="submit"
                                onClick={() => this.onSubmit(errors)}>
                                {isSubmitting ? <Loader /> : `${Send_notification}`}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>);
    }
}

export default CreatePopup;