import { IResponse } from '../../interfaces';
import { BaseController } from "../../classes/http-service";
import { ICreateFaq, IGetLanguageListResponse, IGetPagesListResponse } from './cm-interface';


class ContextManagementController extends BaseController {
  private controller = 'cm';

  public createFaq = async (form: ICreateFaq): Promise<IResponse<any>> => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: ``,
      body: form,
    });

    return result;
  };
  
  public updateFaq = async (form: ICreateFaq, id: number): Promise<IResponse<any>> => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: `${id}`,
      body: form,
    });

    return result;
  };

  public deleteFaq = async (id: number): Promise<IResponse<any>> => {
    const result = await this.connection.DELETE({
      controller: this.controller,
      action: `${id}`,
      body: null
    });

    return result;
  };

  public getList = async (): Promise<IResponse<IGetLanguageListResponse>> => {
    const result = await this.connection.GET({
      controller: this.controller,
      action: `getLanguages`,
    });

    return result;
  };
  
  // public add = async (): Promise<IResponse<any>> => {
  //   const result = await this.connection.POST({
  //     controller: this.controller,
  //     action: `addPage`,
  //     body: {
  //       pageName: "Contact us",
  //       pageSlug: "contact_us"
  //     },
  //   });

  //   return result;
  // };
  
  public getPages = async (): Promise<IResponse<IGetPagesListResponse>> => {
    const result = await this.connection.GET({
      controller: this.controller,
      action: `getAllPages`,
    });

    return result;
  };
  
  public getMediaById = async (pageId: number): Promise<IResponse<any>> => {
    const result = await this.connection.GET({
      controller: this.controller,
      action: `getPageMediasForAdmin/${pageId}`,
    });

    return result;
  };
  
  public uploadPageMedias = async (pageId: number, form: FormData): Promise<IResponse<any>> => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: `uploadPageMedias/${pageId}`,
      body: form,
      noneJSONBody: true,
    });

    return result;
  };

}
export default new ContextManagementController();
