import * as React from 'react';
import Modal from 'react-modal';
import Table, { ITableColumnConfig } from 'src/platform/components/table/table';
import { byPrivateRoute, bySidebar } from 'src/platform/decorators/routes';
import { ROUTES } from 'src/platform/constants/routes';
import Select from 'src/platform/components/select/Select';
import HelperComponent from 'src/platform/classes/helper-component';
import { deviceTypeOptions, userTypeOptionsPushNotifications, accountTypeOptions } from 'src/platform/constants/casual';
import CreatePopup from './create/createPopup';
import { DeviceTypeEnum, UserTypeEnum, AccountTypeEnum, SearchUserTypeEnum } from 'src/platform/enums/enums';
import { IGetListResponse, IGetListRequest } from 'src/platform/api/notification/notification-interface';
import Helper from 'src/platform/services/helper';
import NotificationController from 'src/platform/api/notification/notification';
import { store } from 'react-notifications-component';
import Paging from 'src/platform/components/pagination/pagination';
import DatePicker from "react-datepicker";
import './index.scss';
import moment from 'moment';
import Languages from 'src/platform/services/languages';

interface IState {
  userType: UserTypeEnum,
  accountType: AccountTypeEnum,
  deviceType: DeviceTypeEnum,
  searchMessage: string,
  modalIsOpenIndex: number,
  list: Array<any>,
  details: any,
  page: number,
  size: number,
  totalPages: number,
  totalElements: number,
  loaded: boolean,
  isFirstInit: boolean,
  date: Date,
  description: number,
}

const {
  Reset,
  Account_type,
  Sent_date,
  Close,
  Message,
  User_type,
  Device_type,
  Date_,
  Create,
  Search_message,
  Push_notifications,
  Audience_count,
} = Languages.Translations;

@bySidebar(`${Push_notifications}`, "icon-ic_notifications")
@byPrivateRoute(ROUTES.pushNotifications)
class PushNotifications extends HelperComponent<{}, IState> {

  componentDidMount() {
    this.getNotifList();
  }

  public state: IState = {
    userType: null,
    deviceType: null,
    searchMessage: "",
    modalIsOpenIndex: null,
    list: [],
    details: null,
    page: 0,
    size: 6,
    totalPages: null,
    totalElements: null,
    loaded: false,
    accountType: null,
    isFirstInit: true,
    date: null,
    description: 3,
  }

  public config: Array<ITableColumnConfig<IGetListResponse>> = [
    {
      name: `${Message}`,
      style: { minWidth: 160, maxWidth: 550, marginRight: '2%' },
      cell: function (row: IGetListResponse) {
        return (<div className="G-mt-1 G-mb-1 G-mr-1">
          <p className="P-title G-break-word">{row.notificationTranslatePreviewDtos[0].title}</p>
          <p className="P-text G-break-word">{Helper.sliceSentence(row.notificationTranslatePreviewDtos[0].message, 110)}</p>
        </div>);
      },
    },
    {
      name: `${User_type}`,
      style: { minWidth: 160 },
      cell: function (row: IGetListResponse) {
        return (
          <div className="G-ml-1">{SearchUserTypeEnum[row.customerType]}
            {/* {UserTypeEnum.Sellers === row.userTypeValue ?
              `${enums.AccountTypeEnum[row.accountTypeValue]} ${enums.UserTypeEnum[row.userTypeValue].toLowerCase()}`
              : enums.UserTypeEnum[row.userTypeValue]} */}
          </div>
        );
      },
    },
    // {
    //   name: `${Device_type}`,
    //   style: { minWidth: 130, maxWidth: 130, marginRight: '2%' },
    //   cell: function (row: IGetListResponse) {
    //     return <div>{DeviceTypeEnum[row.osTypeValue]}</div>;
    //   },
    // },
    {
      name: `${Date_}`,
      style: { minWidth: 170, marginRight: '2%' },
      cell: function (row: IGetListResponse) {
        return <div>{moment(row.createdDate).format('DD MMM YYYY HH:mm')}</div>;
      },
    },
    {
      name: `${Audience_count}`,
      style: { minWidth: 130, maxWidth: 130 },
      cell: function (row: IGetListResponse) {
        return row.userCount;
      },
    },
  ];

  public openModal = (index: number) => {
    this.asyncSetState({ modalIsOpenIndex: index });
  }

  public closeModal = () => {
    this.asyncSetState({ modalIsOpenIndex: null });
  }

  public reset = () => {
    this.asyncSetState({
      totalPages: 0,
      searchMessage: "",
      accountType: null,
      userType: null,
      deviceType: null,
      date: null,
    }).then(() => {
      this.getNotifList();
    });
  }

  public inputTimer: any;

  public onInput = async (evt: React.SyntheticEvent<HTMLInputElement>) => {
    await this.asyncSetState({ searchMessage: evt.currentTarget.value })
    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(async () => {
      this.getNotifList();
    }, 300);
  }


  private getNotifList = async (pageNum?: { selected: number }) => {
    const { userType, deviceType, searchMessage, page, size, accountType, date } = this.state;
    let dateLong;

    if (date) {
      dateLong = date.getTime();
    }
    const body: IGetListRequest = {
      page: pageNum ? pageNum.selected : page,
      size,
      form: {
        accountType,
        osType: deviceType,
        text: searchMessage,
        userType,
        date: dateLong,
      }
    }

    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const res = await NotificationController.getList(body);
    if (res.success) {
      this.setState({
        loaded: true,
        isFirstInit: this.state.isFirstInit && !res.data.content.length
      })
    }
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
    if (res.success) {
      this.asyncSetState({
        list: res.data.content,
        totalPages: res.data.totalPages,
        totalElements: res.data.totalElements
      })
    } else {
      store.addNotification(Helper.notificationConfig(res.message, false));
    }

  }
  
  downloadNotifications = async () => {
    const { userType, deviceType, searchMessage, accountType, date } = this.state;
    let dateLong;
    if (date) {
      dateLong = date.getTime();
    }
    const body = {
        accountType,
        osType: deviceType,
        text: searchMessage,
        userType,
        date: dateLong,
      }
    const result = await NotificationController.downloadNotifications(body);
    if (result.data) {
      window.open(result.data, '_blank');
    }
  }

  public render() {
    const { userType,
      deviceType,
      totalPages,
      totalElements,
      modalIsOpenIndex,
      searchMessage,
      list, details,
      loaded,
      accountType,
      date,
      isFirstInit,
      description } = this.state;
    return (
      <>
        <section className="G-main-page P-push-notifications">
          <h4 className="G-mb-2">{Push_notifications} ({totalElements})</h4>
          <div className="G-flex G-justify-between">
            <div className="G-flex G-flex-wrap P-selects-wrapper">
              <input className="G-text-input  G-mr-1 G-mb-2"
                placeholder={Search_message}
                value={searchMessage}
                onChange={this.onInput}
                type="text" />
              <Select
                placeholder={User_type}
                className="G-mr-1"
                options={userTypeOptionsPushNotifications}
                value={userType}
                setFieldValue={(field: string, value: string) =>
                  this.asyncSetState({ userType: Number(value) }).then(() => {
                    this.getNotifList();
                  })} />
              {userType === UserTypeEnum.Sellers ?
                <Select
                  placeholder={Account_type}
                  className="G-mr-1"
                  options={accountTypeOptions}
                  value={accountType}
                  setFieldValue={(field: string, value: string) => this.asyncSetState({ accountType: Number(value) }).then(() => {
                    this.getNotifList();
                  })} /> : null}
              <Select
                placeholder={Device_type}
                options={deviceTypeOptions}
                className="G-mr-1"
                value={deviceType}
                setFieldValue={(field: string, value: string) => this.asyncSetState({ deviceType: Number(value) }).then(() => {
                  this.getNotifList();
                })} />
              <div className="G-mr-1" >
                <DatePicker
                  selected={date}
                  placeholderText={Sent_date}
                  onChange={(date: Date) => this.asyncSetState({ date }).then(() => {
                    this.getNotifList();
                  })}
                  className={`G-text-input G-cursor G-mb-3 P-input`}
                />
              </div>
                <span className=" G-button P-button P-reset" onClick={this.reset}>{Reset}</span>
          </div>
                <div className="G-flex">
                  <button className="G-gray-btn" onClick={this.downloadNotifications}>Export</button>
                  <button className="G-button P-button G-ml-2" onClick={() => this.openModal(1)}>{Create}</button>
                </div>
          </div>
          <Table
            columnConfig={this.config}
            onRowClick={(row: any) => {
              this.openModal(2);
              this.asyncSetState({ details: row })
            }}
            isFirstInit={isFirstInit}
            loaded={loaded}
            data={list} />
          <Paging
            totalPages={totalPages}
            onChange={this.getNotifList}
          />
        </section >
        <Modal
          isOpen={modalIsOpenIndex === 1}
          onRequestClose={this.closeModal}
          style={{
            content: {
              padding: "30px",
              minHeight: "500px",
              width: "100%",
              maxWidth: "700px",
              display: "flex",
              flexDirection: "column",
            },
          }}>
          <CreatePopup closeModal={this.closeModal} getList={this.getNotifList} />
        </Modal>
        <Modal
          isOpen={modalIsOpenIndex === 2}
          onRequestClose={this.closeModal}
          style={{
            content: {
              padding: "30px",
              minHeight: "350px",
              width: "100%",
              maxWidth: "700px",
              display: "flex",
              flexDirection: "column",
            },
          }}>
          <div className="G-column P-push-details">
            <div className="G-ml-auto">
              <span onClick={() => this.asyncSetState({ description: 1 })}
                className={`G-mb-2 G-mr-1 P-lang G-cursor ${description === 1 && "P-green"}`}>ARM</span>
              <span onClick={() => this.asyncSetState({ description: 2 })}
                className={`G-mb-2 G-mr-1 P-lang G-cursor ${description === 2 && "P-green"}`}>RU</span>
              <span onClick={() => this.asyncSetState({ description: 3 })}
                className={`G-mb-2 G-mr-1 P-lang G-cursor ${description === 3 && "P-green"}`}>ENG</span>
            </div>
            {description === 1 ?
              <>
                <h3 className="G-mb-3 G-break-word">
                  {details && details.notificationTranslatePreviewDtos[0].title}
                </h3>
                <p className="G-break-word">{details && details.notificationTranslatePreviewDtos[0].message}</p>
              </> : null}
            {description === 2 ?
              <>
                <h3 className="G-mb-3 G-break-word">
                  {details && details.notificationTranslatePreviewDtos[1].title}
                </h3>
                <p className="G-break-word">{details && details.notificationTranslatePreviewDtos[1].message}</p>
              </> : null}
            {description === 3 ?
              <>
                <h3 className="G-mb-3 G-break-word">
                  {details && details.notificationTranslatePreviewDtos[2].title}
                </h3>
                <p className="G-break-word">{details && details.notificationTranslatePreviewDtos[2].message}</p>
              </> : null}
          </div>
          <button className="G-mr-2 P-cancel P-popup-btn" onClick={this.closeModal}>{Close}</button>
        </Modal>
      </>
    );
  }

}

export default PushNotifications;