import * as React from 'react';
import Table, { ITableColumnConfig } from 'src/platform/components/table/table';
import { OrderAgentStatusEnum } from 'src/platform/enums/enums';
import HelperComponent from 'src/platform/classes/helper-component';
import { RouteComponentProps } from 'react-router';
import Paging from 'src/platform/components/pagination/pagination';
import noPic from 'src/assets/images/no-photo.png';
import moment from 'moment';
import './index.scss';
import Languages from 'src/platform/services/languages';

interface IProps extends RouteComponentProps {
  deliveryHistoryList: Array<any>,
  getDeliveryHistoryList: (pageNum?: { selected: number }) => void,
  loaded: boolean;
  totalPages: number,
  isFirstInit: boolean,
  pageForPagination?: number,
  openModal: (index: number) => void,
}

interface IState {
  deliveryHistoryList: Array<any>,
}

const {
  Order_,
  User_,
  Address,
  Cardboard_weight,
  Creation_date,
  Status,
  Available_Time,
  enums,
  Any_time,
  AMD,
} = Languages.Translations

class DeliveryHistory extends HelperComponent<IProps, IState> {

  public state: IState = {
    deliveryHistoryList: this.props.deliveryHistoryList,
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.deliveryHistoryList !== prevProps.deliveryHistoryList) {
      this.asyncSetState({ deliveryHistoryList: this.props.deliveryHistoryList });
    }
  }

  public inputTimer: any;

  public config: Array<ITableColumnConfig<any>> = [
    {
      name: `${Order_} #`,
      cell: (row: any) => {
        return (<div className="G-cursor"
        >
          {row.orderNumber}
        </div>);
      },
      style: { marginRight: "2%", maxWidth: 130 },
    },
    {
      name: `${User_}`,
      cell: (row: any) => {
        return (<div className="G-cursor G-flex G-align-center"
        onClick={
          (evt: React.SyntheticEvent) => {
              evt.stopPropagation();
              if (row.userTypeValue === 2) {
                this.props.history.push(`/customers/agents/details?id=${row.agentPreviewDto.buyerId}`);
              } else this.props.history.push(`/customers/sellers/details?id=${row.agentPreviewDto.buyerId}`);
          }
        }>
          <span className="P-image G-mr-4 G-mr-1"
            style={{ background: `url(${(row.agentPreviewDto && row.agentPreviewDto.buyerImageUrl) ? `${row.agentPreviewDto.buyerImageUrl}/100/100` : noPic}) center/cover no-repeat` }}>
          </span>
          <span className="G-break-word">
            {row.agentPreviewDto ? row.agentPreviewDto.buyerFullName : ""}
          </span>
        </div>);
      },
      style: { marginRight: "2%", minWidth: 180, maxWidth: 220 },
    },
    {
      name: `${Address}`,
      cell: (row: any) => {

        return (<div className="G-cursor G-flex G-align-center"
        >
          {`${row.addressForOrderDto && row.addressForOrderDto.addressName},
          ${row.addressForOrderDto.apartment ? row.addressForOrderDto.apartment + ' app,' : ''}
          ${row.addressForOrderDto.building ? row.addressForOrderDto.building + ' building,' : ''} 
          ${row.addressForOrderDto.entrance ? row.addressForOrderDto.entrance + ' entrance,' : ''} 
          ${row.addressForOrderDto.floor ? row.addressForOrderDto.floor + ' floor' : ''}`}
        </div>);
      },
      style: { marginRight: "2%", minWidth: 180, maxWidth: 220 },
    },
    {
      name: `${Cardboard_weight}`,
      cell: (row: any) => {
        return (<div className="G-cursor G-break-word"
        >
          {row.weight}
        </div>);
      },
      style: { marginRight: "2%", minWidth: 95, maxWidth: 120 },
    },
    {
      name: `${AMD}`,
      cell: (row: any) => {
        return (<div className="G-cursor G-break-word"
        >
          {row.amount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
        </div>);
      },
      style: { marginRight: "2%", maxWidth: 120 },
    },
    {
      name: `${Available_Time}`,
      cell: (row: any) => {
        return (<div className="G-cursor">
          {row.orderTimesDtos ? row.orderTimesDtos.map((item: any, index: any) => {
            let from = item.fromTime;
            let to = item.toTime;
            return (<div key={index} className='P-time G-mr-1'>
              {item.fromTime !== 0 ? <>
                {moment(new Date((from * 1000) - 14400000)).format('HH:mm')} - 
                {moment(new Date((to * 1000) - 14400000)).format('HH:mm')}</> : `${Any_time}`}
            </div>)
          }) : "-"}
        </div>);
      },
      style: { marginRight: "2%", minWidth: 105, maxWidth: 170 },
    },
    {
      name: Creation_date,
      cell: (row: any) => {
        return (<div className="G-cursor" >
          {moment(row.createdDate).format('DD MMM YYYY HH:mm')}
        </div>);
      },
      style: { marginRight: "2%", minWidth: 65 },
    },
    {
      name: `${Status}`,
      cell: (row: any) => {
        return (<div className="G-cursor"
          style={{
            color: `${
              row.orderBuyerStatusEnumValue === OrderAgentStatusEnum.CANCELED ||
                row.orderBuyerStatusEnumValue === OrderAgentStatusEnum.REJECTED
                ? '#FF0057' : "#04A556"}`
          }}>
          {enums.OrderAgentStatusEnum[row.orderBuyerStatusEnumValue]}
        </div>);
      },
      style: { maxWidth: 100, minWidth: 70 },
    },
  ];

  public render() {
    const { deliveryHistoryList } = this.state;
    const { totalPages, loaded, isFirstInit, pageForPagination, openModal } = this.props;

    return (
      <>
        <Table columnConfig={this.config}
          data={deliveryHistoryList}
          isFirstInit={isFirstInit}
          loaded={loaded}
          onRowClick={(row) => {
            openModal(row.orderId);
          }}
        />
        <Paging
          pageForPagination={pageForPagination}
          totalPages={totalPages}
          onChange={this.props.getDeliveryHistoryList}
        />
      </ >
    );
  }
}

export default DeliveryHistory;