import * as React from 'react';
import Table, { ITableColumnConfig } from 'src/platform/components/table/table';
import { OrderAgentStatusEnum } from 'src/platform/enums/enums';
import HelperComponent from 'src/platform/classes/helper-component';
import { RouteComponentProps } from 'react-router';
import Paging from 'src/platform/components/pagination/pagination';
import noPic from 'src/assets/images/no-photo.png'
import moment from 'moment';
import './index.scss';
import Languages from 'src/platform/services/languages';

interface IProps extends RouteComponentProps {
  selfDeliveryHistoryList: Array<any>,
  getSelfDeliveryHistoryList: (pageNum?: { selected: number }) => void,
  loaded: boolean;
  totalPages: number,
  isFirstInit: boolean,
  pageForPagination?: number,
  openModal: (index: number) => void,
}

interface IState {
  selfDeliveryHistoryList: Array<any>,
}

const {
  Order_,
  User_,
  Cardboard_weight,
  Creation_date,
  Status,
  enums,
  AMD,
} = Languages.Translations

class SelfDeliveryHistory extends HelperComponent<IProps, IState> {

  public state: IState = {
    selfDeliveryHistoryList: this.props.selfDeliveryHistoryList,
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.selfDeliveryHistoryList !== prevProps.selfDeliveryHistoryList) {
      this.asyncSetState({ selfDeliveryHistoryList: this.props.selfDeliveryHistoryList });
    }
  }

  public inputTimer: any;

  public config: Array<ITableColumnConfig<any>> = [
    {
      name: `${Order_} #`,
      cell: (row: any) => {
        return (<div className="G-cursor"
        >
          {row.orderNumber}
        </div>);
      },
      style: { marginRight: "2%", maxWidth: 130 },
    },
    {
      name: `${User_}`,
      cell: (row: any) => {
        return (<div className="G-cursor G-flex G-align-center"
        onClick={
          (evt: React.SyntheticEvent) => {
              evt.stopPropagation();
              if (row.userTypeValue === 2) {
                this.props.history.push(`/customers/agents/details?id=${row.agentPreviewDto.buyerId}`);
              } else this.props.history.push(`/customers/sellers/details?id=${row.agentPreviewDto.buyerId}`);
          }
        }>
          <span className="P-image G-mr-4 G-mr-1"
            style={{ background: `url(${(row.agentPreviewDto && row.agentPreviewDto.buyerImageUrl) ? `${row.agentPreviewDto.buyerImageUrl}/100/100` : noPic}) center/cover no-repeat` }}>
          </span>
          <span className="G-break-word">
            {row.agentPreviewDto ? row.agentPreviewDto.buyerFullName : ""}
          </span>
        </div>);
      },
      style: { marginRight: "2%", minWidth: 180, maxWidth: 220 },
    },
    {
      name: `${Cardboard_weight}`,
      cell: (row: any) => {
        return (<div className="G-cursor"
        >
          {row.weight}
        </div>);
      },
      style: { marginRight: "2%", minWidth: 95, maxWidth: 220 },
    },
    {
      name: `${AMD}`,
      cell: (row: any) => {
        return (<div className="G-cursor"
        >
          {row.amount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
        </div>);
      },
      style: { marginRight: "2%", maxWidth: 220 },
    },
    // {
    //   name: "AVAILABLE TIME",
    //   cell: (row: any) => {
    //     return (<div className="G-cursor"
    //     >
    //       {row.orderTimesDtos ? row.orderTimesDtos.map((item: any, index: any) => {
    //         let from = item.fromTime;
    //         let to = item.toTime;
    //         return (<div key={index} className='P-time G-mr-1'>
    //           {moment(new Date((from * 1000) - 14400000)).format('HH:mm')} - {moment(new Date((to * 1000) - 14400000)).format('HH:mm')}
    //         </div>)
    //       }) : "-"}
    //     </div>);
    //   },
    //   style: { marginRight: "2%", minWidth: 105 },
    // },
    {
      name: Creation_date,
      cell: (row: any) => {
        return (<div className="G-cursor" onClick={() => {
        }}>
          {moment(row.createdDate).format('DD MMM YYYY HH:mm')}
        </div>);
      },
      style: { marginRight: "2%", minWidth: 65, maxWidth: 200 },
    },
    {
      name: `${Status}`,
      cell: (row: any) => {
        return (<div className="G-cursor"
        style={{
          color: `${
            row.orderBuyerStatusEnumValue === OrderAgentStatusEnum.CANCELED ||
              row.orderBuyerStatusEnumValue === OrderAgentStatusEnum.REJECTED
              ? '#FF0057' : "#04A556"}`
        }}
        >
          {enums.OrderAgentStatusEnum[row.orderBuyerStatusEnumValue]}
        </div>)
      },
      style: { maxWidth: 70, minWidth: 70 },
    },
  ]

  public render() {
    const { selfDeliveryHistoryList, } = this.state;
    const { totalPages, loaded, isFirstInit, pageForPagination, openModal } = this.props;
    return (
      <>
        <Table
          columnConfig={this.config}
          data={selfDeliveryHistoryList}
          isFirstInit={isFirstInit}
          loaded={loaded}
          onRowClick={(row) => {
            openModal(row.orderId);
          }}
        />
        <Paging
          pageForPagination={pageForPagination}
          totalPages={totalPages}
          onChange={this.props.getSelfDeliveryHistoryList}
        />
      </>

    );
  }
}

export default SelfDeliveryHistory