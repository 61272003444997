import * as React from 'react';
import HelperComponent from 'src/platform/classes/helper-component';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import "./index.scss";
import DonationsController from 'src/platform/api/donations/donations';
import { IGetDetails } from 'src/platform/api/donations/donations-interface';
import Languages from 'src/platform/services/languages';

export interface IProps {
  closeModal: () => void,
  getList: () => void,
  id: number,
}
export interface IState {
  description: number,
  detailsList: IGetDetails,
  id: number
}

const {
  Close,
  Description,
  Details_,
} = Languages.Translations;

class Details extends HelperComponent<IProps, IState> {

  state: IState = {
    description: 1,
    detailsList: null,
    id: null
  }

  public validationSchema = Yup.object().shape({
    notifTitle_1: Yup.string().min(1).required().trim(),
    notifTitle_2: Yup.string().min(1).required().trim(),
    notifTitle_3: Yup.string().min(1).required().trim(),
    notifMessage_1: Yup.string().min(5).required().trim(),
    notifMessage_2: Yup.string().min(5).required().trim(),
    notifMessage_3: Yup.string().min(5).required().trim(),
  })

  private openDetails = async (id: number) => {
    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const result = await DonationsController.openDetails(id)
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
    if (result.success) {
      this.asyncSetState({ detailsList: result.data })
    }
  }

  componentDidMount = () => {
    this.openDetails(this.props.id)
  }

  render() {
    const { description } = this.state;

    return (<div className="P-create-popup">
      <h5 className="G-mb-3 P-title">{Details_}</h5>
      <Formik
        initialValues={{
          notifTitle_1: "",
          notifTitle_2: "",
          notifTitle_3: "",
          notifMessage_1: "",
          notifMessage_2: "",
          notifMessage_3: "",
        }}

        onSubmit={null}
        validationSchema={this.validationSchema}
      >
        <Form noValidate={true}>
          <div className="G-flex G-justify-between">
            <span className="G-mb-2 P-text G-bold">{Description}</span>
            <div>
              <span onClick={() => this.asyncSetState({ description: 1 })}
                className={`G-mb-2 G-mr-1 P-lang G-cursor ${description === 1 && "P-green"}`}>ARM</span>
              <span onClick={() => this.asyncSetState({ description: 2 })}
                className={`G-mb-2 G-mr-1 P-lang G-cursor ${description === 2 && "P-green"}`}>RU</span>
              <span onClick={() => this.asyncSetState({ description: 3 })}
                className={`G-mb-2 G-mr-1 P-lang G-cursor ${description === 3 && "P-green"}`}>ENG</span>
            </div>
          </div>
          <div className="G-flex P-details-images">
            {this.state.detailsList ?
              this.state.detailsList.projectImages.map((item, image) => {
                return (
                  <div className="P-details-img" key={image}>
                    <div className="P-project-image"
                      style={{ background: `url(${`${item}/300/300`}) center/cover no-repeat` }}
                    ></div>
                    <div className="P-empty-image"></div>
                    {/* {this.state.detailsList ? 
                      Array.from({length: this.state.detailsList.projectImages.length}).map(() => 
                      <div className="P-empty-image"></div>) : ""} */}
                  </div>
                )
              })
              : ""}
          </div>
          {this.state.detailsList ?
            this.state.detailsList.projectTranslateList.map((item, title) => {
              return (
                <div key={title}>
                  {description === 1 ?
                    <div>
                      {item.languageName === "hy" ?
                        <div className="P-am">
                          <div className="P-details-input">{item.title}</div>
                          <div className="P-details-input G-break-word">{item.description}</div>
                        </div>
                        : ""}
                    </div>
                    : null}
                  {description === 2 ?
                    <div>
                      {item.languageName === "ru" ?
                        <div className="P-ru">
                          <div className="P-details-input">{item.title}</div>
                          <div className="P-details-input G-break-word">{item.description}</div>
                        </div>
                        : ""}
                    </div>
                    : null}
                  {description === 3 ?
                    <div>
                      {item.languageName === "en" ?
                        <div className="P-en">
                          <div className="P-details-input">{item.title}</div>
                          <div className="P-details-input G-break-word">{item.description}</div>
                        </div>
                        : ""}
                    </div>
                    : null}
                </div>
              )
            })
            : ""}
          <div className="G-flex G-justify-between G-mt-3">
            <button className="G-mr-2 P-cancel" onClick={this.props.closeModal}>{Close}</button>
          </div>
        </Form>
      </Formik>
    </div>);
  }
}

export default Details;