import * as React from 'react';
import Modal from 'react-modal';
import Table, { ITableColumnConfig } from 'src/platform/components/table/table';
import { AccountTypeEnum, ProjectStatus } from 'src/platform/enums/enums';
import ToolBar from 'src/platform/components/toolbar/toolbar';
import HelperComponent from 'src/platform/classes/helper-component';
import { RouteComponentProps } from 'react-router';
import { store } from 'react-notifications-component';
import Helper from 'src/platform/services/helper';
import Paging from 'src/platform/components/pagination/pagination';
import './index.scss';
import { IGetOrganizationsListModelBody, IGetOrganizationsListRequestBody } from 'src/platform/api/donations/donations-interface';
import DonationsController from 'src/platform/api/donations/donations';
import CreatePopup from './create/createPopup';
import moment from 'moment';
import Details from './details/details';
import EditPopup from './edit/edit';
import Languages from 'src/platform/services/languages';


interface IState {
  statusList: Array<string>,
  text: string,
  projectList: Array<any>,
  requestIsOff: boolean,
  page: number,
  size: number,
  languageName: string,
  totalPages: number,
  totalElements: number,
  accountTypeEnumValue: AccountTypeEnum,
  loaded: boolean;
  isFirstInit: boolean;
  id: number,
  organizationName: string,
  contactPerson: string,
  phoneNumber: number,
  projectsCount: number,
  participatedInCampaigns: number,
  modalIsOpenIndex: boolean,
  infoModal: boolean,
  details: {},
  description: number,
  projectsId: number,
  modalEdit: boolean,
  detailsList: Array<any>,
  deleteModalIsOpen: boolean,
}

const {
  Projects,
  Name,
  Participated_in_campaigns,
  Winner_of_campaign,
  Creation_date,
  Status,
  Create,
  Search,
  Edit,
  Cancel,
  Delete,
  Delete_project,
  Are_you_sure,
  Open,
  Close,
  enums,
} = Languages.Translations;

class Organizations extends HelperComponent<RouteComponentProps, IState> {

  public state: IState = {

    projectList: [],
    page: 0,
    size: 8,
    languageName: "en",
    requestIsOff: false,
    totalPages: null,
    totalElements: null,
    details: [],

    statusList: [],
    accountTypeEnumValue: null,
    text: "",
    loaded: false,
    isFirstInit: true,
    modalIsOpenIndex: false,
    infoModal: false,
    modalEdit: false,
    description: 1,
    deleteModalIsOpen: false,

    id: null,
    organizationName: "",
    contactPerson: "",
    phoneNumber: null,
    projectsCount: null,
    participatedInCampaigns: null,
    projectsId: null,
    detailsList: []
  }

  componentDidMount() {
    this.getProjectList();
  }
  public inputTimer: any;

  private getProjectList = async (pageNum?: { selected: number }) => {

    const { page, size, languageName, text } = this.state;

    const body: IGetOrganizationsListRequestBody = {
      page: pageNum ? pageNum.selected : page,
      size,
      languageName,
      text,
    }

    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(async () => {
      window.dispatchEvent(new CustomEvent('loader', { detail: true }));
      const result = await DonationsController.getProjectList({
        page: pageNum ? pageNum.selected : page,
        size,
        languageName,
        body: body
      });
      if (result.success) {
        this.setState({
          loaded: true,
          isFirstInit: this.state.isFirstInit && !result.data.content.length
        })
      }
      window.dispatchEvent(new CustomEvent('loader', { detail: false }));

      if (result.success) {
        this.asyncSetState({
          projectList: result.data.content,
          totalPages: result.data.totalPages,
          totalElements: result.data.totalElements,
        });

      } else {
        store.addNotification(Helper.notificationConfig(result.message, false));
      }
    }, 300);

  };

  private deleteOrganization = async (id: number) => {
    const result = await DonationsController.deleteOrganization(id)
    if (result.success) {
      this.getProjectList()
      this.closeModal()
    } else {
      store.addNotification(Helper.notificationConfig(result.message, false));
    }
  }

  private openOrCloseProject = async (id: number) => {
    const result = await DonationsController.openOrCloseProject(id)
    if (result.success) {
      this.getProjectList()
      this.setState({ totalPages: 0})
      store.addNotification(Helper.notificationConfig(result.message, true));
    } else {
      store.addNotification(Helper.notificationConfig(result.message, false));
    }
  }

  // private openDetails = async (id: number) => {
  //   const result = await DonationsController.openDetails(id)
  //   if (result.success) {
  //     this.asyncSetState({
  //       details: result.data
  //     })
  //   }
  // }

  public config: Array<ITableColumnConfig<IGetOrganizationsListModelBody>> = [
    {
      name: `${Name}`,
      cell: (row: IGetOrganizationsListModelBody) => {
        return (<div className="G-cursor G-flex G-align-center" onClick={() => this.openInfoModal(row.projectId)}>
          <span className="G-flex G-flex-wrap G-break-word">
            {row.projectName}
          </span>
        </div>);
      },
      style: { marginRight: "2%", minWidth: 280 },
    },
    {
      name: `${Participated_in_campaigns}`,
      cell: (row: IGetOrganizationsListModelBody) => {
        return (<div className="G-cursor" onClick={() => this.openInfoModal(row.projectId)}>
          {row.participation}
        </div>);
      },
      style: { marginRight: "2%", maxWidth: 180 },
    },
    {
      name: `${Winner_of_campaign}`,
      cell: (row: IGetOrganizationsListModelBody) => {
        return (<div className="G-cursor" onClick={() => this.openInfoModal(row.projectId)}>
          {row.winner}
        </div>);
      },
      style: { marginRight: "2%", maxWidth: 180 },
    },
    {
      name: Creation_date,
      cell: (row: IGetOrganizationsListModelBody) => {
        return (<div className="G-cursor" onClick={() => this.openInfoModal(row.projectId)}>
          {moment(row.createdDate).format('DD MMM YYYY')}
        </div>);
      },
      style: { marginRight: "2%", maxWidth: 180 },

    },
    {
      name: `${Status}`,
      cell: (row: IGetOrganizationsListModelBody) => {
        return (<div className="G-cursor"
          onClick={() => this.openInfoModal(row.projectId)}
          style={{
            color: `${
              row.projectStateValue === ProjectStatus.Open
                ? "#04A556" : '#B4B4B4'}`
          }}>
          {enums.ProjectStatus[row.projectStateValue]}
        </div>);
      },
      style: { marginRight: "2%", maxWidth: 180 },
    },

    {
      cell: (row: IGetOrganizationsListModelBody) => {
        return <>
          <ToolBar>
            <li style={{ cursor: "pointer", width: "100%", marginBottom: "10px" }}
              onClick={() => this.openEditModal(row.projectId)}
            >{Edit}
            </li>
            {<li style={{ cursor: "pointer", width: "100%", marginBottom: "10px" }}
              onClick={() => this.openDeleteModal(row.projectId)}
            >{Delete}
            </li>}
            {row.projectStateValue === ProjectStatus.Closed ?
              <li style={{ cursor: "pointer", width: "100%", marginBottom: "10px" }}
              onClick={() => this.openOrCloseProject(row.projectId)}
            >{Open}
            </li> : ""}
            {row.projectStateValue === ProjectStatus.Open ?
              <li style={{ cursor: "pointer", width: "100%" }}
                onClick={() => this.openOrCloseProject(row.projectId)}
                >{Close}
            </li> : ""}
          </ToolBar>
        </>
      },
      style: { minWidth: 30, maxWidth: 40, marginLeft: "auto" },
    }
  ]

  public onInput = async (evt: React.SyntheticEvent<HTMLInputElement>) => {
    await this.asyncSetState({ text: evt.currentTarget.value })
    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(async () => {
      this.getProjectList();
    }, 300);
  }

  public openDeleteModal = (id: number) => {
    this.asyncSetState({ deleteModalIsOpen: true, projectsId: id });
    document.body.style.overflow = "hidden";
  }

  public openModal = () => {
    this.asyncSetState({ modalIsOpenIndex: true });
    document.body.style.overflow = "hidden";
  }

  public openEditModal = (id: number) => {
    this.asyncSetState({ modalEdit: true, projectsId: id });
    document.body.style.overflow = "hidden";
  }

  public closeModal = () => {
    this.asyncSetState({ modalIsOpenIndex: false, infoModal: false, modalEdit: false, deleteModalIsOpen: false });
    document.body.style.overflow = "auto";
  }

  public openInfoModal = (id: number) => {
    this.asyncSetState({ infoModal: true , projectsId: id});
    document.body.style.overflow = "hidden";
  }
  
  downloadProjectForCampaign = async () => {
    const { text } = this.state;

    const body = {
      projectStateValue: 0,
      text,
    }
    const result = await DonationsController.downloadProjectForCampaign(body);
    if (result.data) {
      window.open(result.data, '_blank');
    }
  }

  public render() {
    const { totalPages, totalElements, projectList, text, loaded, isFirstInit } = this.state;

    return (
      <section className="P-sellers-page P-projects-page">
        <div className="G-flex G-mb-2 G-justify-between G-align-center">
          <h4>{Projects} ({totalElements})</h4>
        </div>

        <div className="G-flex G-flex-wrap P-selects-wrapper">
          <div className="G-mr-1 G-flex G-justify-between P-input-button">
            <input className="G-text-input"
              placeholder={Search}
              type="text"
              value={text}
              onChange={this.onInput}
            />

            <div className="G-flex G-justify-between">
              <button className="G-gray-btn" onClick={this.downloadProjectForCampaign}>Export</button>
              <button className="G-button G-ml-2" onClick={this.openModal}>{Create}</button>
            </div>
          </div>

        </div>
        <Table columnConfig={this.config}
          data={projectList}
          isFirstInit={isFirstInit}
          loaded={loaded}
        />
        <Paging
          totalPages={totalPages}
          onChange={this.getProjectList}
        />

        <Modal
          isOpen={this.state.modalIsOpenIndex}
          onRequestClose={this.closeModal}
          style={{
            content: {
              padding: "30px",
              minHeight: "500px",
              maxHeight: "96vh",
              width: "100%",
              maxWidth: "700px",
              display: "flex",
              flexDirection: "column",
            },
          }}>
          <CreatePopup closeModal={this.closeModal} getList={this.getProjectList} />
        </Modal>

        <Modal
          isOpen={this.state.modalEdit}
          onRequestClose={this.closeModal}
          style={{
            content: {
              padding: "30px",
              minHeight: "500px",
              width: "100%",
              maxWidth: "710px",
              maxHeight: "96vh",
              display: "flex",
              flexDirection: "column",
            },
          }}>
          <EditPopup closeModal={this.closeModal} getList={this.getProjectList} id={this.state.projectsId}/>
        </Modal>

        <Modal
          isOpen={this.state.infoModal}
          onRequestClose={this.closeModal}
          style={{
            content: {
              padding: "30px",
              minHeight: "500px",
              width: "100%",
              maxWidth: "710px",
              maxHeight: "96vh",
              display: "flex",
              flexDirection: "column",
            },
          }}>
          <Details closeModal={this.closeModal} getList={() => this.getProjectList() } id={this.state.projectsId}/>
        </Modal>
        <Modal
            isOpen={this.state.deleteModalIsOpen}
            onRequestClose={this.closeModal}
            style={{
              content: {
                padding: "30px",
                minHeight: "180px",
                minWidth: "350px",
                display: "flex",
                flexDirection: "column",
              }
            }}
          >
            <div className="P-delete-modal">
              <h5 className="G-mb-2 P-title">{Delete_project}</h5>
              <p className="G-mb-3 P-text">{Are_you_sure}</p>
              <div className="G-flex">
                <button className="G-mr-2 P-cancel" onClick={this.closeModal}>{Cancel}</button>
                <button className="G-button P-button" onClick={() => this.deleteOrganization(this.state.projectsId)}>{Delete}</button>
              </div>
            </div>
          </Modal>
      </section >
    );
  }
}

export default Organizations;