import * as React from 'react';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router-dom';

import { ROUTES } from 'src/platform/constants/routes';
import { byRoute } from 'src/platform/decorators/routes';
import HelperPureComponent from 'src/platform/classes/helper-pure-component';
import './index.scss';

import SignIn from './sign-in/SignIn';
import SendPhoneNumber from './send-phone-number/SendPhoneNumber';
import VerifyCode from './verify-code/VerifyCode';
import PasswordReset from './password-reset/PasswordReset';
interface IState {

  // Boolean
  loading: boolean;
  passwordIsShown: boolean;
};

interface IRouteParams { code: string };


@byRoute(ROUTES.login)
class Login extends HelperPureComponent<RouteComponentProps<IRouteParams>, IState> {


  public state: IState = {
    loading: false,
    passwordIsShown: false,
  }

  public render() { 
    return (
      <section className="G-main-page P-login-page"> 
            <Switch>
              <Route path="/login/sign-in" component={SignIn}/>
              <Route path="/login/send-number" component={SendPhoneNumber} />
              <Route path="/login/verify-code" component={VerifyCode} />
              <Route path="/login/password-reset" component={PasswordReset} />
              <Redirect to="/login/sign-in"/>
            </Switch> 
      </section >
    );
  }
}

export default Login;