import * as React from 'react';
import { Form, Formik, Field, FormikValues } from 'formik';
import * as Yup from 'yup';
import Loader from 'src/platform/components/loader/loader';
import AgentController from 'src/platform/api/agent/agent';
import { store } from 'react-notifications-component';
import { IPasspostRequest } from 'src/platform/api/customers/interfaces/agentsModel';
import HelperComponent from 'src/platform/classes/helper-component';
import Helper from 'src/platform/services/helper';
import DatePicker from "react-datepicker";
import './index.scss';
import Languages from 'src/platform/services/languages';

export interface PassportDetailsModalProps {
    closeModal: () => void,
}

export interface IState {
    id: string,
    arr: Array<File>,
}

const {
    Add_passport_details,
    Passport_number,
    Given_by,
    Given_date,
    Attach_file_photo,
    Description,
    Add,
    Cancel,
} = Languages.Translations

class PassportDetailsModal extends HelperComponent<PassportDetailsModalProps, IState> {
    state: IState = {
        id: null,
        arr: [],
    }
    componentDidMount() {
        const URL = new URLSearchParams(window.location.search);
        this.asyncSetState({ id: URL.get('id') })
    }
    private reset = async (value: FormikValues, actions: FormikValues) => {
        let body: IPasspostRequest = {
            buyerId: this.state.id,
            givenBy: value.givenBy,
            givenDate: new Date(value.givenDate).getMilliseconds().toString(),
            passportNumber: value.passportNumber,
        }

        const formData = new FormData();

        formData.append('files', value.file);
        window.dispatchEvent(new CustomEvent('loader', { detail: true }));
        const res = await AgentController.addPassportDetails(body, formData);
        window.dispatchEvent(new CustomEvent('loader', { detail: false }));
        if (res.success) {
            this.asyncSetState({ agentDetails: res.data })
            store.addNotification(Helper.notificationConfig(res.message, true));
            this.props.closeModal();
        } else {
            store.addNotification(Helper.notificationConfig(res.message, false));
        }
    }

    public validationSchema = Yup.object().shape({
        passportNumber: Yup.string().min(1).max(10).required(),
        givenBy: Yup.string().matches(/^\+?[0-9]*$/).min(0).max(4).required(),
        givenDate: Yup.string().min(1).required(),
    })

    render() {
        return (<div className="P-passport-details">
            <h6 className="G-mb-4">{Add_passport_details}</h6>
            <Formik
                initialValues={{ passportNumber: "", givenBy: "", givenDate: "", files: null }}
                onSubmit={this.reset}
                validationSchema={this.validationSchema}
            >
                {({ errors, touched, isSubmitting, setFieldValue, values }) => (
                    <Form className="P-form G-column" noValidate={true}>
                        <p className="P-description G-mb-2">{Description}</p>
                        <div className="G-mb-5">
                            <div className="G-flex G-flex-wrap">
                                <Field
                                    type="text"
                                    name="passportNumber"
                                    placeholder={Passport_number}
                                    className={`G-text-input G-mb-3 G-mr-3 P-input ${touched.passportNumber && errors.passportNumber ? "G-error" : ""}`}
                                />
                                <Field
                                    type="number"
                                    name="givenBy"
                                    placeholder={Given_by}
                                    className={`G-text-input G-mb-3 P-input ${touched.givenBy && errors.givenBy ? "G-error" : ""}`}
                                />
                                <DatePicker
                                    selected={values.givenDate}
                                    maxDate={new Date()}
                                    placeholderText={Given_date}
                                    onChange={(date: Date) => setFieldValue("givenDate", date)}
                                    className={`G-text-input G-cursor P-input ${touched.givenDate && errors.givenDate ? "G-error" : ""}`}
                                />
                            </div>
                            <div className="G-flex G-aline-center G-mt-2">
                                <label>
                                    <p className='P-attach G-mr-2'> <i className="icon-icon-12  G-mr-2" /> {Attach_file_photo} </p>
                                    <Field
                                        name="attach"
                                        type="file"
                                        className='G-text-input P-input '
                                        onChange={(event: any) => {
                                            this.state.arr.push(event.currentTarget.files[0]);
                                            setFieldValue("files", this.state.arr);
                                            event.target.value = null;
                                        }}
                                    />
                                </label>
                                <div className="G-flex G-aline-center G-flex-wrap">
                                    {this.state.arr.map((file: any, index: number) =>
                                        <div className="G-mb-1 G-mr-2" key={index}>
                                            {file.name}
                                            <i className="icon-ic_close G-ml-1" onClick={() => {
                                                this.state.arr.splice(index, 1);
                                                setFieldValue("files", this.state.arr);
                                            }} />
                                        </div>)
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="G-flex G-justify-between P-bottom">
                            <button className="G-mr-2 P-cancel" onClick={this.props.closeModal}>{Cancel}</button>
                            <button className="G-button P-button" type="submit">
                                {isSubmitting ? <Loader /> : `${Add}`}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>);
    }
}

export default PassportDetailsModal;