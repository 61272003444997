import { IResponse } from '../../interfaces';
import { BaseController } from "../../classes/http-service";
import { ICreateFaq, IGetFaqDetailsResponse, IGetListResponse } from './faq-interface';


class FaqController extends BaseController {
  private controller = 'faq';

  public createFaq = async (form: ICreateFaq): Promise<IResponse<any>> => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: ``,
      body: form,
    });

    return result;
  };
  
  public updateFaq = async (form: ICreateFaq, id: number): Promise<IResponse<any>> => {
    const result = await this.connection.PUT({
      controller: this.controller,
      action: `${id}`,
      body: form,
    });

    return result;
  };

  public deleteFaq = async (id: number): Promise<IResponse<any>> => {
    const result = await this.connection.DELETE({
      controller: this.controller,
      action: `${id}`,
      body: null
    });

    return result;
  };
  
  public getById = async (id: number): Promise<IResponse<IGetFaqDetailsResponse>> => {
    const result = await this.connection.GET({
      controller: this.controller,
      action: `${id}`,
    });

    return result;
  };

  public getList = async (): Promise<IResponse<IGetListResponse>> => {
    const result = await this.connection.GET({
      controller: this.controller,
      action: `getAllFAQs`,
    });

    return result;
  };

}
export default new FaqController();
