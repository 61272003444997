import { IGetSupportListRequestModel, IGetSupportListRequestBody} from "./support-interface";
import { IResponse } from '../../interfaces';
import { BaseController } from "../../classes/http-service";

class SupportController extends BaseController {
  private controller = 'support';

  public getSupportList = async (form: IGetSupportListRequestModel): Promise<IResponse<any>> => {

    const result = await this.connection.POST<IGetSupportListRequestBody>({
      controller: this.controller,
      action: `${form.page}/${form.size}`,
      body: form.body,
    });
    return result;
  }

  
  public getArchiveList = async (form: IGetSupportListRequestModel): Promise<IResponse<any>> => {
    const result = await this.connection.POST<IGetSupportListRequestBody>({
      controller: this.controller,
      action: `getArchiveList/${form.page}/${form.size}`,
      body: form.body,
    });
    return result;
  }

  public markImportant = async (id: string): Promise<IResponse<any>> => {
    const result = await this.connection.PUT({
      controller: this.controller,
      action: `makeImportant/${id}`,
      body: null,
    });
    return result;
  }

  public sendToArchive = async (id: string): Promise<IResponse<any>> => {

    const result = await this.connection.PUT({
      controller: this.controller,
      action: `moveToArchive/${id}`,
      body: null,
    });
    return result;
  }
    
  public downloadSupportExcelFile = async (form: IGetSupportListRequestBody): Promise<IResponse<any>> => {
    const result = await this.connection.POST<IGetSupportListRequestBody>({
      controller: this.controller,
      action: `downloadSupportExcelFile`,
      body: form,
    });
    return result;
  }
  
  public downloadArchiveSupportExcelFile = async (form: IGetSupportListRequestBody): Promise<IResponse<any>> => {
    const result = await this.connection.POST<IGetSupportListRequestBody>({
      controller: this.controller,
      action: `downloadArchiveSupportExcelFile`,
      body: form,
    });
    return result;
  }

}
export default new SupportController();