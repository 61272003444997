import * as React from 'react';
import "./index.scss"
import HelperComponent from 'src/platform/classes/helper-component';
import ClickOutside from '../click-outside';
import Select from '../select/Select';
import { operatorOptions } from 'src/platform/constants/casual';
import Languages from 'src/platform/services/languages';

export interface IDoubleDropDownProps {
    placeholder: string,
    property: string,
    setOperatorValue?: (key: string, value: any) => void,
    setDoubleDropdownInputValue?: (key: string, value: any) => void,
    operatorValue?: string | number,
    inputValue?: string | number,
}

export interface IDoubleDropDownState {
    dropDownPopup: boolean,
    valueFieldIsShown: boolean,
    operatorValue: number,
    inputValue: string | number,
}

const {
 Operator,
 Reset,
 Value,
} = Languages.Translations

class DoubleDropDown extends HelperComponent<IDoubleDropDownProps, IDoubleDropDownState> {

    state: IDoubleDropDownState = {
        dropDownPopup: false,
        valueFieldIsShown: false,
        operatorValue: Number(this.props.operatorValue),
        inputValue: this.props.inputValue,
    }

    componentDidUpdate(prevProps: IDoubleDropDownProps){
        if(prevProps.operatorValue !== this.props.operatorValue){
            this.asyncSetState({operatorValue: this.props.operatorValue});
        }
        if(prevProps.inputValue !== this.props.inputValue){
            this.asyncSetState({inputValue: this.props.inputValue});
        }
    }

    private reset = () => {
        const { property, setOperatorValue, setDoubleDropdownInputValue } = this.props;

        this.asyncSetState({ valueFieldIsShown: false, 
                             operatorValue: "", 
                             inputValue: "" }).then(() => {
                            setOperatorValue(property, "")
                            setDoubleDropdownInputValue(property, "")
          });
    }

    render() {
        const { placeholder, setOperatorValue, property, setDoubleDropdownInputValue } = this.props;
        const { dropDownPopup, valueFieldIsShown, operatorValue, inputValue } = this.state;

        return (
            <ClickOutside onClickOutside={() => {
                this.asyncSetState({ dropDownPopup: false })
            }}>
                <div className="P-double-dropdown-container" onClick={()=> this.asyncSetState({ dropDownPopup: true })}>
                    <div className="P-double-dropdown"  >
                        <span className={`P-placeholder G-flex ${inputValue ? 'P-selected' : ''}`}>
                            {operatorValue ? operatorOptions[Number(operatorValue) - 1].label : placeholder}
                            <span className={`G-ml-1 ${inputValue ? 'P-selected' : ''}`}>
                                {/* {inputValue || " "} */}
                                {inputValue ? inputValue.toString().length > 4 ? inputValue.toString().slice(0, 4) + '...' : inputValue || " " : inputValue || " "}</span>
                        </span>
                        <i className="icon-ic_arrowdown P-icon-ic_arrowdown"></i>
                        <div className={`P-dropDownPopup ${dropDownPopup ? '' : 'P-dropDownPopup-hidden'}`} >
                        <form className="P-form">
                            <Select
                                placeholder={Operator}
                                options={operatorOptions}
                                setFieldValue={(field: string, value: any) => {
                                    this.asyncSetState({
                                        valueFieldIsShown: true,
                                        operatorValue: value,
                                        dropDownPopup: true,
                                    })
                                    setOperatorValue(property, value)
                                }}
                                value={operatorValue}
                                width="180px"
                                height="40px"
                            />
                            {valueFieldIsShown &&
                                <input
                                    className='G-text-input G-mb-1 P-textInput G-mt-1'
                                    placeholder={Value}
                                    type="number"
                                    onChange={(evt) => {
                                        if(Number(evt.target.value) < 0){
                                            this.asyncSetState({ inputValue: "" })
                                            setDoubleDropdownInputValue(property, "")
                                        }else{
                                            if (evt.target.value.toString().length <= 7) {
                                                this.asyncSetState({ inputValue: evt.target.value })
                                                setDoubleDropdownInputValue(property, evt.target.value)
                                            }
                                        }
                                    }}
                                    value={inputValue}
                                />}
                            {valueFieldIsShown &&
                                <button
                                    onClick={this.reset}
                                    className="P-reset-btn"
                                > {Reset}
                                </button>
                            }
                        </form>
                    </div>
                    </div>
                   
                </div>
            </ClickOutside>
        );
    }
}

export default DoubleDropDown;