import * as React from 'react';
import Helper from 'src/platform/services/helper';
import moment from 'moment';
import { OrderAgentStatusEnum, UserTypeEnum } from 'src/platform/enums/enums';
import { withRouter, RouteComponentProps } from 'react-router';
import Languages from 'src/platform/services/languages';

export interface IAgentOrderDetailProps extends RouteComponentProps {
  item: any,
  makeActive: () => void,
  activeId: number,
}

const {
  Order_,
  Seller_,
  Agent_,
  enums,
  Any_time,
} = Languages.Translations

const AgentOrderDetail = (props: any) => {

  return (
    <div className="P-agentOrderDetail"
      onClick={() => props.makeActive()}
      style={props.activeId === props.item.orderId ? { backgroundColor: "#F1F3F2" } : null}
    >
      <div className="G-flex P-top G-pb-1">
        <span className="P-order-id G-mr-1">{Order_} #{props.item.orderNumber}</span>
        <span className="G-mr-1">{moment(props.item.createdDate).format('DD MMM HH:mm')}</span>
        <span className="P-finished"
          style={{
            color: `${(
              props.item.orderBuyerStatusEnumValue === OrderAgentStatusEnum.PENDING) ? '#7A7A7A'
              : props.item.orderBuyerStatusEnumValue === OrderAgentStatusEnum.ACCEPTED ? "#EFC700" : "#04A556"}`
          }}
        >{enums.OrderAgentStatusEnum[props.item.orderBuyerStatusEnumValue]}
        </span>
      </div>
      <div className="P-bottom G-flex G-justify-between G-pt-1">
        <div className="G-column">
          {props.item.userTypeValue ? <div className="G-mb-1 P-agent">
            {props.item.userTypeValue === UserTypeEnum.Agents ? `${Agent_}` : `${Seller_}`}
          </div> : ""}
          <div className="G-flex" onClick={(evt: React.SyntheticEvent) => {
            if (props.item.userTypeValue === UserTypeEnum.Agents) {
              evt.stopPropagation();
              props.history.push(`/customers/agents/details?id=${props.item.agentPreviewDto && props.item.agentPreviewDto.buyerId}`)
            } else props.history.push(`/customers/sellers/details?id=${props.item.agentPreviewDto && props.item.agentPreviewDto.buyerId}`)
          }}>

            <span className="P-name G-mr-1 G-break-word">
              {props.item.agentPreviewDto && props.item.agentPreviewDto.buyerFullName}
            </span>
          </div>
        </div>
        <div className="P-right-part G-column">
          <div className="G-mb-1">
            {props.item.orderTimesDtos && props.item.orderTimesDtos.map((item: any, index: number) => {
              let from = item.fromTime;
              let to = item.toTime;
              return (<div key={index} className='P-time G-mr-1'>
                {item.fromTime !== 0 ? <>
                  {moment(new Date((from * 1000) - 14400000)).format('HH:mm')} -
                  {moment(new Date((to * 1000) - 14400000)).format('HH:mm')}</> : `${Any_time}`}
              </div>);
            })}
          </div>
          <div className="P-address">{Helper.sliceSentence(`${props.item.addressForOrderDto && props.item.addressForOrderDto.addressName},
              ${props.item.addressForOrderDto.apartment ? props.item.addressForOrderDto.apartment + ' app,' : ''}
              ${props.item.addressForOrderDto.building ? props.item.addressForOrderDto.building + ' building,' : ''} 
              ${props.item.addressForOrderDto.entrance ? props.item.addressForOrderDto.entrance + ' entrance,' : ''} 
              ${props.item.addressForOrderDto.floor ? props.item.addressForOrderDto.floor + ' floor' : ''}`, 120)}</div>
        </div>
      </div>
    </div>);
}

export default withRouter(AgentOrderDetail);