import * as React from 'react';
import HelperComponent from 'src/platform/classes/helper-component';
import { Formik, Form, Field, FormikValues, FormikErrors } from 'formik';
import * as Yup from 'yup';
import DonationsController from 'src/platform/api/donations/donations';
import Loader from 'src/platform/components/loader/loader';
import "./index.scss";
import { IGreateProject } from 'src/platform/api/donations/donations-interface';
import Languages from 'src/platform/services/languages';

export interface IProps {
  closeModal: () => void,
  getList: () => void,
}
export interface IState {
  description: number,
  images: Blob[],
  imageSrc: (ArrayBuffer | string)[],
  redBorder: boolean,
}

const {
  Close,
  Create_project,
  Description,
  Title,
  Save,
} = Languages.Translations;

class CreatePopup extends HelperComponent<IProps, IState> {

  fileElement = React.createRef<HTMLInputElement>();
  state: IState = {
    description: 1,
    images: [],
    imageSrc: [],
    redBorder: false,
  }

  public validationSchema = Yup.object().shape({
    title_1: Yup.string().min(1).required().trim(),
    title_2: Yup.string().min(1).required().trim(),
    title_3: Yup.string().min(1).required().trim(),
    description_1: Yup.string().min(5).required().trim(),
    description_2: Yup.string().min(5).required().trim(),
    description_3: Yup.string().min(5).required().trim(),
  })

  private sendProject = async (values: FormikValues, errors: FormikValues) => {

    const body: IGreateProject = {
      projectTranslateList: [
        {
          languageName: "hy",
          description: values.description_1.trim(),
          title: values.title_1,
        },
        {
          languageName: "ru",
          description: values.description_2.trim(),
          title: values.title_2,
        },
        {
          languageName: "en",
          description: values.description_3.trim(),
          title: values.title_3,
        }
      ],
    }

    if (this.state.images.length) {
      const res = await DonationsController.sendProject(body);
      if (res.success) {
        await this.uploadImage(res.data)
      }
    } else if (!this.state.images.length) {
      this.setState({ redBorder: true })
    } else this.setState({ redBorder: false })
  }

  onSubmit = (errors: FormikErrors<FormikValues>) => {
    if (!errors.title_1 && errors.title_2) {
      this.asyncSetState({ description: 2 });
    }
    if (!errors.title_1 && !errors.title_2 && errors.title_3) {
      this.asyncSetState({ description: 3 });
    }
  }

  selectFile = (event: any) => {
    const images = this.state.images;
    const imageSrc = this.state.imageSrc;
    Object.keys(event.target.files).forEach(key => {
      images.push(event.target.files[key]);
      const READER = new FileReader();
      READER.readAsDataURL(event.target.files[key]);
      READER.onload = (READER) => {
        imageSrc.push(READER.target.result);
        this.setState({ imageSrc });
      };
    });
    this.setState({ images });
    this.fileElement.current.value = '';
    this.setState({ redBorder: false })
  }

  uploadImage = async (projectId: number) => {
    const { images } = this.state
    const data = new FormData()
    images.forEach(x => data.append('files', x));
    const res = await DonationsController.uploadImage(data, projectId);
    if (res.success) {
      this.props.closeModal()
      this.props.getList()
    }
  }

  deleteLocalImage = (index: number) => {
    const { imageSrc, images } = this.state;
    imageSrc.splice(index, 1);
    images.splice(index, 1);
    this.setState({
      imageSrc,
      images,
    });
  }

  render() {
    const { description, imageSrc, redBorder } = this.state;

    return (<div className="P-create-popup">
      <h5 className="G-mb-3 P-title">{Create_project}</h5>
      <Formik
        initialValues={{
          title_1: "",
          title_2: "",
          title_3: "",
          description_1: "",
          description_2: "",
          description_3: "",
        }}

        onSubmit={this.sendProject}
        validationSchema={this.validationSchema}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form noValidate={true}>
            <div className="G-flex G-justify-between">
              <span className="G-mb-2 P-text G-bold">{Description}</span>
              <div>
                <span onClick={() => this.asyncSetState({ description: 1 })}
                  className={`G-mb-2 G-mr-1 P-lang G-cursor ${description === 1 && "P-green"}`}>ARM</span>
                <span onClick={() => this.asyncSetState({ description: 2 })}
                  className={`G-mb-2 G-mr-1 P-lang G-cursor ${description === 2 && "P-green"}`}>RU</span>
                <span onClick={() => this.asyncSetState({ description: 3 })}
                  className={`G-mb-2 G-mr-1 P-lang G-cursor ${description === 3 && "P-green"}`}>ENG</span>
              </div>
            </div>
            <div className="G-flex G-flex-wrap P-create-images">
              <label className={redBorder ? "P-red-border" : ""}>
                <input
                  name="selectedFile"
                  type="file"
                  ref={this.fileElement}
                  multiple
                  onChange={this.selectFile}
                />
                <i className="icon-ic_plus"></i>
              </label>

              {imageSrc ?
                imageSrc.map((item, index) => {
                  return (
                    <div className="P-details-img" key={index}>
                      <div className="P-project-image"
                        style={{ background: `url('${item}') center/cover no-repeat` }}
                      >
                        <i className="P-delete-icon icon-cancel" onClick={() => this.deleteLocalImage(index)}></i>
                      </div>
                    </div>)
                })
                : ""}
            </div>
            {description === 1 ? <div className="P-am">
              <Field
                name="title_1"
                placeholder={Title}
                className={`G-text-input G-mb-2 P-input ${touched.title_1 && errors.title_1 ? "G-error" : ""}`}
                type="text" />
              <Field
                component="textarea"
                name="description_1"
                placeholder={Description}
                className={`G-textarea P-text-area G-mb-3 P-input ${touched.description_1 && errors.description_1 ? "G-error" : ""}`}
                cols={1}
                rows={1}
              />
            </div> : null}
            {description === 2 ?
              <div className="P-ru">
                <Field
                  name="title_2"
                  placeholder={Title}
                  className={`G-text-input G-mb-2 P-input ${touched.title_2 && errors.title_2 ? "G-error" : ""}`}
                  type="text" />
                <Field
                  component="textarea"
                  name="description_2"
                  placeholder={Description}
                  className={`G-textarea P-text-area G-mb-3 P-input ${touched.description_2 && errors.description_2 ? "G-error" : ""}`}
                  cols={1}
                  rows={1}
                />
              </div> : null}
            {description === 3 ?
              <div className="P-en">
                <Field
                  name="title_3"
                  placeholder={Title}
                  className={`G-text-input G-mb-2 P-input ${touched.title_3 && errors.title_3 ? "G-error" : ""}`}
                  type="text" />
                <Field
                  component="textarea"
                  name="description_3"
                  placeholder={Description}
                  className={`G-textarea P-text-area G-mb-3 P-input ${touched.description_3 && errors.description_3 ? "G-error" : ""}`}
                  cols={1}
                  rows={1}
                />
              </div> : null}
            <div className="G-flex G-justify-between">
              <button className="G-mr-2 P-cancel" onClick={this.props.closeModal}>{Close}</button>
              <button className="G-button P-button" type="submit"
                onClick={() => this.onSubmit(errors)}>
                {isSubmitting ? <Loader /> : `${Save}`}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>);
  }
}

export default CreatePopup;