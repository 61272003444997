import * as React from 'react';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router-dom';
import { ROUTES } from 'src/platform/constants/routes';
import { byPrivateRoute, bySidebar } from 'src/platform/decorators/routes';
import HelperPureComponent from 'src/platform/classes/helper-pure-component';
import SupportMessages from './support-messages/SupportMessages';
import Archive from './archive/Archive';
import './index.scss';
import Languages from 'src/platform/services/languages';

const {
  Support_,
  Support_messages,
  Archive_,
} = Languages.Translations;

@bySidebar(`${Support_}`, "icon-ic_support", [{name:`${Support_messages}`, path: "/support/messages"},{name:`${Archive_}`, path: "/support/archive"}])
@byPrivateRoute(ROUTES.support)
class Support extends HelperPureComponent<RouteComponentProps, {}> {


  public render() {
    return (
      <>
        <Switch>
          <Route path="/support/messages" component={SupportMessages} />
          <Route path="/support/archive" component={Archive} />
          <Redirect to="/support/support-messages" />
        </Switch>
      </ >
    );
  }
}

export default Support;