import * as React from 'react';
import './index.scss';
import HelperComponent from 'src/platform/classes/helper-component';
import { Link, RouteComponentProps } from 'react-router-dom';
import DatePicker from "react-datepicker";
import Modal from 'react-modal';
import AddToCampaign from '../add/add-campaign';
import { ICreateCampaign } from 'src/platform/api/campaign/campaign-interface';
import DonationsController from 'src/platform/api/donations/donations';
import { ROUTES } from 'src/platform/constants/routes';
import Languages from 'src/platform/services/languages';
import moment from 'moment';
import * as Yup from 'yup';
import CampaignController from 'src/platform/api/campaign/campaign';

interface IState {
  startDate: Date,
  amount: number,
  addModal: boolean,
  saveModal: boolean,
  amountError: boolean,
  projectList: Array<any>,
  projectName: string,
  projectId: number,
  campaignId: number
}

const {
  Save,
  Projects,
  Amount,
  Started_date,
  Attach_projects,
  Information,
  New_Campaign,
  Edit_Campaign,
  Charity_campaigns,
  list_is_empty,
  Yes,
  No,
  Project_names,
  Campaign_information,
  Campaign_create_text,
} = Languages.Translations;
class CreateCampaigns extends HelperComponent<RouteComponentProps, IState> {

  public state: IState = {
    startDate: null,
    amount: null,
    addModal: false,
    saveModal: false,
    amountError: false,
    projectList: [],
    projectName: "",
    projectId: null,
    campaignId: null,
  }

  componentDidMount() {
    const id = new URLSearchParams(this.props.match.params);
    if (id) {
      this.asyncSetState({campaignId: +id.get('id')})
      this.getCampaignDetails(+id.get('id'))
    }
  }

  public openAddModal = () => {
    this.asyncSetState({ addModal: true });
    document.body.style.overflow = "hidden";
  }

  public closeModal = () => {
    this.asyncSetState({ addModal: false });
    document.body.style.overflow = "auto";
  }

  public openSaveModal = () => {
    if (this.state.projectList.length && this.state.amount && this.state.startDate) {
      this.asyncSetState({ saveModal: true });
      document.body.style.overflow = "hidden";
    }
  }

  public closeSaveModal = () => {
    this.asyncSetState({ saveModal: false });
    document.body.style.overflow = "auto";
  }

  changeAmount = (event: any) => {
    this.setState({ amount: event.target.value })
  }

  public validationSchema = Yup.object().shape({
    amount: Yup.number().min(1).required(),
    startedDate: Yup.string().min(5).required().trim(),
  })

  saveCampaign = async () => {
    const { amount, startDate, campaignId } = this.state
    const body: ICreateCampaign = {
      amount: amount,
      projectIds: this.state.projectList.map((item) => { return (item.projectId) }),
      startedDate: startDate ? (campaignId ? startDate : startDate.getTime()) : ''
    }
    if (this.state.projectList.length) {
      if (this.state.campaignId) {
        const result = await DonationsController.editCampaign(body, this.state.campaignId);
        if (result.success) {
          this.props.history.push(`/donations/Charity/campaigns`);
        }
      } else {
        const result = await DonationsController.saveCampaign(body);
        if (result.success) {
          this.props.history.push(`/donations/Charity/campaigns`);
        }
      }
    }
  }

  private getCampaignDetails = async (id: number) => {
    const result = await CampaignController.getDetails(id)
    if (result.success) {
      this.setState({
        startDate: result.data.startedDate,
        amount: result.data.amount,
        projectList: result.data.projects,
      })
    }
  }

  render() {
    const { startDate, amount, campaignId } = this.state
    return (
      <section>
        <div className="G-flex G-justify-between G-mb-2">
          <div className="G-flex">
            <Link to={`${ROUTES.donations}/Charity/campaigns`}><h4 className="G-mb-2">{Charity_campaigns} / </h4></Link>
            <h4><span className="P-new">{campaignId ? Edit_Campaign : New_Campaign}</span></h4>
          </div>
          <button className="G-button P-create-button" onClick={() => { this.openSaveModal() }}>{Save}</button>
        </div>

        <div className="P-newcapmaign-box">
          <h5>{Information}</h5>
          <div className="G-flex P-campaigns-fields">
            <div>
              <DatePicker
                className={`G-text-input G-cursor G-ml-3 P-date-field`}
                selected={startDate}
                placeholderText={Started_date}
                onChange={(startDate: Date) => this.asyncSetState({ startDate }).then(() => {
                  // this.getDashboardDetail()
                })}
              />
            </div>
            <input className={!this.state.amountError ? "P-amount-field" : "P-amount-red"} type="number" placeholder={Amount} value={amount ? amount : ""} onChange={this.changeAmount} />

          </div>
        </div>
        <div className="G-flex G-justify-between G-mb-2 G-mt-6">
          <h4 className="G-mb-2">{Projects}</h4>
          <button className="G-button P-create-button" onClick={() => { this.openAddModal() }}>{Attach_projects}</button>
        </div>

        <div className="P-top-project">
          <div className="P-project">
            <div className="G-flex P-project-title">
              <span className="G-mr-1">{Projects}</span>
            </div>
          </div>

          <div className="P-project-list">
            {this.state.projectList.length ? <div>
              {this.state.projectList ?
                this.state.projectList.map((item) => {
                  return (
                    <div className="G-flex-justify-between G-align-center P-project-item">
                      <p className="G-flex G-align-center G-cursor G-break-word G-mr-1">{item.projectName}</p>
                    </div>
                  )
                })
                : ""}
            </div> : <div className="G-flex P-empty"><h5>{list_is_empty}</h5></div>}

          </div>
        </div>

        <Modal
          isOpen={this.state.addModal}
          onRequestClose={this.closeModal}
          style={{
            content: {
              padding: "30px",
              minHeight: "500px",
              width: "100%",
              maxWidth: "1000px",
              maxHeight: "720px",
              display: "flex",
              flexDirection: "column",
            },
          }}>
          <AddToCampaign closeModal={this.closeModal} projectList={this.state.projectList} />
        </Modal>

        <Modal
          isOpen={this.state.saveModal}
          onRequestClose={this.closeSaveModal}
          style={{
            content: {
              padding: "30px",
              minHeight: "300px",
              width: "100%",
              maxWidth: "700px",
              maxHeight: "720px",
              display: "flex",
              flexDirection: "column",
            },
          }}>
          {this.state.saveModal ? <>
            <h4 className="G-mb-3">{Campaign_create_text}</h4>
            <p className="G-bold G-mr-1 G-mb-1">{Campaign_information}</p>
            <div className="G-flex"><p className="G-bold G-mr-1 nowrap">{Project_names} -</p>{this.state.projectList.map((item) => { return (item.projectName + ',  ') })}</div>
          <div className="G-flex"><p className="G-bold G-mr-1">{Amount} -</p> {(+amount).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>
            <div className="G-flex G-mb-1"><p className="G-bold G-mr-1">{Started_date} -</p>{moment(startDate).format('DD MMM YYYY')}</div>
            <div className="G-flex G-justify-end G-mt-auto">
              <button className="G-mr-2 P-cancel" onClick={this.closeSaveModal}>{No}</button>
              <button className="G-button P-create-button" type="submit" onClick={() => { this.saveCampaign() }} >{Yes}</button>
            </div>
          </> : ''}
        </Modal>

      </section>
    )
  }
}

export default CreateCampaigns;