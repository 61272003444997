import * as React from 'react';
import Table, { ITableColumnConfig } from 'src/platform/components/table/table';
import { ListStatusEnum, VehicleTypeEnum, OrderAgentStatusEnum } from 'src/platform/enums/enums';
import HelperComponent from 'src/platform/classes/helper-component';
import { IUserDetailsModel, IAgentFeedbackModel, IAgentAllOrdersModel, IAgentCardboardAllOrdersModel, IUserRightDetailsModel } from 'src/platform/api/customers/interfaces/agentsModel'
import { ROUTES } from 'src/platform/constants/routes';
import { Link, RouteComponentProps } from 'react-router-dom';
import Modal from 'react-modal';
import PassportDetailsModal from './components/PassportDetailsModal';
import BlockModal from '../../components/blockModal';
import UserController from 'src/platform/api/user/user';
import Helper from 'src/platform/services/helper';
import { store } from 'react-notifications-component';
import noPic from 'src/assets/images/no-photo.png'
import { formatDate } from 'src/platform/services/helper';
import './index.scss';
import moment from 'moment';
import Languages from 'src/platform/services/languages';
import OrderController from 'src/platform/api/order/order';
import Paging from 'src/platform/components/pagination/pagination';
import LimitsModal from '../../components/limits-modal/limitsModal';


const {
  enums,
} = Languages.Translations;

interface IState {
  modalIsOpenIndex: number,
  agentDetails: IUserDetailsModel,
  feedbackList: Array<any>,
  loaded: boolean,
  isFirstInit: boolean,
  page: number,
  size: number,
  totalPagesFeedback: number,
  totalPagesPurchases: number,
  totalPagesDelivery: number,
  allBuyersPurchasesList: Array<any>,
  allBuyersDeliveryList: Array<any>,
  agentId: any,
  agentRightDetail: IUserRightDetailsModel,
}

const {
  Change,
  Creation_date,
  Status,
  Sellers_,
  Deal_ID,
  Cardboard_weight,
  Location,
  Block_user,
  Unblock_user,
  User_details,
  Personal_information,
  deals_done,
  sold_cardboard,
  Cardboard_purchases,
  Cardboard_delivery,
  kg,
  Ratings,
  Feedbacks,
  Order_,
  Agents_,
  Verified,
  Not_verified,
  Add,
  total_withdrawed_amount,
  withdrawals,
  Active,
  Blocked,
  Deactivated,
  AMD,
} = Languages.Translations;

class AgentDetails extends HelperComponent<RouteComponentProps> {

  public configPurch: Array<ITableColumnConfig<IAgentAllOrdersModel>> = [
    {
      name: `${Deal_ID}`,
      cell: function (row: IAgentAllOrdersModel) {
        return row.orderNumber;
      },
    },
    {
      name: `${Cardboard_weight}`,
      cell: function (row: IAgentAllOrdersModel) {
        return row.weight;
      },
    },
    {
      name: `${AMD}`,
      cell: function (row: IAgentAllOrdersModel) {
        return row.price;
      }
    },
    {
      name: `${Location}`,
      style: { marginRight: "2%" },
      cell: function (row: IAgentAllOrdersModel) {
        return <div className="G-flex-wrap">{row.addressForOrderDto.addressName}</div>;
      },
    },
    {
      name: `${Sellers_}`,
      style: { marginRight: "2%" },
      cell: (row: IAgentAllOrdersModel) => {
        return (
          <div className="G-cursor G-flex G-align-center" onClick={() => {
            this.props.history.push(`/customers/sellers/details?id=${row.sellerPreviewDto.sellerId}`);
          }}>
            <span className="G-flex G-flex-wrap G-break-word">
              {row.sellerPreviewDto.sellerFullName}
            </span>
          </div>
        )
      }
    },
    {
      name: Creation_date,
      cell: function (row: IAgentAllOrdersModel) {
        return <>{row.createdDate ? moment(row.createdDate).format('DD MMM YYYY HH:mm') : "-"}</>;
      }
    },
    {
      name: `${Status}`,
      cell: (row: IAgentAllOrdersModel) => {
        return (<div className=""
          style={{
            color: `${(row.orderSellerStatusEnumValue === OrderAgentStatusEnum.AGENT_REQUESTED ||
              row.orderSellerStatusEnumValue === OrderAgentStatusEnum.AGENT_CHOSEN ||
              row.orderSellerStatusEnumValue === OrderAgentStatusEnum.AGENT_FOUND ||
              row.orderSellerStatusEnumValue === OrderAgentStatusEnum.PENDING)
              ? '#7A7A7A'
              : row.orderSellerStatusEnumValue === OrderAgentStatusEnum.CANCELED ||
                row.orderSellerStatusEnumValue === OrderAgentStatusEnum.REJECTED
                ? '#FF0057' : "#04A556"}`
          }}>
          {enums.OrderAgentStatusEnum[row.orderSellerStatusEnumValue]}
        </div>)
      },
      style: { marginRight: "1%" },
    },
  ]

  public configDelivery: Array<ITableColumnConfig<IAgentCardboardAllOrdersModel>> = [
    {
      name: `${Deal_ID}`,
      cell: function (row: IAgentCardboardAllOrdersModel) {
        return row.orderNumber;
      },
    },
    {
      name: `${Cardboard_weight}`,
      cell: function (row: IAgentCardboardAllOrdersModel) {
        return row.weight;
      },
    },
    {
      name: `${AMD}`,
      cell: function (row: IAgentCardboardAllOrdersModel) {
        return row.price;
      }
    },
    {
      name: `${Location}`,
      style: { marginRight: "2%" },
      cell: function (row: IAgentCardboardAllOrdersModel) {
        return <div className="G-flex-wrap">{row.addressForOrderDto.addressName}</div>;
      },
    },
    {
      name: Creation_date,
      cell: function (row: IAgentCardboardAllOrdersModel) {
        return <>{row.createdDate ? moment(row.createdDate).format('DD MMM YYYY HH:mm') : "-"}</>;
      }
    },
    {
      name: `${Status}`,
      cell: (row: IAgentCardboardAllOrdersModel) => {
        return (<div className=""
          style={{
            color: `${(row.orderBuyerStatusEnumValue === OrderAgentStatusEnum.AGENT_REQUESTED ||
              row.orderBuyerStatusEnumValue === OrderAgentStatusEnum.AGENT_CHOSEN ||
              row.orderBuyerStatusEnumValue === OrderAgentStatusEnum.AGENT_FOUND ||
              row.orderBuyerStatusEnumValue === OrderAgentStatusEnum.PENDING)
              ? '#7A7A7A'
              : row.orderBuyerStatusEnumValue === OrderAgentStatusEnum.CANCELED ||
                row.orderBuyerStatusEnumValue === OrderAgentStatusEnum.REJECTED
                ? '#FF0057' : "#04A556"}`
          }}>
          {enums.OrderAgentStatusEnum[row.orderBuyerStatusEnumValue]}
        </div>)
      },
      style: { maxWidth: 75, marginRight: "1%" },
    },
  ]

  public configFedbacks: Array<ITableColumnConfig<IAgentFeedbackModel>> = [
    {
      name: `${Sellers_}`,
      cell: (row: IAgentFeedbackModel) => {
        return (
          <div className="G-cursor G-flex G-align-center" onClick={() => {
            this.props.history.push(`/customers/sellers/details?id=${row.userId}`);
          }}>
            <span className="P-seller-image G-mr-1"
              style={{ background: `url(${row.userImage ? `${row.userImage}/100/100` : noPic}) center/cover no-repeat` }}>
            </span>
            <span className="G-flex G-flex-wrap G-break-word">
              {row.userName}
            </span>
          </div>
        );
      },
    },
    {
      name: `${Ratings}`,
      cell: function (row: IAgentFeedbackModel) {
        return (
          Array.from({ length: row.rate }).map(() => <i className="G-mr-1 icon-ic_star"></i>)
        );
      },
    },
    {
      name: `${Feedbacks}`,
      style: { marginRight: "2%" },
      cell: function (row: IAgentFeedbackModel) {
        return (<div className="G-break-word">{row.feedback ? row.feedback : "-"}</div>);
      }
    },
    {
      name: `${Order_} №`,
      cell: function (row: IAgentFeedbackModel) {
        return row.orderNumber;
      },
    },
    {
      name: Creation_date,
      cell: function (row: IAgentFeedbackModel) {
        return (moment(row.createdDate).format('DD MMM YYYY HH:mm'));
      }
    },
  ]

  public state: IState = {
    modalIsOpenIndex: null,
    agentDetails: null,
    feedbackList: [],
    loaded: false,
    isFirstInit: true,
    page: 0,
    size: 8,
    totalPagesFeedback: null,
    totalPagesPurchases: null,
    totalPagesDelivery: null,
    allBuyersPurchasesList: [],
    allBuyersDeliveryList: [],
    agentId: null,
    agentRightDetail: null
  }

  public reset = () => {
  }

  componentDidMount() {
    const URL = new URLSearchParams(this.props.history.location.search);
    this.getAgentDetails(URL.get('id'))

    this.setState({ isFirstInit: true, loaded: false, })
  }


  public getAgentDetails = async (id: string) => {
    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const res = await UserController.userDetails(id);
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
    if (res.success) {
      this.asyncSetState({ agentDetails: res.data, agentId: res.data.id, agentRightDetail: res.data.buyerDetail })
      this.getAllOrdersByBuyerrId()
      this.getFeedbackListById()
      this.getCardboardDeliveryByBuyerId()
    } else {
      store.addNotification(Helper.notificationConfig(res.message, false));
    }
  }

  public getFeedbackListById = async (pageNum?: { selected: number }) => {
    const { page, size, agentId } = this.state
    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const result = await UserController.getFeedbackListById({
      page: pageNum ? pageNum.selected : page,
      size,
      buyerId: agentId
    })
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
    if (result.success) {
      this.setState({
        totalPagesFeedback: result.data.totalPages,
        feedbackList: result.data.content,
        loaded: true,
        isFirstInit: this.state.isFirstInit && !result.data.length
      })
    }
  }

  public getAllOrdersByBuyerrId = async (pageNum?: { selected: number }) => {
    const { page, size, agentId } = this.state
    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const result = await OrderController.getAllOrdersByBuyerId({
      page: pageNum ? pageNum.selected : page,
      size,
      buyerId: agentId
    })
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
    if (result.success) {
      this.setState({
        totalPagesPurchases: result.data.totalPages,
        allBuyersPurchasesList: result.data.content,
        loaded: true,
        isFirstInit: this.state.isFirstInit && !result.data.content.length
      })
    }
  }

  public getCardboardDeliveryByBuyerId = async (pageNum?: { selected: number }) => {
    const { page, size, agentId } = this.state
    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const result = await OrderController.getCardboardDeliveryByBuyerId({
      page: pageNum ? pageNum.selected : page,
      size,
      buyerId: agentId
    })
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
    if (result.success) {
      this.setState({
        totalPagesDelivery: result.data.totalPages,
        allBuyersDeliveryList: result.data.content,
        loaded: true,
        isFirstInit: this.state.isFirstInit && !result.data.content.length
      })
    }
  }
  public openModal = (index: number) => {
    this.asyncSetState({ modalIsOpenIndex: index });
  }

  public closeModal = () => {
    this.asyncSetState({ modalIsOpenIndex: null });
    const URL = new URLSearchParams(this.props.history.location.search);
    this.getAgentDetails(URL.get('id'))
  }

  private blockUser = async (id: string) => {
    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const res = await UserController.blockUser(id);

    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
    if (res.success) {
      this.asyncSetState({ modalIsOpenIndex: null })
      const URL = new URLSearchParams(this.props.history.location.search);
      this.getAgentDetails(URL.get('id'))
    } else {
      store.addNotification(Helper.notificationConfig(res.message, false));
    }
  }

  public render() {
    const { modalIsOpenIndex, agentDetails, feedbackList, loaded, isFirstInit, allBuyersPurchasesList,
      allBuyersDeliveryList, totalPagesPurchases, totalPagesDelivery, agentRightDetail, totalPagesFeedback } = this.state;

    return (
      <section className="P-agent-details-page">
        {agentDetails ? <>
          <div className="G-flex G-mb-2 G-justify-between G-align-center">
            <div className="G-flex">
              <Link to={`${ROUTES.customers}/agents`}>
                <h4 className="G-mr-1">
                  {Agents_}
                </h4>
              </Link>
              <h4>/</h4>
              <h4 className="G-ml-1 P-crumb">{User_details}</h4>
            </div>
            {agentDetails.userStatusEnumValue !== ListStatusEnum.Deactivated ? <span className=" G-button P-button nowrap"
              onClick={() => this.asyncSetState({ modalIsOpenIndex: 1 })}>
              {agentDetails.userStatusEnumValue === ListStatusEnum.Blocked ? `${Unblock_user}` : `${Block_user}`}
            </span> : null}
          </div>
          <div className="P-details-box G-flex G-justify-between G-mb-4">
            <div className="P-left G-flex G-justify-between ">
              <div>
                <p className="P-personal-info">{Personal_information}</p>
                <div className="G-mt-4 P-user-image-block">
                  <div className="P-image G-mr-4 G-mr-1"
                    style={{ background: `url(${agentDetails.imageUrl ? `${agentDetails.imageUrl}/500/500` : noPic}) center/cover no-repeat` }}>
                  </div>
                  <div className="P-name-block">
                    <div className="G-flex G-mb-1">
                      <p className="P-name">{agentDetails.fullName ? agentDetails.fullName : agentDetails.companyName}</p>
                      <div className="P-rating"><i className=" G-ml-2 G-mr-1 icon-ic_star"></i>{agentDetails.rate}</div>
                    </div>
                    <p className={`${agentDetails.userStatusEnumValue === ListStatusEnum.Blocked ? "P-blocked" :
                      agentDetails.userStatusEnumValue === ListStatusEnum.Active ? "P-active" : "P-deactivated"}`}>
                      {`${agentDetails.userStatusEnumValue === ListStatusEnum.Blocked ? `${Blocked}` :
                        agentDetails.userStatusEnumValue === ListStatusEnum.Active ? `${Active}` : `${Deactivated}`}`}
                    </p>
                  </div>

                </div>
              </div>
              <div className="P-contacts G-mt-2">
                <p className="G-mb-2 P-contact">
                  <i className="icon-ic_phone G-mr-2" />
                  {agentDetails.phoneNumber}
                </p>
                <p className="G-mb-2 P-contact">
                  <i className="icon-ic_mail G-mr-2" />
                  {agentDetails.email ? agentDetails.email : "-"}
                </p>
                <p className="G-mb-2 P-contact">
                  <i className="icon-ic_calendar G-mr-2" />
                  {formatDate(agentDetails.dateOfBirth)}
                </p>
                <p className="G-mb-2 P-contact">
                  <i className="icon-ic_car G-mr-2" />
                  {VehicleTypeEnum[agentDetails.vehicleTypeEnumValue]} {agentDetails.vehicleNumber}
                </p>
                {agentDetails.passportVerified ?
                  <p>
                    <i className="icon-id-card G-mr-2" />
                    {Verified}
                  </p> :
                  <p>
                    <i className="icon-id-card G-mr-2" />
                    {Not_verified} <span onClick={() => this.openModal(2)} className="P-add-btn G-ml-4">{Add}</span>
                  </p>
                }
                <p className="G-mt-2">
                  <i className="icon-id-card G-mr-2" />
                  {agentDetails.pointsLimit} <span onClick={() => this.openModal(3)} className="P-add-btn G-ml-4">{Change}</span>
                </p>
              </div>
            </div>
            {agentRightDetail ?
              <div className="P-right G-flex G-flex-wrap G-ml-3">
                <div className="P-small-block G-mb-2">
                  <div>
                    <p className="P-top">{agentRightDetail.dealsDone}</p>
                    <p className="P-bottom">{deals_done}</p>
                  </div>
                </div>
                <div className="P-small-block G-mb-2">
                  <div>
                    <div className="G-flex">
                      <p className="P-top">{agentRightDetail.soldCardboard}</p>
                      <p className="P-kg">{kg}</p>
                    </div>
                    <p className="P-bottom">{sold_cardboard}</p>
                  </div>
                </div>
                <div className="P-small-block">
                  <div>
                    <p className="P-top">{agentRightDetail.withdrawals}</p>
                    <p className="P-bottom">{withdrawals}</p>
                  </div>
                </div>
                <div className="P-small-block">
                  <div>
                    <p className="P-top">{agentRightDetail.withdrawalsAmount}</p>
                    <p className="P-bottom">{total_withdrawed_amount}</p>
                  </div>
                </div>
                <div className="P-small-block">
                  <div>
                    <p className="P-top">{agentDetails.balance}</p>
                    <p className="P-bottom">current balance</p>
                  </div>
                </div>
              </div> : ""}
          </div>
          <div className="G-flex G-justify-between G-flex-wrap P-selects-wrapper">
          </div>
          <Modal
            isOpen={modalIsOpenIndex === 2}
            onRequestClose={this.closeModal}
            style={{
              content: {
                padding: "0px",
                display: "flex",
                flexDirection: "column",
              },
            }}
          >
            <PassportDetailsModal closeModal={this.closeModal} />
          </Modal>
          <Modal
            isOpen={modalIsOpenIndex === 1}
            onRequestClose={this.closeModal}
            style={{
              content: {
                padding: "30px",
                minHeight: "180px",
                minWidth: "350px",
                display: "flex",
                flexDirection: "column",
              }
            }}
          >
            <BlockModal status={agentDetails.userStatusEnumValue} closeModal={this.closeModal} blockUser={this.blockUser} />
          </Modal>
          <Modal
            isOpen={modalIsOpenIndex === 3}
            onRequestClose={this.closeModal}
            style={{
              content: {
                padding: "30px",
                minHeight: "180px",
                minWidth: "350px",
                display: "flex",
                flexDirection: "column",
              }
            }}
          >
            <LimitsModal status={agentDetails.userStatusEnumValue} closeModal={this.closeModal}/>
          </Modal>
          {allBuyersPurchasesList.length ? <>
            <p className="P-personal-info G-mb-3">{Cardboard_purchases}</p>
            <Table columnConfig={this.configPurch}
              data={allBuyersPurchasesList}
              isFirstInit={isFirstInit}
              loaded={loaded}
            />

            <Paging
              totalPages={totalPagesPurchases}
              onChange={this.getAllOrdersByBuyerrId}
            />
          </> : ""}
          {allBuyersDeliveryList.length ? <>
            <p className="P-personal-info G-mb-3 G-mt-3">{Cardboard_delivery}</p>
            <Table columnConfig={this.configDelivery}
              data={allBuyersDeliveryList}
              isFirstInit={isFirstInit}
              loaded={loaded}
            />
            <Paging
              totalPages={totalPagesDelivery}
              onChange={this.getCardboardDeliveryByBuyerId}
            />
          </> : ""}
          {feedbackList.length ? <>
            <p className="P-personal-info G-mb-3 G-mt-3">{Feedbacks}</p>
            <Table columnConfig={this.configFedbacks}
              data={feedbackList}
              isFirstInit={isFirstInit}
              loaded={loaded}
            />
            <Paging
              totalPages={totalPagesFeedback}
              onChange={this.getFeedbackListById}
            />
          </> : ""}
        </>
          : null}
      </section >
    );
  }
}

export default AgentDetails;