import * as React from 'react';
import { ListStatusEnum } from 'src/platform/enums/enums';
import './index.scss';
import Languages from 'src/platform/services/languages';
import Loader from 'src/platform/components/loader/loader';
import { Field, Form, Formik, FormikValues } from 'formik';
import OrderController from 'src/platform/api/order/order';
interface IState {
  weightLimit: number,
  minLimit: number,
  negativeLimit: number,
  limitData: any,
}

export interface LimitsModalProps {
    closeModal: () => void;
    status: ListStatusEnum;
}

const {
    Agent_cardboard_limits,
    Save,
    Cancel,
    Weight_limit,
    Minimum_limit,
    Negative_limit,
} = Languages.Translations


class LimitsModal extends React.Component<LimitsModalProps, IState> {
  public state: IState = {
    weightLimit: null,
    minLimit: null,
    negativeLimit: null,
    limitData: {},
  }
private save = async (values: FormikValues) => {
  this.setState({
    weightLimit: values.weightLimit,
    minLimit: values.minLimit,
    negativeLimit: values.negativeLimit,
  })   
  const URL = new URLSearchParams(window.location.search);
  this.setLimits(+URL.get('id'));
}

componentDidMount() {
  this.getLimits();
}

private getLimits = async () => {
  const URL = new URLSearchParams(window.location.search);
  window.dispatchEvent(new CustomEvent('loader', { detail: true }));
  const result = await OrderController.getLimits(URL.get('id'));
  window.dispatchEvent(new CustomEvent('loader', { detail: false }));
  if (result.success) {
    this.setState({
      limitData: result.data,
      weightLimit: result.data.pointsLimit,
      minLimit: result.data.minimalPoints,
      negativeLimit: result.data.negativeLimit,
    })   
  };
}

private setLimits = async (id: number) => {
  const body = {
    minimalPoints: this.state.minLimit,
    negativeLimit: this.state.negativeLimit,
    pointsLimit: this.state.weightLimit,
  }
  window.dispatchEvent(new CustomEvent('loader', { detail: true }));
  const result = await OrderController.setLimits(id, body);
  window.dispatchEvent(new CustomEvent('loader', { detail: false }));
  if (result.success) {
    this.props.closeModal();
  }
}

    render() {
        const { weightLimit, minLimit, negativeLimit } = this.state;
        
        return (<div className="P-sign-out-modal P-block-modal">
            <h5 className="G-mb-2 P-title">{Agent_cardboard_limits}</h5>
            <div className="P-container">
          <Formik
            enableReinitialize={true}
            initialValues={{
              weightLimit: weightLimit,
              minLimit: minLimit,
              negativeLimit: negativeLimit,
            }}
            onSubmit={this.save}
          >
            {({ errors, touched, isSubmitting, values }) => (
              <Form className="P-form" noValidate={true}>
                <div className="G-flex">
                  <div className="">
                    <div className="P-inp P-inp-1">
                      <p className="P-title">{Weight_limit}</p>
                      <Field
                        type="number"
                        name="weightLimit"
                        className={`P-field ${touched.weightLimit && errors.weightLimit ? "G-error" : ""}`}
                      />
                    </div>
                    <div className="P-inp P-inp-1">
                      <p className="P-title">{Minimum_limit}</p>
                      <Field
                        type="number"
                        name="minLimit"
                        className={`P-field ${touched.minLimit && errors.minLimit ? "G-error" : ""}`}
                      />
                    </div>
                    <div className="P-inp P-inp-2">
                      <p className="P-title">{Negative_limit}</p>
                      <Field
                        type="number"
                        name="negativeLimit"
                        className={`P-field ${touched.negativeLimit && errors.negativeLimit ? "G-error" : ""}`}
                      />
                    </div>
                  </div>
                </div>
                <div className="G-flex G-justify-between G-w-100p G-mt-4">
                    <button className="G-mr-2 P-cancel" onClick={this.props.closeModal}>{Cancel}</button>
                    <button
                      type="submit"
                      className="G-button P-button"
                    > {isSubmitting ? <Loader /> : `${Save}`}
                    </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        </div>);
    }
}

export default LimitsModal;