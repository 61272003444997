import * as React from 'react';
import Helper from 'src/platform/services/helper';
import noPic from 'src/assets/images/no-photo.png'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { OrderType, OrderAgentStatusEnum, UserTypeEnum } from 'src/platform/enums/enums';
import HelperComponent from 'src/platform/classes/helper-component';
import OrderController from 'src/platform/api/order/order';
import DetailsMap from '../../sellers/orders-map/MapDetails';
import moment from 'moment';
import './index.scss';
import Languages from 'src/platform/services/languages';

export interface IProps extends RouteComponentProps {
  activeId: number;
  orderType: number;
  ordersList: Array<any>;
  acceptOrder: (id: string) => void,
  openModal: (index: number, orderId: number) => void,
}

export interface IState {
  details: any,
}

const {
  Order_,
  Seller_,
  Agent_,
  view_details,
  Cardboard_apx_weight,
  Cardboard_actual_weight,
  Cardboard_price,
  Agent_attached_date,
  Order_start_date,
  Order_finish_date,
  Accept,
  Cancel,
  Finished,
  Reject,
  enums,
  kg,
  AMD,
  Order_information,
  Any_time,
  Use_for_donation,
  Earn_money,
} = Languages.Translations

class AgentOrderDetailRight extends HelperComponent<IProps, IState> {

  public state: IState = {
    details: {},
  }

  componentDidMount() {
    this.getOrderDetails(this.props.activeId);
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.activeId !== prevProps.activeId) {
      this.getOrderDetails(this.props.activeId);
    }
  }

  private getOrderDetails = async (id: string | number) => {

    if (this.props.orderType === OrderType.Delivery) {
      window.dispatchEvent(new CustomEvent('loader', { detail: true }));
      const result = await OrderController.getAgentsDeliveryOrderDetails(this.props.activeId);
      window.dispatchEvent(new CustomEvent('loader', { detail: false }));
      if (result.success) {
        this.asyncSetState({
          details: result.data,
        });
      }
    } else {
      window.dispatchEvent(new CustomEvent('loader', { detail: true }));
      const result = await OrderController.getAgentsSelfDeliveryOrderDetails(this.props.activeId);
      window.dispatchEvent(new CustomEvent('loader', { detail: false }));
      if (result.success) {
        this.asyncSetState({
          details: result.data,
        });
      }
    }
  };

  render() {
    const { details } = this.state;
    const { orderType } = this.props;

    return (
      <>
        <div className="P-top G-flex G-align-center G-pl-2 P-arrow">
          <i className="icon-ic_back G-mr-2" onClick={() => {
            this.props.history.goBack();
          }} />
          <div className="G-flex G-justify-between P-title ">
            <span>{Order_information}</span>
            <span className="P-order-num">{Order_} #{details.orderNumber}</span>
            <span className="P-status"
              style={{
                color: `${(
                  details.orderBuyerStatusEnumValue === OrderAgentStatusEnum.PENDING) ? '#7A7A7A'
                  : details.orderBuyerStatusEnumValue === OrderAgentStatusEnum.ACCEPTED ? "#EFC700" : "#04A556"}`
              }}>{enums.OrderSellerStatusEnum[details.orderSellerStatusEnumValue]}</span>
          </div>
        </div>
        <div className="G-flex P-sub-right">
          <div className="P-information">
            <div className="P-row P-row-one G-flex">
              <div>
                {details.agentPreviewDto ? <div className="G-mb-1 P-seller">
                  {details.agentPreviewDto.userTypeValue === UserTypeEnum.Agents ? `${Agent_}` : `${Seller_}`}
                </div> : ""}
                <div className="G-flex G-align-center">
                  <div className="G-flex P-image"
                    style={{ background: `url(${details.agentPreviewDto && details.agentPreviewDto.buyerImageUrl ? `${details.agentPreviewDto.buyerImageUrl}/100/100` : noPic}) center/cover no-repeat` }}>
                  </div>
                  <div className="G-flex G-flex-column">
                    <span className="G-break-word">{details.agentPreviewDto ? details.agentPreviewDto.buyerFullName : ""}</span>
                    {details.agentPreviewDto ?
                      <Link to={details.agentPreviewDto.userTypeValue === UserTypeEnum.Agents ?
                        `/customers/agents/details?id=${details.agentPreviewDto.buyerId}` :
                        `/customers/sellers/details?id=${details.agentPreviewDto.buyerId}`}
                        className="G-green G-cursor">{view_details}
                      </Link>
                      : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="P-row P-row-two">
              {details.agentPreviewDto && details.agentPreviewDto.userTypeValue === UserTypeEnum.Sellers ?
                <div className="G-flex G-align-center">
                  <p>{details.isDonate ? Use_for_donation : Earn_money}</p>
                </div>
                : ''}
              <div className="G-flex G-align-center">
                <p>{Order_start_date} </p>
                <p>
                  {moment(details.createdDate).format('DD MMM YYYY HH:mm')}
                </p>
              </div>
              <div className="G-flex">
                {OrderType.Delivery === orderType
                  && <>
                    <p className="G-mr-1"> {Agent_attached_date} </p>
                    {details.attachedDate ? <p>
                      {moment(details.attachedDate).format('DD MMM YYYY HH:mm')}
                    </p> : <p className="G-mr-6">-</p>}
                  </>}
              </div>
              <div className="G-flex">
                <p className="G-mr-1">{Order_finish_date} </p>
                <p>
                  {moment(details.lastModifiedDate).format('DD MMM YYYY HH:mm')}
                </p>
              </div>
            </div>
            <div className="P-row P-row-three">
              {orderType === OrderType.Delivery ? <div className="G-flex">
                <i className="icon-ic_map" />
                <div>
                  {details && details.addressForOrderDto &&
                    `${details.addressForOrderDto.addressName},
                                        ${details.addressForOrderDto.apartment ? details.addressForOrderDto.apartment + ' app,' : ''}
                                        ${details.addressForOrderDto.building ? details.addressForOrderDto.building + ' building,' : ''} 
                                        ${details.addressForOrderDto.entrance ? details.addressForOrderDto.entrance + ' entrance,' : ''} 
                                        ${details.addressForOrderDto.floor ? details.addressForOrderDto.floor + ' floor' : ''}`
                  }
                </div>
              </div>
                : <div className="G-flex ">
                  <i className="icon-ic_map" />
                  <div>{details && details.deliverAddressForUser && details.deliverAddressForUser.addressName}</div>
                </div>}
              {details.orderTimes ? <div className="G-flex">
                {orderType === OrderType.Delivery && details.orderTimes.length ? <i className="icon-clock-5" /> : ""}
                {orderType === OrderType.Delivery && details.orderTimes.length ?
                  <div className="G-flex G-align-center P-clock-row">
                    {details && details.orderTimes && details.orderTimes.map((item: any, index: any) => {
                      let from = item.fromTime;
                      let to = item.toTime;
                      return (<div key={index} className="G-mr-1">
                        {item.fromTime !== 0 ? <>
                          {moment(new Date((from * 1000) - 14400000)).format('HH:mm')} -
                          {moment(new Date((to * 1000) - 14400000)).format('HH:mm')}</> : `${Any_time}`}
                      </div>)
                    })}
                  </div>
                  :
                  //     <div className="G-flex G-align-center">
                  //     <i className="icon-clock-5" />
                  //     {details && details.deliverAddressForUser && details.deliverAddressForUser.workingDateTimeDtos.map((item: any, index: any) => {
                  //         let from = item.fromTime;
                  //         let to = item.toTime;
                  //         return (<div key={index} className="G-mr-1">
                  //             {moment(new Date((from * 1000) - 14400000)).format('HH:mm')} - {moment(new Date((to * 1000) - 14400000)).format('HH:mm')}
                  //         </div>)
                  //     })}
                  // </div>
                  ""}
              </div> : ""}

              <div className="G-flex">
                {!!details.comment ? <i className="icon-ic_comment" /> : ""}
                {!!details.comment && <div className="G-break-word">
                  {details.comment}
                </div>}
              </div>
            </div>
            <div className="P-row P-row-four">
              <div>
                <span className="P-four-titles">{Cardboard_apx_weight}</span>
                <span className="G-bold P-forSale">{details.weightForSale} {kg}</span>
              </div>
              <div>
                <span className="P-four-titles">{Cardboard_actual_weight}</span>
                {details.finalWeight ? <span className="G-bold P-weight-final">{details.finalWeight} {kg}</span> : "-"}
              </div>
              <div>
                <span className="P-four-titles">{Cardboard_price}</span>
                {details.price ? <span className="G-bold P-weight-final">{details.price} {AMD}</span> : "-"}
              </div>
            </div>
            {Helper.role ? <>
              {(details.statusEnumValue || details.orderBuyerStatusEnumValue) !== OrderAgentStatusEnum.ACCEPTED ?
                <div className="G-flex G-mt-4">
                  <div className="P-accept P-order-btn G-cursor G-mr-2"
                    onClick={
                      (evt: React.SyntheticEvent) => {
                        if (Helper.role) {
                          evt.stopPropagation();
                          this.props.acceptOrder(details.id);
                        }
                      }
                    }>{Accept}</div>

                  <div className="P-reject P-order-btn G-cursor"
                    onClick={
                      (evt: React.SyntheticEvent) => {
                        if (Helper.role) {
                          evt.stopPropagation();
                          this.props.openModal(2, details.id);
                        }
                      }
                    }>{Reject}</div>
                </div>
                :
                <div className="G-flex P-finish-container G-mt-4">
                  <div className="P-finish P-order-btn G-cursor G-mr-2"
                    onClick={
                      (evt: React.SyntheticEvent) => {
                        if (Helper.role) {
                          evt.stopPropagation();
                          this.props.openModal(1, details.id);
                        }
                      }
                    }>{Finished}</div>

                  <div className="P-cancel P-order-btn G-cursor"
                    onClick={
                      (evt: React.SyntheticEvent) => {
                        if (Helper.role) {
                          evt.stopPropagation();
                          this.props.openModal(3, details.id);
                        }
                      }
                    }>{Cancel}
                  </div>
                </div>}
            </> : null}
          </div>
          {<div style={{ height: "auto", width: '100%' }}>
            {details ? <DetailsMap
              data={orderType === OrderType.Delivery ? details.addressForOrderDto : details.deliverAddressForUser}
              details={details}
            /> : null}
          </div>}
        </div>
      </>);
  }

}

export default withRouter(AgentOrderDetailRight);

