import * as React from 'react';
import HelperComponent from 'src/platform/classes/helper-component';
import { userTypeOptions } from 'src/platform/constants/casual';
import DatePicker from "react-datepicker";
import Select from 'src/platform/components/select/Select';
import Message from '../components/message';
import Helper from 'src/platform/services/helper';
import { store } from 'react-notifications-component';
import SupportController from 'src/platform/api/support/support';
import './index.scss';
import Languages from 'src/platform/services/languages';

interface IState {
  date: Date,
  userType: string,
  page: number,
  size: number,
  archiveList: Array<any>,
  requestIsOff: boolean,
  totalPages: number,
  totalElements: number,
  searchMessage: string,
  userNameOrPhone: string,
  loaded: boolean,
  isFirstInit: boolean,
}

const {
  Archive_,
  Search_message,
  Date_,
  User_name_or_contacts,
  User_type,
  Reset,
  list_is_empty,
  no_search,
} = Languages.Translations;

class Archive extends HelperComponent {

  public state: IState = {
    page: 0,
    size: 6,
    totalPages: null,
    totalElements: null,
    archiveList: [],
    requestIsOff: false,

    searchMessage: "",
    userNameOrPhone: "",
    date: null,
    userType: "",
    loaded: false,
    isFirstInit: true,
  }

  componentDidMount() {
    this.getArchiveList()
    window.addEventListener('scroll', this.handleScroll)
  }

  public reset = () => {

    this.asyncSetState({
      searchMessage: "",
      userNameOrPhone: "",
      date: null,
      userType: "",
      page: 0,
    }).then(() => {
      this.getArchiveList();
    })
  }
  public getArchiveList = async () => {
    const { page, size, searchMessage, userNameOrPhone, date, userType } = this.state;

    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const res = await SupportController.getArchiveList({ page, size, body: { searchMessage, userNameOrPhone, date, userType } });
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
    if (res.success) {
      this.asyncSetState({
        archiveList: res.data.content,
        totalPages: res.data.totalPages,
        totalElements: res.data.totalElements,
        loaded: true,
        isFirstInit: this.state.isFirstInit && !res.data.content.length
      })
    } else {
      store.addNotification(Helper.notificationConfig(res.message, false));
    }
  }

  loadMore = async () => {
    const { requestIsOff, archiveList, page, size, totalPages, searchMessage, userNameOrPhone, date, userType, } = this.state;

    if (!requestIsOff && page < totalPages) {
      this.asyncSetState({ requestIsOff: true })
      const res = await SupportController.getArchiveList({ 
        page: this.state.page + 1, 
        size, 
        body: { searchMessage, userNameOrPhone, date, userType }  });
      if (res && res.success) {
        this.asyncSetState({
          archiveList: [...archiveList, ...res.data.content],
          page: this.state.page + 1,
          totalPages: res.data.totalPages,
        });

        this.asyncSetState({ requestIsOff: false });
      }else{
        store.addNotification(Helper.notificationConfig(res.message, false));
      }
    }
  }

  public handleScroll = (e: any) => {
    if (document.documentElement.scrollHeight - document.documentElement.scrollTop - document.documentElement.clientHeight - 50 <= 0) {
      this.loadMore();
    }
  }
  public inputTimer: any;

  public onSearchMessages = async (evt: React.SyntheticEvent<HTMLInputElement>) => {

    await this.asyncSetState({ searchMessage: evt.currentTarget.value, page: this.state.page = 0 })
    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(() => {
      this.getArchiveList();
    }, 300);
  }

  public onSearchContacts = async (evt: React.SyntheticEvent<HTMLInputElement>) => {

    await this.asyncSetState({ userNameOrPhone: evt.currentTarget.value, page: this.state.page = 0 })
    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(() => {
      this.getArchiveList();
    }, 300);
  }
  
  downloadArchiveSupportExcelFile = async () => {
    const { searchMessage, userNameOrPhone, date, userType } = this.state;

    const body = {
      searchMessage, 
      userNameOrPhone, 
      date, 
      userType, 
    }

    const result = await SupportController.downloadArchiveSupportExcelFile(body);
    window.open(result.data, '_blank');
  }

  public render() {

    const { date, userType, archiveList, searchMessage, userNameOrPhone, loaded, isFirstInit, totalElements } = this.state;

    return (
      <section className="G-main-page P-archive-page" onScroll={this.handleScroll}>
        <div className="G-flex G-mb-2 G-justify-between G-align-center">
          <h4 className="">{Archive_} ({totalElements})</h4>
          <button className="G-gray-btn" onClick={this.downloadArchiveSupportExcelFile}>Export</button>
        </div>
        <div className="G-flex G-flex-wrap P-selects-wrapper">
          <input className="G-text-input G-mr-2 G-mb-2"
            placeholder={Search_message}
            type="text"
            value={searchMessage}
            onChange={this.onSearchMessages}
          />
          <input className="G-text-input G-mr-2 G-mb-2"
            placeholder={User_name_or_contacts}
            value={userNameOrPhone}
            onChange={this.onSearchContacts}
            type="text" />
          <div className='G-mr-2 G-mb-2'>
            <DatePicker
              className="G-text-input G-cursor"
              selected={date}
              placeholderText={Date_}
              onChange={(date: Date) => this.asyncSetState({ date: date.getTime() }).then(() => {
                this.getArchiveList();
              }) }
            />
          </div>
          <Select
            width="220px"
            name="userType"
            placeholder={User_type}
            options={userTypeOptions}
            value={userType}
            setFieldValue={(name: string, value: string) => this.asyncSetState({ userType: value }).then(() => {
              this.getArchiveList();
            }) }
            className="G-mb-2 G-mr-2"
          />
          <span className="G-button P-button" onClick={this.reset}>{Reset}</span>
        </div>
        {!!archiveList.length && archiveList.map((item, index) => {
          return <Message key={index} item={item} getList={null}/>
        }, this.getArchiveList)}
        {isFirstInit && !archiveList.length && loaded && <h3 className="no-result">{list_is_empty}</h3>}
        {!isFirstInit && !archiveList.length && loaded && <h3 className="no-result">{no_search}</h3>}
      </section >
    );
  }
}

export default Archive;