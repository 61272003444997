import * as React from 'react';
import { Link } from 'react-router-dom';
import "./index.scss"
import Languages from 'src/platform/services/languages';
import { ColorEnum } from 'src/platform/enums/enums';

const {
  list_is_empty,
  no_search,
} = Languages.Translations;

type TableCellType = string | number | HTMLElement | React.ReactNode | null;

export interface ITableColumnConfig<Data extends object> {
  cell(row: Data, index: number): TableCellType;
  wrapperClass?(row: Data, index?: number): string;
  className?: string;
  name?: string | number | HTMLElement | React.ReactNode;
  style?: React.CSSProperties;
  openModal?: any;
};

interface IProps<Data extends { [key: string]: any }> {
  onRowClick?(row: Data, index?: number): void;
  hoverButtons?(row: Data, index?: number): HTMLElement | React.ReactNode;
  columnConfig: Array<ITableColumnConfig<Data>>;
  data: Data[];
  redirectUrl?(row: Data, index?: number): string;
  scroll?: (e: any) => void;
  isFirstInit?: boolean;
  loaded?: boolean;
};

class Table<Data extends { [key: string]: any }> extends React.PureComponent<IProps<Data>, {}> {

  private getRowTitle = (column: ITableColumnConfig<Data>, item: Data, index: number) => {
    const value = column.cell(item, index);
    if (typeof value === 'string' ||
      typeof value === 'number') return value.toString();

    return "";
  }
  
  shouldComponentUpdate(nextprops:any,nextState:any) {
    return true
  }

  public render() {

    const { columnConfig, data, onRowClick, redirectUrl, hoverButtons, isFirstInit, loaded } = this.props;
    
    const Row = redirectUrl ? Link : 'ul';
    return (
      <div className="HQ-data-table">
          <ul className="HQ-data-table-header">
            {columnConfig.map((item, index) => <li className={item.className} key={index} style={item.style || {}}>
              <span>{item.name}</span>
            </li>)}
          </ul>
          {!!data && !!data.length && 
          <div className="HQ-data-table-body" onScroll={this.props.scroll}>
            {data.map((item, rowIndex) => onRowClick
              ?
              <Row to={redirectUrl && redirectUrl(item, rowIndex)}
                  onClick={() => onRowClick && onRowClick(item, rowIndex)}
                  key={rowIndex}
                  className="HQ-pointer"
                  style={{ backgroundColor: item.colorEnumValue === ColorEnum.Yellow ? 'rgba(239,199,0,0.14)' : item.colorEnumValue === ColorEnum.Red ? 'rgba(227,5,88,0.14)' : '#ffffff'}}
              >
                {columnConfig.map((childItem, index) =>
                  <li key={index} title={this.getRowTitle(childItem, item, rowIndex)} style={childItem.style || {}}>
                    <div className={childItem.wrapperClass && childItem.wrapperClass(item)}>
                        {childItem.cell(item, rowIndex)}
                    </div>
                  </li>
                )}
                <li className="HQ-data-table-actions">{hoverButtons && hoverButtons(item, rowIndex)}</li>
              </Row>
              : <Row to={redirectUrl && redirectUrl(item, rowIndex)} key={item.id || rowIndex}>
                    {columnConfig.map((childItem, index) => 
                      <li key={index} title={this.getRowTitle(childItem, item, rowIndex)} style={childItem.style || {}}>
                        <div className={childItem.wrapperClass && childItem.wrapperClass(item)}>
                            {childItem.cell(item, rowIndex)}
                        </div>
                      </li>
                    )}
                    <li className="HQ-data-table-actions">
                        {hoverButtons && hoverButtons(item, rowIndex)}
                    </li>
                </Row>)}
          </div>}
          
            {isFirstInit && !data.length && loaded && <h3 className="no-result">{list_is_empty}</h3>}
            {!isFirstInit && !data.length && loaded && <h3 className="no-result">{no_search}</h3> }
      </div>
    );
  }
}

export default Table;
