import * as React from 'react';
import Table, { ITableColumnConfig } from 'src/platform/components/table/table';
import { ListStatusEnum, AccountTypeEnum, OrderAgentStatusEnum, EcoTypeEnum } from 'src/platform/enums/enums';
import { ISellerDetailsListModel } from 'src/platform/api/customers/interfaces/sellersModel';
import { RouteComponentProps, Link } from 'react-router-dom';
import HelperComponent from 'src/platform/classes/helper-component';
import { ROUTES } from 'src/platform/constants/routes';
import Modal from 'react-modal';
import BlockModal from '../../components/blockModal';
import TaxModal from '../../components/tax-modal/taxModal';
import UserController from 'src/platform/api/user/user';
import Helper from 'src/platform/services/helper';
import { store } from 'react-notifications-component';
import { IUserDetailsModel, IUserRightDetailsModel, ISellerFeedbackModel } from 'src/platform/api/customers/interfaces/agentsModel';
import noPic from 'src/assets/images/no-photo.png'
import { formatDate } from 'src/platform/services/helper';
import OrderController from 'src/platform/api/order/order';
import './index.scss';
import moment from 'moment';
import Paging from 'src/platform/components/pagination/pagination';
import Languages from 'src/platform/services/languages';
import DonationsController from 'src/platform/api/donations/donations';
import { IGetDonationsBySellerId } from 'src/platform/api/donations/donations-interface';
import master from 'src/assets/images/ic_master.png'
import genius from 'src/assets/images/ic_genius.png'
import hero from 'src/assets/images/ic_hero.png'
import citizen from 'src/assets/images/ic_green.svg'
import { IGetActivityListRequestModel } from 'src/platform/api/user/user-interface';

interface IState {
 
  modalIsOpenIndex: number,
  sellerDetails: IUserDetailsModel,
  allOrdersList: Array<any>,
  loaded: boolean;
  isFirstInit: boolean,
  page: number,
  size: number,
  sellerId: any,
  totalPages: number,
  totalPagesDonation: number,
  totalPagesFeedback: number,
  sellerRightDetail: IUserRightDetailsModel,
  donationsList: Array<any>,
  feedbackList: Array<any>,
  activityList: Array<any>,
  taxModal: boolean,

}

const {
  enums,
  Sellers_,
  Deal_ID,
  Cardboard_weight,
  Amount,
  Location,
  Agent_,
  Block_user,
  Unblock_user,
  Creation_date,
  Status,
  User_details,
  Personal_information,
  Active,
  Blocked,
  Deactivated,
  deals_done,
  sold_cardboard,
  donations,
  total_donated_amount,
  kg,
  Projects,
  Winner,
  Ratings,
  Feedbacks,
  Order_,
  Donation_date,
  vote_date,
  Campaign,
  Deleted,
  Set,
  Name,
  Current_balance,
  Date_,
  User_status_activity
} = Languages.Translations;

class SellerDetails extends HelperComponent<RouteComponentProps, IState> {

  public state: IState = {
    taxModal: false,
    modalIsOpenIndex: null,
    sellerDetails: null,
    allOrdersList: [],
    loaded: false,
    isFirstInit: true,
    page: 0,
    size: 8,
    totalPages: null,
    totalPagesDonation: null,
    totalPagesFeedback: null,
    sellerId: null,
    sellerRightDetail: null,
    feedbackList: [],
    donationsList: [],
    activityList: []
  }
 
  public config: Array<ITableColumnConfig<ISellerDetailsListModel>> = [
    {
      name: `${Deal_ID}`,
      style: { maxWidth: 100 , marginRight: "2%"},
      cell: function (row: ISellerDetailsListModel) {
        return row.orderNumber;
      },
    },
    {
      name: `${Cardboard_weight}`,
      style: { maxWidth: 120 , marginRight: "2%"},
      cell: function (row: ISellerDetailsListModel) {
        return row.weight;
      },
    },
    {
      name: `${Amount}`,
      style: { maxWidth: 100 , marginRight: "2%"},
      cell: function (row: ISellerDetailsListModel) {
        return row.price;
      }
    },
    {
      name: `${Location}`,
      style: { maxWidth: 180 , marginRight: "2%"},
      cell: function (row: ISellerDetailsListModel) {
        return <div className="G-flex-wrap">{row.addressForOrderDto.addressName}</div>;
      },
    },
    {
      name: `${Agent_}`,
      style: { marginRight: "2%"},
      cell: (row: ISellerDetailsListModel) => {
        return (
          <div className="G-cursor G-flex G-align-center" onClick={() => {
            if (row.agentPreviewDto.buyerId) {
              this.props.history.push(`/customers/agents/details?id=${row.agentPreviewDto.buyerId}`)
            }
          }}>
            <span className="P-seller-image G-mr-1"
              style={{ background: `url(${row.agentPreviewDto.buyerImageUrl ? `${row.agentPreviewDto.buyerImageUrl}/100/100` : noPic}) center/cover no-repeat` }}>
            </span>
            <span className="G-flex G-flex-wrap G-break-word">
              {row.agentPreviewDto.buyerFullName ? row.agentPreviewDto.buyerFullName : "-"}
            </span>
          </div>
        )
      }
    },
    {
      name: Creation_date,
      cell: function (row: ISellerDetailsListModel) {
        return <>{row.createdDate ? moment(row.createdDate).format('DD MMM YYYY') : "-"}</>;
      }
    },
    {
      name: `${Status}`,
      cell: (row: ISellerDetailsListModel) => {
        return (<div className=""
        style={{
          color: `${(row.orderSellerStatusEnumValue === OrderAgentStatusEnum.AGENT_REQUESTED ||
            row.orderSellerStatusEnumValue === OrderAgentStatusEnum.AGENT_CHOSEN ||
            row.orderSellerStatusEnumValue === OrderAgentStatusEnum.AGENT_FOUND ||
            row.orderSellerStatusEnumValue === OrderAgentStatusEnum.PENDING)
            ? '#7A7A7A'
            : row.orderSellerStatusEnumValue === OrderAgentStatusEnum.CANCELED ||
              row.orderSellerStatusEnumValue === OrderAgentStatusEnum.REJECTED
              ? '#FF0057' : "#04A556"}`
        }}>
        {enums.OrderAgentStatusEnum[row.orderSellerStatusEnumValue]}
        </div>)
      },
      style: { maxWidth: 100, marginRight: "1%" },
    },
  ]

  public configDonation: Array<ITableColumnConfig<IGetDonationsBySellerId>> = [
    {
      name: `${Campaign} №`,
      cell: function (row: IGetDonationsBySellerId) {
        return (
            <span className="G-flex G-flex-wrap G-break-word">
              {row.campaignNumber}
            </span>
        );
      },
    },
    {
      name: `${Projects}`,
      style: { marginRight: "2%" },
      cell: function (row: IGetDonationsBySellerId) {
        return (
          <span className="G-flex G-flex-wrap G-break-word">
            {row.voteProjectName}
          </span>
      );
      },
    },
    {
      name: `${Winner}`,
      style: { marginRight: "2%" },
      cell: function (row: IGetDonationsBySellerId) {
        return (<div className="G-break-word">{row.campaignWinnerProjectName ? row.campaignWinnerProjectName : "-"}</div>);
      }
    },
    {
      name: `${Amount}`,
      style: { marginRight: "2%", maxWidth: 100 },
      cell: function (row: IGetDonationsBySellerId) {
        return row.donatedAmount;
      },
    },
    {
      name: `${vote_date}`,
      cell: function (row: IGetDonationsBySellerId) {
        return ( moment(row.voteDate).format('DD MMM YYYY HH:mm') );
      }
    },
    {
      name: `${Donation_date}`,
      cell: function (row: IGetDonationsBySellerId) {
        return ( moment(row.donationDate).format('DD MMM YYYY HH:mm') );
      }
    },
  ]

  public configFedbacks: Array<ITableColumnConfig<ISellerFeedbackModel>> = [
    {
      name: `${Agent_}`,
      cell: (row: ISellerFeedbackModel) => {
        return (
          <div className="G-cursor G-flex G-align-center" onClick={() => {
            this.props.history.push(`/customers/agents/details?id=${row.userId}`);
          }}>
            <span className="P-seller-image G-mr-1"
              style={{ background: `url(${row.userImage ? `${row.userImage}/100/100` : noPic}) center/cover no-repeat` }}>
            </span>
            <span className="G-flex G-flex-wrap G-break-word">
              {row.userName}
            </span>
          </div>
        );
      },
    },
    {
      name: `${Ratings}`,
      cell: function (row: ISellerFeedbackModel) {
        return (
          Array.from({length: row.rate}).map(() => <i className="G-mr-1 icon-ic_star"></i>)
        );
      },
    },
    {
      name: `${Feedbacks}`,
      style: { marginRight: "2%" },
      cell: function (row: ISellerFeedbackModel) {
        return (<div className="G-break-word">{row.feedback ? row.feedback : "-"}</div>);
      }
    },
    {
      name: `${Order_}`,
      cell: function (row: ISellerFeedbackModel) {
        return row.orderNumber;
      },
    },
    {
      name: Creation_date,
      cell: function (row: ISellerFeedbackModel) {
        return ( moment(row.createdDate).format('DD MMM YYYY HH:mm') );
      }
    },
  ]
  
  public configActivity: Array<ITableColumnConfig<IGetActivityListRequestModel>> = [
    {
      name: `${Status}`,
      cell: function (row: IGetActivityListRequestModel) {
        return (
            <span className="G-flex G-flex-wrap G-break-word">
              {AccountTypeEnum[row.accountType]}
            </span>
        );
      },
    },
    {
      name: `${Name}`,
      style: { marginRight: "2%" },
      cell: function (row: IGetActivityListRequestModel) {
        return (
          <span className="G-flex G-flex-wrap G-break-word">
            {row.fullName}
          </span>
      );
      },
    },
    {
      name: `${Date_}`,
      cell: function (row: IGetActivityListRequestModel) {
        return ( moment(row.createDate).format('DD MMM YYYY HH:mm') );
      }
    },
    {
      name: `${Current_balance}`,
      style: { marginRight: "2%", maxWidth: 100 },
      cell: function (row: IGetActivityListRequestModel) {
        return row.balance;
      },
    },
  ]

  private blockUser = async (id: string) => {
    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const res = await UserController.blockUser(id);

    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
    if (res.success) {
      this.asyncSetState({
        modalIsOpenIndex: null
      })
      const URL = new URLSearchParams(this.props.history.location.search);
      this.getSellerDetails(URL.get('id'))
    } else {
      store.addNotification(Helper.notificationConfig(res.message, false));
    }
  }

  private setTaxNumber = async (id: string, taxNumber: string) => {
    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const res = await UserController.setTaxNumber(id, taxNumber);

    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
    if (res.success) {
      this.asyncSetState({
        taxModal: false
      })
      const URL = new URLSearchParams(this.props.history.location.search);
      this.getSellerDetails(URL.get('id'))
    } else {
      store.addNotification(Helper.notificationConfig(res.message, false));
    }
  }

  componentDidMount() {
    const URL = new URLSearchParams(this.props.history.location.search);
    this.getSellerDetails(URL.get('id'))
  }

  public inputTimer: any;

  public getFeedbackListBySellerId = async (pageNum?: { selected: number }) => {
    const {page, size, sellerId} = this.state
    const result = await UserController.getFeedbackListBySellerId({ 
      page: pageNum ? pageNum.selected : page, 
      size, 
      sellerId: sellerId
    })
    if (result.success) {
      this.setState({
        feedbackList: result.data.content,
        totalPagesFeedback: result.data.totalPages,
        loaded: true,
        isFirstInit: this.state.isFirstInit && !result.data.length
      })
    }
  }
  
  public getSellerAccounts = async () => {
    const {sellerId} = this.state
    const result = await UserController.getSellerAccounts(sellerId)
    if (result.success) {
      this.setState({
        activityList: result.data,
        totalPagesFeedback: result.data.totalPages,
        loaded: true,
        isFirstInit: this.state.isFirstInit && !result.data.length
      })
    }
  }

  private getSellerDetails = async (id: string) => {
    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const res = await UserController.userDetails(id);
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));

    if (res.success) {
      this.asyncSetState({ sellerDetails: res.data, sellerId: res.data.id, sellerRightDetail: res.data.sellerDetail })
      this.getAllOrdersBySellerId()
      this.getFeedbackListBySellerId()
      this.getListDonationBySellerId()
      this.getSellerAccounts()
      
    } else {
      store.addNotification(Helper.notificationConfig(res.message, false));
    }
  }
  public getAllOrdersBySellerId = async (pageNum?: { selected: number }) => {
    const {page, size, sellerId} = this.state
    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(async () => {
    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const result = await OrderController.getAllOrdersBySellerId({
      page: pageNum ? pageNum.selected : page,
      size,
      sellerId: sellerId
      })
    if (result.success) {
      this.setState({
        totalPages: result.data.totalPages,
        allOrdersList: result.data.content,
        loaded: true,
        isFirstInit: this.state.isFirstInit && !result.data.content.length
      })
    }
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
  }, 300);
  }

  private getListDonationBySellerId = async (pageNum?: { selected: any }) => {
    const {page, size, sellerId} = this.state
    const result = await DonationsController.getListBySellerId({
      page: pageNum ? pageNum.selected : page,
      size,
      sellerId: sellerId
    });

    if (result.success) {
      this.asyncSetState({ 
        donationsList: result.data, 
      })
    }
  }

  public addTax = () => {
    this.asyncSetState({ taxModal: true });
  }

  public closeTax = () => {
    this.asyncSetState({ taxModal: false });
  }

  public openModal = (index: number) => {
    this.asyncSetState({ modalIsOpenIndex: index });
  }

  public closeModal = () => {
    this.asyncSetState({ modalIsOpenIndex: null });
  }

  public render() {
    const { modalIsOpenIndex, 
            sellerDetails, 
            sellerRightDetail, 
            allOrdersList, 
            isFirstInit, 
            loaded, 
            totalPages, 
            totalPagesDonation,
            feedbackList, 
            activityList,
            totalPagesFeedback, 
            taxModal,
            donationsList } = this.state;

    return (
      <section className="P-seller-details-page">
        {sellerDetails ? <>
          <div className="G-flex G-mb-2 G-justify-between G-align-center">
            <div className="G-flex">
              <Link to={`${ROUTES.customers}/sellers`}>
                <h4 className="G-mr-1">
                  {Sellers_}
              </h4>
              </Link>
              <h4>/</h4>
              <h4 className="G-ml-1 P-crumb">{User_details}</h4>
            </div>
            {sellerDetails.userStatusEnumValue !== ListStatusEnum.Deactivated ? <span className=" G-button P-button nowrap"
              onClick={() => this.asyncSetState({ modalIsOpenIndex: 1 })}>
              {sellerDetails.userStatusEnumValue === ListStatusEnum.Blocked ? `${Unblock_user}` : `${Block_user}`}
            </span> : null}
          </div>
          <div className="P-details-box G-flex G-justify-between G-mb-4">
            <div className="P-left G-mb-2 G-flex G-justify-between">
              <div>
                <p className="P-personal-info">{Personal_information}</p>
                <div className="G-mt-4 P-user-image-block">
                  <div className="P-image G-mr-4 G-mr-1"
                    style={{ background: `url(${sellerDetails.imageUrl ? `${sellerDetails.imageUrl}/500/500` : noPic}) center/cover no-repeat` }}>
                      <div className="P-user-type" style={{ 
                        background: `url(${
                          sellerDetails.sellerStatusEnumValue === EcoTypeEnum.HERO ? hero :
                          sellerDetails.sellerStatusEnumValue === EcoTypeEnum.CITIZEN ? citizen : 
                          sellerDetails.sellerStatusEnumValue === EcoTypeEnum.GENIUS ? genius :
                          sellerDetails.sellerStatusEnumValue === EcoTypeEnum.MASTER ? master : ""
                        }) center/cover no-repeat` }} >
                      </div>
                  </div>
                  <div className="P-name-block">
                    <p className="P-name">{sellerDetails.fullName ? sellerDetails.fullName : sellerDetails.companyName}</p>
                    <div className="G-flex">
                      <p className="G-mr-1">{sellerDetails ? enums.AccountTypeEnum[sellerDetails.userAccountTypeEnumValue]: null}</p>
                      <p className="G-mr-1 P-dot">•</p>
                      <p className={`${sellerDetails.userStatusEnumValue === ListStatusEnum.Blocked ? "P-blocked" :
                        sellerDetails.userStatusEnumValue === ListStatusEnum.Active ? "P-active" : "P-deactivated"}`}
                      >
                        {`${sellerDetails.userStatusEnumValue === ListStatusEnum.DELETED ? `${Deleted}` :
                          sellerDetails.userStatusEnumValue === ListStatusEnum.Blocked ? `${Blocked}` :
                          sellerDetails.userStatusEnumValue === ListStatusEnum.Active ? `${Active}` : `${Deactivated}`}`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="P-contacts G-mt-4">
                <p className="G-mb-2 P-contact">
                  <i className="icon-ic_phone G-mr-2" />
                  {sellerDetails.phoneNumber}
                </p>
                <p className="G-mb-2 P-contact">
                      <i className="icon-ic_mail G-mr-2" />
                      {sellerDetails.email ? sellerDetails.email : "-"}
                </p>
                {sellerDetails.userAccountTypeEnumValue !== AccountTypeEnum.Corporate ?
                  <> 
                    <p className="G-mb-2 P-contact">
                      <i className="icon-ic_calendar G-mr-2" />
                      {formatDate(sellerDetails.dateOfBirth)}
                    </p>
                  </>
                  : <p className="G-mb-2 P-contact">
                      <i className="icon-ic_tax G-mr-2" />
                      {sellerDetails.taxNumber} <p className="P-add-tax-btn" onClick={() => this.addTax()} >{Set}</p>
                  </p>}
             
              </div>
            </div>
            {sellerRightDetail ?
            <div className="P-right G-flex G-flex-wrap G-ml-3">
              <div className="P-small-block G-mb-2">
                <div>
                  <p className="P-top">{sellerRightDetail.dealsDone}</p>
                  <p className="P-bottom">{deals_done}</p>
                </div>
              </div>
              <div className="P-small-block G-mb-2">
                <div>
                  <div className="G-flex">
                    <p className="P-top">{sellerRightDetail.soldCardboard}</p>
                    <p className="P-kg">{kg}</p>
                  </div>
                    <p className="P-bottom">{sold_cardboard}</p>
                </div>
              </div>
              <div className="P-small-block">
                <div>
                  <p className="P-top">{sellerRightDetail.donations}</p>
                  <p className="P-bottom">{donations}</p>
                </div>
              </div>
              <div className="P-small-block">
                <div>
                  <p className="P-top">{sellerRightDetail.totalDonatedAmount}</p>
                  <p className="P-bottom">{total_donated_amount}</p>
                </div>
              </div>
              <div className="P-small-block">
                <div>
                  <p className="P-top">{sellerDetails.balance}</p>
                  <p className="P-bottom">current balance</p>
                </div>
              </div>
            </div> : ""}
          </div>
          <div className="G-flex G-justify-between G-flex-wrap P-selects-wrapper">
          </div>
          {allOrdersList.length ? <>
            <Table 
              columnConfig={this.config} 
              data={allOrdersList}
              isFirstInit={isFirstInit}
              loaded={loaded} 
              />
            <Paging
              totalPages={totalPages}
              onChange={this.getAllOrdersBySellerId}
            />
          </>: ""}
          {donationsList.length ? <>
            <p className="P-personal-info G-mb-3 G-mt-3">{donations}</p>
              <Table columnConfig={this.configDonation}
                data={donationsList}
                isFirstInit={isFirstInit}
                loaded={loaded}
              />
              <Paging
                totalPages={totalPagesDonation}
                onChange={this.getListDonationBySellerId}
              />
          </> : ""}
          {feedbackList.length ? <>
            <p className="P-personal-info G-mb-3 G-mt-3">{Feedbacks}</p>
              <Table columnConfig={this.configFedbacks}
                data={feedbackList}
                isFirstInit={isFirstInit}
                loaded={loaded}
              />
              <Paging
                totalPages={totalPagesFeedback}
                onChange={this.getFeedbackListBySellerId}
              />
          </> : ""}
          {activityList.length ? <>
            <p className="P-personal-info G-mb-3 G-mt-3">{User_status_activity}</p>
              <Table columnConfig={this.configActivity}
                data={activityList}
                isFirstInit={isFirstInit}
                loaded={loaded}
              />
          </> : ""}
          <Modal
            isOpen={modalIsOpenIndex === 1}
            onRequestClose={this.closeModal}
            style={{
              content: {
                padding: "30px",
                minHeight: "180px",
                minWidth: "350px",
                display: "flex",
                flexDirection: "column",
              }
            }}
          >
            <BlockModal closeModal={this.closeModal} blockUser={this.blockUser} status={sellerDetails.userStatusEnumValue} />
          </Modal>
          <Modal
            isOpen={taxModal}
            onRequestClose={this.closeTax}
            style={{
              content: {
                padding: "30px",
                minHeight: "180px",
                minWidth: "350px",
                display: "flex",
                flexDirection: "column",
              }
            }}
          >
            <TaxModal closeModal={this.closeTax} setTaxNumber={this.setTaxNumber} taxNumber={sellerDetails.taxNumber}/>
          </Modal>
        </>
          : null}
      </section >
    );
  }
}

export default SellerDetails; 