export enum ListStatusEnum {
  Active = 1,
  Blocked,
  Deactivated,
  DELETED,
  NotActive,
}

export enum OrderSellerStatusEnum {
  PENDING = 1,
  AGENT_FOUND ,
  AGENT_IN_PLACE ,
  AGENT_REQUESTED ,
  AGENT_CHOSEN ,
  FINISHED ,
  CANCELED ,
  REJECTED,
  ACCEPTED,

  "Pending" = 1,
  "Agent found",
  "Agent in place",
  "Agent requested",
  "Agent chosen",
  "Finished",
  "Canceled",
  "Rejected",
  "Accepted"
}

export enum OrderAgentStatusEnum {
  PENDING = 1,
  AGENT_FOUND ,
  AGENT_IN_PLACE ,
  AGENT_REQUESTED ,
  AGENT_CHOSEN ,
  FINISHED ,
  CANCELED ,
  REJECTED,
  ACCEPTED,

  "Pending" = 1,
  "Agent found",
  "Agent in place",
  "Agent requested",
  "Agent chosen",
  "Finished",
  "Canceled",
  "Rejected",
  "Accepted"
}

export enum SearchUserTypeEnum {
  INDIVIDUAL_CORPORATE_SELLER = 1,
  AGENT ,
  CORPORATE_SELLER ,
  ALL ,
  INDIVIDUAL_SELLER ,
  INDIVIDUAL_SELLER_BUYER ,
  CORPORATE_SELLER_BUYER ,

  "Individual corporate seller" = 1,
  "Agent",
  "Corporate seller",
  "All",
  "Individual seller",
  "Individual seller agent",
  "Corporate seller agent",
}

export enum ChartsUserTypeEnum {
  INDIVIDUAL_SELLER = 1,
  CORPORATE_SELLER ,
  AGENT ,
  INDIVIDUAL_CORPORATE_SELLER,

  "Individual seller" = 1,
  "Corporate seller",
  "Agent",
  "Individual corporate seller",
}

export enum AuditoryTypeEnum {
  Segment = 1,
  Specific,
  All,
}

export enum UserTypeEnum {
  Sellers = 1,
  Agents,
  Guests,
  All,
}

export enum UserTypeSingleEnum {
  Seller = 1,
  Agent,
  Guest,
}

export enum DashboardCountType {
  Increase = 1, 
  Decrease
}

export enum DashboardPageEnum {
  General = 1, 
  Requests,
  Leaderboard,
  Users,
}

export enum ContextPageEnum {
  Media = 1, 
  Content,
  Faq,
}

export enum MediaTypeEnum {
  Video = 1, 
  Image,
}

export enum EcoTypeEnum {
  CITIZEN = 1,
  MASTER,
  GENIUS,
  HERO,
}
export enum MessageSubjectEnum {
  ApplicationWorkflow = 1,
  SuggestionsAndRecommendations ,
  ReportAnIssue ,

  "Application Workflow" = 1,
  "Suggestions And Recommendations" , 
  "Report An Issue" ,
}
export enum AccountTypeEnum {
  Individual = 1,
  Corporate,
  All,
}


export enum CustomerTypeEnum {
  Individual = 1,
  Corporate,
  Agent,
}

export enum PushTypeEnum {
  Segment = 1,
  SearchUser,
  All,
}

export enum ColorEnum {
  Yellow = 1,
  Red,
  Non,
}

export enum DeviceTypeEnum {
  Android = 1,
  iOS,
  All = 4,
}

export enum OperatorEnum {
  Equal = 1,
  NotEqual,
  StartsWith,
  EndsWith
}

export enum VehicleTypeEnum {
  Walking = 1,
  Carriage,
  Bicycle,
  Motorcycle,
  Sedan,
  Pickup,
  Vav,
  Truck,
}

export enum MessagesTypeEnum {
  All = '',
  Important = 1,
  Unread
}

export enum StatisticShowDateEnum {
  Hourly = 1,
  Daily,
  Monthly,
  Yearly,
}

export enum DataShowEnum {
  Monthly = 1,
  Yearly,
}

export enum SelectTimeRangeEnum {  
  Today = 1,
  Yesterday,
  Week,
  Month,
  Year,
  CustomRange,
  All
}

export enum OrderType {
  Delivery = 1,
  SelfDelivery = 2,
  Sellers,
}

export enum ProjectStatus {
  Open = 1,
  Closed,
}

export const languageEnum = {
  hy: 1,
  en: 2,
};