import * as React from 'react';
import noPic from 'src/assets/images/no-photo.png'
import { Link } from 'react-router-dom';
import { OrderSellerStatusEnum } from 'src/platform/enums/enums';
import HelperComponent from 'src/platform/classes/helper-component';
import OrderController from 'src/platform/api/order/order';
import DetailsMap from '../orders-map/MapDetails';
import moment from 'moment';
import './index.scss';
import Helper from 'src/platform/services/helper';
import Languages from 'src/platform/services/languages';
import { ICancelOrderModelBody } from 'src/platform/api/order/order-interface';
import { store } from 'react-notifications-component';
import Modal from 'react-modal';

export interface IProps {
    activeId: number;
}

export interface IState {
    details: any,
    modalIsOpenIndex: number,
    weight: string;
}

const {
    Order_,
    Seller_,
    Agent_,
    Order_information,
    view_details,
    Cardboard_apx_weight,
    Cardboard_actual_weight,
    Cardboard_price,
    Agent_attached_date,
    Order_start_date,
    Order_finish_date,
    enums,
    Any_time,
    kg,
    Close,
    Finish,
    Set_actual_cardboard,
    Finish_request,
    Are_you_sure_cancel,
    Cancel
} = Languages.Translations

class SellersOrdersDetailRight extends HelperComponent<IProps, IState> {

    public state: IState = {
        details: {},
        modalIsOpenIndex: null,
        weight: '',
    }

    componentDidMount() {
        this.getSellersOrderDetails(this.props.activeId);
    }

    componentDidUpdate(prevProps: IProps) {
        if (this.props.activeId !== prevProps.activeId) {
            this.getSellersOrderDetails(this.props.activeId);
        }
    }

    private getSellersOrderDetails = async (id: string | number) => {
        window.dispatchEvent(new CustomEvent('loader', { detail: true }));
        const result = await OrderController.getSellersOrderDetails(this.props.activeId);
        window.dispatchEvent(new CustomEvent('loader', { detail: false }));
        if (result.success) {
            this.asyncSetState({
                details: result.data,
            });
        } else {
            // store.addNotification(Helper.notificationConfig(result.message, false));
        }
    };

    private cancelOrder = async () => {
        const body: ICancelOrderModelBody = {
            orderId: this.props.activeId,
            reason: 'Order canceled by admin',
        }

        window.dispatchEvent(new CustomEvent('loader', { detail: true }));
        const result = await OrderController.cancelOrder(body);
        window.dispatchEvent(new CustomEvent('loader', { detail: false }));
        if (result.success) {
            store.addNotification(Helper.notificationConfig(result.message, true));
            this.asyncSetState({ page: 0 }).then(() => {
                this.getSellersOrderDetails(this.props.activeId)
            });
        } else {
            store.addNotification(Helper.notificationConfig(result.message, false));
        }
        this.closeModal()
    }
    
  private finishOrder = async () => {
    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const result = await OrderController.finishOrder(this.props.activeId.toString(), this.state.weight);
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
    if (result.success) {
      store.addNotification(Helper.notificationConfig(result.message, true));
      this.closeModal()
    }
  }

    public openModal = (index: number) => {
        this.asyncSetState({ modalIsOpenIndex: index });
    }

    public closeModal = () => {
        this.asyncSetState({ modalIsOpenIndex: null });
    }

    public onInput = async (evt: React.SyntheticEvent<HTMLInputElement>) => {
        await this.asyncSetState({ weight: evt.currentTarget.value })
    }
    render() {
        const { details, modalIsOpenIndex, weight } = this.state;

        return (
            <>
                <div className="P-top G-flex G-align-center G-pl-2 P-arrow">
                    <Link to={"/orders/sellers"}> 
                        <i className="icon-ic_back G-mr-2" />
                    </Link>
                    <div className="G-flex G-justify-between P-title">
                        <span>{Order_information}</span>
                        <span className="P-order-num">{Order_} #{details.orderNumber}</span>
                        <span className="P-status" style={{
                            color: `${(details.orderSellerStatusEnumValue === OrderSellerStatusEnum.AGENT_REQUESTED ||
                                details.orderSellerStatusEnumValue === OrderSellerStatusEnum.AGENT_CHOSEN ||
                                details.orderSellerStatusEnumValue === OrderSellerStatusEnum.AGENT_FOUND ||
                                details.orderSellerStatusEnumValue === OrderSellerStatusEnum.PENDING)
                                ? '#7A7A7A'
                                : details.orderSellerStatusEnumValue === OrderSellerStatusEnum.CANCELED
                                    ? '#FF0057' : "#04A556"}`
                        }}>{enums.OrderSellerStatusEnum[details.orderSellerStatusEnumValue]}</span>
                    </div>
                </div>
                <div className="G-flex P-sub-right">
                    <div className="P-information">
                        <div className="P-row P-row-one G-flex">
                            <div className="G-mr-6">
                                <div className="G-mb-1 P-seller">{Seller_}</div>
                                <div className="G-flex G-align-center G-mb-1">
                                    <div className="G-flex P-image"
                                        style={{ background: `url(${details.sellerPreviewDto && details.sellerPreviewDto.sellerImageUrl ? `${details.sellerPreviewDto.sellerImageUrl}/100/100` : noPic}) center/cover no-repeat` }}>
                                    </div>
                                    <div className="G-flex G-flex-column">
                                        <span>{details.sellerPreviewDto && details.sellerPreviewDto.sellerFullName}</span>
                                        {details.sellerPreviewDto && !Helper.role ?
                                            <Link to={`/customers/sellers/details?id=${details.sellerPreviewDto.sellerId}`}
                                                className="G-green G-cursor">{view_details}
                                            </Link>
                                            : null}
                                    </div>
                                </div>
                            </div>
                            {details.buyerFullName ? <div>
                                <div className="G-mb-1 P-seller">{Agent_}</div>
                                <div className="G-flex G-align-center">
                                    <div className="G-flex P-image"
                                        style={{ background: `url(${details.sellerImageUrl ? `${details.sellerImageUrl}/100/100` : noPic}) center/cover no-repeat` }}>
                                    </div>
                                    <span>{details.buyerFullName}</span>
                                </div>
                            </div> : ""}
                        </div>
                        <div className="P-row P-row-two">
                            <div className="G-flex">
                                <p>{Order_start_date} </p>
                                {details.orderStartDate ?
                                    <p className="P-details-date">
                                        {moment(details.orderStartDate).format('DD MMM YYYY HH:mm')}
                                    </p>
                                    : <p className="G-mr-6">-</p>}
                            </div>
                            <div className="G-flex">
                                <p>{Agent_attached_date} </p>
                                {details.buyerRequestedDate ?
                                    <p className="P-details-date">
                                        {moment(details.buyerRequestedDate).format('DD MMM YYYY HH:mm')}
                                    </p>
                                    : <p className="G-mr-6">-</p>}
                            </div>
                            <div className="G-flex">
                                <p>{Order_finish_date} </p>
                                {details.orderFinishDate ?
                                    <p className="P-details-date">
                                        {moment(details.orderFinishDate).format('DD MMM YYYY HH:mm')}
                                    </p>
                                    : <p className="G-mr-6">-</p>}
                            </div>
                        </div>
                        <div className="P-row P-row-three">
                            <div>
                                <i className="icon-ic_map G-mr-2" />
                                {details && details.addressForOrderDto &&
                                    `${details.addressForOrderDto.addressName},
                                    ${details.addressForOrderDto.apartment ? details.addressForOrderDto.apartment + ' app,' : ''}
                                    ${details.addressForOrderDto.building ? details.addressForOrderDto.building + ' building,' : ''} 
                                    ${details.addressForOrderDto.entrance ? details.addressForOrderDto.entrance + ' entrance,' : ''} 
                                    ${details.addressForOrderDto.floor ? details.addressForOrderDto.floor + ' floor' : ''}`
                                }
                            </div>
                            <div className="G-flex G-align-center">
                                <i className="icon-clock-5 G-mr-2" />
                                {details && details.orderTimes && details.orderTimes.map((item: any, index: any) => {
                                    let from = item.fromTime;
                                    let to = item.toTime;
                                    return (<div key={index} className="G-mr-1">
                                        {item.fromTime !== 0 ? <>
                                            {moment(new Date((from * 1000) - 14400000)).format('HH:mm')} -
                                            {moment(new Date((to * 1000) - 14400000)).format('HH:mm')}</> : `${Any_time}`}
                                    </div>)
                                })}
                            </div>
                            {!!details.comment && <div className="G-break-word">
                                <i className="icon-ic_comment G-mr-2" />
                                {details.comment}
                            </div>}
                        </div>
                        <div className="P-row P-row-four G-mb-1">
                            <div>
                                <span>{Cardboard_apx_weight}</span>
                                <span className="G-bold">{details.weightForSale ? details.weightForSale : "-"}</span>
                            </div>
                            <div>
                                <span>{Cardboard_actual_weight}</span>
                                <span className="G-bold">{details.finalWeight ? details.finalWeight : "-"}</span>
                            </div>
                            <div>
                                <span>{Cardboard_price}</span>
                                <span className="G-bold">{details.price ? details.price : "-"}</span>
                            </div>
                        </div>
                        <div className="G-flex G-mb-1">
                        {details.orderSellerStatusEnumValue === OrderSellerStatusEnum.PENDING ? 
                        <button className="G-btn G-small G-black-btn G-mr-1" onClick={() => this.openModal(2)}>Cancel</button> : ''}

                            {
                            details.orderSellerStatusEnumValue === OrderSellerStatusEnum.PENDING ||
                            details.orderSellerStatusEnumValue === OrderSellerStatusEnum.AGENT_CHOSEN ||
                            details.orderSellerStatusEnumValue === OrderSellerStatusEnum.AGENT_FOUND ||
                            details.orderSellerStatusEnumValue === OrderSellerStatusEnum.AGENT_IN_PLACE || 
                            details.orderSellerStatusEnumValue === OrderSellerStatusEnum.AGENT_REQUESTED ? 
                            <button className="G-btn G-green-btn G-small" onClick={() => this.openModal(1)}>Finish</button> : ''}
                        </div>
                    </div>
                    <div style={{ height: "auto", width: '100%' }}>
                        <DetailsMap
                            data={details.addressForOrderDto}
                            details={details}
                        />
                    </div>
                </div>
                <Modal
                    isOpen={modalIsOpenIndex === 1}
                    onRequestClose={this.closeModal}
                    style={{
                        content: {
                            padding: "25px",
                            height: "270px",
                            minWidth: "550px",
                            display: "flex",
                            flexDirection: "column",
                        }
                    }}>
                    <div className="P-order-modal">
                        <h5 className="P-title">{Finish_request}</h5>
                        <p className="P-text G-mb-2">{Set_actual_cardboard}</p>
                        <input className="G-text-input  G-mr-1 G-mb-2"
                            placeholder={kg}
                            value={weight}
                            onChange={this.onInput}
                            type="text" />
                        <div className="G-flex G-justify-between G-mt-auto">
                            <button className="G-mr-2 P-cancel" onClick={this.closeModal}>{Close}</button>
                            <button className="G-button P-order-button" onClick={this.finishOrder}>
                                {Finish}
                            </button>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={modalIsOpenIndex === 2}
                    onRequestClose={this.closeModal}
                    style={{
                        content: {
                            padding: "25px",
                            height: "270px",
                            minWidth: "550px",
                            display: "flex",
                            flexDirection: "column",
                        }
                    }}>
                    <div className="P-order-modal">
                        <h5 className="P-title">{Cancel}</h5>
                        <p className="P-text G-mb-2">{Are_you_sure_cancel}</p>
                        <div className="G-flex G-justify-between G-mt-auto">
                            <button className="G-mr-2 P-cancel" onClick={this.closeModal}>{Close}</button>
                            <button className="G-button P-order-button" onClick={this.cancelOrder}>
                                {Cancel}
                            </button>
                        </div>
                    </div>
                </Modal>
            </>);
    }

}

export default SellersOrdersDetailRight;