import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ROUTES } from 'src/platform/constants/routes';
import { byPrivateRoute, bySidebar } from 'src/platform/decorators/routes';
import noPic from 'src/assets/images/no-photo.png';
import ReactMinimalPieChart from 'react-minimal-pie-chart';
import { SelectTimeRangeEnum, UserTypeSingleEnum, DashboardCountType, UserTypeEnum, DashboardPageEnum } from 'src/platform/enums/enums';
import Select from 'src/platform/components/select/Select';
import { selectTimeRangeOptions, messageSubjectOptions } from 'src/platform/constants/casual';
import DatePicker from "react-datepicker";
import './index.scss';
import DashboardController from 'src/platform/api/dashboard/dashboard';
import { IGetUserDetailsModel } from 'src/platform/api/dashboard/dashboard-interface';
import moment from 'moment';
import Languages from 'src/platform/services/languages';
import HelperComponent from 'src/platform/classes/helper-component';
import Leaderboard from './Leaderboard';
import Requests from './Requests';
import Users from './Users';

interface IState {
  startDate: Date,
  endDate: Date,
  selectTimeRangeEnum: SelectTimeRangeEnum;
  androidUsagePercent: string;
  iosUsagePercent: string;
  fromDate: Date;
  toDate: Date;
  topAgentsList: Array<any>;
  topIndividualList: Array<any>;
  topCorporateList: Array<any>;
  supportList: Array<any>;
  userDetails: IGetUserDetailsModel;
  activePage: DashboardPageEnum;

  // Boolean
  corporate: boolean;
  isOpen: boolean;
};

const {
  Dashboard_,
  Today,
  new_orders,
  orders_to_deliver,
  new_messages,
  donation_votings,
  Newset_support_message,
  List_is_empty,
  Device_usage,
  iOs_devices,
  Android_devices,
  Top_sellers,
  Individuals,
  Corporate,
  User_name,
  Finished_orders,
  Sold_Cardboard,
  Donation_Amounts,
  Top_agents,
  Bought_Cardboard,
  Total_earnings,
  Start,
  End,
  new_user,
  enums,
  Leaderboard_,
  General,
  Requests_,
  Customer,
} = Languages.Translations;


@bySidebar(`${Dashboard_}`, "icon-ic_dashboard")
@byPrivateRoute(ROUTES.dashboad)
class Dashboard extends HelperComponent<RouteComponentProps, IState> {


  public state: IState = {
    startDate: null,
    endDate: null,
    corporate: false,
    selectTimeRangeEnum: SelectTimeRangeEnum.Today,
    isOpen: false,
    androidUsagePercent: "",
    iosUsagePercent: "",
    fromDate: null,
    toDate: null,
    activePage: DashboardPageEnum.General,
    supportList: [],
    topAgentsList: [],
    topIndividualList: [],
    topCorporateList: [],
    userDetails: {
      id: 0,
      newSellerCount: 0,
      newSellerIncreasePercent: null,
      newSellerCountType: 0,
      sellerOrderFinishedCount: 0,
      sellerOrderIncreasePercent: null,
      sellerOrderCountType: 0,
      buyerOrderFinishedCount: 0,
      buyerOrderIncreasePercent: null,
      buyerOrderCountType: 0,
      newSupportMessageCount: 0,
      newSupportMessageIncreasePercent: null,
      newSupportMessageCountType: 0,
      donationVatingCount: null,
      donationIncreasePercent: null,
      donationVatingCountType: 0
    }
  }

  componentDidMount() {
    this.getDeviceUsage()
    this.getNewestSupportMessage()
    this.getTopFiveUsers()
    this.getDashboardDetail()
  }

  getDeviceUsage = async () => {
    const result = await DashboardController.deviceUsage();
    if (result.success) {
      this.setState({
        androidUsagePercent: result.data.androidUsagePercent,
        iosUsagePercent: result.data.iosUsagePercent,
      })
    }
  }

  public getNewestSupportMessage = async () => {
    const result = await DashboardController.getNewestSupportMessage();
    if (result.success) {
      this.setState({
        supportList: result.data
      })
    }
  }

  public getTopFiveUsers = async () => {
    const result = await DashboardController.getTopFiveUsers();
    if (result.success) {
      this.setState({
        topAgentsList: result.data.buyerModel,
        topIndividualList: result.data.individualSellerModel,
        topCorporateList: result.data.corporateSellerModel
      })
    }
  }

  public getDashboardDetail = async () => {
    let fromDate = this.state.startDate;
    let toDate = this.state.endDate;

    switch (this.state.selectTimeRangeEnum) {
      case SelectTimeRangeEnum.Today:
        fromDate = moment().toDate();
        toDate = null;
        this.state.startDate = null;
        this.state.endDate = null;
        break;
      case SelectTimeRangeEnum.Yesterday:
        fromDate = moment().subtract(1, 'day').toDate();
        toDate = moment().subtract(1, 'day').toDate();
        this.state.startDate = null;
        this.state.endDate = null;
        break;
      case SelectTimeRangeEnum.Week:
        fromDate = moment().subtract(1, 'weeks').toDate();
        toDate = null;
        this.state.startDate = null;
        this.state.endDate = null;
        break;
      case SelectTimeRangeEnum.Month:
        fromDate = moment().subtract(1, 'months').toDate();
        toDate = null;
        this.state.startDate = null;
        this.state.endDate = null;
        break;
      case SelectTimeRangeEnum.Year:
        fromDate = moment().subtract(1, 'years').toDate();
        toDate = null;
        this.state.startDate = null;
        this.state.endDate = null;
        break;
      default:
        break;
    }

    if (fromDate) {
      window.dispatchEvent(new CustomEvent('loader', { detail: true }));
      const result = await DashboardController.getDashboardDetail({
        fromDate: moment(fromDate).startOf('day').toDate().getTime(),
        toDate: toDate ? moment(toDate).endOf('day').toDate().getTime() : ''
      });
      window.dispatchEvent(new CustomEvent('loader', { detail: false }));

      if (result.success) {
        this.setState({
          userDetails: result.data
        })
      }
    }
  }


  changeCorporate = () => {
    this.setState({ corporate: true })
  }

  changeIndividual = () => {
    this.setState({ corporate: false })
  }

  openDateInput = () => {
    this.setState({ isOpen: true })
  }

  public onSelect = async (field: string, value: number) => {

    await this.asyncSetState({ selectTimeRangeEnum: value });

    if (value === SelectTimeRangeEnum.CustomRange) {
      this.openDateInput();
    } else this.setState({ isOpen: false });
    this.getDashboardDetail()
  }

  onClickUser = (item: any) => {
    if (item.userTypeEnumValue === UserTypeSingleEnum.Seller) {
      this.props.history.push(`/customers/sellers/details?id=${item.userId}`)
    } else if (item.userTypeEnumValue === UserTypeSingleEnum.Agent) {
      this.props.history.push(`/customers/agents/details?id=${item.userId}`)
    }
  }

  changePage = (page: DashboardPageEnum) => {
    this.setState({ activePage: page });
    // this.state.activePage = page;
  }


  public render() {
    const { selectTimeRangeEnum, startDate, endDate, userDetails } = this.state;

    return (
      <section className="G-main-page P-dashboard-page">
        <div className="P-navigation">
          <h4>{Dashboard_}</h4>
          <p className={`${(this.state.activePage === DashboardPageEnum.General) ? "P-active" : ""}`} onClick={() => this.changePage(DashboardPageEnum.General)}>{General}</p>
          <p className={`${(this.state.activePage === DashboardPageEnum.Requests) ? "P-active" : ""}`} onClick={() => this.changePage(DashboardPageEnum.Requests)}>{Requests_}</p>
          <p className={`${(this.state.activePage === DashboardPageEnum.Leaderboard) ? "P-active" : ""}`} onClick={() => this.changePage(DashboardPageEnum.Leaderboard)}>{Leaderboard_}</p>
          <p className={`${(this.state.activePage === DashboardPageEnum.Users) ? "P-active" : ""}`} onClick={() => this.changePage(DashboardPageEnum.Users)}>{Customer}</p>
        </div>

        {this.state.activePage === DashboardPageEnum.General ? <>
          <div className="G-flex-center-end G-mr-1">

            <Select
              className="G-ml-5"
              height="45px"
              width="220px"
              placeholder={Today}
              value={selectTimeRangeEnum}
              options={selectTimeRangeOptions}
              setFieldValue={this.onSelect}
            />
            {
              this.state.isOpen ?
                <div className="G-flex">
                  <DatePicker
                    className={`G-text-input G-cursor G-ml-3`}
                    selected={startDate}
                    placeholderText={Start}
                    onChange={(startDate: Date) => this.asyncSetState({ startDate }).then(() => {
                      this.getDashboardDetail()
                    })}
                  />
                  <DatePicker
                    className={`G-text-input G-cursor G-ml-3`}
                    selected={endDate}
                    minDate={startDate}
                    placeholderText={End}
                    onChange={(endDate: Date) => this.asyncSetState({ endDate }).then(() => {
                      this.getDashboardDetail()
                    })}
                  />
                </div>
                : null}
          </div>


          <div className="G-flex G-mt-2 G-mb-2 G-justify-around">
            <div className="P-top-item">
              <h1>{userDetails.newSellerCount}</h1>
              <span>{new_user}</span>
              {selectTimeRangeEnum !== SelectTimeRangeEnum.CustomRange ?
                <p className={
                  userDetails.newSellerCountType === DashboardCountType.Increase ? "P-percent-green" :
                    userDetails.newSellerCountType === DashboardCountType.Decrease ? "P-percent-red" : "P-percent-black"}>
                  <span className={
                    userDetails.newSellerCountType === DashboardCountType.Increase ? "icon-ic_increase G-mr-1" :
                      userDetails.newSellerCountType === DashboardCountType.Decrease ? "icon-ic_decrease G-mr-1" : ""}></span>
                  {userDetails.newSellerIncreasePercent}%</p>
                : null}
            </div>
            <div className="P-top-item">
              <h1>{userDetails.sellerOrderFinishedCount}</h1>
              <span>{new_orders}</span>
              {selectTimeRangeEnum !== SelectTimeRangeEnum.CustomRange ?
                <p className={
                  userDetails.sellerOrderCountType === DashboardCountType.Increase ? "P-percent-green" :
                    userDetails.sellerOrderCountType === DashboardCountType.Decrease ? "P-percent-red" : "P-percent-black"}>
                  <span className={
                    userDetails.sellerOrderCountType === DashboardCountType.Increase ? "icon-ic_increase G-mr-1" :
                      userDetails.sellerOrderCountType === DashboardCountType.Decrease ? "icon-ic_decrease G-mr-1" : ""}></span>
                  {userDetails.sellerOrderIncreasePercent}%</p>
                : null}
            </div>
            <div className="P-top-item">
              <h1>{userDetails.buyerOrderFinishedCount}</h1>
              <span>{orders_to_deliver}</span>
              {selectTimeRangeEnum !== SelectTimeRangeEnum.CustomRange ?
                <p className={
                  userDetails.buyerOrderCountType === DashboardCountType.Increase ? "P-percent-green" :
                    userDetails.buyerOrderCountType === DashboardCountType.Decrease ? "P-percent-red" : "P-percent-black"}>
                  <span className={
                    userDetails.buyerOrderCountType === DashboardCountType.Increase ? "icon-ic_increase G-mr-1" :
                      userDetails.buyerOrderCountType === DashboardCountType.Decrease ? "icon-ic_decrease G-mr-1" : ""}></span>
                  {userDetails.buyerOrderIncreasePercent}%</p>
                : null}
            </div>
            <div className="P-top-item">
              <h1>{userDetails.newSupportMessageCount}</h1>
              <span>{new_messages}</span>
              {selectTimeRangeEnum !== SelectTimeRangeEnum.CustomRange ?
                <p className={
                  userDetails.newSupportMessageCountType === DashboardCountType.Increase ? "P-percent-green" :
                    userDetails.newSupportMessageCountType === DashboardCountType.Decrease ? "P-percent-red" : "P-percent-black"}>
                  <span className={
                    userDetails.newSupportMessageCountType === DashboardCountType.Increase ? "icon-ic_increase G-mr-1" :
                      userDetails.newSupportMessageCountType === DashboardCountType.Decrease ? "icon-ic_decrease G-mr-1" : ""}></span>
                  {userDetails.newSupportMessageIncreasePercent}%</p>
                : null}
            </div>
            <div className="P-top-item">
              <h1>{userDetails.donationVatingCount}</h1>
              <span>{donation_votings}</span>
              {selectTimeRangeEnum !== SelectTimeRangeEnum.CustomRange ?
                <p className={
                  userDetails.donationVatingCountType === DashboardCountType.Increase ? "P-percent-green" :
                    userDetails.donationVatingCountType === DashboardCountType.Decrease ? "P-percent-red" : "P-percent-black"}>
                  <span className={
                    userDetails.donationVatingCountType === DashboardCountType.Increase ? "icon-ic_increase G-mr-1" :
                      userDetails.donationVatingCountType === DashboardCountType.Decrease ? "icon-ic_decrease G-mr-1" : ""}></span>
                  {userDetails.donationIncreasePercent}%</p>
                : null}
            </div>
          </div>


          <div className="G-flex G-justify-around">
            <div className="P-support-messages">
              <h5>{Newset_support_message}</h5>

              {this.state.supportList.length ?
                this.state.supportList.map((item, news) => {
                  return (
                    <div className="P-message-subject G-mt-2" key={news}>
                      <div className={`G-flex ${item.userTypeEnumValue !== UserTypeEnum.Guests ? 'G-cursor' : ''}`}
                        onClick={() => this.onClickUser(item)}>
                        <span className="P-image" style={{ background: `url(${item.imageUrl ? `${item.imageUrl}/100/100` : noPic}) center/cover no-repeat` }}>
                        </span>
                        <div className="P-message-name">
                          <p>{item.name ? item.name : item.fullName ? item.fullName : item.companyName}</p>
                          <span>{enums.UserTypeSingleEnum[item.userTypeEnumValue]}</span>
                        </div>
                      </div>
                      <h6 className="G-break-word">{item.subject ? item.subject : messageSubjectOptions[item.messageSubjectEnumValue - 1].label}</h6>
                      <p className="G-break-word">{item.message}</p>
                    </div>
                  )
                })
                : <div className="G-flex G-justify-center P-empty"><h5>{List_is_empty}</h5></div>}

            </div>
            <div className="P-device">
              <h5>{Device_usage}</h5>
              <div className="G-flex-align-center">
                <div className="P-device-usage">
                  <ReactMinimalPieChart
                    animate={true}
                    animationDuration={500}
                    animationEasing="ease-out"
                    cx={50}
                    cy={50}
                    data={[
                      {
                        color: '#04A556',
                        title: `IOS ${this.state.iosUsagePercent}%`,
                        value: +this.state.iosUsagePercent
                      },
                      {
                        color: '#F15A32',
                        title: `Android ${this.state.androidUsagePercent}%`,
                        value: +this.state.androidUsagePercent
                      },
                    ]}
                    label={false}
                    labelPosition={50}
                    lengthAngle={360}
                    lineWidth={60}
                    onClick={undefined}
                    onMouseOut={undefined}
                    onMouseOver={undefined}
                    paddingAngle={0}
                    radius={50}
                    rounded={false}
                    startAngle={270}
                    viewBoxSize={[100, 100]}
                  />
                </div>

                <div className="P-device-right">
                  <div className="G-mb-4 G-flex">
                    <div className="P-ios P-cub G-mr-1"></div>
                    <p>{iOs_devices}<br />{this.state.iosUsagePercent + '%'}</p>
                  </div>
                  <div className="G-flex">
                    <div className="P-android P-cub G-mr-1"></div>
                    <p>{Android_devices} <br />{this.state.androidUsagePercent + '%'}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="G-flex G-mt-2 G-justify-around">

            <div className="P-top-sellers">
              <div className="P-sellers">
                <div className="G-flex-justify-between G-mb-4">
                  <h5>{Top_sellers}</h5>
                  <div className="nowrap">
                    <button className={`${this.state.corporate ? " G-mr-3 G-gray G-bold" : "G-light-green G-bold G-mr-3"}`} onClick={this.changeIndividual}>{Individuals}</button>
                    <button className={`${!this.state.corporate ? "G-gray G-bold" : "G-light-green G-bold"}`} onClick={this.changeCorporate}>{Corporate}</button>
                  </div>
                </div>
                <div className="G-flex P-sellers-title">
                  <span className="G-mr-1">{User_name}</span>
                  <span className="P-numbers">{Finished_orders}</span>
                  <span className="P-numbers">{Sold_Cardboard}</span>
                  <span className="P-numbers">{Donation_Amounts}</span>
                </div>
              </div>

              {
                !this.state.corporate ?
                  <div className="P-sellers-list">
                    {this.state.topIndividualList.map((item, ind) => {
                      return (
                        <div className="G-flex-justify-between G-align-center P-sellers-item" key={ind}>
                          <p className="G-flex G-align-center G-cursor G-break-word G-mr-1"
                            onClick={() => { this.props.history.push(`/customers/sellers/details?id=${item.sellerId}`); }}>
                            <span className="P-image" style={{ background: `url(${item.imageUrl ? `${item.imageUrl}/100/100` : noPic}) center/cover no-repeat` }}>
                            </span>{item.fullName}</p>
                          <p className="P-numbers">{item.finishOrdersCount}</p>
                          <p className="P-numbers">{item.soldCardBoardCount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                          <p className="P-numbers">{item.donationAmount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                        </div>
                      )
                    })

                    }
                  </div>
                  :
                  <div className="P-sellers-list">
                    {this.state.topCorporateList.map((item, corp) => {
                      return (
                        <div className="G-flex-justify-between G-align-center P-sellers-item" key={corp}>
                          <p className="G-flex G-align-center G-cursor G-break-word G-mr-1"
                            onClick={() => { this.props.history.push(`/customers/sellers/details?id=${item.sellerId}`); }}>
                            <span className="P-image" style={{ background: `url(${item.imageUrl ? `${item.imageUrl}/100/100` : noPic}) center/cover no-repeat` }}>
                            </span>{item.fullName}</p>
                          <p className="P-numbers">{item.finishOrdersCount}</p>
                          <p className="P-numbers">{item.soldCardBoardCount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                          <p className="P-numbers">{item.donationAmount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                        </div>
                      )
                    })

                    }
                  </div>
              }

            </div>

            <div className="P-top-sellers">

              <div className="P-sellers">
                <div className="G-flex-justify-between G-mb-4">
                  <h5>{Top_agents}</h5>
                </div>
                <div className="G-flex P-sellers-title">
                  <span className="G-mr-1">{User_name}</span>
                  <span className="P-numbers">{Finished_orders}</span>
                  <span className="P-numbers">{Bought_Cardboard}</span>
                  <span className="P-numbers">{Total_earnings}</span>
                </div>
              </div>

              <div className="P-sellers-list">
                {this.state.topAgentsList.map((item, agent) => {
                  return (
                    <div className="G-flex-justify-between G-align-center P-sellers-item" key={agent}>
                      <p className="G-flex G-align-center G-cursor G-break-word G-mr-1"
                        onClick={() => { this.props.history.push(`/customers/agents/details?id=${item.buyerId}`); }}>
                        <span className="P-image" style={{ background: `url(${item.imageUrl ? `${item.imageUrl}/100/100` : noPic}) center/cover no-repeat` }}>
                        </span> {item.fullName}</p>
                      <p className="P-numbers">{item.finishedOrdersCount}</p>
                      <p className="P-numbers">{item.boughtCardboardCount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                      <p className="P-numbers">{item.totalEarnings.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                    </div>
                  )
                })

                }
              </div>
            </div>

          </div>
        </> : 
        this.state.activePage === DashboardPageEnum.Requests ?
        <Requests filterByMonth={false} type={null} data1={[]} data2={[]} showType={null}/> : 
        this.state.activePage === DashboardPageEnum.Leaderboard ?
        <Leaderboard/> : <Users/>}
      </section >
    );
  }
}

export default Dashboard;