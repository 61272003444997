import * as React from 'react';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router-dom';
import { ROUTES } from 'src/platform/constants/routes';
import { byPrivateRoute, bySidebar } from 'src/platform/decorators/routes';
import HelperPureComponent from 'src/platform/classes/helper-pure-component';
import Organiztions from './organizations/Organizations';
import './index.scss';
import Campaigns from './Charity/Campaigns';
import CreateCampaigns from './Charity/create/create-campaign';
import Languages from 'src/platform/services/languages';

const {
  donations,
  Projects,
  Charity_campaigns,
} = Languages.Translations;

@bySidebar(`${donations}`, "icon-ic_loyality", [{name:`${Projects}`, path: "/donations/organizations"},{name:`${Charity_campaigns}`, path: "/donations/Charity/campaigns"}])
@byPrivateRoute(ROUTES.donations)
class Donations extends HelperPureComponent<RouteComponentProps> {

  public render() { 
    return (
      <section className="G-main-page P-customers-page"> 
            <Switch>
              <Route path="/donations/organizations" component={Organiztions} exact={true}/>
              <Route path="/donations/Charity/campaigns" component={Campaigns}  exact={true}/>
              <Route path="/donations/Charity/campaigns/create-campaign" component={CreateCampaigns}  exact={true}/>
              <Route path="/donations/Charity/campaigns/edit-campaign/:id" component={CreateCampaigns}  exact={true}/>
              
              <Redirect to="/donations/organizations"/>
            </Switch> 
      </section >
    );
  }
}

export default Donations;