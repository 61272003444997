import { withRouter, RouteComponentProps } from "react-router-dom";
import HelperPureComponent from '../classes/helper-pure-component';

class ScrollIntoView extends HelperPureComponent<RouteComponentProps> {

  componentDidMount = () => setTimeout(() => window.scrollTo(0, 0));

  componentDidUpdate = (prevProps:RouteComponentProps) => {
      
    if (this.props.location.pathname !== prevProps.location.pathname){
          // document.querySelector(".G-main-page").scrollTop = 0;
        window.scrollTo(0, 0);    
      }
  };

  render = () => this.props.children;
}

export default withRouter(ScrollIntoView);