import moment from 'moment';
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { StatisticShowDateEnum } from 'src/platform/enums/enums';

export interface ChartIProps {
  type: number;
  filterByMonth: boolean;
  data1: any[];
  data2: any[];
  showType: StatisticShowDateEnum;
}

export default class Chart extends React.Component<ChartIProps, {}> {
  render() {
    const { type, filterByMonth, data1, data2, showType } = this.props;
    let array = [{
      name: '',
      value1: '',
      value2: '',
    }]
    
    let dateShowType;
    if (data1 && data1.length) {
      data1.forEach((x, i) => {
        if (showType === StatisticShowDateEnum.Hourly && !filterByMonth) {
          dateShowType = (moment(x.date).format("HH:mm") + (data2[i] && data2[i].date ? ('-' + moment(data2[i].date).format("HH:mm")) : ''))
        } else if (showType === StatisticShowDateEnum.Yearly && !filterByMonth) {
          dateShowType = (moment(x.date).format("YY") + (data2[i] && data2[i].date ? ('-' + moment(data2[i].date).format("YY")) : ''))
        } else {
          dateShowType = (moment(x.date).format("DD MMM") + (data2[i] && data2[i].date ? ('-' + moment(data2[i].date).format("DD MMM")) : ''))
        }
  
        if (type === 1) {
          array.push({
            name: dateShowType,
            value1: x.weight,
            value2: data2[i] ? data2[i].weight : 0,
          })
        } else if (type === 2) {
          array.push({
            name: dateShowType,
            value1: x.requestsCount,
            value2: data2[i] ? data2[i].requestsCount : 0,
          })
        } else if (type === 3) {
          array.push({
            name: dateShowType,
            value1: x.requestsCount,
            value2: data2[i] ? data2[i].requestsCount : 0,
          })
        } else if (type === 4) {
          array.push({
            name: dateShowType,
            value1: x.points,
            value2: data2[i] ? data2[i].points : 0,
          })
        }
      })
    }
    array.splice(0, 1)
    

    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={array}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis yAxisId="left" orientation="left" stroke="" />
          <YAxis yAxisId="right" orientation="right" stroke="" />
          <Tooltip />
          {/* <Legend /> */}
          <Bar barSize={15} yAxisId="left" dataKey="value1" fill={type === 1 ? '#EFC700' : type === 2 ? '#2FA763' : type === 3 ? '#E30558' : ''} />
          {filterByMonth ? <Bar barSize={15} yAxisId="right" dataKey="value2" fill={type === 1 ? '#F9EDB1' : type === 2 ? '#8DD6B2' : type === 3 ? '#F5BCD1' : ''} /> : ''}
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
