import { BaseController } from 'src/platform/classes/http-service';
import { IResponse } from 'src/platform/interfaces';
import { ICreateCampaign } from './campaign-interface';

class CampaignController extends BaseController {
  private controller = 'campaign';

  public addCampaign = async (form: ICreateCampaign): Promise<IResponse<any>> => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: ``,
      body: form
    });

    return result;
  };

  public getDetails = async (id: number): Promise<IResponse<any>> => {
    const result = await this.connection.GET({
      controller: 'campaign',
      action: `${id}`,
    });

    return result;
  };
  
  public deleteCampaign = async (id: number): Promise<IResponse<any>> => {
    const result = await this.connection.DELETE({
      controller: this.controller,
      action: `${id}`,
      body: null
    });

    return result;
  };

}
export default new CampaignController();