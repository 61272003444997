import * as React from 'react';
import { Switch, RouteComponentProps } from 'react-router-dom';

import { ROUTES } from 'src/platform/constants/routes';
import { byPrivateRoute, bySidebar } from 'src/platform/decorators/routes';
import HelperPureComponent from 'src/platform/classes/helper-pure-component';
import './index.scss';
import noPic from 'src/assets/images/no-photo.png';
import { IGetTransactionsListModelBody } from 'src/platform/api/transactions/transactions-interface';
import Table, { ITableColumnConfig } from 'src/platform/components/table/table';
import moment from 'moment';
import Paging from 'src/platform/components/pagination/pagination';
import TransactionsController from 'src/platform/api/transactions/transactions'
import Languages from 'src/platform/services/languages';

interface IProps extends RouteComponentProps {
}
interface IState {

  transactionList: Array<IGetTransactionsListModelBody>
  page: number,
  size: number,

  id: number;
  totalPages: number,
  totalElements: number,
  transactionNumber: number,
  buyerModelDto: {
    buyerId: number;
    buyerFullName: string;
    buyerImageUrl: string;
    buyerPhoneNumber: number;
    buyerAttachDate: Date;
  }
  price: number,
  transactionDate: Date,
  transactionTypeEnumValue: number,
  requestIsOff: boolean,
  pageForPagination: number,
  text: string,
  loaded: boolean,
  isFirstInit: boolean;
};

const {
  Agents_,
  Phone_number,
  Points_amount,
  Date_,
  Search,
  Transactions_,
} = Languages.Translations;

@bySidebar(`${Transactions_}`, "icon-ic_transactions")
@byPrivateRoute(ROUTES.transactions)
class Transactions extends HelperPureComponent<IProps, IState> {


  public state: IState = {
    transactionList: [],
    page: 0,
    size: 8,
    id: null,
    transactionNumber: null,
    buyerModelDto: {
      buyerId: null,
      buyerFullName: "",
      buyerImageUrl: "",
      buyerPhoneNumber: null,
      buyerAttachDate: null,
    },
    price: null,
    transactionDate: null,
    transactionTypeEnumValue: 2,
    totalPages: 2,
    totalElements: null,
    requestIsOff: false,
    pageForPagination: 0,
    text: "",
    loaded: false,
    isFirstInit: true,
  }

  componentDidMount() {
    this.getTransactionList();
  }

  downloadTransactions = async () => {
    const result = await TransactionsController.downloadTransactions();
    window.open(result.data, '_blank');
  }

  getTransactionList = async (pageNum?: { selected: number }) => {
    this.setState({ loaded: false });
    const { page, size, id, text, transactionNumber, buyerModelDto: { buyerId, buyerFullName, buyerImageUrl, buyerPhoneNumber, buyerAttachDate }, transactionTypeEnumValue, price, transactionDate } = this.state;

    const body: IGetTransactionsListModelBody = {
      id,
      transactionNumber,
      buyerModelDto: {
        buyerId,
        buyerFullName,
        buyerImageUrl,
        buyerPhoneNumber,
        buyerAttachDate
      },
      transactionTypeEnumValue,
      price,
      transactionDate,
    };

    window.dispatchEvent(new CustomEvent('loader', { detail: true }));
    const result = await TransactionsController.getTransactionsList({
      page: pageNum ? pageNum.selected : page,
      size,
      text,
      body: body
    });
    if (result.success) {
      this.setState({
        transactionList: result.data.content,
        totalPages: result.data.totalPages,
        totalElements: result.data.totalElements,
        isFirstInit: this.state.isFirstInit && !result.data.content.length,
        loaded: true,
        pageForPagination: pageNum ? pageNum.selected : page,
      });
    }
    window.dispatchEvent(new CustomEvent('loader', { detail: false }));
  }

  public inputTimer: any;
  public onSearch = async (evt: React.SyntheticEvent<HTMLInputElement>) => {

    await this.asyncSetState({ text: evt.currentTarget.value })
    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(() => {
      this.getTransactionList();
    }, 300);
  }

  public config: Array<ITableColumnConfig<IGetTransactionsListModelBody>> = [
    {
      name: `${Transactions_} №`,
      cell: (row: IGetTransactionsListModelBody) => {
        return (<div className="" >
          {row.transactionNumber}
        </div>);
      },
      style: { marginRight: "2%" },
    },
    {
      name: `${Agents_}`,
      cell: (row: IGetTransactionsListModelBody) => {
        return (
        <div className="G-cursor G-flex G-align-center" onClick={(evt: React.SyntheticEvent) => this.props.history.push(`/customers/agents/details?id=${row.buyerModelDto.buyerId}`)}>
          <span className="P-image G-mr-4 G-mr-1" style={{ background: `url(${row.buyerModelDto.buyerImageUrl ? `${row.buyerModelDto.buyerImageUrl}/100/100` : noPic}) center/cover no-repeat` }}></span>
          <span className="G-break-word">
            {row.buyerModelDto.buyerFullName}
          </span>
        </div>
        );
      },
      style: { marginRight: "2%" },
    },
    {
      name: `${Phone_number}`,
      cell: (row: IGetTransactionsListModelBody) => {
        return <div className="">{row.buyerModelDto.buyerPhoneNumber}</div>;
      },
      style: { marginRight: "2%" },
    },
    {
      name: `${Points_amount}`,
      cell: (row: IGetTransactionsListModelBody) => {
        return <div className="G-break-word">
          {row.transactionTypeEnumValue === 1 ? "-" : "+"}{row.price.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
        </div>;
      },
      style: { marginRight: "2%" },
    },
    {
      name: `${Date_}`,
      cell: (row: IGetTransactionsListModelBody) => {
        return <div className="">{moment(row.transactionDate).format('DD MMM YYYY HH:mm')}</div>;
      },
      style: { marginRight: "2%" },
    },
  ]

  public render() {
    const { transactionList, totalPages, totalElements, text, isFirstInit, loaded } = this.state;

    return (
      <section className="G-main-page P-transactions-page">
        <Switch>

        </Switch>

        <div className="G-flex G-justify-between">
          <h4>{Transactions_} ({totalElements})</h4>
          <button className="G-gray-btn" onClick={this.downloadTransactions}>Export</button>
        </div>

        <input className="G-text-input G-mt-2 G-mb-2"
          placeholder={Search}
          type="text"
          value={text}
          onChange={this.onSearch}
        />

        <Table
          columnConfig={this.config}
          data={transactionList}
          isFirstInit={isFirstInit}
          loaded={loaded}
        />
        <Paging
          totalPages={totalPages}
          onChange={this.getTransactionList}
        />

      </section >
    );
  }
}

export default Transactions;