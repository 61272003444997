import * as React from 'react';
import DoubleDropDown from 'src/platform/components/double-drop-down/DoubleDropDown';
import MultiSelect from 'src/platform/components/multi-select/MultiSelect';
import { AccountTypeEnum } from 'src/platform/enums/enums';
import { accountTypeOptions, OrderSellerSearchStatusOptions } from 'src/platform/constants/casual';
import { ISellersOrdersListResponseModel } from 'src/platform/api/order/order-interface';
import HelperComponent from 'src/platform/classes/helper-component';
import { RouteComponentProps, Route, Switch } from 'react-router';
import SellersOrdersDetails from './details/SellersOrdersDetails';
import SellersOrders from './SellersOrders';
import OrderController from 'src/platform/api/order/order';
import Select from 'src/platform/components/select/Select';
import OrdersMap from './orders-map/OrdersMap';
import { NavLink, Link } from 'react-router-dom';
import { ROUTES } from 'src/platform/constants/routes';
import { IDoubleDropDownOption } from 'src/platform/interfaces';
import DatePicker from "react-datepicker";
import './index.scss';
import Languages from 'src/platform/services/languages';
import Helper from 'src/platform/services/helper';
import { store } from 'react-notifications-component';


interface IState {
  sellersOrdersList: Array<ISellersOrdersListResponseModel>,
  sellersOrdersMapList: Array<any>,
  requestIsOff: boolean,
  page: number,
  size: number,
  pageForPagination: number,
  totalPages: number,
  totalElements: number,
  loaded: boolean;
  isFirstInit: boolean,
  viewType: number;

  addressName: string,
  nameBuyer: string,
  nameSellerOrOrderNumber: string,
  statusList: Array<number>,
  accountTypeEnumValue: AccountTypeEnum,
  searchCriteriaWeight: IDoubleDropDownOption,
  createdDate: Date,
}

const {
  Sellers_orders,
  Reset,
  Search_name_or,
  Agent_name,
  Address,
  With_weight,
  Details_,
  Account_type,
  Creation_date_,
} = Languages.Translations

class SellersContainer extends HelperComponent<RouteComponentProps, IState> {

  public state: IState = {
    sellersOrdersList: [],
    sellersOrdersMapList: [],
    page: 0,
    size: 8,
    requestIsOff: false,
    totalPages: null,
    totalElements: null,
    loaded: false,
    viewType: 1,
    isFirstInit: true,
    pageForPagination: 0,

    accountTypeEnumValue: null,
    nameSellerOrOrderNumber: "",
    statusList: [],
    addressName: "",
    nameBuyer: "",
    createdDate: null,
    searchCriteriaWeight: {
      operationEnumValue: null,
      value: null,
    },
  }

  componentDidMount() {
    const last = window.location.pathname.split('/').pop();
    if (last === "map") {
      this.asyncSetState({ viewType: 2 }).then(() => {
        this.getList();
      })
    } else {
      this.getList();
    }
  }

  public inputTimer: any;
  private getList = async (pageNum?: { selected: number }) => {

    const { page,
      size,
      statusList,
      accountTypeEnumValue,
      nameSellerOrOrderNumber,
      nameBuyer,
      viewType,
      searchCriteriaWeight,
      createdDate,
      addressName,
    } = this.state;

    let body: {
      operationEnumValue: number,
      value: number,
    };

    if (searchCriteriaWeight.value) {
      body = {
        operationEnumValue: Number(searchCriteriaWeight.operationEnumValue),
        value: Number(searchCriteriaWeight.value),
      }
    } else body = null;

    if (viewType === 1) {
      clearTimeout(this.inputTimer);
      this.inputTimer = setTimeout(async () => {
        window.dispatchEvent(new CustomEvent('loader', { detail: true }));

        const result = await OrderController.getSellersOrdersList({
          page: pageNum ?
            pageNum.selected : page,
          size,
          body: { statusList, nameBuyer, nameSellerOrOrderNumber, accountTypeEnumValue, searchCriteriaWeight: body, addressName, createdDate }
        });

        window.dispatchEvent(new CustomEvent('loader', { detail: false }));
        if (result.success) {
          this.asyncSetState({
            sellersOrdersList: result.data.content,
            totalPages: result.data.totalPages,
            totalElements: result.data.totalElements,
            pageForPagination: pageNum ? pageNum.selected : page,
            loaded: true,
            isFirstInit: this.state.isFirstInit && !result.data.content.length
          });
        }
        
      }, 300);
    } else {

      clearTimeout(this.inputTimer);
      this.inputTimer = setTimeout(async () => {
        window.dispatchEvent(new CustomEvent('loader', { detail: true }));
        const result = await OrderController.getSellersOrdersMapList({
          statusList, nameBuyer, nameSellerOrOrderNumber, accountTypeEnumValue, searchCriteriaWeight: body, addressName, createdDate
        });

        window.dispatchEvent(new CustomEvent('loader', { detail: false }));
        if (result.success) {
          this.asyncSetState({
            sellersOrdersMapList: result.data,
            loaded: true,
            isFirstInit: this.state.isFirstInit && !result.data.length
          });

        }
        else{
          store.addNotification(Helper.notificationConfig('Something went wrong', false));
        }
        
      }, 300);
    }
  };

  loadMore = async () => {
    const { requestIsOff, sellersOrdersList, page, size, totalPages } = this.state;

    if (!requestIsOff && page < totalPages) {
      this.asyncSetState({ requestIsOff: true })
      const res = await OrderController.getSellersOrdersList({ page: this.state.page + 1, size });
      if (res && res.success) {
        this.asyncSetState({
          sellersOrdersList: [...sellersOrdersList, ...res.data.content],
          page: this.state.page + 1,
          totalPages: res.data.totalPages,
        });
        this.asyncSetState({ requestIsOff: false });
      }else{
        // store.addNotification(Helper.notificationConfig(res.message, false));
      }
    }
  }

  public reset = () => {
    this.asyncSetState({
      page: 0,
      accountTypeEnumValue: null,
      nameSellerOrOrderNumber: "",
      statusList: [],
      addressName: "",
      nameBuyer: "",
      totalPages: 0,
      pageForPagination: 0,
      pageNum: 0,
      searchCriteriaWeight: {
        operationEnumValue: null,
        value: "",
      },
      createdDate: null,
    }).then(() => {
      this.getList();
    });
  }

  public onInput = async (name: string, evt?: React.SyntheticEvent<HTMLInputElement>) => {

    if (name === "addressName") {
      await this.asyncSetState({ addressName: evt.currentTarget.value })
    } else if (name === "nameSellerOrOrderNumber") {
      await this.asyncSetState({ nameSellerOrOrderNumber: evt.currentTarget.value })
    } else if (name === "nameBuyer") {
      await this.asyncSetState({ nameBuyer: evt.currentTarget.value })
    }
    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(async () => {
      this.getList();
    }, 300);
  }

  public onMultiSelect = (arr: Array<string>) => {
    this.asyncSetState({ statusList: arr }).then(() => {
      this.getList();
    });;
  }

  public onSelect = (field: string, value: number) => {
    this.asyncSetState({ accountTypeEnumValue: value }).then(() => {
      this.getList();
    });;
  }

  public setOperatorValue = (key: string, value: any) => {
    this.state[key].operationEnumValue = value;
    this.asyncSetState({ [key]: this.state[key] }).then(() => {
      this.getList();
    });
  }

  public setDoubleDropdownInputValue = async (key: string, value: any) => {
    this.state[key].value = value;

    this.asyncSetState({ key: this.state[key] })
      .then(() => {
        this.getList();
      });
  }
  
  download = async () => {
    const { 
      accountTypeEnumValue,
      addressName,
      nameSellerOrOrderNumber,
      searchCriteriaWeight,
      statusList,
      createdDate,
      nameBuyer,
    } = this.state;
    let searchCriteriaPrice: {
      operationEnumValue: number,
      value: number,
    };
    let weight;
    if (searchCriteriaWeight.value) {
      weight = {
        operationEnumValue: Number(searchCriteriaWeight.operationEnumValue),
        value: Number(searchCriteriaWeight.value),
      }
    } else weight = null;
    const body = {
      accountTypeEnumValue,
      nameBuyer,
      addressName,
      nameSellerOrOrderNumber,
      statusList,
      createdDate,
      searchCriteriaWeight: weight,
      searchCriteriaPrice,
    }
    const result = await OrderController.downloadSellerOrder(body);
    if (result.data) {
      window.open(result.data, '_blank');
    }
  }

  public render() {
    const { viewType,
      statusList,
      accountTypeEnumValue,
      nameSellerOrOrderNumber,
      loaded,
      isFirstInit,
      totalPages,
      addressName,
      nameBuyer,
      sellersOrdersList,
      searchCriteriaWeight,
      sellersOrdersMapList,
      createdDate,
      totalElements
    } = this.state;

    return (
      <section className="P-sellers-orders-page">
        <div className="G-flex G-align-center">
          <div className="G-flex G-mb-2 G-justify-between G-align-center">
            <Link to={`${ROUTES.orders}/sellers`}>
              <h4 className="G-mr-1"> {Sellers_orders} ({totalElements})</h4>
            </Link>
            {window.location.pathname.split('/').pop() === "details" ? <>
              <h4>/</h4>
              <h4 className="G-ml-1 P-crumb"> {Details_}</h4>
            </> : ""}
          </div>
          <div className='G-ml-auto'>
            <NavLink to={"/orders/sellers"} exact={true} className="P-icon" activeClassName="P-icon-active">
              <i className={`icon-ic_list G-mr-2`}
                onClick={() => this.asyncSetState({ viewType: 1 }).then(() => {
                  this.getList();
                })} />
            </NavLink>
            <NavLink to={"/orders/sellers/map"} exact={true} className="P-icon" activeClassName="P-icon-active">
              <i className={`icon-ic_map G-mr-2`}
                onClick={() => this.asyncSetState({ viewType: 2 }).then(() => {
                  this.getList();
                })} />
            </NavLink>
          </div>
          <button className="G-gray-btn" onClick={this.download}>Export</button>
        </div>
        <div className="G-flex G-flex-wrap P-selects-wrapper">
          <input className="G-text-input G-mr-1"
            placeholder={Search_name_or}
            type="text"
            value={nameSellerOrOrderNumber}
            onChange={(event) => this.onInput("nameSellerOrOrderNumber", event)}
          />
          <div className="G-mr-1">
            <DoubleDropDown placeholder={With_weight}
              property="searchCriteriaWeight"
              operatorValue={searchCriteriaWeight.operationEnumValue}
              inputValue={searchCriteriaWeight.value}
              setOperatorValue={this.setOperatorValue}
              setDoubleDropdownInputValue={this.setDoubleDropdownInputValue}
            />
          </div>
          <input className="G-text-input G-mr-1"
            placeholder={Address}
            type="text"
            value={addressName}
            onChange={(event) => this.onInput("addressName", event)}
          />
          <input className="G-text-input G-mr-1"
            placeholder={Agent_name}
            type="text"
            value={nameBuyer}
            onChange={(event) => this.onInput("nameBuyer", event)}
          />
          <div className="G-mr-1">
            <Select
              height="45px"
              width="220px"
              placeholder={Account_type}
              options={accountTypeOptions}
              value={accountTypeEnumValue}
              setFieldValue={this.onSelect}
            />
          </div>
          <div className="G-mr-1 P-date-picker">
            <label>
              <DatePicker
                className="G-text-input G-cursor"
                selected={createdDate}
                placeholderText={Creation_date_}
                onChange={(date: Date) => {
                  if(date){
                    this.asyncSetState({ createdDate: date.getTime() }).then(() => {
                      this.getList();
                    })
                  }
                }}
              />
              <i className="icon-ic_arrowdown P-icon-ic_arrowdown"></i>
            </label>
          </div>
          <div className="G-mr-1">
            <MultiSelect
              name="status"
              height="45px"
              width="220px"
              setFieldValue={this.onMultiSelect}
              value={statusList}
              options={OrderSellerSearchStatusOptions} />
          </div>
          <span className=" G-button P-button" onClick={this.reset}>{Reset}</span>
        </div>
        <Switch>
          <Route path="/orders/sellers"
            render={(props) => <SellersOrders
              {...props}
              sellersOrdersList={sellersOrdersList}
              getSellersOrdersList={this.getList}
              loaded={loaded}
              pageForPagination={this.state.pageForPagination}
              isFirstInit={isFirstInit}
              totalPages={totalPages}
              viewType={viewType} />}
            exact={true}
          />
          <Route path="/orders/sellers/details"
            render={(props) => <SellersOrdersDetails
              sellersOrdersList={sellersOrdersList}
              getSellersOrdersList={this.getList}
              loaded={loaded}
              isFirstInit={isFirstInit}
              totalPages={totalPages}
              loadMore={this.loadMore}
              {...props} />}
            exact={true} />
          <Route path="/orders/sellers/map"
            render={(props) => <OrdersMap
              sellersOrdersMapList={sellersOrdersMapList}
            />} />
        </Switch>
      </section >
    );
  }
}

export default SellersContainer;