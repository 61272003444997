import { BaseController } from "src/platform/classes/http-service";
import { IGetOrganizationsListModel, IGetOrganizationsListRequestBody, IGreateProject, IGetPreviusListModel, IGetDonatListModel } from './donations-interface';
import { IResponse } from 'src/platform/interfaces';
import { ICreateCampaign } from '../campaign/campaign-interface';

class DonationsController extends BaseController {

  private controller = 'donationProject';

  public getProjectList = async (form: IGetOrganizationsListModel): Promise<IResponse<any>> => {
    const result = await this.connection.POST<IGetOrganizationsListRequestBody>({
      controller: this.controller,
      action: `getAllProjectsForCampaign/${form.page}/${form.size}/?languageName=${form.languageName}`,
      body: form.body,
    });

    return result;
  }

  public deleteOrganization = async (id: number) => {
    const result = await this.connection.DELETE({
      controller: this.controller,
      action: `/${id}`,
      body: null
    });

    return result;
  }

  public openOrCloseProject = async (projectId: number): Promise<IResponse<any>> => {
    const result = await this.connection.PUT({
      controller: this.controller,
      action: `openOrCloseProject/${projectId}`,
      body: null,
      noneJSONBody: false
    });

    return result;
  };

  
  public openDetails = async (id: number): Promise<IResponse<any>> => {
    const result = await this.connection.GET({
      controller: this.controller,
      action: `${id}`,
    });

    return result;
  };

  public editDetails = async (id: number, form:IGreateProject): Promise<IResponse<any>> => {
    const result = await this.connection.PUT({
      controller: this.controller,
      action: `${id}`,
      body: form
    });

    return result;
  };

  public sendProject = async (form: IGreateProject): Promise<IResponse<any>> => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: ``,
      body: form,
    });

    return result;
  };

  public uploadImage = async (formData: FormData, projectId: number): Promise<IResponse<any>> => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: `uploadImage/?projectId=${projectId}`,
      noneJSONBody: true,
      body: formData,
    });

    return result;
  };

  public deleteImageFromProject = async (id: number, fileName: string) => {
    const result = await this.connection.DELETE({
      controller: this.controller,
      action: `deleteImageFromProject/?projectId=${id}&fileName=${fileName}`,
      body: null
    });

    return result;
  }
  

  public saveCampaign = async (form: ICreateCampaign): Promise<IResponse<any>> => {
    const result = await this.connection.POST({
      controller: 'campaign',
      action: ``,
      body: form
    });

    return result;
  };
  
  public editCampaign = async (form: ICreateCampaign, id: number): Promise<IResponse<any>> => {
    const result = await this.connection.PUT({
      controller: 'campaign',
      action: `${id}`,
      body: form
    });

    return result;
  };

  public getCurrectCampaign = async (): Promise<IResponse<any>> => {
    const result = await this.connection.GET({
      controller: 'campaign',
      action: `getCurrentCampaign`,
    });

    return result;
  };

  public downloadCampaign = async (): Promise<IResponse<any>> => {
    const result = await this.connection.POST({
      controller: 'campaign',
      action: `download`,
      body: null,
    });
    return result;
  }

  public getDetails = async (id: number): Promise<IResponse<any>> => {
    const result = await this.connection.GET({
      controller: 'campaign',
      action: `${id}`,
    });

    return result;
  };

  public getPreviousList = async (form: IGetPreviusListModel): Promise<IResponse<any>> => {
    const result = await this.connection.GET({
      controller: 'campaign',
      action: `${form.page}/${form.size}`,
    });

    return result;
  };

  public getListBySellerId = async (form: IGetDonatListModel): Promise<IResponse<any>> => {
    const result = await this.connection.GET({
      controller: this.controller,
      action: `getListBySellerId/${form.page}/${form.size}?sellerId=${form.sellerId}`,
    });

    return result;
  };
  
  public downloadProjectForCampaign = async (form: any): Promise<IResponse<any>> => {
    const result = await this.connection.POST({
      controller: this.controller,
      action: `downloadProjectForCampaign`,
      body: form
    });

    return result;
  };
  
}

export default new DonationsController();