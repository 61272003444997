// import {  } from "./settings-interface";
import { BaseController } from "../../classes/http-service";
import { IResponse } from 'src/platform/interfaces';

class SettingsController extends BaseController {
  private controller = 'settings';

  public getSettings = async (): Promise<IResponse<any>> => {

    const result = await this.connection.GET({
      controller: this.controller,
      action: `getSettings`,
    });

    return result;
  }

  public getEcoStatusIndividual = async (): Promise<IResponse<any>> => {

    const result = await this.connection.GET({
      controller: this.controller,
      action: `getEcoStatusIndividual`,
    });

    return result;
  }

  public getEcoStatusCorporate = async (): Promise<IResponse<any>> => {

    const result = await this.connection.GET({
      controller: this.controller,
      action: `getEcoStatusCorporate`,
    });

    return result;
  }

  public editSettings = async (body: IEditSettings): Promise<IResponse<any>> => {
    const result = await this.connection.PUT({      
      action: 'editSettings',
      controller: this.controller,
      body: body,
      noneJSONBody: false
      });
    return result;
  }

  public editEcoStatusIndividual = async (form: {statusDtos: Array<IEditEcoStatusIndividual>}): Promise<IResponse<any>> => {
    const result = await this.connection.PUT({      
      action: 'editEcoStatusIndividual',
      controller: this.controller,
      body: form,
      noneJSONBody: false
      });
    return result;
  }

  public editEcoStatusCorporate = async (form: {statusDtos: Array<IEditEcoStatusCorporate>}): Promise<IResponse<any>> => {
    const result = await this.connection.PUT({      
      action: 'editEcoStatusCorporate',
      controller: this.controller,
      body: form,
      noneJSONBody: false
      });
    return result;
  }

}
export default new SettingsController();