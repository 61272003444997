import * as React from 'react';
import Helper from 'src/platform/services/helper';
import noPic from 'src/assets/images/no-photo.png'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { OrderSellerStatusEnum, OrderType, OrderAgentStatusEnum, AccountTypeEnum } from 'src/platform/enums/enums';
import HelperComponent from 'src/platform/classes/helper-component';
import OrderController from 'src/platform/api/order/order';
import moment from 'moment';
import { store } from 'react-notifications-component';
import './index.scss';
import { OrderTypeOptions } from 'src/platform/constants/casual';
import Languages from 'src/platform/services/languages';

export interface IProps extends RouteComponentProps {
    activeId: number;
    orderType: number;
    closeModal: () => void;
}

export interface IState {
    details: any,
}

const {
    Order_,
    Order_information,
    Seller_,
    Agent_,
    view_details,
    Cardboard_apx_weight,
    Cardboard_actual_weight,
    Cardboard_price_,
    Agent_attached_date,
    Order_start_date,
    Order_finish_date,
    Agent_rate,
    Seller_rate,
    Order_type,
    Close,
    enums,
    Any_time,
    kg,
    AMD,
    Use_for_donation,
    Earn_money,
} = Languages.Translations

class HistoryOrderDetailRightPart extends HelperComponent<IProps, IState> {

    public state: IState = {
        details: {},
    }

    componentDidMount() {
        this.getOrderDetails(this.props.activeId);

    }

    componentDidUpdate(prevProps: IProps) {
        if (this.props.activeId !== prevProps.activeId) {
            this.getOrderDetails(this.props.activeId);
        }
    }

    private getOrderDetails = async (id: string | number) => {

        if (this.props.orderType === OrderType.Delivery) {
            window.dispatchEvent(new CustomEvent('loader', { detail: true }));
            const result = await OrderController.getAgentsDeliveryOrderDetails(this.props.activeId);
            window.dispatchEvent(new CustomEvent('loader', { detail: false }));
            if (result.success) {
                this.asyncSetState({
                    details: result.data,
                });
            } else {
                store.addNotification(Helper.notificationConfig(result.message, false));
            }
        } else if (this.props.orderType === OrderType.SelfDelivery) {
            window.dispatchEvent(new CustomEvent('loader', { detail: true }));
            const result = await OrderController.getAgentsSelfDeliveryOrderDetails(this.props.activeId);
            window.dispatchEvent(new CustomEvent('loader', { detail: false }));
            if (result.success) {
                this.asyncSetState({
                    details: result.data,
                });
            }
        } else {
            window.dispatchEvent(new CustomEvent('loader', { detail: true }));
            const result = await OrderController.getSellersOrderDetails(this.props.activeId);
            window.dispatchEvent(new CustomEvent('loader', { detail: false }));
            if (result.success) {
                this.asyncSetState({
                    details: result.data,
                });
            } else {
                store.addNotification(Helper.notificationConfig(result.message, false));
            }
        }
    };

    render() {
        const { details } = this.state;
        const { orderType } = this.props;

        return (
            <div className="P-history-order-details">
                <div className="P-top G-flex G-align-center G-pl-2 G-pr-2 P-arrow">
                    <div className="G-flex G-justify-between P-title">
                        <span>{Order_information}</span>
                        <span className="P-order-num">{Order_} #{details.orderNumber}</span>
                        {details.orderSellerStatusEnumValue
                            ? <span className=""
                                style={{
                                    color: `${details.orderSellerStatusEnumValue === OrderSellerStatusEnum.CANCELED
                                            ? '#FF0057' : "#04A556"}`
                                }}>
                                {enums.OrderSellerStatusEnum[details.orderSellerStatusEnumValue]}
                            </span> : null}
                        {details.statusEnumValue ? <span className="P-status" style={{
                            color: `${details.statusEnumValue === OrderAgentStatusEnum.CANCELED ||
                                    details.statusEnumValue === OrderAgentStatusEnum.REJECTED
                                    ? '#FF0057' : "#04A556"}`
                        }}>
                            {enums.OrderAgentStatusEnum[details.statusEnumValue]}
                        </span> : null}
                        {details.orderBuyerStatusEnumValue ? <span className="P-status" style={{
                            color: `${details.orderBuyerStatusEnumValue === OrderAgentStatusEnum.CANCELED ||
                                    details.orderBuyerStatusEnumValue === OrderAgentStatusEnum.REJECTED
                                    ? '#FF0057' : "#04A556"}`
                        }}>
                            {enums.OrderAgentStatusEnum[details.orderBuyerStatusEnumValue]}
                        </span> : null}
                    </div>
                </div>
                <div className="G-flex P-sub-right">
                    <div className="P-information">
                        {details.reason ?
                            <div className="P-reason G-mt-3"><i className="icon-cancel"></i><p>{details.reason}</p></div>
                            : ""}
                        <div className="P-row P-row-one G-flex">
                            <div>
                                <div className="G-mb-1 P-seller">{orderType === OrderType.Sellers ? `${Seller_}` : `${Agent_}`}</div>
                                <div className="G-flex G-align-center">
                                    <div className="G-flex P-image"
                                        style={{ background: `url(${details.agentPreviewDto && details.agentPreviewDto.buyerImageUrl ? `${details.agentPreviewDto.buyerImageUrl}/100/100` : details.sellerPreviewDto && details.sellerPreviewDto.sellerImageUrl ? details.sellerPreviewDto.sellerImageUrl : noPic}) center/cover no-repeat` }}>
                                    </div>
                                    <div>
                                        <div>{details.agentPreviewDto ? details.agentPreviewDto.buyerFullName : ""}</div>
                                        <div>{details.sellerPreviewDto ? details.sellerPreviewDto.sellerFullName : ""}</div>
                                        <div>{AccountTypeEnum[details.accountTypeEnumValue]}</div>
                                        {details.agentPreviewDto && !Helper.role ?
                                            <Link to={`/customers/agents/details?id=${details.agentPreviewDto.buyerId}`}
                                                className="P-green G-cursor">{view_details}
                                            </Link>
                                            : null}
                                        {details.sellerPreviewDto && !Helper.role ?
                                            <Link to={`/customers/sellers/details?id=${details.sellerPreviewDto.sellerId}`}
                                                className="P-green G-cursor">{view_details}
                                            </Link>
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="P-row P-row-two">
                            {details ?
                                <div className="G-flex G-align-center">
                                    <p>{details.isDonate ? Use_for_donation : Earn_money}</p>
                                </div>
                            : ''}
                            {orderType !== OrderType.Sellers && <div className="G-flex G-align-center">
                                <p className="G-mr-1">{Order_type} </p>
                                <p >
                                    {OrderTypeOptions[orderType - 1].label}
                                </p>
                            </div>}
                            <div className="G-flex G-align-center">
                                <p>{Order_start_date} </p>
                                <p>
                                    {moment(details.createdDate).format('DD MMM YYYY HH:mm')}
                                </p>
                            </div>

                            {OrderType.Sellers === orderType ?
                                <div className="G-flex">
                                    <p> {Agent_attached_date} </p>
                                    {details.buyerRequestedDate ? <p>
                                        {moment(details.buyerRequestedDate).format('DD MMM YYYY HH:mm')}
                                    </p> : <p className="G-mr-6">-</p>}
                                </div>
                                : ""}

                            <div className="G-flex">
                                <p>{Order_finish_date} </p>
                                {details.lastModifiedDate || details.orderFinishDate ? <p>
                                    {moment(details.lastModifiedDate ? details.lastModifiedDate : details.orderFinishDate).format('DD MMM YYYY HH:mm')}
                                </p> : <p className="G-mr-6">-</p>}
                            </div>
                        </div>

                        <div className="P-row P-row-three">
                            {orderType === OrderType.Sellers
                                && <div className="G-mb-1 G-flex">
                                    <i className="icon-ic_map G-mr-2" />
                                    <div>
                                        {details && details.addressForOrderDto &&
                                            `${details.addressForOrderDto.addressName},
                                             ${details.addressForOrderDto.apartment ? details.addressForOrderDto.apartment + ' app,' : ''}
                                             ${details.addressForOrderDto.building ? details.addressForOrderDto.building + ' building,' : ''} 
                                             ${details.addressForOrderDto.entrance ? details.addressForOrderDto.entrance + ' entrance,' : ''} 
                                             ${details.addressForOrderDto.floor ? details.addressForOrderDto.floor + ' floor' : ''}`
                                        }
                                    </div>
                                </div>}

                            {orderType === OrderType.SelfDelivery
                                && <div className="G-mb-1 G-flex">
                                    <i className="icon-ic_map G-mr-2" />
                                    <div>{details && details.deliverAddressForUser && details.deliverAddressForUser.addressName}</div>
                                </div>}

                            {orderType === OrderType.Delivery
                                && <div className="G-mb-1 G-flex">
                                    <i className="icon-ic_map G-mr-2" />
                                    <div>{details && details.addressForOrderDto && details.addressForOrderDto.addressName}</div>
                                </div>}

                            {orderType !== OrderType.SelfDelivery && !!details.orderTimes && !!details.orderTimes.length ?
                                <div className="G-flex G-align-center G-mb-1">
                                    <i className="icon-clock-5 G-mr-2" />
                                    {details && details.orderTimes && details.orderTimes.map((item: any, index: any) => {
                                        let from = item.fromTime;
                                        let to = item.toTime;
                                        return (<div key={index} className="G-mr-1">
                                            {item.fromTime !== 0 ? <>
                                                {moment(new Date((from * 1000) - 14400000)).format('HH:mm')} -
                                                {moment(new Date((to * 1000) - 14400000)).format('HH:mm')}</> : `${Any_time}`}
                                        </div>)
                                    })}
                                </div>
                                :
                                //     <div className="G-flex G-align-center">
                                //     <i className="icon-clock-5 G-mr-2" />
                                //     {details && details.deliverAddressForUser && details.deliverAddressForUser.workingDateTimeDtos.slice(0, 1).map((item: any, index: any) => {
                                //         let from = item.fromTime;
                                //         let to = item.toTime;
                                //         return (<div key={index} className="G-mr-1">
                                //             {moment(new Date((from * 1000) - 14400000)).format('HH:mm')} - {moment(new Date((to * 1000) - 14400000)).format('HH:mm')}
                                //         </div>)
                                //     })}
                                // </div>
                                ""}
                            {!!details.comment && <div className="G-flex G-align-center">
                                <i className="icon-ic_comment G-mr-2" />
                                {details.comment ? details.comment : "-"}
                            </div>}
                        </div>
                        <div className="P-row P-row-four">
                            <div>
                                <span>{Cardboard_apx_weight}</span>
                                <span className="G-bold P-forSale">{details.weightForSale ? `${details.weightForSale} ${kg}` : <p className="G-mr-2">-</p>}</span>
                            </div>
                            <div>
                                <span>{Cardboard_actual_weight}</span>
                                {details.finalWeight ? <span className="G-bold P-weight-final">{details.finalWeight} {kg}</span> : "-"}
                            </div>
                            <div>
                                <span>{Cardboard_price_}</span>
                                {details.price ? <span className="G-bold P-weight-final">{details.price} {AMD}</span> : "-"}
                            </div>
                        </div>
                        {details.sellerRate || details.sellerFeedback ? <div className="G-mt-1 G-bold">{Seller_rate}</div> : ""}
                        <div className="G-mt-1 P-details-feedback">
                            {details.sellerRate ?
                                <div>{Array.from({ length: 5 }).map(() => <i className="G-mr-1 icon-ic_star P-grey-star"></i>)}</div> : ""}
                            <div className="P-details-star">{Array.from({ length: details.sellerRate }).map(() => <i className="G-mr-1 icon-ic_star P-yellow-star"></i>)}</div>
                            <p className="G-mt-1 G-mb-1 G-break-word">{details.sellerFeedback}</p>
                        </div>
                        {details.agentRate || details.agentFeedback ? <div className="G-mt-1 G-bold">{Agent_rate}</div> : ""}
                        <div className="G-mt-1 P-details-feedback">
                            {details.agentRate ?
                                <div>{Array.from({ length: 5 }).map(() => <i className="G-mr-1 icon-ic_star P-grey-star"></i>)}</div> : ""}
                            <div className="P-details-star">{Array.from({ length: details.agentRate }).map(() => <i className="G-mr-1 icon-ic_star P-yellow-star"></i>)}</div>
                            <p className="G-mt-1 G-mb-1 G-break-word">{details.agentFeedback}</p>
                        </div>
                        <button
                            type="button"
                            className="G-button P-button G-ml-auto G-mt-5 G-mb-3"
                            onClick={this.props.closeModal}
                        > {Close}
                        </button>
                    </div>
                </div>
            </div>);
    }

}

export default withRouter(HistoryOrderDetailRightPart);