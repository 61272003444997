import * as React from 'react';
import { ListStatusEnum } from 'src/platform/enums/enums';
import './index.scss';
import Languages from 'src/platform/services/languages';

export interface BlockModalProps {
    closeModal: () => void;
    blockUser: (id: string) => void;
    status: ListStatusEnum;
}

export interface BlockModalState {

}

const {
    Block_user,
    Unblock_user,
    Block,
    Unblock,
    Are_you_sure_block,
    Are_you_sure_unblock,
    Cancel,
} = Languages.Translations

class BlockModal extends React.Component<BlockModalProps, BlockModalState> {
    render() {
        const { status } = this.props;
        
        return (<div className="P-sign-out-modal P-block-modal">
            <h5 className="G-mb-2 P-title">{`${status === 2? `${Unblock_user}` : `${Block_user}`}`}</h5>
            <p className="G-mb-3 P-text">{ListStatusEnum.Active === status ? Are_you_sure_block : Are_you_sure_unblock}
                {/* {ListStatusEnum.Active === status ? "block"
                : ListStatusEnum.Blocked === status ? "unblock" : null} this user? */}
            </p>
            <div className="G-flex">
                <button className="G-mr-2 P-cancel" onClick={this.props.closeModal}>{Cancel}</button>
                <button className="G-button P-button" onClick={() => {
                    const URL = new URLSearchParams(window.location.search);
                    this.props.blockUser(URL.get('id'));
                }}>
                    {ListStatusEnum.Active === status ? `${Block}`
                    : ListStatusEnum.Blocked === status ? `${Unblock}` : null}
                </button>
            </div>
        </div>);
    }
}

export default BlockModal;